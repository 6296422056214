import React, { Component } from 'react';
import { connect } from 'react-redux';
import M from 'materialize-css';
import ExaminationActions from 'pgdb-data-layer/lib/Redux/ExaminationRedux';
import PropTypes from 'prop-types';
import TextInput from '../../../Components/TextInput/index';
import AddressInput from '../../../Components/AddressInput/index';
import Validator from '../../../Utilities/Validator';
import AddressFinder from '../../../Utilities/AddressFinder';
import Callout from '../../../Components/Callout';
import Navigator from '../../../Navigation/Navigator';
import { getToken } from '../../../Session/SessionRedux';

class ExamDeliveryAddress extends Component {
   constructor(props) {
      super(props);

      this.lastSavedState = {
         examDeliveryEmail: '',
         examDeliveryAddress: '',
         allFieldsValid: false,
         formValidity: {
            examDeliveryEmail: true,
            examDeliveryAddress: true,
         },
         isVerified: false,
         dataLoaded: false,
         isSubmitted: false,
      };

      this.state = Object.assign({}, this.lastSavedState);
   }

   componentDidMount() {
      const { examDelivery, token, getExaminationResultAddress } = this.props;

      if (!Validator.isNullOrWhiteSpace(examDelivery.examDeliveryEmail)) {
         this.populateToState();
      } else {
         getExaminationResultAddress(token);
         this.setState({ dataLoaded: true });
      }
   }

   next = () => {
      const { examDeliveryEmail, examDeliveryAddress } = this.state;
      const { saveExamDeliveryDetails } = this.props;
      const validity = this.validateFormFields();
      M.Toast.dismissAll();

      if (!validity.allFieldsValid) {
         M.toast({
            html: 'Please correctly fill in all mandatory fields',
            classes: 'error',
         });
      } else {
         const examDeliveryData = {
            examDeliveryEmail,
            examDeliveryAddress,
         };

         saveExamDeliveryDetails(examDeliveryData);

         this.setState({ isSubmitted: true });
         Navigator.to('/exam-payment-summary');
      }
   };

   back = () => {
      Navigator.toBackward();
   };

   validateFormFields() {
      const { examDeliveryEmail, examDeliveryAddress } = this.state;

      const validity = {
         examDeliveryEmail:
            Validator.isValidEmail(examDeliveryEmail) &&
            Validator.isValidLength(examDeliveryEmail, 100),
         examDeliveryAddress: Validator.isValidAddress(examDeliveryAddress),
      };

      const allFieldsValid = Object.values(validity).every(v => !!v);
      this.setState({ formValidity: validity, allFieldsValid });
      return { formValidity: validity, allFieldsValid };
   }

   populateToState() {
      const { examDelivery } = this.props;
      const newState = {};
      newState.examDeliveryEmail = examDelivery.examDeliveryEmail;
      newState.examDeliveryAddress = examDelivery.examDeliveryAddress;
      newState.examDelivery = AddressFinder.getObject(
         examDelivery.businessBillingAddressAddressLine1,
         examDelivery.businessBillingAddressAddressLine2,
         examDelivery.businessBillingAddressSuburbTown,
         examDelivery.businessBillingAddressCity,
         examDelivery.businessBillingAddressPostalCode,
         examDelivery.businessBillingAddressCountry,
         examDelivery.businessBillingAddressCountryCode
      );

      newState.allFieldsValid = true;
      newState.formValidity = {
         examDeliveryEmail: true,
         examDeliveryAddress: true,
         contactName: true,
         phoneNumber: true,
         businessPostalAddress: true,
         businessPhysicalAddress: true,
      };
      newState.dataLoaded = true;

      this.lastSavedState = Object.assign({}, this.state, newState);
      this.setState(newState);
   }

   render() {
      const {
         dataLoaded,
         examDeliveryEmail,
         examDeliveryAddress,
         formValidity,
      } = this.state;

      return (
         <main>
            <div className="section no-pad-bot" id="index-banner">
               <div className="container company-contact-details">
                  <div className="row">
                     <div className="col s12 m12 l6 offset-l1">
                        <div className="col s12 m12 l12">
                           <p className="columnheader">My Exams</p>
                        </div>
                        <div className="col s12 m12 l12">
                           <span className="grey2-text sub-text">
                              Return all my Certifier exam papers and results by
                              courier to:
                           </span>
                        </div>
                        <div className="col s12 m12 l12">
                           {dataLoaded && (
                              <AddressInput
                                 inputValid={formValidity.examDeliveryAddress}
                                 onSubmit={value => {
                                    this.setState({
                                       examDeliveryAddress: value,
                                    });
                                 }}
                                 onChange={value => {
                                    this.setState({
                                       examDeliveryAddress: value,
                                    });
                                 }}
                                 name="examDeliveryAddress"
                                 value={examDeliveryAddress}
                                 type="text"
                                 id="exam_delivery_address"
                                 placeholder="Start typing to search for your exam delivery address"
                                 label="Delivery Address for Exam Papers"
                                 mandatory
                                 postalCheckRequired={false}
                              />
                           )}
                        </div>
                        <div className="col s12 m12 l12">
                           <TextInput
                              type="text"
                              id="exam_email"
                              placeholder="Enter the Exam Email"
                              label="Email Address for Exam GST Receipt"
                              mandatory
                              value={examDeliveryEmail}
                              inputValid={formValidity.examDeliveryEmail}
                              onChange={e => {
                                 this.setState({
                                    examDeliveryEmail: e.target.value,
                                 });
                              }}
                           />
                        </div>
                        <div className="col s12 m12 l12 control-row">
                           <br />
                           <br />
                           <button
                              type="button"
                              className="waves-effect waves-light btn btn-inactive"
                              onClick={() => this.back()}
                           >
                              Back
                           </button>
                           <button
                              type="button"
                              className="waves-effect waves-light btn btn-ml-30"
                              onClick={() => this.next()}
                           >
                              Next
                           </button>
                        </div>
                        <div className="col s12 m12 l12 control-row">
                           <Callout>
                              If you change your delivery or email address, it
                              will not affect your current profile details.
                           </Callout>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </main>
      );
   }
}

ExamDeliveryAddress.propTypes = {
   user: PropTypes.object,
   examDelivery: PropTypes.object,
   saveExamDeliveryDetails: PropTypes.func.isRequired,
   getExaminationResultAddress: PropTypes.func.isRequired,
   token: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
   return {
      examDelivery: state.examination.examDeliveryDetails,
      user: state.user,
      token: getToken(state),
   };
};

const mapDispatchToProps = {
   saveExamDeliveryDetails: ExaminationActions.saveExamDeliveryDetails,
   getExaminationResultAddress:
      ExaminationActions.getExaminationResultAddressRequest,
};

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(ExamDeliveryAddress);
