import ExaminationActions, {
   isFetchingExamination,
} from 'pgdb-data-layer/lib/Redux/ExaminationRedux';
import React, { Component } from 'react';
import * as DASHBOARD_CARDS_HELPERS from 'pgdb-data-layer/lib/Helpers/DashboardCards';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DashboardCard from '../index';
import { getToken } from '../../../Session/SessionRedux';

class ExamsCard extends Component {
   constructor(props) {
      super(props);
      this.state = {
         isExamTileVisible: false,
      };
   }

   componentDidMount() {
      const { dispatch, token } = this.props;
      dispatch(ExaminationActions.getUserExamEligibilityRequest(token));
   }

   componentDidUpdate(prevProps) {
      const { examination } = this.props;
      if (examination !== prevProps.examination) {
         this.calculateUserExams();
      }
   }

   calculateUserExams() {
      const { examination } = this.props;
      const description = DASHBOARD_CARDS_HELPERS.calculateUserExams(
         examination
      );
      const isExamsBookable =
         examination.eligibility && examination.eligibility.isUserEligible;

      this.setState({
         isExamTileVisible: isExamsBookable,
         description,
      });
   }

   render() {
      const { isExamTileVisible, description } = this.state;
      const navLink = {
         href: 'Exams',
         text: 'Enrol',
      };
      const { isLoading } = this.props;

      return (
         <DashboardCard
            header="Exams"
            icon="description"
            iconStyle="light-blue-text"
            description={description}
            visible={!isLoading}
            disabled={!isLoading && !isExamTileVisible}
            navLink={isExamTileVisible ? navLink : null}
         />
      );
   }
}

ExamsCard.propTypes = {
   examination: PropTypes.any,
   dispatch: PropTypes.func.isRequired,
   isLoading: PropTypes.bool.isRequired,
   token: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
   return {
      examination: state.examination,
      token: getToken(state),
      isLoading: isFetchingExamination('getUserExamEligibility', state),
   };
};

export default connect(mapStateToProps)(ExamsCard);
