import React from 'react';
import { string } from 'prop-types';
import './LicenceCard.scss';
import Icon from '../Icon';

export const LicenceStatus = ({ iconName, status }) => {
   return (
      <>
         <Icon className="round" iconName={iconName} />
         <br />
         <span>{status}</span>
      </>
   );
};

LicenceStatus.propTypes = {
   iconName: string.isRequired,
   status: string.isRequired,
};
