import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import PaymentActions from 'pgdb-data-layer/lib/Redux/PaymentRedux';
import Validator from '../../Utilities/Validator';
import * as helper from '../../Helpers/Helpers';

const PaymentFail = ({ payment, getPaymentResponseRequest, location }) => {
   const redirect = helper.browserBackButtonPrevention();

   if (redirect) {
      return <Redirect push to={redirect} />;
   }

   const [requestSubmitted, setRequestSubmitted] = useState(false);

   if (!requestSubmitted) {
      const result = new URLSearchParams(location.search).get('result');
      setRequestSubmitted(true);
      getPaymentResponseRequest(result);
   }

   return (
      <main>
         <div className="section no-pad-bot" id="index-banner">
            <div className="container">
               <div className="row">
                  <div className="col s12 m12 l7 offset-l1">
                     <div className="row">
                        <div className="col l12">
                           <p className="columnheader">Payment unsuccessful</p>
                        </div>
                     </div>
                  </div>
                  <div className="col s12 m12 l6 offset-l1">
                     <div className="row">
                        <div className="col l12">
                           <span className="">
                              The transaction did not complete.{` `}
                              {payment &&
                              payment.paymentResponse &&
                              !Validator.isNullOrWhiteSpace(
                                 payment.paymentResponse.referenceNumber
                              )
                                 ? `Reference Number: ${
                                      payment.paymentResponse.referenceNumber
                                   }.`
                                 : ''}
                           </span>
                        </div>
                     </div>
                  </div>
                  <div className="col s12 m12 l6 offset-l1">
                     <div className="row">
                        <div className="col l12">
                           <span className="">
                              Please check your card and bank detail before
                              trying it again.
                           </span>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </main>
   );
};

PaymentFail.propTypes = {
   payment: PropTypes.object.isRequired,
   getPaymentResponseRequest: PropTypes.func.isRequired,
   location: PropTypes.object.isRequired,
};

const mapState = state => ({
   payment: state.payment,
});

const mapDispatchToProps = {
   getPaymentResponseRequest: PaymentActions.getPaymentResponseRequest,
};

export default connect(
   mapState,
   mapDispatchToProps
)(PaymentFail);
