import React from 'react';
import { connect } from 'react-redux';
import Moment from 'moment';
import ExaminationActions from 'pgdb-data-layer/lib/Redux/ExaminationRedux';
import { getToken } from '../../../Session/SessionRedux';

const WithdrawalModalBody = ({
   modalState,
   dispatch,
   examinationDetails,
   token,
}) => {
   const selectedExam = examinationDetails.find(
      exam => exam.examinationId === modalState.selectedRow
   );

   const onModalConfirm = () => {
      dispatch(
         ExaminationActions.withdrawUserFromExaminationRequest(
            token,
            modalState.selectedRow
         )
      );
      dispatch(ExaminationActions.closeModal());
   };

   return (
      <>
         <div className="modal-content-padding">
            <div className="medium-font-size modal-content-line-height">
               <>
                  <h2>
                     Please confirm your withdrawal from your
                     <br />
                     {selectedExam.examination.course.courseName} on <br />
                     {Moment(selectedExam.examination.examinationDate).format(
                        'DD MMMM YYYY'
                     )}
                  </h2>
                  <p>
                     The examination charge will be refunded to the payment
                     method used for the original booking and your examination
                     enrolment will be cancelled. Please allow up to 10 working
                     days for the amount to show up in your account.
                  </p>
               </>
            </div>
         </div>
         <div id="modal-footer" className="modal-footer center">
            <button
               type="submit"
               onClick={() => {
                  dispatch(ExaminationActions.closeModal());
               }}
               className="waves-effect waves-light btn btn-outline button-margin"
            >
               Back
            </button>
            <button
               type="submit"
               onClick={() => onModalConfirm()}
               className="waves-effect waves-light btn button-margin"
            >
               Confirm
            </button>
         </div>
      </>
   );
};

const mapStateToProps = state => {
   return {
      modalState: state.examination.modalState,
      examinationDetails: state.examination.examinationDetails,
      token: getToken(state),
   };
};

export default connect(mapStateToProps)(WithdrawalModalBody);
