import history from './History';

class Navigator {
   constructor(history) {
      this.history = history;
   }

   location = () => {
      return this.history.location.pathname;
   };

   toBackward = () => {
      this.history.goBack();
   };

   to = path => {
      this.history.push(path);
   };

   // predefined routes
   toProfilePhotoCriteria = () => {
      this.history.push('/photocriteria');
   };

   toProfilePhotoEditor = () => {
      this.history.push('/profile-photo-editor');
   };

   toProfile = () => {
      this.history.push('/profile');
   };

   toRegistrationApply = () => {
      this.history.push('/registration-apply');
   };

   toRegistrationType = () => {
      this.history.push('/registration-type');
   };

   toRegistrationApplication = () => {
      this.history.push('/registration-application');
   };

   toRegistrationApplicantDetails = () => {
      this.history.push('/registration-applicant-details');
   };

   toRegistrationFitAndProper = () => {
      this.history.push('/registration-fit-and-proper');
   };

   toRegistrationUploadDocuments = () => {
      this.history.push('/registration-upload-documents');
   };

   toRegistrationReviewAndSubmit = () => {
      this.history.push('/registration-review-and-submit');
   };

   toRegistrationSuccess = () => {
      this.history.push('/registration-success');
   };

   toRegistrationPayment = (applicationId, contactId) => {
      this.history.push(`/registration-payment/${applicationId}/${contactId}`);
   };

   toRegistrationPaymentSuccess = () => {
      this.history.push(`/registration-payment-success`);
   };

   toPendingDocuments = applicationId => {
      this.history.push(`/registration-pending-documents/${applicationId}`);
   };

   toDashboard = () => {
      this.history.push('/dashboard');
   };

   toLogin = () => {
      this.history.push('/login');
   };

   toLogout = () => {
      this.history.push('/logout');
   };

   toCompanyContactDetails = () => {
      this.history.push('/renew-company-contact-details');
   };

   toCompanyPayAndNominate = () => {
      this.history.push('/pay-and-nominate');
   };

   toPaymentSummary = () => {
      this.history.push('/renew-summary');
   };

   toCompanyRenewalSummary = () => {
      this.history.push('/company-renewal-summary');
   };

   toUnableToPerformOnline = () => {
      this.history.push('/unable-to-perform');
   };

   toLicenceRegApplicationContactDetails = () => {
      this.history.push('/licence-regapp-contact-details');
   };

   toLicenceRegApplicationFit2Practice = () => {
      this.history.push('/licence-regapp-fit2practice');
   };

   toLicenceRegApplicationCPD = () => {
      this.history.push('/licence-regapp-cpd');
   };

   toLicenceRegApplicationSupervisors = () => {
      this.history.push('/licence-regapp-supervisors');
   };

   toLicenceRegApplicationSummary = () => {
      this.history.push('/licence-regapp-summary-confirmation');
   };
}

const navigator = new Navigator(history);

export default navigator;
