import React, { useState } from 'react';
import { connect } from 'react-redux';
import { pick, has } from 'lodash';
import SupervisionActions from 'pgdb-data-layer/lib/Redux/SupervisionRedux';
import {
   TRAINEE_LICENCE_TYPES,
   EXEMPT_LICENCE_TYPES,
   LICENCE_TYPES,
   GASFITTER_LICENCE_TYPES,
   DRAINLAYER_LICENCE_TYPES,
} from 'pgdb-data-layer/lib/Constants/LicenceTypes';
import { getToken } from '../../../Session/SessionRedux';

const AcceptModalBody = ({ modalState, dispatch, token }) => {
   const exemptGasfitter = pick(LICENCE_TYPES, ['LCG']);

   // Determine type of work supervisee is going to do
   let workType = 'sanitary plumbing work';
   if (has(GASFITTER_LICENCE_TYPES, modalState.selectedRow.licenceTypeCode)) {
      workType = 'gasfitting work';
   } else if (
      has(DRAINLAYER_LICENCE_TYPES, modalState.selectedRow.licenceTypeCode)
   ) {
      workType = 'drainlaying work';
   }

   const [buttonEnabled, setButtonEnabled] = useState(false);

   const onModalConfirm = () => {
      dispatch(
         SupervisionActions.acceptSupervisionRequest(
            token,
            modalState.selectedRow.supervisionId
         )
      );
      dispatch(SupervisionActions.closeModal());
   };

   return (
      <>
         <div className="modal-content-padding">
            <div className="medium-font-size modal-content-line-height">
               <>
                  <label>
                     <input
                        onChange={e => {
                           setButtonEnabled(e.target.checked);
                        }}
                        type="checkbox"
                        className="filled-in checkbox-blue"
                     />
                     <span className="medium-font-size black-text">
                        I confirm to;
                     </span>
                  </label>
                  <p>
                     1. Notify the Board by{' '}
                     <span className="activeblue-text">email</span> if my
                     supervision of this person ceases.
                  </p>
                  <p>
                     2. Remain responsible for all {workType} undertaken by this
                     person until the Board has received{' '}
                     <span className="activeblue-text">written notice</span>{' '}
                     that they’re no longer supervised by me, or until their
                     authorisation expires.
                  </p>
                  {has(
                     TRAINEE_LICENCE_TYPES,
                     modalState.selectedRow.licenceTypeCode
                  ) && (
                     <p>
                        3. Ensure this person works in my presence, or the
                        presence of a currently licensed Tradesman or Certifier,
                        during the first 12 months of holding a trainee licence
                        for this trade.
                     </p>
                  )}
                  {has(
                     EXEMPT_LICENCE_TYPES,
                     modalState.selectedRow.licenceTypeCode
                  ) && (
                     <p>
                        3. Ensure this person works in my presence, or the
                        presence of a currently licensed Tradesman or Certifier,
                        during the first 24 months of holding an exemption under
                        supervision for this trade.
                     </p>
                  )}
                  {has(
                     exemptGasfitter,
                     modalState.selectedRow.licenceTypeCode
                  ) && (
                     <p>
                        4. Ensure this person does not work on pipes or
                        appliances connected to any supply of gas; and that when
                        the work is to be connected to the gas supply, this
                        connection is made by the supervisor.
                     </p>
                  )}
               </>
            </div>
         </div>
         <div id="modal-footer" className="modal-footer center">
            <button
               type="submit"
               onClick={() => {
                  dispatch(SupervisionActions.closeModal());
               }}
               className="waves-effect waves-light btn btn-outline button-margin"
            >
               Back
            </button>
            <button
               type="submit"
               disabled={!buttonEnabled}
               onClick={() => onModalConfirm()}
               className="waves-effect waves-light btn button-margin"
            >
               Yes
            </button>
         </div>
      </>
   );
};

const mapStateToProps = state => {
   return {
      modalState: state.supervision.modalState,
      token: getToken(state),
   };
};

export default connect(mapStateToProps)(AcceptModalBody);
