import React from 'react';
import { string, node, func } from 'prop-types';

const TableRow = ({ className, children, onClick }) => {
   return (
      <tr className={className} onClick={onClick}>
         {children}
      </tr>
   );
};

TableRow.defaultProps = {
   className: '',
   onClick: null,
};

TableRow.propTypes = {
   className: string,
   children: node.isRequired,
   onClick: func,
};

export default TableRow;
