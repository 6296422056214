import React, { useState, useCallback } from 'react';
import { bool, arrayOf, func } from 'prop-types';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import './PublicRegister.scss';
import { DefaultPagingModel } from 'pgdb-data-layer/lib/Helpers/Others/PagedQuery';
import {
   isFetchingPublicRegister,
   getPublicRegisterData,
} from 'pgdb-data-layer/lib/Redux/PublicRegisterRedux';
import CheckboxInput from '../../Components/CheckboxInput';
import GroupedDropdown from '../../Components/GroupedDropdown';
import TextInput from '../../Components/TextInput';
import SubmitButton from '../../Components/Buttons/SubmitButton';

import {
   SEARCH_TYPES,
   SEARCH_LICENCE_TYPE_OPTIONS,
   INITIAL_SEARCH_STATE,
   INITIAL_VALIDATION_STATE,
   CURRENTLY_LICENSED_ONLY,
   COMBINED_SEARCH_VALIDATION,
   SEARCH_ACTION,
   getSearchResultTypeDescription,
   getSearchTypeDescription,
   getInputPlaceholder,
   getInputMaxLength,
   getFieldId,
   hasValidationErrors,
   normalizeSearchValues,
} from './PublicRegister/data';
import { validateAdvancedSearchRequest } from './PublicRegister/validation';

import { contactResult } from './PublicRegister/types';
import ConditionalValidationMessage from './PublicRegister/ConditionalValidationMessage';
import SearchingPlaceholder from './PublicRegister/SearchingPlaceholder';
import SearchResults from './PublicRegister/SearchResults';

const INITIAL_HAS_SEARCHED = false;
const INITIAL_SCROLL_TO = false;
const EMPTY_VALIDATION_MESSAGE = '';
const DEFAULT_PAGESIZE = DefaultPagingModel.pageSize;
const DEFAULT_PAGENUMBER = DefaultPagingModel.pageNumber;

const PublicRegisterAdvanced = ({ isLoading, contacts, dispatch }) => {
   const [searchValue, setSearchValue] = useState(INITIAL_SEARCH_STATE);
   const [paging, setPaging] = useState(DefaultPagingModel);

   const [validationMessage, setValidationMessage] = useState(
      INITIAL_VALIDATION_STATE
   );
   const [hasSearched, setHasSearched] = useState(INITIAL_HAS_SEARCHED);
   const [shouldScrollTo, setShouldScrollTo] = useState(INITIAL_SCROLL_TO);

   const showSearchResults = hasSearched && !isLoading;

   const updateSearchValue = (key, value) => {
      const updated = { ...searchValue };
      updated[key] = value;
      setSearchValue(updated);
   };

   const resetSearchValues = () => {
      setSearchValue(INITIAL_SEARCH_STATE);
      setPaging(DefaultPagingModel);
      setValidationMessage(INITIAL_VALIDATION_STATE);
      setShouldScrollTo(false);
   };

   const onCurrentlyLicensedChanged = checked => {
      updateSearchValue(CURRENTLY_LICENSED_ONLY, checked);
      setShouldScrollTo(false);
   };

   const onSearchValueChange = (key, value) => {
      updateSearchValue(key, value);

      // clear existing validation message
      const updatedValidation = { ...validationMessage };
      updatedValidation[key] = EMPTY_VALIDATION_MESSAGE;

      // also wiping out the 'no value' validation message
      updatedValidation[COMBINED_SEARCH_VALIDATION] = EMPTY_VALIDATION_MESSAGE;

      setValidationMessage(updatedValidation);
      setShouldScrollTo(false);
   };
   const updatePagingValue = (key, value) => {
      const updated = { ...paging };
      updated[key] = value;
      setPaging(updated);
   };

   const onChangePage = useCallback((event, page) => {
      paging.pageNumber = page;
      searchValue.pageNumber = page;

      const normalizedSearchValues = normalizeSearchValues(
         searchValue,
         SEARCH_TYPES.ADVANCED
      );
      dispatch(SEARCH_ACTION(SEARCH_TYPES.ADVANCED, normalizedSearchValues));
   });

   const onChangeRowsPerPage = useCallback(event => {
      paging.pageSize = event.target.value;
      searchValue.pageSize = event.target.value;

      const normalizedSearchValues = normalizeSearchValues(
         searchValue,
         SEARCH_TYPES.ADVANCED
      );
      dispatch(SEARCH_ACTION(SEARCH_TYPES.ADVANCED, normalizedSearchValues));
   });

   const onSubmitButtonClicked = () => {
      searchValue.pageSize = DEFAULT_PAGESIZE;
      searchValue.pageNumber = DEFAULT_PAGENUMBER;

      const normalizedSearchValues = normalizeSearchValues(
         searchValue,
         SEARCH_TYPES.ADVANCED
      );

      const validation = validateAdvancedSearchRequest(normalizedSearchValues);
      const updatedValidation = { ...INITIAL_VALIDATION_STATE, ...validation };

      setValidationMessage(updatedValidation);

      const isValid = !hasValidationErrors(updatedValidation);
      setShouldScrollTo(isValid);
      setHasSearched(isValid);

      if (isValid) {
         //Reset to default page
         paging.pageSize = DEFAULT_PAGESIZE;
         paging.pageNumber = DEFAULT_PAGENUMBER;
         updatePagingValue(paging.pageNumber, paging);
         updatePagingValue(paging.pageSize, paging);

         dispatch(SEARCH_ACTION(SEARCH_TYPES.ADVANCED, normalizedSearchValues));
      }
   };

   return (
      <main>
         <div className="section no-pad-bot">
            <div className="container">
               <div className="row">
                  <div className="col s12">
                     <h4 className="columnheader left">Advanced Search</h4>
                  </div>
               </div>
               <br />
               <div className="row">
                  <div className="col s6">
                     <h5 className="subheader sub-text">
                        You can search any combination of these categories
                     </h5>
                  </div>
               </div>
               <div className="row">
                  <div className="col s2">
                     <p>
                        <label
                           htmlFor={getFieldId(SEARCH_TYPES.LICENCE)}
                           className="grey2-text input-label-horizontal"
                        >
                           {getSearchTypeDescription(SEARCH_TYPES.LICENCE)}
                        </label>
                     </p>
                  </div>
                  <div className="col s4">
                     <TextInput
                        type="text"
                        id={getFieldId(SEARCH_TYPES.LICENCE)}
                        inputValid={!validationMessage[SEARCH_TYPES.LICENCE]}
                        placeholder={getInputPlaceholder(SEARCH_TYPES.LICENCE)}
                        value={searchValue[SEARCH_TYPES.LICENCE]}
                        onChange={e =>
                           onSearchValueChange(
                              SEARCH_TYPES.LICENCE,
                              e.target.value
                           )
                        }
                        maxLength={getInputMaxLength(SEARCH_TYPES.LICENCE)}
                        onSubmit={onSubmitButtonClicked}
                     />
                     <ConditionalValidationMessage
                        message={validationMessage[SEARCH_TYPES.LICENCE]}
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col s2">
                     <p>
                        <label
                           htmlFor={getFieldId(SEARCH_TYPES.NAME)}
                           className="grey2-text input-label-horizontal"
                        >
                           {getSearchResultTypeDescription(SEARCH_TYPES.NAME)}
                        </label>
                     </p>
                  </div>
                  <div className="col s4">
                     <TextInput
                        type="text"
                        id={getFieldId(SEARCH_TYPES.NAME)}
                        inputValid={!validationMessage[SEARCH_TYPES.NAME]}
                        placeholder={getInputPlaceholder(SEARCH_TYPES.NAME)}
                        value={searchValue[SEARCH_TYPES.NAME]}
                        onChange={e =>
                           onSearchValueChange(
                              SEARCH_TYPES.NAME,
                              e.target.value
                           )
                        }
                        maxLength={getInputMaxLength(SEARCH_TYPES.NAME)}
                        onSubmit={onSubmitButtonClicked}
                     />
                     <ConditionalValidationMessage
                        message={validationMessage[SEARCH_TYPES.NAME]}
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col s2">
                     <p>
                        <label
                           htmlFor={getFieldId(SEARCH_TYPES.SUBURB)}
                           className="grey2-text input-label-horizontal"
                        >
                           {getSearchTypeDescription(SEARCH_TYPES.SUBURB)}
                        </label>
                     </p>
                  </div>
                  <div className="col s4">
                     <TextInput
                        type="text"
                        id={getFieldId(SEARCH_TYPES.SUBURB)}
                        inputValid={!validationMessage[SEARCH_TYPES.SUBURB]}
                        placeholder={getInputPlaceholder(SEARCH_TYPES.SUBURB)}
                        value={searchValue[SEARCH_TYPES.SUBURB]}
                        onChange={e =>
                           onSearchValueChange(
                              SEARCH_TYPES.SUBURB,
                              e.target.value
                           )
                        }
                        maxLength={getInputMaxLength(SEARCH_TYPES.SUBURB)}
                        onSubmit={onSubmitButtonClicked}
                     />
                     <ConditionalValidationMessage
                        message={validationMessage[SEARCH_TYPES.SUBURB]}
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col s2">
                     <p>
                        <label
                           htmlFor={getFieldId(SEARCH_TYPES.TOWN)}
                           className="grey2-text input-label-horizontal"
                        >
                           {getSearchTypeDescription(SEARCH_TYPES.TOWN)}
                        </label>
                     </p>
                  </div>
                  <div className="col s4">
                     <TextInput
                        type="text"
                        id={getFieldId(SEARCH_TYPES.TOWN)}
                        inputValid={!validationMessage[SEARCH_TYPES.TOWN]}
                        placeholder={getInputPlaceholder(SEARCH_TYPES.TOWN)}
                        value={searchValue[SEARCH_TYPES.TOWN]}
                        onChange={e =>
                           onSearchValueChange(
                              SEARCH_TYPES.TOWN,
                              e.target.value
                           )
                        }
                        maxLength={getInputMaxLength(SEARCH_TYPES.TOWN)}
                        onSubmit={onSubmitButtonClicked}
                     />
                     <ConditionalValidationMessage
                        message={validationMessage[SEARCH_TYPES.TOWN]}
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col s2">
                     <p>
                        <label
                           htmlFor={getFieldId(SEARCH_TYPES.POSTCODE)}
                           className="grey2-text input-label-horizontal"
                        >
                           {getSearchTypeDescription(SEARCH_TYPES.POSTCODE)}
                        </label>
                     </p>
                  </div>
                  <div className="col s4">
                     <TextInput
                        type="text"
                        id={getFieldId(SEARCH_TYPES.POSTCODE)}
                        inputValid={!validationMessage[SEARCH_TYPES.POSTCODE]}
                        placeholder={getInputPlaceholder(SEARCH_TYPES.POSTCODE)}
                        value={searchValue[SEARCH_TYPES.POSTCODE]}
                        onChange={e =>
                           onSearchValueChange(
                              SEARCH_TYPES.POSTCODE,
                              e.target.value
                           )
                        }
                        maxLength={getInputMaxLength(SEARCH_TYPES.POSTCODE)}
                        onSubmit={onSubmitButtonClicked}
                     />
                     <ConditionalValidationMessage
                        message={validationMessage[SEARCH_TYPES.POSTCODE]}
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col s2">
                     <p>
                        <label
                           htmlFor={getFieldId(SEARCH_TYPES.LICENCE_TYPE)}
                           className="grey2-text input-label-horizontal"
                        >
                           {getSearchTypeDescription(SEARCH_TYPES.LICENCE_TYPE)}
                        </label>
                     </p>
                  </div>
                  <div className="col l4">
                     <GroupedDropdown
                        id={getFieldId(SEARCH_TYPES.LICENCE_TYPE)}
                        value={searchValue[SEARCH_TYPES.LICENCE_TYPE]}
                        values={SEARCH_LICENCE_TYPE_OPTIONS}
                        placeholder={getInputPlaceholder(
                           SEARCH_TYPES.LICENCE_TYPE
                        )}
                        keepPlaceholder
                        onChange={e =>
                           onSearchValueChange(
                              SEARCH_TYPES.LICENCE_TYPE,
                              e.target.value
                           )
                        }
                     />
                     <ConditionalValidationMessage
                        message={validationMessage[SEARCH_TYPES.LICENCE_TYPE]}
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col s4 offset-s2">
                     <CheckboxInput
                        label="Currently Licensed"
                        value={searchValue[CURRENTLY_LICENSED_ONLY]}
                        onChange={e =>
                           onCurrentlyLicensedChanged(e.target.checked)
                        }
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col s2 offset-s2 btn-search-margin">
                     <SubmitButton onClick={onSubmitButtonClicked} />
                  </div>
               </div>
               {validationMessage[COMBINED_SEARCH_VALIDATION] && (
                  <div className="row">
                     <div className="col s4 offset-s2">
                        <ConditionalValidationMessage
                           message={
                              validationMessage[COMBINED_SEARCH_VALIDATION]
                           }
                        />
                     </div>
                  </div>
               )}
               <div className="row">
                  <div className="col s4 offset-s2">
                     <button
                        type="button"
                        className="btn-flat btn-link"
                        onClick={resetSearchValues}
                     >
                        Reset
                     </button>
                     <Link to="/public-register" className="search-switch-link">
                        Basic Search
                     </Link>
                  </div>
               </div>
               {isLoading && <SearchingPlaceholder />}
               {showSearchResults && (
                  <SearchResults
                     searchType={SEARCH_TYPES.ADVANCED}
                     contacts={contacts}
                     scrollToResults={shouldScrollTo}
                     searchValue={searchValue}
                     dispatch
                     onChangeRowsPerPage={onChangeRowsPerPage}
                     onChangePage={onChangePage}
                     paging={paging}
                  />
               )}
            </div>
         </div>
      </main>
   );
};

PublicRegisterAdvanced.propTypes = {
   isLoading: bool.isRequired,
   contacts: arrayOf(contactResult).isRequired,
   dispatch: func.isRequired,
};

const mapStateToProps = state => {
   return {
      contacts: getPublicRegisterData(state.publicRegister),
      isLoading: isFetchingPublicRegister(state),
   };
};

export default connect(mapStateToProps)(PublicRegisterAdvanced);
