import { TO_CURRENCY } from 'pgdb-data-layer/lib/Constants/Regex';
import history from '../Navigation/History';

export const formatCurrency = (item, decimalPoints) => {
   return item.toFixed(decimalPoints).replace(TO_CURRENCY, '$&,');
};

export const browserBackButtonPrevention = () => {
   // little hack to prevent going back to payment page ;) hopefully it works
   if (history.location.hash !== '#!') {
      // dump bunch of history here
      const hashNumber = Number(history.location.hash.substring(1));
      if (hashNumber > 49) {
         return `${history.location.pathname}${history.location.search}#!`;
      }

      return `${history.location.pathname}${
         history.location.search
      }#${hashNumber + 1}`;
   }
   return null;
};
