import React, { useRef, useEffect } from 'react';
import { string, number, bool, arrayOf } from 'prop-types';
import { LICENCE_TYPES } from 'pgdb-data-layer/lib/Constants/LicenceTypes';
import {
   getSearchResultTypeDescription,
   SEARCH_TYPES,
   INITIAL_SEARCH_STATE,
} from '../data';

const SearchResultHeader = ({
   searchType,
   searchValue,
   count,
   scrollToResults,
}) => {
   const headerEl = useRef(null);

   const typeDescription = getSearchResultTypeDescription(searchType);
   const searchValueText =
      searchType === SEARCH_TYPES.LICENCE_TYPE
         ? LICENCE_TYPES[searchValue]
         : searchValue;
   const noResultPrefix = count === 0 ? 'No ' : '';
   const resultPlural = count !== 1 ? 's' : '';

   // when search results are rendered, scroll to them
   useEffect(() => {
      if (!scrollToResults) return;

      headerEl.current.scrollIntoView({
         behavior: 'smooth',
      });
   });

   return (
      <>
         <div className="row" ref={headerEl}>
            <div className="col s12">
               {searchValue[0] ? (
                  <h4 className="columnheader">
                     {noResultPrefix}Result for {typeDescription} &lsquo;
                     {searchValueText}
                     &rsquo;
                  </h4>
               ) : (
                  <h4 className="columnheader">
                     {noResultPrefix}Result for {typeDescription}
                  </h4>
               )}
               <p>
                  Showing {count} result{resultPlural}
               </p>
            </div>
         </div>
      </>
   );
};

SearchResultHeader.propTypes = {
   searchType: string.isRequired,
   searchValue: arrayOf(INITIAL_SEARCH_STATE),
   count: number.isRequired,
   scrollToResults: bool.isRequired,
};

export default SearchResultHeader;
