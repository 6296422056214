import React from 'react';
import { string, func } from 'prop-types';
import {
   RENEWED,
   RENEWING,
   PAID,
} from 'pgdb-data-layer/lib/Constants/LicenceCard';
import * as RENEWAL_LICENCE_CARD_HELPERS from 'pgdb-data-layer/lib/Helpers/RenewalLicenceCard';
import * as LICENCE_CARD_HELPERS from 'pgdb-data-layer/lib/Helpers/LicenceCard';
import TextLink from '../TextLink';
import { LicenceStatus } from './LicenceStatus';
import { LicenceTitle } from './LicenceTitle';

const RenewalOptions = ({ openModal, onRenew }) => {
   return (
      <>
         <button
            type="button"
            className="waves-effect waves-light btn renew-btn"
            onClick={onRenew}
         >
            Renew
         </button>
         <br />
         <TextLink className="vLicence link" onClick={openModal}>
            Don&apos;t Renew
         </TextLink>
      </>
   );
};

RenewalOptions.propTypes = {
   openModal: func,
   onRenew: func,
};

RenewalOptions.defaultProps = {
   openModal: null,
   onRenew: null,
};

const RenewalCardBody = ({ icon, status, onRenewalUndo }) => {
   return (
      <div className="change-hover">
         <div className="renew-action">
            <LicenceStatus status={status} iconName={icon} />
         </div>
         <div
            onClick={onRenewalUndo}
            onKeyPress={onRenewalUndo}
            className="change-action"
            role="button"
            tabIndex="0"
         >
            <LicenceStatus iconName="undo" status="Change" />
         </div>
      </div>
   );
};

RenewalCardBody.propTypes = {
   icon: string.isRequired,
   status: string.isRequired,
   onRenewalUndo: func.isRequired,
};

export const RenewalLicenceCard = ({
   level, // e.g. Certifying
   discipline, // e.g. Plumber
   renewalAction, // one of 'Renewed', 'RenewingLater', 'NotRenewingEver', and 'Paid' (when licence has been paid for but not yet renewed)
   onRenewalModal,
   onRenew,
   onRenewalUndo,
}) => {
   const statusText =
      renewalAction === 'RenewedBefore'
         ? renewalAction
         : RENEWAL_LICENCE_CARD_HELPERS.getRenewalActionText(renewalAction);
   const icon = RENEWAL_LICENCE_CARD_HELPERS.getIconForRenewalAction(
      renewalAction
   );
   const iconPath = RENEWAL_LICENCE_CARD_HELPERS.getRenewalIconPath(
      renewalAction === 'RenewedBefore' ? 'Renewed' : renewalAction,
      discipline
   );

   const isPaid = renewalAction === PAID;
   const isRenewedBefore = renewalAction === 'RenewedBefore';
   const hasSelection = statusText !== RENEWING;
   const hasBody = !isPaid && !isRenewedBefore && hasSelection;
   const isRenewing = renewalAction === RENEWED || isPaid;
   const opacityModifier = !isRenewing && !isRenewedBefore ? ' opacity-01' : '';
   const color =
      !isRenewing && !isRenewedBefore
         ? 'disabled'
         : LICENCE_CARD_HELPERS.getDisciplineColor(discipline[0]);

   const cardClasses = `licence-card licence-card-x2 ${color}-licence`;
   const imageClass = `card-image${opacityModifier}`;

   return (
      <div className="col l4">
         <div className={cardClasses}>
            <img
               alt={`${discipline} Licence Card`}
               className={imageClass}
               src={iconPath}
            />
            <LicenceTitle level={level} discipline={discipline} />
            {!hasSelection && (
               <RenewalOptions openModal={onRenewalModal} onRenew={onRenew} />
            )}
            {hasBody && (
               <RenewalCardBody
                  status={statusText}
                  icon={icon}
                  onRenewalUndo={onRenewalUndo}
               />
            )}
            {isRenewedBefore ? (
               <LicenceStatus iconName="check" status="Renewed" />
            ) : isPaid ? (
               <LicenceStatus iconName="check" status="Paid" />
            ) : null}
         </div>
      </div>
   );
};

RenewalLicenceCard.propTypes = {
   level: string.isRequired,
   discipline: string.isRequired,
   renewalAction: string.isRequired,
   onRenewalModal: func.isRequired,
   onRenew: func.isRequired,
   onRenewalUndo: func.isRequired,
};
