import React, { useState, useEffect } from 'react';
import { node } from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { Prompt } from 'react-router';
import M from 'materialize-css';
import * as LICENCE_CARD_HELPERS from 'pgdb-data-layer/lib/Helpers/LicenceCard';
import { getLicenceLevel } from 'pgdb-data-layer/lib/Helpers/Licence';
import RenewActions, {
   isFetchingRenewalSuccess,
   isFetchingRenewal,
} from 'pgdb-data-layer/lib/Redux/RenewalRedux';
import SupervisionActions, {
   getMySupervisors,
   isFetchingSupervision,
   isFetchingSupervisionSuccess,
} from 'pgdb-data-layer/lib/Redux/SupervisionRedux';
import Navigator from '../../Navigation/Navigator';
import { RenewalLicenceCard } from '../../Components/LicenceCard/RenewalLicenceCard';
import Callout from '../../Components/Callout';
import './RenewLicencesIndividual.scss';
import { getToken } from '../../Session/SessionRedux';
import Loading from '../../Components/Loading';

const NONE = 'None';
const PAID = 'Paid';
const RENEWED = 'Renewed';
const RENEWING_LATER = 'RenewingLater';
const NOT_RENEWING_FOR_PERIOD = 'NotRenewingForPeriod';
const NOT_RENEWING_EVER = 'NotRenewingEver';
const RENEWED_BEFORE = 'RenewedBefore';
const STATUSES_FOR_PROCEEDING = [PAID, RENEWED, RENEWED_BEFORE];

const validRenewalActions = renewalActions => {
   const renewalStatuses = Object.values(renewalActions).map(a => a.status);
   return (
      renewalStatuses.every(status => status !== null && status !== NONE) &&
      renewalStatuses.some(status => STATUSES_FOR_PROCEEDING.includes(status))
   );
};

const LicenceCards = ({
   licences,
   setModalVisibility,
   setLicenceType,
   setLicenceName,
   renewalStatus,
   setRenewalAction,
}) => {
   return licences.map((licence, i) => {
      if (renewalStatus[licence.licenceTypeCode] == null) return <></>;
      const licenceRenewalAction =
         renewalStatus[licence.licenceTypeCode].status;
      const licenceTrade = LICENCE_CARD_HELPERS.getTradesmanDisciplineName(
         licence.licenceDisciplineCode
      );
      const licenceLevel = getLicenceLevel(licence.licenceTypeCode);
      return (
         <RenewalLicenceCard
            key={licence.applicationId}
            level={licenceLevel}
            discipline={licenceTrade}
            renewalAction={licenceRenewalAction}
            onRenewalModal={() => {
               setLicenceType(licence.licenceTypeCode);
               setLicenceName(`${licenceLevel} ${licenceTrade}`);
               setModalVisibility(true);
            }}
            onRenew={() => {
               setRenewalAction(licence.licenceTypeCode, RENEWED);
            }}
            onRenewalUndo={() => {
               setRenewalAction(licence.licenceTypeCode, NONE);
            }}
         />
      );
   });
};

const EmptyApplications = () => {
   return (
      <Container>
         <div className="row">
            <h4 className="columnheader left">
               You have no licences to renew.
            </h4>
         </div>
      </Container>
   );
};

const Container = ({ children }) => {
   return (
      <main>
         <div className="section no-pad-bot" id="index-banner">
            <div className="container">
               <div className="row">
                  <div className="col l9 offset-l1">
                     <div className="inner-page-container">{children}</div>
                  </div>
               </div>
            </div>
         </div>
      </main>
   );
};

const DontRenewModal = ({
   isVisible,
   setVisibility,
   licenceType,
   licenceName,
   saveRenewalAction,
}) => {
   let renewalAction = 'RenewingLater';
   return (
      <Modal
         isOpen={isVisible}
         className="generic-modal"
         overlayClassName="generic-modal-overlay"
         appElement={document.getElementById('root')}
      >
         <>
            <div className="modal-content-padding">
               <div className="medium-font-size modal-content-line-height">
                  <p>
                     I don&apos;t want to renew my {licenceName} Licence, but I
                     will&hellip;
                  </p>
                  <div className="left-section-35">
                     <div className="radio-align-left">
                        <label>
                           <input
                              className="with-gap"
                              name="group1"
                              type="radio"
                              onClick={() => {
                                 renewalAction = RENEWING_LATER;
                              }}
                              defaultChecked
                           />
                           <span>Later</span>
                        </label>
                     </div>
                     <div className="radio-align-left">
                        <label>
                           <input
                              className="with-gap"
                              name="group1"
                              type="radio"
                              onClick={() => {
                                 renewalAction = NOT_RENEWING_FOR_PERIOD;
                              }}
                           />
                           <span>Not this year</span>
                        </label>
                     </div>
                     <div className="radio-align-left">
                        <label>
                           <input
                              className="with-gap"
                              name="group1"
                              type="radio"
                              onClick={() => {
                                 renewalAction = NOT_RENEWING_EVER;
                              }}
                           />
                           <span>Never</span>
                        </label>
                     </div>
                  </div>
               </div>
            </div>
            <div className="modal-footer center">
               <button
                  type="submit"
                  onClick={() => {
                     setVisibility(false);
                  }}
                  className="waves-effect waves-light btn btn-outline button-margin"
               >
                  Cancel
               </button>
               <button
                  type="submit"
                  onClick={() => {
                     saveRenewalAction(licenceType, renewalAction);
                     setVisibility(false);
                  }}
                  className="waves-effect waves-light btn button-margin"
               >
                  Confirm
               </button>
            </div>
         </>
      </Modal>
   );
};

Container.propTypes = {
   children: node.isRequired,
};

const convertToDto = (renewalActions, needLicenseCard) => {
   return Object.values(renewalActions)
      .filter(a => {
         return a.status !== 'RenewedBefore';
      })
      .map(action => {
         return {
            licenceTypeCode: action.typeCode,
            licenceYearId: action.year,
            renewalAction: action.status === PAID ? RENEWED : action.status,
            licenseCardRequested : needLicenseCard,
         };
      });
};

const RenewLicencesIndividual = ({
   userApplications,
   isLoading,
   isSaved,
   isFetchedUserApplications,
   dispatch,
   token,   
}) => {
   const [saveBtnClicked, setSaveBtnClicked] = useState(false);
   const [originalRenewalActions, setOriginalRenewalActions] = useState({});
   const [renewalActions, setRenewalActions] = useState({});
   const [licenceType, setLicenceType] = useState(null);
   const [licenceName, setLicenceName] = useState(null);
   const [isModalVisible, setModalVisibility] = useState(false);
   const [hasLoaded, setHasLoaded] = useState(false);
   const [needLicenseCard, setLicenseCardRequestStatus2] = useState(false);
   const applications = userApplications || [];
   const hasApplicationData = !isLoading && hasLoaded;

   const [saveSubmitted, setSaveSubmitted] = useState(false);
   const returnUrl = new URLSearchParams(window.location.search).get(
      'returnurl'
   );
   
   const setLicenseCardRequestStatus = e => {
      if (e.target.checked) {
         setLicenseCardRequestStatus2(true);
      } else {
         setLicenseCardRequestStatus2(false);
      }
   };

   useEffect(() => {
      if (!hasLoaded) {
         dispatch(SupervisionActions.getUserApplicationsRenewalRequest(token));
         setHasLoaded(true);
      }

      if (saveSubmitted && isSaved) {
         returnUrl ? Navigator.to(`/${returnUrl}`) : Navigator.to('/renew-cpd');
      }
   });

   useEffect(() => {
      if (isFetchedUserApplications) {
         const actions = {};
         applications.forEach((app, _) => {
            actions[app.licenceTypeCode] = {
               typeCode: app.licenceTypeCode,
               year: app.licenceYearId,
               status:
                  [RENEWED, PAID].some(e => e === app.licenceStatus) &&
                  app.licence !== null
                     ? RENEWED_BEFORE
                     : app.licenceStatus || NONE,
            };
         });
         setRenewalActions(actions);
         setOriginalRenewalActions(actions);
      }
   }, [hasLoaded, applications]);

   const updateRenewalStatus = (licenceCode, status) => {
      // Types: {None, Renewed, RenewingLater, NotRenewingForPeriod, NotRenewingEver}
      const newState = { ...renewalActions };
      newState[licenceCode].status = status;
      setRenewalActions(newState);
   };

   return (
      <Container>
         <Loading isLoading={!hasApplicationData}>
            {applications.length === 0 ? (
               <EmptyApplications />
            ) : (
               <>
                  <Prompt
                     when={
                        !saveBtnClicked &&
                        !saveSubmitted &&
                        originalRenewalActions !== renewalActions
                     }
                     message="You have unsaved changes. Are you sure you wish to leave the page and lose your changes?"
                  />
                  <div className="row">
                     <h4 className="columnheader left">
                        Which licences would you like to renew?
                     </h4>
                  </div>
                  <div className="row">
                     <p className="sub-header left">
                        These are the licences you had last year.
                     </p>
                  </div>
                  <div className="center-align card-row row">
                     <LicenceCards
                        licences={applications}
                        setModalVisibility={setModalVisibility}
                        setLicenceType={setLicenceType}
                        setLicenceName={setLicenceName}
                        renewalStatus={renewalActions}
                        setRenewalAction={updateRenewalStatus}
                     />
                  </div>
                  {!validRenewalActions(renewalActions) && (
                     <div className="row">
                        <div className="col s8">
                           <Callout>
                              You need to confirm all your licences before you
                              can proceed.
                           </Callout>
                        </div>
                     </div>
                  )}
                  <div className="row">
                     <button
                        type="button"
                        className="waves-effect waves-light btn btn-inactive"
                        onClick={() => Navigator.to('/fit2practice-wizard')}
                     >
                        Back
                     </button>
                     <button
                        type="button"
                        className="waves-effect waves-light btn btn-ml-30"
                        disabled={!validRenewalActions(renewalActions)}
                        onClick={() => {
                           setSaveBtnClicked(true);
                           dispatch(
                              RenewActions.saveApplicationRenewalActionRequest(
                                 token,
                                 convertToDto(renewalActions, needLicenseCard)
                              )
                           );
                           setSaveSubmitted(true);
                           M.Toast.dismissAll();
                           M.toast({
                              html: 'Your changes have been saved',
                              classes: 'success',
                           });
                        }}
                     >
                        Next
                     </button>
                     <label className="btn-ml-30 grey2-text">
                        <input
                           type="checkbox"
                           className="filled-in checkbox-blue btn-ml-30"
                           onChange={e => setLicenseCardRequestStatus(e)}
                        />
                        <span>
                           Request a physical licence card                             
                        </span>                                        
                     </label>  
                     <label>
                           <span className="grey2-Note-text"> (Please note, this will add an additional cost of $4.50 to the total amount)</span>
                     </label>                     
                  </div>
                  <DontRenewModal
                     isVisible={isModalVisible}
                     setVisibility={setModalVisibility}
                     licenceType={licenceType}
                     licenceName={licenceName}
                     saveRenewalAction={updateRenewalStatus}
                  />
               </>
            )}
         </Loading>
      </Container>
   );
};

RenewLicencesIndividual.defaultProps = {
   isLoading: true,
};

const mapStateToProps = state => {
   return {
      userApplications: getMySupervisors(state),
      isFetchedUserApplications: isFetchingSupervisionSuccess(
         'getUserApplicationsRenewal',
         state
      ),
      isLoading:
         isFetchingSupervision('getUserApplicationsRenewal', state) ||
         isFetchingRenewal('saveApplicationRenewal', state),
      isSaved: isFetchingRenewalSuccess('saveApplicationRenewal', state),
      token: getToken(state),
   };
};

export default connect(mapStateToProps)(RenewLicencesIndividual);
