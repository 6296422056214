import React from 'react';
import { NavLink } from 'react-router-dom';
import TextLink from '../../../../Components/TextLink';
import Icon from '../../../../Components/Icon';

const RegistrationUploadDocumentsReview = ({ uploadedDocuments }) => {
   return (
      <div className="registration-review-container">
         <div className="review-heading">
            <h6 className="review-title">UPLOADED DOCUMENTS</h6>
            <div className="review-edit-section">
               <NavLink
                  to="/registration-upload-documents"
                  className="review-edit-button"
               >
                  <i className="material-icons edit-icon">create</i>
                  EDIT
               </NavLink>
            </div>
         </div>

         <div>
            <p className="review-subheading">Attachments</p>
         </div>
         <div className="upload-section">
            <div className="uploaded-files">
               {uploadedDocuments &&
                  uploadedDocuments.map(item => {
                     return (
                        <div className="uploaded-file-item">
                           <span className="uploaded-file-icon-and-name">
                              <Icon
                                 className="attach-icon"
                                 iconName="attach_file"
                              />
                              <TextLink className="uploaded-file-review">
                                 {item.fileName}
                              </TextLink>
                           </span>
                        </div>
                     );
                  })}
            </div>
         </div>
      </div>
   );
};

export default RegistrationUploadDocumentsReview;
