import React from 'react';
import PropTypes from 'prop-types';
import './Callout.scss';

const SHAZZA_PATH = '/images/shazza-small.jpg';

const Callout = ({ children }) => {
   return (
      <div className="row callout">
         <div>
            <div className="callout-profile-img">
               <img
                  className="circle"
                  src={SHAZZA_PATH}
                  width="50"
                  height="50"
                  alt="Callout Profile Helper"
               />
            </div>
         </div>
         <div className="col s10 m10 l10 grey2-text">
            <div className="callout-body">{children}</div>
         </div>
      </div>
   );
};

const { node } = PropTypes;

Callout.propTypes = {
   children: node.isRequired,
};

export default Callout;
