import { combineReducers } from 'redux';
import configureStore from './CreateStore';
import rootSaga from '../Saga';
import { reducer as sessionReducer } from '../Session/SessionRedux';

export default () => {
   const finalReducers = combineReducers({
      application: require('pgdb-data-layer/lib/Redux/ApplicationsRedux')
         .reducer,
      dashboard: require('pgdb-data-layer/lib/Redux/DashboardRedux').reducer,
      session: sessionReducer,
      user: require('pgdb-data-layer/lib/Redux/UserInfoRedux').reducer,
      licence: require('pgdb-data-layer/lib/Redux/LicenceRedux').reducer,
      publicRegister: require('pgdb-data-layer/lib/Redux/PublicRegisterRedux')
         .reducer,
      result: require('pgdb-data-layer/lib/Redux/ResultRedux').reducer,
      course: require('pgdb-data-layer/lib/Redux/CoursesRedux').reducer,
      supervision: require('pgdb-data-layer/lib/Redux/SupervisionRedux')
         .reducer,
      receipt: require('pgdb-data-layer/lib/Redux/ReceiptsRedux').reducer,
      renewal: require('pgdb-data-layer/lib/Redux/RenewalRedux').reducer,
      companyRenewal: require('pgdb-data-layer/lib/Redux/CompanyRenewalRedux')
         .reducer,
      examination: require('pgdb-data-layer/lib/Redux/ExaminationRedux')
         .reducer,
      payment: require('pgdb-data-layer/lib/Redux/PaymentRedux').reducer,
      renewalSummary: require('pgdb-data-layer/lib/Redux/RenewalSummaryRedux')
         .reducer,
      bulkRenewal: require('pgdb-data-layer/lib/Redux/BulkRenewalRedux')
         .reducer,
      registration: require('pgdb-data-layer/lib/Redux/RegistrationRedux')
         .reducer,
      gender: require('pgdb-data-layer/lib/Redux/GenderRedux').reducer,
      ethnicity: require('pgdb-data-layer/lib/Redux/EthnicityRedux').reducer,
      fitAndProper: require('pgdb-data-layer/lib/Redux/FitAndProperRedux')
         .reducer,
      enum: require('pgdb-data-layer/lib/Redux/EnumRedux').reducer,
   });

   // eslint-disable-line prefer-const
   let { store, sagasManager, sagaMiddleware } = configureStore(
      // eslint-disable-line prefer-const
      finalReducers,
      rootSaga
   );
   if (module.hot) {
      module.hot.accept(() => {
         const nextRootReducer = require('./').reducers; // eslint-disable-line  import/no-self-import

         store.replaceReducer(nextRootReducer);

         const newYieldedSagas = require('../Saga').default;
         sagasManager.cancel();
         sagasManager.done.then(() => {
            sagasManager = sagaMiddleware.run(newYieldedSagas);
         });
      });
   }

   return store;
};
