import * as Cookies from 'js-cookie';
import isPromise from 'is-promise';
import SessionActions from './SessionRedux';

let instance;

const USER_SESSION = 'USER-SESSION';
const USER_DATA = 'USER_DATA';

export default class SessionService {
   constructor(store, options) {
      instance = this;
      SessionService.setOptions(store, options);
      return instance;
   }

   static setOptions(
      store,
      {
         driver,
         validateSession,
         refreshOnCheckAuth = false,
         expires = 360,
         redirectPath = 'login',
         server = false,
      } = {}
   ) {
      instance.store = store;
      instance.refreshOnCheckAuth = refreshOnCheckAuth;
      instance.redirectPath = redirectPath;
      instance.expires = expires;
      instance.driver = driver;
      instance.server = server;
      instance.validateSession = validateSession;

      // configure the storage
      const storageOptions = {
         name: 'redux-react-session',
      };
      const localforage = require('localforage');
      if (driver && driver !== 'COOKIES') {
         storageOptions.driver = localforage[driver];
      }
      instance.storage = localforage.createInstance(storageOptions);
   }

   static initSessionService(store, options, id_token) {
      instance = new SessionService(store, options);
      return SessionService.refreshFromLocalStorage(id_token);
   }

   static invalidateSession() {
      instance.store.dispatch(SessionActions.invalidSession());
      SessionService.deleteSession();
      SessionService.deleteUser();
   }

   static attemptLoadUser(session, id_token) {
      return SessionService.loadUser()
         .then(user => {
            instance.store.dispatch(SessionActions.getUserSessionSuccess(user));
            instance.store.dispatch(
               SessionActions.getSessionSuccess(session.access_token, id_token)
            );
         })
         .catch(() => {
            instance.store.dispatch(SessionActions.getUserSessionError());
         });
   }

   static refreshFromLocalStorage(id_token) {
      return SessionService.loadSession()
         .then(session => {
            if (instance.validateSession) {
               const value = instance.validateSession(session);

               if (isPromise(value)) {
                  return value
                     .then(valid => {
                        if (!valid) {
                           throw new Error('Session is invalid');
                        }
                        return this.attemptLoadUser(session, id_token);
                     })
                     .catch(() => {
                        this.invalidateSession();
                     });
               }
               if (!value) {
                  this.invalidateSession();
                  return;
               }
            }
            return this.attemptLoadUser(session, id_token);
         })
         .catch(() => {
            instance.store.dispatch(SessionActions.getSessionError());
         });
   }

   static checkAuth(nextState, replace, next) {
      const { refreshOnCheckAuth, store } = instance;
      SessionService.loadSession()
         .then(session => {
            refreshOnCheckAuth &&
               store.dispatch(
                  SessionActions.getSessionSuccess(
                     session.access_token,
                     session.id_token
                  )
               );
            SessionService.loadUser()
               .then(user => {
                  refreshOnCheckAuth &&
                     store.dispatch(SessionActions.getUserSessionSuccess(user));
                  next();
               })
               .catch(() => next());
         })
         .catch(() => {
            refreshOnCheckAuth &&
               store.dispatch(SessionActions.getSessionError());
            refreshOnCheckAuth &&
               store.dispatch(SessionActions.getUserSessionError());
            replace({
               pathname: instance.redirectPath,
               state: { nextPathname: nextState.location.pathname },
            });
            next();
         });
   }

   static saveSession(session) {
      return new Promise(resolve => {
         if (instance.server) {
            instance[USER_SESSION] = session;
            instance.store.dispatch(
               SessionActions.getSessionSuccess(
                  session.access_token,
                  session.id_token
               )
            );
            resolve();
         } else if (instance.driver === 'COOKIES') {
            Cookies.set(
               USER_SESSION,
               {
                  access_token: session.access_token,
               },
               {
                  expires: instance.expires,
               }
            );
            instance.store.dispatch(
               SessionActions.getSessionSuccess(
                  session.access_token,
                  session.id_token
               )
            );
            resolve();
         } else {
            instance.storage
               .setItem(USER_SESSION, session)
               .then(() => {
                  instance.store.dispatch(
                     SessionActions.getSessionSuccess(
                        session.access_token,
                        session.id_token
                     )
                  );
                  resolve();
               })
               .catch(() => {
                  Cookies.set(USER_SESSION, session, {
                     expires: instance.expires,
                  });
                  instance.store.dispatch(
                     SessionActions.getSessionSuccess(
                        session.access_token,
                        session.id_token
                     )
                  );
                  resolve();
               });
         }
      });
   }

   static loadSession() {
      return new Promise((resolve, reject) => {
         if (instance.server) {
            instance[USER_SESSION] ? resolve(instance[USER_SESSION]) : reject();
         } else if (instance.driver === 'COOKIES') {
            const cookies = Cookies.getJSON(USER_SESSION);
            cookies ? resolve(cookies) : reject('Session not found');
         } else {
            instance.storage
               .getItem(USER_SESSION)
               .then(currentSession => {
                  if (currentSession) {
                     resolve(currentSession);
                  } else {
                     const cookies = Cookies.getJSON(USER_SESSION);
                     cookies ? resolve(cookies) : reject('Session not found');
                  }
               })
               .catch(err => reject(err));
         }
      });
   }

   static deleteSession() {
      return instance.storage.removeItem(USER_SESSION).then(() => {
         instance.store.dispatch(SessionActions.getSessionError());
         Cookies.remove(USER_SESSION);
         delete instance[USER_SESSION];
      });
   }

   static saveUser(user) {
      return new Promise(resolve => {
         if (instance.server) {
            instance[USER_DATA] = user;
            instance.store.dispatch(SessionActions.getUserSessionSuccess(user));
            resolve();
         } else if (instance.driver === 'COOKIES') {
            instance.store.dispatch(SessionActions.getUserSessionSuccess(user));
            // delete permission property from object
            // in order to shorten the value in cookie
            const dataToStoreInCookie = { ...user };
            delete dataToStoreInCookie.permission;
            Cookies.set(USER_DATA, dataToStoreInCookie, {
               expires: instance.expires,
            });
            resolve();
         } else {
            instance.storage
               .setItem(USER_DATA, user)
               .then(user => {
                  instance.store.dispatch(
                     SessionActions.getUserSessionSuccess(user)
                  );
                  resolve();
               })
               .catch(() => {
                  instance.store.dispatch(
                     SessionActions.getUserSessionSuccess(user)
                  );
                  Cookies.set(USER_DATA, user, { expires: instance.expires });
                  resolve();
               });
         }
      });
   }

   static loadUser() {
      return new Promise((resolve, reject) => {
         if (instance.server) {
            instance[USER_DATA] ? resolve(instance[USER_DATA]) : reject();
         } else if (instance.driver === 'COOKIES') {
            const cookies = Cookies.getJSON(USER_DATA);
            cookies ? resolve(cookies) : reject('User not found');
         } else {
            instance.storage
               .getItem(USER_DATA)
               .then(currentUser => {
                  if (currentUser) {
                     resolve(currentUser);
                  } else {
                     const cookies = Cookies.getJSON(USER_DATA);
                     cookies ? resolve(cookies) : reject('User not found');
                  }
               })
               .catch(err => reject(err));
         }
      });
   }

   static deleteUser() {
      return instance.storage.removeItem(USER_DATA).then(() => {
         instance.store.dispatch(SessionActions.getUserSessionError());
         Cookies.remove(USER_DATA);
         delete instance[USER_DATA];
      });
   }
}
