import React from 'react';
import { TRAINEE_LICENCE_TYPES } from 'pgdb-data-layer/lib/Constants/LicenceTypes';
import { hasTraineePaid } from 'pgdb-data-layer/lib/Helpers/Renewal';
import Table from '../../../../Components/GenericTable/Table';
import TableRow from '../../../../Components/GenericTable/TableRow';
import TableCell from '../../../../Components/GenericTable/TableCell';
import TableHeaderCell from '../../../../Components/GenericTable/TableHeaderCell';
import TableHead from '../../../../Components/GenericTable/TableHead';
import TableBody from '../../../../Components/GenericTable/TableBody';
import {
   getStylingForRenewingStatus,
   getStylingForLicenceStatus,
} from './StylingHelper';
import * as helper from '../../../../Helpers/Helpers';
import * as StatusCode from '../../Shared/StatusCodes';
import Validator from '../../../../Utilities/Validator';

const LicenceRenewalTable = ({ tableData }) => {
   const generateTableRows = () => {
      let index = 1;
      const traineePaid = hasTraineePaid(tableData);

      return tableData
         .filter(f => !f.isDisciplinaryFee)
         .map(value => {
            const appStyles = getStylingForLicenceStatus(
               !Validator.isNullOrWhiteSpace(value.userRenewalAction)
                  ? value.userRenewalAction
                  : !Validator.isNullOrWhiteSpace(value.licenceStatus)
                  ? 'Renewed'
                  : '',
               value.licenceStatus
            );
            const licenceStyles = getStylingForRenewingStatus(
               !Validator.isNullOrWhiteSpace(value.userRenewalAction)
                  ? value.userRenewalAction
                  : !Validator.isNullOrWhiteSpace(value.licenceStatus)
                  ? 'Renewed'
                  : ''
            );
            let totalAmount = 0;
            value.fees
               .filter(f => !f.isPaid)
               .forEach(s => (totalAmount += s.amount));
            const isRenewing =
               value.userRenewalAction === StatusCode.USER_ACTION_RENEW;

            const isRenewedBefore = !Validator.isNullOrWhiteSpace(
               value.licenceStatus
            );

            let fees = '';
            if (TRAINEE_LICENCE_TYPES[value.licenceTypeCode]) {
               fees = isRenewing
                  ? traineePaid
                     ? 'Paid'
                     : 'Per Trainee Fee'
                  : '';
            } else {
               fees = isRenewing
                  ? totalAmount > 0
                     ? helper.formatCurrency(totalAmount, 2)
                     : 'Paid'
                  : '';
            }

            return (
               <React.Fragment key={index}>
                  <TableRow key={value.licenceNumber} className="subrow-no-btm">
                     <TableCell
                        className="smaller roman-font licence-number-cell"
                        color="grey"
                     >
                        Licence {index++}
                     </TableCell>
                     <TableCell
                        icon={appStyles.icon}
                        color={appStyles.color}
                        className="licence-cell"
                     >
                        {value.description}
                     </TableCell>
                     <TableCell
                        icon={licenceStyles.icon}
                        color={licenceStyles.color}
                     >
                        {isRenewedBefore
                           ? 'Renewed'
                           : isRenewing
                           ? 'Renewing'
                           : 'Not Renewing'}
                     </TableCell>
                     <TableCell color="black" className="bold">
                        {isRenewing && '$'}
                     </TableCell>
                     <TableCell
                        color="black"
                        className="bold text-right width-reduction amount-cell"
                        amount="1"
                     >
                        {fees}
                     </TableCell>
                  </TableRow>
               </React.Fragment>
            );
         });
   };

   const generateDisciplinaryFeeRows = () => {
      let index = 1;

      return tableData
         .filter(f => f.isDisciplinaryFee)
         .map(value => {
            let totalAmount = 0;
            value.fees
               .filter(f => !f.isPaid)
               .forEach(s => (totalAmount += s.amount));

            return (
               <React.Fragment key={index}>
                  <TableRow key={value.licenceNumber} className="subrow-no-btm">
                     <TableCell />
                     <TableCell className="bold disciplinary-cell">
                        {value.fees[0].description}
                     </TableCell>
                     <TableCell />
                     <TableCell color="black" className="bold">
                        {'$'}
                     </TableCell>
                     <TableCell
                        color="black"
                        className="bold text-right width-reduction amount-cell"
                        amount="1"
                     >
                        {totalAmount > 0
                           ? helper.formatCurrency(totalAmount, 2)
                           : 'Paid'}
                     </TableCell>
                  </TableRow>
               </React.Fragment>
            );
         });
   };

   return (
      <Table className="table-head-compressed borderless licence-table">
         <TableHead>
            <TableRow>
               <TableHeaderCell className="no-left-pad">
                  I'm Paying for
               </TableHeaderCell>
               <TableHeaderCell className="icon-left-pad">
                  Type of Licence
               </TableHeaderCell>
               <TableHeaderCell className="icon-left-pad">
                  Renewal Status
               </TableHeaderCell>
               <TableHeaderCell />
               <TableHeaderCell className="no-left-pad amount-header">
                  Amount to Pay
               </TableHeaderCell>
            </TableRow>
         </TableHead>

         <TableBody>
            {generateTableRows()}
            {generateDisciplinaryFeeRows()}
         </TableBody>
      </Table>
   );
};

export default LicenceRenewalTable;
