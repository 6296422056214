import React, { useEffect, useRef, useState, Fragment } from 'react';
import { array, object, func } from 'prop-types';
import { connect } from 'react-redux';
import M from 'materialize-css';
import Moment from 'moment';
import LicenceActions, {
   isFetchingLicenceDetailsSuccess,
} from 'pgdb-data-layer/lib/Redux/LicenceRedux';
import RenewActions, {
   getUnmetApplicationCriteria,
} from 'pgdb-data-layer/lib/Redux/RenewalRedux';
import { getUserInfoData } from 'pgdb-data-layer/lib/Redux/UserInfoRedux';
import { CERTIFYING_LICENCE_TYPES } from 'pgdb-data-layer/lib/Constants/LicenceTypes';
import { getLicenceLevel } from 'pgdb-data-layer/lib/Helpers/Licence';
import { getUserContactPhoto } from 'pgdb-data-layer/lib/Helpers/Profile';
import FileSaver from 'file-saver';
import PaymentActions from 'pgdb-data-layer/lib/Redux/PaymentRedux';
import ReceiptsActions from 'pgdb-data-layer/lib/Redux/ReceiptsRedux';
import { getToken } from '../../Session/SessionRedux';
import Callout from '../../Components/Callout';
import { LicenceCard } from '../../Components/LicenceCard/index';
import LicenceCardBack from '../../Components/LicenceIdCard/LicenceCardBack';
import LicenceCardFront from '../../Components/LicenceIdCard/LicenceCardFront';
import { UnmetCriteriaCallout } from '../../Components/CriteriaCallout';
import TextLink from '../../Components/TextLink';
import PaymentSuccessfulLicences from './Shared/PaymentViews/PaymentSuccessfulLicences';
import * as StatusCode from './Shared/StatusCodes';
import './Confirmation.scss';

const PendingApplicationNotification = ({ unmetCriteria }) => {
   if (!unmetCriteria || unmetCriteria.length === 0) return <></>;

   return unmetCriteria.map((criteria, i) => {
      if (!criteria.descriptions || criteria.descriptions.length === 0)
         return <Fragment key={i} />;

      return <UnmetCriteriaCallout key={i} unmetCriteria={criteria} />;
   });
};

PendingApplicationNotification.propTypes = {
   unmetCriteria: array.isRequired,
};

const createPersonCardDto = (firstName, lastName, photo, licenceId, licences, expiry) => {
   const trades = licences.map(
      l => `${getLicenceLevel(l.typeCode)} ${l.trade}`
   );
   Moment.locale('en');
   const expire = Moment(expiry)
      .format('DD MMMM YYYY')
      .toUpperCase();

   return {
      FirstName: firstName,
      LastName: lastName,
      Image: photo,
      Barcode: licenceId,
      Expire: expire,
      Trades: trades,
   };
};

const Confirmation = ({
   userLicence,
   userSession,
   contactData,
   unmetCriteria,
   licenceApplicationRequest,
   getPaymentResponseRequest,
   unmetCriteriaRequest,
   getUserReceiptDetailsFromReferenceRequest,
   location,
   payment,
   userReceiptDetails,
   fetchedSuccess,
   bulkLicences,
   token,
   licenceDetailsRequest,
   isDownLoaded,
   licenceDetails,
   licenceDetailsReset,
}) => {
   const licenceCardRef = useRef(null);

   const [requestSubmitted, setRequestSubmitted] = useState(false);
   const [isPdfDownloadStarted, setIsPdfDownloadStarted] = useState(false);

   if (!requestSubmitted) {
      const result = new URLSearchParams(location.search).get('result');
      setRequestSubmitted(true);
      getPaymentResponseRequest(result);
   }

   const hasCurrentLicences =
      userLicence &&
      userLicence.currentLicences &&
      userLicence.currentLicences.length > 0;

   const hasCurrentData =
      userLicence &&
      userLicence.currentData &&
      userLicence.currentData.trades &&
      userLicence.currentData.trades.length > 0;

   const userName = contactData ? contactData.fullName : 'PGDB User';
   const licenceId =
      contactData && contactData.registrationNumber
         ? contactData.registrationNumber
         : 0;

   const currentPersonCard = hasCurrentLicences
      ? createPersonCardDto(
           contactData.firstName,
           contactData.lastName,
           getUserContactPhoto(contactData),
           licenceId,
           userLicence.currentLicences,
           userLicence.currentData.expires
        )
      : {};

   const downloadPDF = () => {
      FileSaver.saveAs(userReceiptDetails, 'Receipt.pdf');
   };

   const downloadLicencePdf = expiry => {
      if (token) {
         licenceDetailsRequest(token, licenceId, expiry);
         setIsPdfDownloadStarted(true);
      }
   };

   useEffect(() => {
      if (isPdfDownloadStarted && isDownLoaded) {
         const blob = new Blob([licenceDetails], {
            type: 'application/pdf',
         });

         FileSaver.saveAs(
            blob,
            `Licence-details-${userSession.licence_id}.pdf`
         );
         setIsPdfDownloadStarted(false);
         licenceDetailsReset();
      }
   }, [licenceDetails, isDownLoaded, isPdfDownloadStarted]);

   useEffect(() => {
      if (
         payment.paymentResponse != null &&
         payment.paymentResponse.applicationIds != null
      ) {
         licenceApplicationRequest(
            token,
            payment.paymentResponse.applicationIds
         );
         unmetCriteriaRequest(token, payment.paymentResponse.applicationIds);
      }

      if (fetchedSuccess) {
         downloadPDF();
      }

      Moment.locale('en');
   }, [payment, userReceiptDetails]);

   const renderCards = licences => {
      return licences.currentLicences.map((licence, i) => {
         const level = getLicenceLevel(licence.typeCode);

         return (
            <LicenceCard
               key={i}
               level={level}
               discipline={licence.trade}
               status={licence.status}
               openCard={() => {
                  const instance = M.Modal.init(licenceCardRef.current);
                  instance.open();
               }}
               downloadCard={() => downloadLicencePdf(licence.expiryDate)}
            />
         );
      });
   };

   const getReceiptDetails = paymentResponse => {
      if (paymentResponse) {
         const paymentReference = paymentResponse.referenceNumber;
         getUserReceiptDetailsFromReferenceRequest(token, paymentReference);
      } else {
         M.toast({
            html: `Sorry there seems to be an issue downloading your receipt at the moment.
            Please contact the Plumbers, Gasfitters and Drainlayers Board.`,
            classes: 'error',
         });
      }
   };

   const getLicenceItem = (contact, applications) => {
      return {
         contactId: contact.contactId,
         fullName: contact.fullName,
         registrationNumber: contact.registrationNumber,
         applications: applications,
      };
   };

   const renderLicenceItems = (licences, section) => {
      return (
         <div className="row">
            <div className="col s12 offset-l1">
               <span className="section-header sub-heading" />
               <PaymentSuccessfulLicences
                  tableData={licences}
                  sectionType={section}
               />
            </div>
         </div>
      );
   };

   const renderBulkLicences = () => {
      if (!bulkLicences || !bulkLicences.length) {
         return null;
      }

      // populate the active & Inactive licence items
      const activeLicences = [];
      const inActiveLicences = [];

      bulkLicences.forEach(c => {
         const activeApps = [];
         const inActiveApps = [];
         c.applications.forEach(a => {
            const isActive =
               a.activeLicence &&
               ((a.hasSupervisorApproved &&
                  a.supervisorLicenceStatus ===
                     StatusCode.LICENCE_STATUS_ACTIVE) ||
                  CERTIFYING_LICENCE_TYPES[a.licenceTypeCode]);

            if (isActive) {
               activeApps.push(a);
            } else {
               inActiveApps.push(a);
            }
         });
         if (activeApps.length) {
            activeLicences.push(getLicenceItem(c, activeApps));
         }
         if (inActiveApps.length) {
            inActiveLicences.push(getLicenceItem(c, inActiveApps));
         }
      });

      // render bulk licences
      return (
         <>
            {activeLicences &&
               activeLicences.length > 0 &&
               renderLicenceItems(activeLicences, 1)}
            {inActiveLicences &&
               inActiveLicences.length > 0 &&
               renderLicenceItems(inActiveLicences, 2)}
            <div className="row">
               <div className="col s12 offset-l1">
                  <Callout>
                     They also need to confirm their contact details to complete
                     the renewal process.
                  </Callout>
               </div>
            </div>
         </>
      );
   };

   return (
      <main>
         <br />
         <br />
         <div className="section no-pad-bot">
            <div className="container">
               <div className="row center-align">
                  <div className="col l7 offset-l1">
                     <div className="inner-page-container">
                        <div className="row">
                           <div className="col s12">
                              <h4 className="columnheader left">
                                 Payment complete
                              </h4>
                           </div>
                        </div>

                        <Callout>
                           Thank you for your payment.
                           <br />
                           We&apos;ve emailed you a receipt but you can also{' '}
                           <TextLink
                              onClick={() =>
                                 getReceiptDetails(payment.paymentResponse)
                              }
                           >
                              download it here.
                           </TextLink>
                        </Callout>

                        {hasCurrentLicences && (
                           <div>
                              <div className="row">
                                 <h4 className="licence-statuses left ">
                                    These are the statuses for your licences
                                 </h4>
                              </div>
                              {hasCurrentData && (
                                 <div className="row">
                                    <p className="no-btm left licence-statuses-msg">
                                       You should receive your Licence Card
                                       within the next 10 working days if you
                                       have paid for a physical card.
                                    </p>
                                 </div>
                              )}
                              <div className="center-align card-row row">
                                 {renderCards(userLicence)}
                              </div>
                           </div>
                        )}

                        <PendingApplicationNotification
                           unmetCriteria={unmetCriteria}
                        />

                        {/* NICE TO HAVE
                        <div>
                           <div className="row">
                              <h4 className="columnheader left">
                                 How would you rate this renewal process?
                              </h4>
                           </div>
                           <div className="row">
                              <p className="sub-header no-btm left">
                                 Your feedback will help us improve this feature
                                 for you.
                              </p>
                           </div>
                           <div className="row radio-spread">
                              {generateRadioButtons(5)}
                           </div>
                           <div className="text-align-left">
                              <SubmitButton
                                 text="Home"
                                 colorInvert={true}
                                 onClick={() => Navigator.toDashboard()}
                              />
                           </div>
                        </div>
                        */}

                        <div
                           id="modal1"
                           ref={licenceCardRef}
                           className="modal black-text licence-modal"
                        >
                           {hasCurrentLicences && (
                              <>
                                 <LicenceCardFront
                                    details={currentPersonCard}
                                 />
                                 <br />
                                 <LicenceCardBack details={currentPersonCard} />
                              </>
                           )}
                        </div>
                     </div>
                  </div>
               </div>

               {renderBulkLicences()}
            </div>
         </div>
      </main>
   );
};

const mapStateToProps = state => {
   return {
      userLicence: state.licence.licences,
      userSession: state.session.user,
      contactData: getUserInfoData(state.user),
      payment: state.payment,
      unmetCriteria: getUnmetApplicationCriteria(state.renewal),
      userReceiptDetails: state.receipt.receiptDetailsFromReference,
      fetchedSuccess:
         state.receipt.async.getUserReceiptDetailsFromReference.success,
      bulkLicences: state.licence.licences.otherLicences,
      token: getToken(state),
      isDownLoaded: isFetchingLicenceDetailsSuccess(state),
      licenceDetails: state.licence.licenceDetails,
   };
};

Confirmation.propTypes = {
   getPaymentResponseRequest: func.isRequired,
   location: object.isRequired,
};

const mapDispatchToProps = {
   getPaymentResponseRequest: PaymentActions.getPaymentResponseRequest,
   licenceApplicationRequest: LicenceActions.licenceApplicationRequest,
   unmetCriteriaRequest: RenewActions.getUnmetApplicationCriteriaByIdsRequest,
   getUserReceiptDetailsFromReferenceRequest:
      ReceiptsActions.getUserReceiptDetailsFromReferenceRequest,
   licenceDetailsRequest: LicenceActions.licenceDetailsRequest,
   licenceDetailsReset: LicenceActions.licenceDetailsReset,
};

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(Confirmation);
