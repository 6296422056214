import React from 'react';
import { connect } from 'react-redux';
import SupervisionActions from 'pgdb-data-layer/lib/Redux/SupervisionRedux';
import { getToken } from '../../../Session/SessionRedux';

const ChangeSupervisorModalBody = ({ modalState, dispatch, token }) => {
   const onModalConfirm = () => {
      dispatch(
         SupervisionActions.changeSupervisorRequest(token, {
            supervisionId: modalState.selectedRow.supervisionId,
            newSupervisorId: modalState.pendingNewSupervisor.supervisorGuid,
         })
      );
      dispatch(SupervisionActions.closeModal());
   };

   return (
      <>
         <div className="modal-content-padding">
            <div className="medium-font-size modal-content-line-height">
               <>
                  <p>
                     Would you like to nominate{' '}
                     {modalState.pendingNewSupervisor.fullName} as supervisor
                     for your {modalState.selectedRow.licenceName} licence?
                  </p>
               </>
            </div>
         </div>
         <div id="modal-footer" className="modal-footer center">
            <button
               type="submit"
               onClick={() => {
                  dispatch(SupervisionActions.closeModal());
               }}
               className="waves-effect waves-light btn btn-outline button-margin"
            >
               Back
            </button>
            <button
               type="submit"
               onClick={() => onModalConfirm()}
               className="waves-effect waves-light btn button-margin"
            >
               Yes
            </button>
         </div>
      </>
   );
};

const mapStateToProps = state => {
   return {
      modalState: state.supervision.modalState,
      token: getToken(state),
   };
};

export default connect(mapStateToProps)(ChangeSupervisorModalBody);
