import React from 'react';
import {
    Table,
    TableBody,
    TableRow,
    TableCell,
 } from '../../../../../Components/GenericTable';
 import * as helper from '../../../../../Helpers/Helpers';

const CompanyLicenceRenewalTotal = ({ totalAmount }) => { 
    const total = totalAmount
    ? helper.formatCurrency(totalAmount, 2)
    : 0.0;

    return (
        <Table className="table-head-compressed borderless total-table">
        <TableBody>
            <TableRow className="total-row">
                <TableCell />
                <TableCell />
                <TableCell className="total-label-cell">Total</TableCell>
                <TableCell color="black" className="currency-cell bold">
                    $
                </TableCell>
                <TableCell className="total-cell">{total}</TableCell>
            </TableRow>
        </TableBody>
        </Table>
    );
}

export default CompanyLicenceRenewalTotal;