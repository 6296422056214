import React from 'react';
import './ExaminationModalAlert.scss';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import ExaminationActions from 'pgdb-data-layer/lib/Redux/ExaminationRedux';
import WithdrawalModalBody from './ExaminationModalContent/WithdrawalModalBody';

class ExaminationModalAlert extends React.Component {
   getModalContent = () => {
      const { modalState } = this.props;
      let modalBody = 'Incorrect Type';
      if (modalState.actionType === 'Withdrawal') {
         modalBody = <WithdrawalModalBody />;
      }
      return modalBody;
   };

   render() {
      const { dispatch } = this.props;
      const modalBody = this.getModalContent();
      return (
         <div id="examination-modal">
            <Modal
               isOpen
               onRequestClose={() => {
                  dispatch(ExaminationActions.closeModal());
               }}
               className="generic-modal"
               overlayClassName="generic-modal-overlay"
               appElement={document.getElementById('root')}
            >
               {modalBody}
            </Modal>
         </div>
      );
   }
}

const mapStateToProps = state => {
   return {
      modalState: state.examination.modalState,
   };
};

export default connect(mapStateToProps)(ExaminationModalAlert);
