import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { SUPERVISION_STATUS } from 'pgdb-data-layer/lib/Constants/Supervision';
import * as SUPERVISION_HELPERS from 'pgdb-data-layer/lib/Helpers/Supervision';
import Table from '../../../Components/GenericTable/Table';
import TableRow from '../../../Components/GenericTable/TableRow';
import SupervisionTextBoxCell from './SupervisionTextBoxCell';
import TableCell from '../../../Components/GenericTable/TableCell';
import TableHeaderCell from '../../../Components/GenericTable/TableHeaderCell';
import TableHead from '../../../Components/GenericTable/TableHead';
import TableBody from '../../../Components/GenericTable/TableBody';
import TextLink from '../../../Components/TextLink';

const MySupervisorsTable = ({
   tableData,
   renew,
   registrationSupervisor,
   className,
}) => {
   const [inputTarget, setInputTarget] = useState(null);

   const clearInputTarget = () => {
      setInputTarget(null);
   };

   const updateInputTarget = supervision => {
      if (renew) {
         setInputTarget(supervision.applicationId);
      } else {
         setInputTarget(supervision.supervisionId);
      }
   };

   const generateTableRows = () => {
      return (
         <>
            {tableData.map((value, i) => {
               let isMySupervisorsActive = true;
               let rowElementSupervisorContent = value.supervisorName;
               // If this is on the renewal page, use applicationId as identifier rather than supervisionId
               const isTarget = renew
                  ? inputTarget === value.applicationId
                  : inputTarget === value.supervisionId;

               const nominateSupervisor = value.supervisorId == null && renew;
               const isCertifying = value.licenceType === 'Certifying';

               if (value.supervisionStatus === SUPERVISION_STATUS.DECLINED) {
                  isMySupervisorsActive = false;
                  rowElementSupervisorContent = (
                     <>
                        <span className="block-text second-line small">
                           {value.supervisorName} has declined their
                           supervision.
                        </span>
                        <span className="block-text second-line small">
                           Please nominate a new supervisor.
                        </span>
                     </>
                  );
               } else if (
                  value.supervisionStatus === SUPERVISION_STATUS.PENDING ||
                  !value.supervisorRenewedLicence
               ) {
                  isMySupervisorsActive = false;
                  rowElementSupervisorContent = (
                     <>
                        {!value.supervisorRenewedLicence && (
                           <span className="block-text second-line small my-supervisor-text">
                              {value.supervisorName} needs to renew their
                              licence
                           </span>
                        )}
                        {value.supervisionStatus !==
                           SUPERVISION_STATUS.ACCEPTED && (
                           <span className="block-text second-line small">
                              {value.supervisorName} needs to accept your
                              nomination
                           </span>
                        )}
                     </>
                  );
               } else if (
                  !SUPERVISION_HELPERS.isSupervisionDateValid(value) &&
                  !renew
               ) {
                  isMySupervisorsActive = false;
                  rowElementSupervisorContent = (
                     <>
                        <span className="block-text second-line small">
                           {value.supervisorName} has ended their supervision.
                        </span>
                        <span className="block-text second-line small">
                           Please nominate a new supervisor.
                        </span>
                     </>
                  );
               }

               const supervisionEndDate = value.endDate
                  ? Moment(value.endDate).format('DD/MM/YYYY')
                  : 'N/A';
               const licenceExpiryDate = value.licenceExpiryDate
                  ? Moment(value.licenceExpiryDate).format('DD/MM/YYYY')
                  : 'N/A';

               return (
                  <TableRow
                     key={renew ? value.applicationId : value.supervisionId}
                  >
                     <TableCell
                        color={
                           isMySupervisorsActive || isCertifying
                              ? 'black'
                              : 'orange'
                        }
                        icon={
                           isMySupervisorsActive || isCertifying
                              ? 'check'
                              : 'sync'
                        }
                     >
                        {value.licenceName === undefined
                           ? value.applicationTypeDescription
                           : value.licenceName}
                     </TableCell>
                     {renew && isCertifying ? (
                        <TableCell
                           className="status-cell icon-left-pad"
                           color="black"
                        >
                           No Supervision needed
                        </TableCell>
                     ) : (
                        <>
                           {isTarget ? (
                              <SupervisionTextBoxCell
                                 newSupervisor={renew && nominateSupervisor}
                                 selectedSupervisee={value}
                                 clearInputTarget={clearInputTarget}
                                 registrationPaymentFontSize="registration-payment-font-size"
                              />
                           ) : (
                              <>
                                 {renew && nominateSupervisor ? (
                                    <TableCell className="small icon-left-pad">
                                       <TextLink
                                          onClick={() => {
                                             if (isTarget) {
                                                clearInputTarget();
                                             } else {
                                                updateInputTarget(value);
                                             }
                                          }}
                                       >
                                          {isTarget
                                             ? 'Cancel'
                                             : 'Nominate Supervisor'}
                                       </TextLink>
                                    </TableCell>
                                 ) : (
                                    <TableCell
                                       className="status-cell"
                                       color={
                                          isMySupervisorsActive
                                             ? 'black'
                                             : 'orange'
                                       }
                                       icon={
                                          isMySupervisorsActive
                                             ? 'check'
                                             : 'sync'
                                       }
                                    >
                                       {rowElementSupervisorContent}
                                    </TableCell>
                                 )}
                              </>
                           )}
                        </>
                     )}
                     {!renew && (
                        <>
                           <TableCell className="small">
                              {supervisionEndDate}
                           </TableCell>
                           <TableCell className="small">
                              {licenceExpiryDate}
                           </TableCell>
                        </>
                     )}
                     {renew && nominateSupervisor ? (
                        <TableCell className="small">
                           <TextLink
                              onClick={() => {
                                 if (isTarget) {
                                    clearInputTarget();
                                 } else {
                                    updateInputTarget(value);
                                 }
                              }}
                           >
                              {isTarget ? 'Cancel' : ''}
                           </TextLink>
                        </TableCell>
                     ) : (
                        <TableCell className="small">
                           <TextLink
                              onClick={() => {
                                 if (isTarget) {
                                    clearInputTarget();
                                 } else {
                                    updateInputTarget(value);
                                 }
                              }}
                           >
                              {isTarget ? 'Cancel' : 'Change'}
                           </TextLink>
                        </TableCell>
                     )}
                  </TableRow>
               );
            })}
         </>
      );
   };

   return (
      <>
         {registrationSupervisor && (
            <h4 className="payment-subheader">Supervisor Nomination</h4>
         )}
         <Table>
            <TableHead>
               <TableRow>
                  <TableHeaderCell className={`icon-left-pad ${className}`}>
                     My Licences
                  </TableHeaderCell>
                  <TableHeaderCell className={`icon-left-pad ${className}`}>
                     My Supervisors
                  </TableHeaderCell>
                  {!renew && (
                     <>
                        <TableHeaderCell className={`no-left-pad ${className}`}>
                           Supervision Ends
                        </TableHeaderCell>
                        <TableHeaderCell className={`no-left-pad ${className}`}>
                           Licence Year Ends
                        </TableHeaderCell>
                     </>
                  )}
                  <TableHeaderCell className={`no-left-pad ${className}`} />
               </TableRow>
            </TableHead>

            <TableBody className="supervisor-body">
               {generateTableRows()}
            </TableBody>
         </Table>
      </>
   );
};

const { array } = PropTypes;

MySupervisorsTable.propTypes = {
   tableData: array.isRequired,
};

export default connect()(MySupervisorsTable);
