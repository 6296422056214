import React,{ useEffect, useState } from 'react';
import RadioButtons from '../../../Components/RadioButtons';
import { object, bool } from 'prop-types';
import { connect } from 'react-redux';
import LicenceActions, {
   getLicenceData,
} from 'pgdb-data-layer/lib/Redux/LicenceRedux';

import FitAndProperActions, {  
   getQAsData,
   isFetchingQA,
   isFetchingQASuccess,
   isSaveQASuccess,
 } from 'pgdb-data-layer/lib/Redux/FitAndProperRedux';

import UserActions, {
   getUserInfoData,
} from 'pgdb-data-layer/lib/Redux/UserInfoRedux';
import { getToken } from '../../../Session/SessionRedux';
import * as CPD_HELPERS from 'pgdb-data-layer/lib/Helpers/CPD';
import './FitAndProper.scss';
import Loading from '../../../Components/Loading';
import M from 'materialize-css';
import { isEmpty } from 'lodash';
import Navigator from '../../../Navigation/Navigator';
import Icon from '../../../Components/Icon';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import FiberManualRecorOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';

const OPTION_YES = 'Yes';
const OPTION_NO = 'No';

const FitAndProper = ({
   userLicence, 
   token, 
   dispatch, 
   fitAndProperData, 
   footer, 
   isLoading,
   isSaveSuccess,
   readyToPreformFooterOpertation,
   setAnswerState,
   location,
   userContactData,
}) => {
   const [saveSubmitted, setSaveSubmitted] = useState(false);
   const [initialDataLoaded, setInitialDataLoaded] = useState(false);
   const [answerData, setAnswerData] = useState({});
   const [isRegistered, setIsRegistered] = useState(false);
   const returnUrl = location ? new URLSearchParams(location.search).get('returnurl') : "";
      
   useEffect(() => {
      dispatch(LicenceActions.licenceRequest(token));
      setAnswerData({});
      loadFitAndProperData();
      loadUserContactInfo();
   }, []);

   useEffect(() => {
      if (saveSubmitted && isSaveSuccess) {
         M.toast({
            html: 'Your Fit & Proper Questions are being saved',
            classes: 'success',
         });

         if (returnUrl) {
            Navigator.to(`/${returnUrl}`);
         } 

         setSaveSubmitted(false);
         loadFitAndProperData();
      }
   }, [saveSubmitted, isSaveSuccess, initialDataLoaded]);
   
   useEffect(() => {
      if (CPD_HELPERS.checkUserRegistration(userContactData)) {
         // only proceed with getting user results if they have a registration/licence
         setIsRegistered(true);
      }
   }, [userContactData]);

   // get the current location to set the editable condition
   // when in the F2P Questions dedicated page(/fit2practice), search if there is any of active license,
   // and if any, disable save function,
   // when in the licence renewal process, practitioner can edit answer always.
   const locationPath = window.location.pathname;
   
   const disableSave = returnUrl 
            ? false
            : locationPath === '/fit2practice';

   const loadFitAndProperData = () => {
      dispatch(FitAndProperActions.questionsAndAnswersReset());
      setInitialDataLoaded(false);
      dispatch(FitAndProperActions.questionsAndAnswersRequest(token));
   };

   const loadUserContactInfo = () => {
      dispatch(UserActions.getUserContactInfoRequest(token));
   };

   const onValueChange = (groupName, val) => {
      // set answerData only if empty.
      if (isEmpty(answerData)) {
         // eslint-disable-next-line array-callback-return
         fitAndProperData.questionsAndAnswers.map((q, i) => {
            answerData[q.id] = q.latestAnswer;
         });
         setAnswerData(answerData);
      }
      
      answerData[groupName] = val === OPTION_YES ? true: false;
      setSaveSubmitted(false);
      setAnswerData(answerData);
      if (setAnswerState) {
         setAnswerState({ ...answerData });
      }
   };

   const trySaveFitAndProper = () => {
      setSaveSubmitted(true);

      // converts from object to array of objects
      const postData = [];
      for(let key in answerData) {
         postData.push({"id": parseInt(key), "questionText": "", "latestAnswer": answerData[key]});
      }
      
      dispatch(
         FitAndProperActions.saveAnswerListRequest(
            token,
            JSON.stringify(postData)
         )
      );
      
      return true;
   };

   const importantNote = () => {
      return (
         <div className='important-note'>
            <div className='important-note-header'>
               <ErrorOutlineIcon />
            <b>Important Note</b>
            </div>
            <p className='important-note-content'>
               <p>
               If you answer ‘yes’ to one of these questions you need to provide us with additional information before your licence can be issued.
               </p>
               <p>
               You will need to provide us with:
               </p>
                  <ul>
                     <li>
                        <FiberManualRecordIcon />	
                        A written explanation of the matter(s). If relevant, please include the date of the incident, place of incident, incident summary, outcome and date of outcome.
                     </li>
                     <li><br /></li>
                     <li>
                        <FiberManualRecordIcon />
                        If required, we will request further information, such as:                                
                        <ul className='important-note-sublist'>
                           <li>
                              <FiberManualRecorOutlinedIcon /> Court documents
                           </li>
                           <li>
                              <FiberManualRecorOutlinedIcon />	Copy of charges 
                           </li>
                           <li>
                              <FiberManualRecorOutlinedIcon />	Legal correspondence
                           </li>
                           <li>
                              <FiberManualRecorOutlinedIcon />	Correspondence from regulatory authorities
                           </li>
                           <li>
                              <FiberManualRecorOutlinedIcon />	Decisions
                           </li>
                           <li>
                              <FiberManualRecorOutlinedIcon />	Police or Health certificates if applicable
                           </li>
                        </ul>
                     </li>                      
                  </ul>               
               <p>
               <b>Please submit the information to <a href="mailto:licensing@pgdb.co.nz">licensing@pgdb.co.nz.</a></b> 
               <p>We will review the information within <b>10</b> working days. </p>
               </p>
               <p>For more details, please refer to the criminal record check information from the Ministry Of Justice by clicking <a href="https://www.justice.govt.nz/criminal-records/" target="_blank" rel="noopener noreferrer">this link</a>.</p>
               <p>
               If you need help, call us on <span className='contact-numnber'><b>0800 743 262</b></span>.
               </p>
               <p>
               Please continue with the rest of your licence application.
               </p>
            </p>
         </div>        
      );
   }

   if (isSaveSuccess && saveSubmitted) {
      if (readyToPreformFooterOpertation) readyToPreformFooterOpertation();
   }

   return (
      <main>
         <Loading isLoading={isLoading}>
            <div className="section no-pad-bot" id="index-banner">
               <div className="container">
                  <div className="row">
                     <div className="questions-page-divider">
                        <div className="questions-section fit-and-proper-page">
                           <div className="row">
                              {!isRegistered && 
                              <div className="col l5 offset-l1">
                                 <h3
                                    className="columnheader"
                                    id="cpd-section-header"
                                 >
                                    Fit & Proper Questions
                                 </h3>
                                 <Icon
                                    className="round large light-yellow-text"
                                    iconName="error"
                                 />
                                 <p id="cpd-sub-description">
                                    Fit & Proper doesn&apos;t apply to you at
                                    this point, as it&apos;s only a requirement
                                    for people registered in the Journeyman,
                                    Tradesman or Certifying classes.
                                 </p>
                              </div>
                              }
                              {isRegistered && 
                              <h4 className="columnheader left">
                                 Fit & Proper Questions
                              </h4>
                              }                              
                           </div>
                           {isRegistered && locationPath === '/fit2practice' &&
                              <div>                           
                                 <p className = 'view-only-note'>
                                    This is where you can view your answers to the fit and proper questions
                                 </p>                                 
                              </div>
                           }
                           {isRegistered &&
                           <div>
                              <p>
                                 The following fit and proper questions will need to be answered when renewing your licence(s).
                              </p>
                              <p>
                                 You can answer the questions when renewing your licence(s) through the licensing tab. If you need to change one of your answers, please contact the licensing team on <span className='contact-numnber'><b>0800 743 262</b></span>
                              </p>
                           </div>
                           }
                           {isRegistered &&
                           fitAndProperData.questionsAndAnswers.map((q, i) => {
                              const latestAnswer = q.latestAnswer === true 
                                       ? OPTION_YES : q.latestAnswer === false 
                                       ? OPTION_NO : ''

                              return <div key={i}>
                                 <div>
                                    <RadioButtons 
                                       radioOptions={[OPTION_YES, OPTION_NO]} 
                                       title={q.questionText} 
                                       groupName={q.id} 
                                       onChange={onValueChange}
                                       disabled={disableSave}
                                       value={latestAnswer}
                                       index={`${i + 1}) `}
                                    />
                                 </div>
                              </div>
                           })}
                           {
                           footer ? footer(() => trySaveFitAndProper()) 
                                          : (isRegistered)
                           }
                        </div>
                        {isRegistered && importantNote()}
                     </div>
                  </div>
               </div>
            </div>
         </Loading>
      </main>
   );
};

FitAndProper.propTypes = {
   userLicence: object.isRequired,
   fitAndProperData: object.isRequired,
   isLoading: bool,
   isSaveSuccess: bool,
   userContactData: object.isRequired,
};

const mapStateToProps = state => {

   return {
      userLicence: getLicenceData(state),
      contactData: getUserInfoData(state.user),
      token: getToken(state),
      isFitAndProperLoaded: isFetchingQASuccess(state),
      isSaveSuccess: isSaveQASuccess('saveAnswerList', state),
      fitAndProperData: getQAsData(state),
      isLoading: isFetchingQA(state),
      userContactData: state.user.contactData,
   }
}

export default connect(mapStateToProps)(FitAndProper);
