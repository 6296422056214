import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import './ApplicationStatus.scss';
import { object } from 'prop-types';
import {
   PLUMBING,
   GASFITTING,
   DRAINLAYING,
} from 'pgdb-data-layer/lib/Constants/Disciplines';
import ApplicationAction, {
   isFetchingApplication,
   isFetchingApplicationSuccess,
} from 'pgdb-data-layer/lib/Redux/ApplicationsRedux';
import ApplicationStatusRow from './ApplicationStatusRow';
import { getToken } from '../../../../Session/SessionRedux';

export const getLastApplicationByDiscipline = (
   listRegistration,
   discipline
) => {
   const listFiltered = Array.from(listRegistration)
      .filter(application => application.discipline === discipline)
      .sort((a, b) => Date.parse(b.enteredDate) - Date.parse(a.enteredDate));

   return listFiltered.length > 0 ? listFiltered[0] : undefined;
};

const ApplicationStatus = ({
   userRegistration,
   token,
   deleteApplication,
   isDeleting,
   isDeleteSuccess,
   onApplicationDeleted,
   saveApplicationRequest,
}) => {
   const [plumberStatus, setPlumberStatus] = useState();
   const [drainlayerStatus, setDrainlayerStatus] = useState();
   const [gasfitterStatus, setGasfitterStatus] = useState();

   useEffect(() => {
      if (
         userRegistration &&
         userRegistration.registration.applicationRegistrations
      ) {
         if (userRegistration.registration.applicationRegistrations) {
            setPlumberStatus(
               getLastApplicationByDiscipline(
                  userRegistration.registration.applicationRegistrations,
                  PLUMBING
               )
            );
            setGasfitterStatus(
               getLastApplicationByDiscipline(
                  userRegistration.registration.applicationRegistrations,
                  GASFITTING
               )
            );
            setDrainlayerStatus(
               getLastApplicationByDiscipline(
                  userRegistration.registration.applicationRegistrations,
                  DRAINLAYING
               )
            );
         }
      }
   }, [userRegistration]);

   useEffect(() => {
      if (isDeleteSuccess) {
         onApplicationDeleted();
      }
   }, [isDeleteSuccess]);

   const deleteApplicationFromRegistration = applicationId => {
      deleteApplication(token, applicationId);
   };

   const continueApplication = application => {
      const payload = {
         applications: [],
      };
      payload.applications.push({
         contactId: application.contactId,
         applicationTypeCode: application.applicationTypeCode,
         licenseYearId: 0,
         createLicence: true,
         isRegistrationApplication: true,
      });

      saveApplicationRequest(token, payload);
   };

   return (
      <>
         {(plumberStatus || gasfitterStatus || drainlayerStatus) && (
            <>
               <div className="row">
                  <div className="col s11 offset-l1">
                     <h4
                        className="columnheader"
                        id="registration-section-header"
                     >
                        Application Status
                     </h4>
                  </div>
               </div>
               <div className="row m-0">
                  <div className="col l10 m12 offset-l1 s12 header-column">
                     <div className="row mb-0">
                        <div className="col s3 m3 l3 center-align">Type</div>
                        <div className="col s2 m2 l2 center-align">
                           Submission date
                        </div>
                        <div className="col s3 l3 m3 center-align">Status</div>
                        <div className="col s4 l4 m4 center-align">Action</div>
                     </div>
                  </div>
               </div>
            </>
         )}

         {plumberStatus && (
            <ApplicationStatusRow
               registration={plumberStatus}
               isDeleting={isDeleting}
               isDeleteSuccess={isDeleteSuccess}
               onDelete={() =>
                  !isDeleting &&
                  deleteApplicationFromRegistration(plumberStatus.applicationId)
               }
               onContinue={() => continueApplication(plumberStatus)}
            />
         )}

         {gasfitterStatus && (
            <ApplicationStatusRow
               registration={gasfitterStatus}
               isDeleting={isDeleting}
               isDeleteSuccess={isDeleteSuccess}
               onDelete={() =>
                  !isDeleting &&
                  deleteApplicationFromRegistration(
                     gasfitterStatus.applicationId
                  )
               }
               onContinue={() => continueApplication(gasfitterStatus)}
            />
         )}
         {drainlayerStatus && (
            <ApplicationStatusRow
               registration={drainlayerStatus}
               isDeleting={isDeleting}
               isDeleteSuccess={isDeleteSuccess}
               onDelete={() =>
                  !isDeleting &&
                  deleteApplicationFromRegistration(
                     drainlayerStatus.applicationId
                  )
               }
               onContinue={() => continueApplication(drainlayerStatus)}
            />
         )}
      </>
   );
};

ApplicationStatus.propTypes = {
   userRegistration: object,
};

const mapStateToProps = state => {
   return {
      userRegistration: state.registration,
      token: getToken(state),
      isDeleting: isFetchingApplication('deleteApplication', state),
      isDeleteSuccess: isFetchingApplicationSuccess('deleteApplication', state),
   };
};

const mapDispatchToProps = {
   deleteApplication: ApplicationAction.deleteApplicationRequest,
   saveApplicationRequest: ApplicationAction.saveApplicationRequest,
};

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(ApplicationStatus);
