import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SupervisionActions, {
   isUserSupervisionTasksCompleteSelector,
   isFetchingSupervision,
} from 'pgdb-data-layer/lib/Redux/SupervisionRedux';
import DashboardCard from '../index';
import { getToken } from '../../../Session/SessionRedux';

class SupervisionCard extends Component {
   componentDidMount() {
      const { dispatch, token } = this.props;
      dispatch(
         SupervisionActions.getUserSupervisionTasksCompleteRequest(token)
      );
   }

   render() {
      const { isLoading, supervisionComplete } = this.props;
      return (
         <DashboardCard
            header="Supervision"
            icon="how_to_reg"
            iconStyle={
               supervisionComplete.completed
                  ? 'light-green-text'
                  : 'orange-text'
            }
            description={
               supervisionComplete.completed
                  ? 'Your supervision tasks have been completed'
                  : 'You have pending supervision tasks'
            }
            visible={!isLoading}
            disabled={!isLoading && !supervisionComplete.isVisible}
            navLink={{
               href: 'Supervision',
               text: 'View Details',
            }}
         />
      );
   }
}

SupervisionCard.defaultProps = {
   isLoading: true,
};

SupervisionCard.propTypes = {
   supervisionComplete: PropTypes.any,
   dispatch: PropTypes.func.isRequired,
   token: PropTypes.string.isRequired,
   isLoading: PropTypes.bool,
};

const mapStateToProps = state => {
   return {
      supervisionComplete: isUserSupervisionTasksCompleteSelector(state),
      token: getToken(state),
      isLoading: isFetchingSupervision(
         'getUserSupervisionTasksComplete',
         state
      ),
   };
};

export default connect(mapStateToProps)(SupervisionCard);
