import React from 'react';
import { object } from 'prop-types';
import { useHistory } from 'react-router-dom';
import Navigator from '../../../Navigation/Navigator';
import './RegistrationApplicantDetails.scss';
import Button from '../../../Components/Buttons/DefaultButton/button';
import ProfileEntry from '../Profile/ProfileEntry';

const RegistrationApplicationDetails = ({ location }) => {
   const history = useHistory();

   const registrationApplicantDetailsHeader = () => {
      return (
         <div className="col s3 m6 l7 offset-l1">
            <div className="row profile-row">
               <div className="col l12">
                  <h4 className="columnheader">Registration Application</h4>
               </div>
            </div>
            <div className="row profile-row">
               <div className="col l12">
                  <h6 className="subheader">
                     {`Section 1/3: Applicant's & Public Register Details`}
                  </h6>
               </div>
            </div>
         </div>
      );
   };

   const onSaveClicked = (onSave, onPostSave) => {
      let saved = false;
      if (onSave) saved = onSave();
      if (saved && onPostSave) onPostSave();
   };

   const footerGenerator = (onBackAction, onNextAction) => {
      return onSaveHandler => {
         return (
            <div className="col m8 l8 offset-l1">
               <div className="row profile-row next-button-registration right">
                  <div className="navigate-buttons">
                     <button
                        className="waves-effect waves-light button-margin registration-application-button"
                        onClick={() => {
                           let saved = false;
                           if (onSaveHandler) saved = onSaveHandler();
                           if (saved && onNextAction) onNextAction();
                        }}
                     >
                        SAVE DRAFT AND NEXT
                     </button>
                  </div>
               </div>
            </div>
         );
      };
   };

   return (
      <main>
         <div className="section no-pad-bot" id="index-banner">
            <ProfileEntry
               header={registrationApplicantDetailsHeader()}
               footer={footerGenerator(
                  () => {
                     Navigator.toRegistrationType();
                  },
                  () => {
                     Navigator.toRegistrationFitAndProper();
                  }
               )}
               location={location}
               routeBackUrl="/registration-type"
               isRegistration
            />
         </div>
      </main>
   );
};

export default RegistrationApplicationDetails;
