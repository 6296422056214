import { Component } from 'react';
import { connect } from 'react-redux';
import { isEqual, get, includes, remove } from 'lodash';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import Navigator from '../../Navigation/Navigator';
import { callsToHandled, callsRequiredRTRChecks } from './CallsToHandle';
import * as session from '../../Session';

class ErrorHandler extends Component {
   constructor(props) {
      super(props);
      this.state = {
         hasError: false,
         hasSessionExpired: null,
         hasCheckedSession: false,
         errorPaths: [],
      };
   }

   componentDidMount() {
      // Validate if the user session has expired.
      // This circumvents a potential race condition when rendering the
      // component.
      session.hasSessionExpired().then(hasSessionExpired => {
         this.setState({ hasSessionExpired, hasCheckedSession: true });
      });
   }

   static getDerivedStateFromError(error) {
      return { hasError: true };
   }

   componentDidUpdate(prevProps, prevState) {
      const { hasError, hasSessionExpired, errorPaths } = this.state;
      const { state } = this.props;

      if (hasSessionExpired) {
         Navigator.toLogin();
      }

      if (isEqual(this.props, prevProps)) {
         return;
      }

      // check for any page errors:
      if (hasError) {
         const unexpError = 'Unexpected Error';
         this.setState({ hasError: false }, () => {
            this.logErrorIntoAppInsight(unexpError);
            Navigator.to({
               pathname: '/error',
               error: { type: unexpError },
            });
         });
      }

      // check the list of api calls for any errors
      callsToHandled.forEach(item => {
         const path = `${item}.error`;

         const isExisted = includes(errorPaths, path);
         const foundErrorItem = get(state, path);

         if (foundErrorItem && !isExisted) {
            errorPaths.push(path);
            this.setState({ errorPaths }, () => {
               if (
                  includes(callsRequiredRTRChecks, item) &&
                  foundErrorItem.status === 422
               ) {
                  this.setState({ hasError: false }, () => {
                     Navigator.toUnableToPerformOnline();
                  });
               } else if (foundErrorItem.status === 401) {
                  // unauthorised requests should be logged out because
                  // people don't like to read error pages
                  this.setState({ hasError: false }, () => {
                     Navigator.toLogout();
                  });
               } else if (foundErrorItem.status === null) {
                  // if no status code is returned then we know that it never made it to the server
                  // this could be caused by a stalled request from the browser.
                  // chrome will stall requests if the updated item is no longer present on the page
                  // i.e. user quickly navigated between different pages before the api could return back results
                  // for the first page.
                  // chrome will also stall requests when more than 6 requests are trying to write to cache store concurrently.
                  // this will be handled by the no-store cache header set on each request within the data layer
                  this.logWarningIntoAppInsight(foundErrorItem);
               } else {
                  this.setState({ hasError: false }, () => {
                     Navigator.to({
                        pathname: '/error',
                        error: foundErrorItem,
                     });
                  });
                  this.logErrorIntoAppInsight(foundErrorItem);
               }
            });
         }

         if (!foundErrorItem && isExisted) {
            remove(errorPaths, s => {
               return s === path;
            });
            this.setState({ errorPaths });
         }
      });
   }

   logErrorIntoAppInsight = error => {
      const { appInsight } = this.props;
      if (appInsight) {
         const message =
            typeof error === 'string' ? error : error.type ? error.type : error;

         appInsight.trackException({
            error: new Error(message),
            severityLevel: SeverityLevel.error,
         });
      }
   };

   logWarningIntoAppInsight = error => {
      const { appInsight } = this.props;
      if (appInsight) {
         const message =
            typeof error === 'string' ? error : error.type ? error.type : error;

         appInsight.trackException({
            error: new Error(message),
            severityLevel: SeverityLevel.Warning,
         });
      }
   };

   logInfoIntoAppInsight = error => {
      const { appInsight } = this.props;
      if (appInsight) {
         const message =
            typeof error === 'string' ? error : error.type ? error.type : error;

         appInsight.trackTrace({
            message,
            severityLevel: SeverityLevel.Information,
         });
      }
   };

   render() {
      const { hasCheckedSession } = this.state;
      const { children } = this.props;

      if (hasCheckedSession) {
         return children;
      }
      return null;
   }
}

const mapStateToProps = state => {
   return {
      state,
   };
};

export default connect(mapStateToProps)(ErrorHandler);
