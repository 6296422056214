import Immutable from 'seamless-immutable';
import { isEmpty } from 'lodash';
import {
   getSearchTypeDescription,
   SEARCH_TYPES,
   CURRENTLY_LICENSED_ONLY,
   COMBINED_SEARCH_VALIDATION,
   hasSearchValues,
   INITIAL_SEARCH_STATE,
} from './data';
import { SEARCH_LICENCE_TYPES } from 'pgdb-data-layer/lib/Constants/LicenceTypes';
import {
   IS_LICENCE_NUMBER,
   IS_VALID_SEARCH_TEXT,
   IS_POSTCODE,
} from 'pgdb-data-layer/lib/Constants/Regex';

const MIN_TEXT_SEARCH_LENGTH = 3;
const MIN_POSTCODE_SEARCH_LENGTH = 3;
const MAX_POSTCODE_SEARCH_LENGTH = 10;

const isValidSearchLicenceNumber = licenceNumber => {
   if (!licenceNumber) return false;

   return IS_LICENCE_NUMBER.test(licenceNumber.trim());
};

export const getFormattedSearchLicenceNumber = licenceNumber => {
   if (!licenceNumber || !isValidSearchLicenceNumber(licenceNumber)) return '';

   let licenceCodeText = licenceNumber.trim();

   const employerPrefix = 'E';
   const isEmployer = licenceCodeText[0].toUpperCase() === employerPrefix;
   if (isEmployer)
      licenceCodeText = licenceCodeText.substring(employerPrefix.length);

   const intCode = parseInt(licenceCodeText, 10);
   let intCodeText = intCode.toString();
   const MAX_LENGTH = 5;

   // for display, we always want a 5 digit number, so pad with leading zeroes
   if (intCodeText.length < MAX_LENGTH)
      intCodeText = intCodeText.padStart(5, '0');

   const employerValuePrefix = isEmployer ? employerPrefix : '';
   return employerValuePrefix + intCodeText;
};

// applies for string search values
const isValidSearchText = text => {
   if (!text) return false;

   return IS_VALID_SEARCH_TEXT.test(text.trim());
};

const isValidSearchName = isValidSearchText;
const isValidSearchSuburb = isValidSearchText;
const isValidSearchTown = isValidSearchText;

const isValidSearchPostcode = postcode => {
   if (!postcode) return false;

   const textPostcode =
      typeof postcode === 'number' ? postcode.toString() : postcode;

   const trimmedPostcode = textPostcode.trim();
   if (
      !trimmedPostcode ||
      trimmedPostcode.length < MIN_POSTCODE_SEARCH_LENGTH ||
      trimmedPostcode.length > MAX_POSTCODE_SEARCH_LENGTH
   ) {
      return false;
   }

   return IS_POSTCODE.test(trimmedPostcode);
};

const isValidSearchLicenceType = licenceType => {
   if (!licenceType) return false;

   const trimmedLicenceType = licenceType.trim();
   return !!SEARCH_LICENCE_TYPES[trimmedLicenceType];
};

const validationSuccess = Immutable({ valid: true, message: '' });
export const emptyTextValidationMessage = Immutable({
   valid: false,
   message: 'You need to enter a value to search for.',
});

const validateLicenceNumber = licenceNumber => {
   const licenceNumberText =
      typeof licenceNumber === 'number'
         ? licenceNumber.toString()
         : licenceNumber;

   const trimmedLicenceNumber = (licenceNumberText || '').trim();
   if (trimmedLicenceNumber === '') return emptyTextValidationMessage;

   return isValidSearchLicenceNumber(trimmedLicenceNumber)
      ? validationSuccess
      : { valid: false, message: 'Please provide a valid licence number.' };
};

const tooShortTextValidationMessage = Immutable({
   valid: false,
   message: 'Please enter a minimum of 3 characters to search this category.',
});
const validateName = name => {
   if (!name) return emptyTextValidationMessage;
   if (name.length < MIN_TEXT_SEARCH_LENGTH)
      return tooShortTextValidationMessage;

   return isValidSearchName(name)
      ? validationSuccess
      : { valid: false, message: 'Please enter a valid name.' };
};

const validateSuburb = suburb => {
   if (!suburb) return emptyTextValidationMessage;
   if (suburb.length < MIN_TEXT_SEARCH_LENGTH)
      return tooShortTextValidationMessage;

   return isValidSearchSuburb(suburb)
      ? validationSuccess
      : { valid: false, message: 'Please enter a valid suburb.' };
};

const validateTown = town => {
   if (!town) return emptyTextValidationMessage;
   if (town.length < MIN_TEXT_SEARCH_LENGTH)
      return tooShortTextValidationMessage;

   return isValidSearchTown(town)
      ? validationSuccess
      : { valid: false, message: 'Please enter a valid town/city.' };
};

const validatePostcode = postcode => {
   if (!postcode) return emptyTextValidationMessage;

   return isValidSearchPostcode(postcode)
      ? validationSuccess
      : { valid: false, message: 'Please enter a valid postcode.' };
};

const validateLicenceType = licenceType => {
   if (!licenceType)
      return { valid: false, message: 'Please select a licence type.' };

   return isValidSearchLicenceType(licenceType)
      ? validationSuccess
      : { valid: false, message: 'Please provide a valid licence type.' };
};

const validators = Immutable({
   [SEARCH_TYPES.LICENCE]: validateLicenceNumber,
   [SEARCH_TYPES.NAME]: validateName,
   [SEARCH_TYPES.SUBURB]: validateSuburb,
   [SEARCH_TYPES.TOWN]: validateTown,
   [SEARCH_TYPES.POSTCODE]: validatePostcode,
   [SEARCH_TYPES.LICENCE_TYPE]: validateLicenceType,
   [CURRENTLY_LICENSED_ONLY]: _ => {
      return { valid: true, message: '' };
   },
});

export const validateSearchRequest = (searchType, searchValue) => {
   const description = getSearchTypeDescription(searchType);
   if (!description)
      return { valid: false, message: 'Please select a Search Category.' };

   const validator = validators[searchType];
   return validator
      ? validator(searchValue.searchValue)
      : { valid: false, message: 'Invalid search type.' };
};

export const validateAdvancedSearchRequest = searchValues => {
   if (!hasSearchValues(searchValues)) {
      return {
         [COMBINED_SEARCH_VALIDATION]: emptyTextValidationMessage.message,
      };
   }

   const validationResult = {};

   Object.keys(searchValues).forEach(searchType => {
      const searchValue = searchValues[searchType];
      const validator = validators[searchType];

      if (!searchValue) return;

      const result = validator
         ? validator(searchValue)
         : { valid: false, message: 'Invalid search type.' };

      if (!result.valid) validationResult[searchType] = result.message;
   });

   // edge case, when we are searching for a licence number
   // this is a strict search and we should have no other values
   if (isEmpty(validationResult)) {
      const hasLicenceNumber =
         searchValues[SEARCH_TYPES.LICENCE] !==
         INITIAL_SEARCH_STATE[SEARCH_TYPES.LICENCE];
      if (hasLicenceNumber) {
         const withoutLicence = { ...searchValues, [SEARCH_TYPES.LICENCE]: '' };
         if (hasSearchValues(withoutLicence)) {
            return {
               [COMBINED_SEARCH_VALIDATION]:
                  'Unable to search for a licence number with other categories.',
            };
         }
      }
   }

   return validationResult;
};
