import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty, find } from 'lodash';
import * as EXAMINATION from 'pgdb-data-layer/lib/Constants/Examination';
import {
   Table,
   TableHead,
   TableHeaderCell,
   TableRow,
   TableBody,
} from '../../../../Components/GenericTable';
import CertifierExamRow from './CertifierExamRow';

const CertifierExamTable = ({ examination, examinationDetails }) => {
   const checkExaminationHasBeenBooked = examinationId => {
      return !find(examinationDetails.filter(detail => !detail.isDeleted), {
         examinationId,
      });
   };

   if (
      isEmpty(examination.userExaminations) ||
      !examination.userExaminations.some(
         exam =>
            EXAMINATION.isCertifierCourse(exam.course.courseNumber.trim()) &&
            checkExaminationHasBeenBooked(exam.examinationId)
      )
   ) {
      return <></>;
   }

   const certifierExaminations = examination.userExaminations
      .filter(
         exam =>
            EXAMINATION.isCertifierCourse(exam.course.courseNumber.trim()) &&
            checkExaminationHasBeenBooked(exam.examinationId)
      )
      .map(exam => ({
         ...exam,
         enableVenueSelection: { value: false, writable: true },
      }));

   return (
      <>
         <h5 className="subheader">Certifier</h5>
         <Table>
            <colgroup>
               <col width="5%" />
               <col width="30%" />
               <col width="20%" />
               <col width="30%" />
               <col width="15%" />
            </colgroup>
            <TableHead>
               <TableRow>
                  <TableHeaderCell />
                  <TableHeaderCell className="no-left-pad">
                     Exam Type
                  </TableHeaderCell>
                  <TableHeaderCell className="no-left-pad">
                     Exam Date
                  </TableHeaderCell>
                  <TableHeaderCell className="no-left-pad">
                     Exam Venue
                  </TableHeaderCell>
                  <TableHeaderCell className="center" />
               </TableRow>
            </TableHead>
            <TableBody>
               {certifierExaminations.map((value, i) => {
                  return (
                     <CertifierExamRow
                        examination={value}
                        key={value.examinationId}
                     />
                  );
               })}
            </TableBody>
         </Table>
      </>
   );
};

CertifierExamTable.propTypes = {
   examination: PropTypes.any,
   examinationDetails: PropTypes.any,
};

CertifierExamTable.defaultProps = {
   examination: {},
};

const mapStateToProps = state => {
   return {
      examination: state.examination.examinations,
      examinationDetails: state.examination.examinationDetails,
   };
};

export default connect(mapStateToProps)(CertifierExamTable);
