import { connect } from 'react-redux';
import ExaminationActions from 'pgdb-data-layer/lib/Redux/ExaminationRedux';
import { getUserInfoData } from 'pgdb-data-layer/lib/Redux/UserInfoRedux';
import FileSaver from 'file-saver';
import { isEqual } from 'lodash';
import M from 'materialize-css';
import PropTypes from 'prop-types';
import React from 'react';
import ExternalLink from '../../../../Components/ExternalLink';
import TableCell from '../../../../Components/GenericTable/TableCell';
import TableRow from '../../../../Components/GenericTable/TableRow';
import { getToken } from '../../../../Session/SessionRedux';

class TradesmanExamRow extends React.Component {
   constructor(props) {
      super(props);
      this.state = { checked: false, isDownloading: false };
      this.handleChange = this.handleChange.bind(this);
   }

   handleChange(e) {
      const { checked } = this.state;
      this.setState({ checked: !checked });
   }

   componentDidUpdate(prevProps) {
      const { isDownloading } = this.state;

      if (
         !isEqual(prevProps.eligibilityReport, this.props.eligibilityReport) &&
         isDownloading
      ) {
         this.downloadPDF();
         this.setState({ isDownloading: !isDownloading });
      }
   }

   getExamEligibilityDetails() {
      const { isDownloading } = this.state;
      const { token } = this.props;

      if (token) {
         this.setState({ isDownloading: !isDownloading });
         this.props.dispatch(
            ExaminationActions.getUserExamEligibilityReportRequest(token)
         );
      }
   }

   downloadPDF() {
      const { eligibilityReport, contactData } = this.props;
      if (eligibilityReport) {
         const blob = new Blob([eligibilityReport], {
            type: 'application/pdf',
         });

         FileSaver.saveAs(
            blob,
            `EligibilityReport-${contactData.registrationNumber}.pdf`
         );
      } else {
         M.toast({
            html: `Sorry there seems to be an issue retrieving your eligibility report.
            Please contact the Plumbers, Gasfitters and Drainlayers Board.`,
            classes: 'error',
         });
      }
   }

   render() {
      const { checked } = this.state;
      const { examination } = this.props;

      return (
         <TableRow>
            <td className="table-data small status-cell">
               <label>
                  <input
                     type="checkbox"
                     className="filled-in checkbox-blue"
                     onChange={this.handleChange}
                     checked={checked}
                  />
                  <span />
               </label>
            </td>
            <TableCell>{examination.course.courseName}</TableCell>
            <TableCell>
               {checked && (
                  <span
                     onClick={() => this.getExamEligibilityDetails()}
                     className="exam-link-button"
                  >
                     Download Eligibility Report
                  </span>
               )}
            </TableCell>
            <TableCell>
               {checked && (
                  <ExternalLink href="https://pgdb.aspeqexams.com/">
                     Register with Aspeq
                  </ExternalLink>
               )}
            </TableCell>
         </TableRow>
      );
   }
}

TradesmanExamRow.propTypes = {
   examination: PropTypes.any,
   token: PropTypes.string.isRequired,
};

TradesmanExamRow.defaultProps = {
   examination: {},
};

const mapStateToProps = state => {
   return {
      eligibilityReport: state.examination.eligibilityReport,
      contactData: getUserInfoData(state.user),
      token: getToken(state),
   };
};

export default connect(mapStateToProps)(TradesmanExamRow);
