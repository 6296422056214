import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Router, Route } from 'react-router-dom';
import M from 'materialize-css';
import Header from './Layout/Header';
import Footer from './Layout/Footer';
import Home from './Pages/Home';
import Login from './Pages/Login';
import Licences from './Pages/Licences';
import Registration from './Pages/Registrations/Registration';
import RegistrationApply from './Pages/RegistrationApply/RegistrationApply';
import RegistrationType from './Pages/RegistrationType';
import RegistrationApplicantDetails from './Pages/RegistrationApplicantDetails/RegistrationApplicantDetails';
import RegistrationFitAndProper from './Pages/RegistrationFitAndProper/RegistrationFitAndProper';
import RegistrationUploadDocuments from './Pages/RegistrationUploadDocuments/RegistrationUploadDocuments';
import RegistrationReviewAndSubmit from './Pages/RegistrationReviewAndSubmit/RegistrationReviewAndSubmit';
import RegistrationSuccess from './Pages/RegistrationSuccess/RegistrationSuccess';
import LicenceRegistrationFitAndProper from './Pages/LicenceRegistrationFitAndProper/FitAndProperPage';
import Renew from './Pages/Renew';
import RenewContactDetails from './Pages/RenewContactDetails';
import RenewCPD from './Pages/RenewCPD';
import RenewSupervisors from './Pages/RenewSupervisors';
import RenewLicencesIndividual from './Pages/RenewLicencesIndividual';
import CPD from './Pages/CPD';
import Exams from './Pages/Exam/Exams';
import Logout from './Pages/Logout';
import Supervision from './Pages/Supervision/index';
import Profile from './Pages/Profile';
import Receipts from './Pages/Receipts';
import Notifications from './Pages/Notifications';
import PublicRegister from './Pages/PublicRegister';
import PublicRegisterAdvanced from './Pages/PublicRegisterAdvanced';
import SignInCallback from './Pages/SignInCallback';
import LoginRoute from './Landing/loginRoute';
import history from '../Navigation/History';
import ProfilePhotoCriteria from './Pages/Profile/ProfilePhotoCriteria';
import ProfilePhotoEditor from './Pages/Profile/ProfilePhoto/ProfilePhotoEditor';
import RenewalSummary from './Pages/RenewalSummary/RenewalSummary';
import Confirmation from './Pages/Confirmation';
import ExamConfirmation from './Pages/Exam/Confirmation/ExamConfirmation';
import PaymentSuccess from './Pages/PaymentSuccess';
import PaymentFail from './Pages/PaymentFail';
import ExamPaymentSummary from './Pages/Exam/ExamPaymentSummary/ExamPaymentSummary';
import ExamDeliveryAddress from './Pages/Exam/ExamDeliveryAddress';
import UpdateExamDeliveryAddress from './Pages/Exam/UpdateExamDeliveryAddress';
import ErrorPage from './Pages/ErrorPage';
import { BulkRenewLanding, BulkRenewPayAndNominate } from './Pages/BulkRenew';
import {
   CompanyContactDetails,
   PayAndNominate,
   CompanyRenewalSummary,
   PaymentSuccessful,
} from './Pages/CompanyRenewal';
import UnableToPerformOnline from './Pages/UnableToPerformOnline';
import TelemetryProvider from '../AppInsight/TelemetryProvider';
import FitAndProper from './Pages/FitAndProper';
import FitAndProperWizardPage from './Pages/FitAndProper/FitAndProperWizardPage';
import StylingPage from './Pages/Styling/StylingPage';
import RegistrationPendingDocuments from './Pages/RegistrationPendingDocuments/RegistrationPendingDocuments';
import RegistrationPayment from './Pages/RegistrationPayment/RegistrationPayment';
import RegistrationPaymentSuccess from './Pages/RegistrationPayment/RegistrationPaymentSuccess';

const Main = ({ authenticated, checked }) => (
   <Router history={history}>
      <TelemetryProvider>
         {checked && (
            <>
               <Header authenticated={authenticated} />
               <Route path="/error" component={ErrorPage} />
               <Route path="/login" component={Login} />
               <Route path="/logout" component={Logout} />
               <Route path="/signincallback" component={SignInCallback} />
               <Route
                  exact
                  path="/"
                  component={Login}
                  authenticated={authenticated}
               />
               <Route
                  exact
                  path="/public-register"
                  component={PublicRegister}
                  authenticated={authenticated}
               />
               <Route
                  exact
                  path="/public-register/advanced"
                  component={PublicRegisterAdvanced}
                  authenticated={authenticated}
               />
               <Route
                  path="/company-renew"
                  render={() => <Renew isCompany />}
               />
               <Route
                  exact
                  path="/renew-company-contact-details"
                  component={CompanyContactDetails}
               />
               <Route
                  exact
                  path="/pay-and-nominate"
                  component={PayAndNominate}
               />
               <Route
                  exact
                  path="/company-renewal-summary"
                  component={CompanyRenewalSummary}
               />
               <Route
                  exact
                  path="/renew-company-payment-successful"
                  component={PaymentSuccessful}
               />
               <Route
                  exact
                  path="/payment-success/:url"
                  component={PaymentSuccess}
               />
               <Route exact path="/payment-fail" component={PaymentFail} />
               <Route
                  exact
                  path="/unable-to-perform"
                  component={UnableToPerformOnline}
               />
               <LoginRoute
                  exact
                  path="/exam-delivery-address"
                  component={ExamDeliveryAddress}
                  authenticated={authenticated}
               />
               <LoginRoute
                  exact
                  path="/update-exam-details"
                  component={UpdateExamDeliveryAddress}
                  authenticated={authenticated}
               />
               <LoginRoute
                  exact
                  path="/exam-payment-summary"
                  component={ExamPaymentSummary}
                  authenticated={authenticated}
               />
               <LoginRoute
                  exact
                  path="/dashboard"
                  component={Home}
                  authenticated={authenticated}
               />
               <LoginRoute
                  exact
                  path="/licences"
                  component={Licences}
                  authenticated={authenticated}
               />
               <LoginRoute
                  exact
                  path="/renew"
                  component={Renew}
                  authenticated={authenticated}
                  isCompany={false}
               />
               <LoginRoute
                  exact
                  path="/renew-contact-details"
                  component={RenewContactDetails}
                  authenticated={authenticated}
               />
               <LoginRoute
                  exact
                  path="/renew-cpd"
                  component={RenewCPD}
                  authenticated={authenticated}
               />
               <LoginRoute
                  exact
                  path="/renew-supervisors"
                  component={RenewSupervisors}
                  authenticated={authenticated}
               />
               <LoginRoute
                  exact
                  path="/renew-licences"
                  component={RenewLicencesIndividual}
                  authenticated={authenticated}
               />
               <LoginRoute
                  exact
                  path="/renew-summary"
                  component={RenewalSummary}
                  authenticated={authenticated}
               />
               <LoginRoute
                  exact
                  path="/cpd"
                  component={CPD}
                  authenticated={authenticated}
               />
               <LoginRoute
                  exact
                  path="/bulk-renew-landing"
                  component={BulkRenewLanding}
                  authenticated={authenticated}
               />
               <LoginRoute
                  exact
                  path="/bulk-renew-pay-and-nominate"
                  component={BulkRenewPayAndNominate}
                  authenticated={authenticated}
               />
               <LoginRoute
                  exact
                  path="/exams"
                  component={Exams}
                  authenticated={authenticated}
               />
               <LoginRoute
                  exact
                  path="/supervision"
                  component={Supervision}
                  authenticated={authenticated}
               />
               <LoginRoute
                  exact
                  path="/profile"
                  component={Profile}
                  authenticated={authenticated}
               />
               <LoginRoute
                  exact
                  path="/receipts"
                  component={Receipts}
                  authenticated={authenticated}
               />
               <LoginRoute
                  exact
                  path="/notifications"
                  component={Notifications}
                  authenticated={authenticated}
               />
               <LoginRoute
                  exact
                  path="/photocriteria"
                  component={ProfilePhotoCriteria}
                  authenticated={authenticated}
               />
               <LoginRoute
                  exact
                  path="/profile-photo-editor"
                  component={ProfilePhotoEditor}
                  authenticated={authenticated}
               />
               <LoginRoute
                  exact
                  path="/confirmation"
                  component={Confirmation}
                  authenticated={authenticated}
               />
               <LoginRoute
                  exact
                  path="/exam-confirmation"
                  component={ExamConfirmation}
                  authenticated={authenticated}
               />
               <LoginRoute
                  exact
                  path="/registration"
                  component={Registration}
                  authenticated={authenticated}
               />
               <LoginRoute
                  exact
                  path="/registration-apply"
                  component={RegistrationApply}
                  authenticated={authenticated}
               />
               <LoginRoute
                  exact
                  path="/registration-type"
                  component={RegistrationType}
                  authenticated={authenticated}
               />
               <LoginRoute
                  exact
                  path="/registration-applicant-details"
                  component={RegistrationApplicantDetails}
                  authenticated={authenticated}
               />
               <LoginRoute
                  exact
                  path="/registration-fit-and-proper"
                  component={RegistrationFitAndProper}
                  authenticated={authenticated}
               />
               <LoginRoute
                  exact
                  path="/registration-upload-documents"
                  component={RegistrationUploadDocuments}
                  authenticated={authenticated}
               />
               <LoginRoute
                  exact
                  path="/registration-review-and-submit"
                  component={RegistrationReviewAndSubmit}
                  authenticated={authenticated}
               />
               <LoginRoute
                  exact
                  path="/registration-success"
                  component={RegistrationSuccess}
                  authenticated={authenticated}
               />
               <LoginRoute
                  exact
                  path="/registration-pending-documents/:applicationId"
                  component={RegistrationPendingDocuments}
                  authenticated={authenticated}
               />
               <LoginRoute
                  exact
                  path="/registration-payment/:applicationId/:contactId"
                  component={RegistrationPayment}
                  authenticated={authenticated}
               />
               <LoginRoute
                  exact
                  path="/registration-payment-success"
                  component={RegistrationPaymentSuccess}
                  authenticated={authenticated}
               />
               <LoginRoute
                  exact
                  path="/fit2practice"
                  component={FitAndProper}
                  authenticated={authenticated}
               />
               <LoginRoute
                  exact
                  path="/fit2practice-wizard"
                  component={FitAndProperWizardPage}
                  authenticated={authenticated}
               />
               <LoginRoute
                  exact
                  path="/styling-components"
                  component={StylingPage}
                  authenticated={authenticated}
               />
               <Footer />
            </>
         )}
      </TelemetryProvider>
   </Router>
);

Main.path = '';
Main.navigationOptions = {
   title: 'PGDB',
};
Main.componentDidMount = () => {
   M.Sidenav.init(this.sidenav);
};

const { bool } = PropTypes;

Main.propTypes = {
   authenticated: bool.isRequired,
   checked: bool.isRequired,
};

const mapState = ({ session }) => ({
   checked: session.checked,
   authenticated: session.authenticated,
});

export default connect(mapState)(Main);
