import React, { useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import ExaminationActions, {
   isFetchingExamination,
} from 'pgdb-data-layer/lib/Redux/ExaminationRedux';
import ResultActions, {
   isFetchingResult,
} from 'pgdb-data-layer/lib/Redux/ResultRedux';
import {
   TRADESMAN_PLUMBER_COURSE_NUM,
   TRADESMAN_GASFITTER_COURSE_NUM,
   TRADESMAN_DRAINLAYER_COURSE_NUM,
   CERTIFIER_PLUMBER_COURSE_NUM,
   CERTIFIER_GASFITTER_COURSE_NUM,
   CERTIFIER_DRAINLAYER_COURSE_NUM,
} from 'pgdb-data-layer/lib/Constants/Examination';
import { APPROVED_CODE, PASS_CODE } from 'pgdb-data-layer/lib/Constants/Course';
import { object, func, string } from 'prop-types';
import { isEmpty, sortBy } from 'lodash';
import M from 'materialize-css';
import FileSaver from 'file-saver';
import { getToken } from '../../../Session/SessionRedux';
import Callout from '../../../Components/Callout';
import Icon from '../../../Components/Icon';
import CertifierExamTable from './Views/CertifierExamTable';
import TradesmanExamTable from './Views/TradesmanExamTable';
import ExamEnrolledTable from './Views/ExamEnrolledTable';
import ExamResultsTable from './Views/ExamResultsTable';
import ExternalLink from '../../../Components/ExternalLink';
import ExaminationModalAlert from '../../../Components/ExaminationModalAlert/Index';
import Loading from '../../../Components/Loading';

const Exam = ({
   user,
   dispatch,
   examination,
   eligibilities,
   eligibilityReport,
   results,
   examinationDetails,
   isLoading,
   modalState,
   token,
}) => {
   const [hasDispatched, setHasDispatched] = useState(false);
   const [isDownloading, setIsDownloading] = useState(false);
   const [tradesExam, setTradesExam] = useState();

   const getExaminationData = useCallback(() => {
      if (user) {
         dispatch(ExaminationActions.getUserExamEligibilityRequest(token));
         dispatch(ExaminationActions.getAvailableExamVenuesRequest(token));
         dispatch(ExaminationActions.getExamCyclesRequest(token));
         dispatch(ExaminationActions.getUserExaminationDetailsRequest(token));
         dispatch(ResultActions.getExaminationResultsRequest(token));
         dispatch(ExaminationActions.getUserExamEligibilityReportReset());
         setHasDispatched(true);
      }
   }, [dispatch]);

   useEffect(() => {
      getExaminationData();
   }, [getExaminationData]);

   if (modalState && modalState.message) {
      M.toast({
         html: modalState.message,
         classes: modalState.success ? 'success' : 'error',
      });
   }

   useEffect(() => {
      if (isDownloading && !isLoading && eligibilityReport instanceof Blob) {
         const blob = new Blob([eligibilityReport], {
            type: 'application/pdf',
         });

         FileSaver.saveAs(blob, `EligibilityReport-${user.licence_id}.pdf`);
         setIsDownloading(false);
         dispatch(ExaminationActions.getUserExamEligibilityReportReset());
      }
   }, [eligibilityReport, isDownloading]);

   const getExamEligibilityDetails = () => {
      if (token) {
         dispatch(
            ExaminationActions.getUserExamEligibilityReportRequest(token)
         );
         setIsDownloading(true);
      }
   };

   const hasExamContent = !(
      isEmpty(results) &&
      isEmpty(examinationDetails) &&
      !eligibilities.isUserEligible
   );

   useEffect(() => {
      if (eligibilities && eligibilities.userExaminationEligibilities) {
         let tradeExams = [];

         eligibilities.userExaminationEligibilities.forEach(e => {
            if (
               !e.isCriteriaMet ||
               (e.isCriteriaMet &&
                  e.courseCriteria.displayText === 'Not Eligible')
            )
               return;

            // skip showing eligible exams if the user has a pass aproved result in the trade or certifying exam for that course
            if (
               !isEmpty(results) &&
               e.courseCriteria.course.courseNumber ===
                  TRADESMAN_GASFITTER_COURSE_NUM &&
               results.find(
                  r =>
                     r.resultStatusCode === APPROVED_CODE &&
                     r.passFailCode === PASS_CODE &&
                     (r.course.courseNumber ===
                        TRADESMAN_GASFITTER_COURSE_NUM ||
                        r.course.courseNumber ===
                           CERTIFIER_GASFITTER_COURSE_NUM)
               )
            )
               return;
            if (
               !isEmpty(results) &&
               e.courseCriteria.course.courseNumber ===
                  TRADESMAN_PLUMBER_COURSE_NUM &&
               results.find(
                  r =>
                     r.resultStatusCode === APPROVED_CODE &&
                     r.passFailCode === PASS_CODE &&
                     (r.course.courseNumber === TRADESMAN_PLUMBER_COURSE_NUM ||
                        r.course.courseNumber === CERTIFIER_PLUMBER_COURSE_NUM)
               )
            )
               return;

            if (
               !isEmpty(results) &&
               e.courseCriteria.course.courseNumber ===
                  TRADESMAN_DRAINLAYER_COURSE_NUM &&
               results.find(
                  r =>
                     r.resultStatusCode === APPROVED_CODE &&
                     r.passFailCode === PASS_CODE &&
                     (r.course.courseNumber ===
                        TRADESMAN_DRAINLAYER_COURSE_NUM ||
                        r.course.courseNumber ===
                           CERTIFIER_DRAINLAYER_COURSE_NUM)
               )
            )
               return;

            if (
               !tradeExams.find(
                  c => c.name.courseName === e.courseCriteria.course.courseName
               )
            ) {
               tradeExams.push({
                  name: e.courseCriteria.course,
                  type: e.courseCriteria.course.courseNumber,
               });
            }
         });
         const sortedExams = [];
         tradeExams = sortBy(tradeExams, v => v.type);

         tradeExams.forEach(e => {
            sortedExams.push(e.name);
         });
         setTradesExam(sortedExams);
      }
   }, [eligibilities, results]);

   const renderExamEligibility = () => {
      return (
         hasExamContent &&
         eligibilities.isUserEligible &&
         tradesExam &&
         tradesExam.length !== 0 && (
            <div className="row eligibility-report">
               <div className="col l10 offset-l1">
                  <p className="grey2-text sub-text">
                     You are currently eligible to sit the following exams:
                  </p>
                  <br />
                  <div className="eligibility-course-list">
                     {tradesExam.map((course, i) => {
                        return (
                           <div className="eligibility-course-name" key={i}>
                              {course.courseName}
                           </div>
                        );
                     })}
                  </div>
                  <div className="control-group">
                     <button
                        type="button"
                        onClick={getExamEligibilityDetails}
                        className="waves-effect waves-light btn"
                     >
                        Download Report
                     </button>
                     <ExternalLink
                        href="https://pgdb.aspeqexams.com/"
                        className="waves-effect waves-light btn"
                     >
                        Register with Aspeq
                     </ExternalLink>
                  </div>
               </div>
            </div>
         )
      );
   };

   return (
      <main>
         <div className="exam">
            <Loading isLoading={!hasDispatched || isLoading}>
               <div className="section" id="index-banner">
                  <div className="container">
                     <div className="row">
                        <div className="col l10 offset-l1">
                           {modalState && modalState.modalOpen && (
                              <div className="col l10 offset-l1">
                                 <ExaminationModalAlert />
                              </div>
                           )}
                           <h4 className="columnheader left">My Exams</h4>
                        </div>
                     </div>
                     {!hasExamContent && (
                        <>
                           <h3 className="columnheader">
                              You may not be eligible to sit any exams at the
                              moment
                           </h3>
                           <div className="row">
                              <div className="col s6 offset-l1">
                                 <Callout>
                                    Depending on the exam you want to sit you
                                    may need to:
                                    <br />
                                    <Icon
                                       className="arrow"
                                       iconName="arrow_forward"
                                    />{' '}
                                    hold a current trainee licence
                                    <br />
                                    <Icon
                                       className="arrow"
                                       iconName="arrow_forward"
                                    />{' '}
                                    or update some of your details with us
                                    <br />
                                 </Callout>
                                 <a
                                    className="btn-small manual-save"
                                    href="mailto:exams@pgdb.co.nz"
                                 >
                                    Contact us
                                 </a>
                                 <Callout>
                                    Click{' '}
                                    <a
                                       href="https://www.pgdb.co.nz/trade/exams.html"
                                       target="_blank"
                                       rel="noopener noreferrer"
                                    >
                                       here
                                    </a>{' '}
                                    to see important information about Tradesman
                                    and Certifying examinations, including
                                    eligibility, timetables and venues
                                 </Callout>
                              </div>
                           </div>
                        </>
                     )}
                     {renderExamEligibility()}
                     {hasExamContent && !isEmpty(examination.userExaminations) && (
                        <>
                           <p className="grey2-text sub-text">
                              You are eligible for the following exams.
                           </p>
                           <CertifierExamTable />
                           <br />
                           <TradesmanExamTable />
                           <br />
                        </>
                     )}
                     {hasExamContent &&
                        !isEmpty(examinationDetails) &&
                        false && <ExamEnrolledTable />}
                     {hasExamContent && !isEmpty(results) && (
                        <ExamResultsTable />
                     )}
                     <div className="row">
                        <div className="col l10 offset-l1">
                           <Callout>
                              <b>
                                 To book your Tradesman and Certifier Exams you
                                 need to:
                              </b>
                              <br />
                              <ul>
                                 <li>
                                    <Icon
                                       className="arrow"
                                       iconName="arrow_forward"
                                    />
                                    Register and create a user account with{' '}
                                    <ExternalLink href="https://pgdb.aspeqexams.com/">
                                       Aspeq
                                    </ExternalLink>
                                 </li>
                                 <li>
                                    <Icon
                                       className="arrow"
                                       iconName="arrow_forward"
                                    />
                                    Come back to this page, download your exam
                                    eligibility report and provide it to{' '}
                                    <ExternalLink href="https://pgdb.aspeqexams.com/">
                                       Aspeq
                                    </ExternalLink>{' '}
                                    for validation
                                 </li>
                                 <li>
                                    <Icon
                                       className="arrow"
                                       iconName="arrow_forward"
                                    />
                                    Once your eligibility has been validated,
                                    you&apos;ll be able to book an exam sitting
                                 </li>
                              </ul>
                              If you have any queries about the exam booking
                              process, please contact the team at Aspeq{' '}
                              <ExternalLink
                                 className="activeblue-text"
                                 href="tel:049139812"
                              >
                                 (04) 913 9812
                              </ExternalLink>{' '}
                              or by email at{' '}
                              <ExternalLink
                                 className="activeblue-text"
                                 href="mailto:info@aspeq.com"
                              >
                                 info@aspeq.com
                              </ExternalLink>
                           </Callout>
                        </div>
                     </div>
                  </div>
               </div>
            </Loading>
         </div>
      </main>
   );
};

Exam.propTypes = {
   user: object.isRequired,
   dispatch: func.isRequired,
   token: string.isRequired,
};

const mapStateToProps = state => {
   return {
      examination: state.examination.examinations,
      examinationDetails: state.examination.examinationDetails,
      eligibilities: state.examination.eligibility,
      eligibilityReport: state.examination.eligibilityReport,
      results: state.result.examination,
      isLoading:
         isFetchingExamination('getUserExamEligibility', state) ||
         isFetchingExamination('getUserExaminationDetails', state) ||
         isFetchingResult('getExaminationResults', state) ||
         isFetchingExamination('getUserExamEligibilityReport', state),
      modalState: state.examination.modalState,
      token: getToken(state),
      user: state.session.user,
   };
};

export default connect(mapStateToProps)(Exam);
