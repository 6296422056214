import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import RegistrationActions, {
   isFetchingRegistration,
   getRegistrationFile,
   getRegistrationFilesByApplication,
   isFetchingRegistrationSuccess,
   isFetchingRegistrationFailure,
} from 'pgdb-data-layer/lib/Redux/RegistrationRedux';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Loading from '../../../Components/Loading';
import FileUpload from '../../../Components/FileUpload';
import { getToken } from '../../../Session/SessionRedux';
import Icon from '../../../Components/Icon';
import './RegistrationUploadDocuments.scss';
import FileSaver from 'file-saver';
import TextLink from '../../../Components/TextLink';
import Navigator from '../../../Navigation/Navigator';
import M from 'materialize-css';
import ExternalLink from '../../../Components/ExternalLink';

const RegistrationUploadDocuments = ({
   token,
   getRegistrationFilesByApplicationRequest,
   registrationFilesByApplication,
   getUserRegistrationRequest,
   deleteRegistrationFileRequest,
   getRegistrationFileDownloadRequest,
   getRegistrationFileDownloadReset,
   userRegistration,
   isFetchingRegistration,
   isDownloaded,
   fileDownloaded,
   isFileDeleted,
   isUploaded,
   isUploadFailed,
   isDeletingFile,
   isDownloadingFile,
   isUploadingFile,
}) => {
   const [hasDispatched, setHasDispatched] = useState(false);
   const [pageReload, setPageReload] = useState(true);
   const [saveFile, setSaveFile] = useState(true);
   const [isUploading, setIsUploading] = useState(false);
   const [isDeleting, setIsDeleting] = useState(false);
   const [isDownloading, setIsDownloading] = useState(false);
   const [processingFileId, setProcessingFileId] = useState(0);
   const [uploadingSectionId, setUploadingSectionId] = useState([]);
   const [isUploadTriggered, setIsUploadTriggered] = useState(false);
   const [isHovering, setIsHovering] = useState(false);

   const isLoading = !hasDispatched;

   useEffect(() => {
      if (pageReload) {
         getUserRegistrationRequest(token);
         setPageReload(false);
      }
      setHasDispatched(true);
   }, [token, pageReload]);

   useEffect(() => {
      setIsDeleting(isDeletingFile);
   }, [isDeletingFile]);

   useEffect(() => {
      setIsDownloading(isDownloadingFile);
   }, [isDownloadingFile]);

   useEffect(() => {
      setIsUploading(isUploadingFile);
   }, [isUploadingFile]);

   useEffect(() => {
      if (isFileDeleted) {
         getUserRegistrationRequest(token);
      }
   }, [isFileDeleted]);

   useEffect(() => {
      if (isDownloaded && saveFile) {
         saveFileLocal();
         setProcessingFileId(0);
         setSaveFile(false);
      }
   }, [isDownloaded, saveFile]);

   useEffect(() => {
      if (isUploaded && isUploadTriggered) {
         M.toast({
            html: 'File Uploaded Successfully',
            classes: 'success',
         });
         getUserRegistrationRequest(token);
         setUploadingSectionId([]);
      }
   }, [isUploaded, isUploadTriggered]);

   useEffect(() => {
      if (isUploadFailed && isUploadTriggered) {
         M.toast({
            html: 'File Uploaded Failed',
            classes: 'error',
         });
         getUserRegistrationRequest(token);
         setIsUploadTriggered(false);
      }
   }, [isUploadFailed, isUploadTriggered]);

   const registrationDocument = {
      1: {
         title:
            'A certified copy of your NZQA-issued certificate or an official copy of your NZQA Record of Achievement (journeyman or tradesman only)',
      },
      2: {
         title: 'NZ Ministry of Justice criminal record check',
      },
      3: {
         title: 'Other',
      },
   };

   const anyScanFailed = () => {
      if (
         userRegistration.registration &&
         userRegistration.registration.applicationFiles
      ) {
         const found = userRegistration.registration.applicationFiles.find(
            f => f.isFileScanFailed === true
         );
         if (found) {
            return true;
         }
      }

      return false;
   };

   const getFilesBySection = sectionId => {
      if (
         userRegistration.registration &&
         userRegistration.registration.applicationFiles
      ) {
         const applicationFiles =
            userRegistration.registration.applicationFiles;
         return applicationFiles.filter(f => f.fileTypeID === sectionId);
      }
   };

   const onFileUploaded = () => {
      setIsUploadTriggered(true);
   };

   const StartDownload = fileId => {
      setProcessingFileId(fileId);
      getRegistrationFileDownloadRequest(token, fileId);
      setSaveFile(true);
   };

   const saveFileLocal = () => {
      if (fileDownloaded) {
         FileSaver.saveAs(fileDownloaded);
         getRegistrationFileDownloadReset();
         setPageReload(true);
      }
   };

   const deleteFile = (fileId, fileName, isFileScanFailed) => {
      if (isUploadingFile || isDeletingFile) return;

      if (!isFileScanFailed) {
         if (
            window.confirm(`Do you want to delete the file : ${fileName}`) !==
            true
         ) {
            return;
         }
      }
      setProcessingFileId(fileId);
      deleteRegistrationFileRequest(token, fileId);
   };

   const onFileSelected = (file, sectionId) => {
      const uploadedFiles = getFilesBySection(sectionId);

      if (uploadedFiles && uploadedFiles.length >= 5) {
         M.toast({
            html: `You have reached maximum number of files allowed for this section.`,
            classes: 'error',
         });
         return false;
      }

      if (uploadedFiles) {
         const duplicateFile = uploadedFiles.find(
            f => f.fileName === file.name
         );

         if (duplicateFile) {
            M.toast({
               html: `File '${file.name}' is already uploaded under '${
                  registrationDocument[sectionId].title
               }' section.`,
               classes: 'error',
            });
            return false;
         }
      }

      setUploadingSectionId(uploadingSectionId => [
         ...uploadingSectionId,
         sectionId,
      ]);
      return true;
   };

   const toolTipImage = sectionId => {
      if (sectionId !== 1) return;

      const imagePath = '/images/nzqa-sample.png';
      return (
         <div
            className={`generic-modal tool-tip-container scale-transition ${
               isHovering ? 'scale-in' : 'scale-out'
            }`}
         >
            <Icon
               className="close-icon right"
               iconName="close"
               onClick={() => setIsHovering(false)}
            />
            <img
               className="tool-tip-image"
               src={imagePath}
               width="250"
               height="200"
            />
            <div className="innter-text">
               Example of New Zealand Certificate in Drainlaying (level 4)
            </div>
         </div>
      );
   };

   const renderDocumentsToUpload = () => {
      return Object.keys(registrationDocument).map((document, key) => {
         const sectionId = key + 1;
         const stringToRender = registrationDocument[document].title;
         const uploadedItems = getFilesBySection(sectionId);

         return (
            <div className="upload-section">
               <div className="upload-document-container" key={sectionId}>
                  <div className="col document-type-container m12 s12 l10">
                     <p className="section-title">
                        {stringToRender}
                        {sectionId === 1 && (
                           <span onClick={() => setIsHovering(true)}>
                              <Icon
                                 className="info-icon right"
                                 iconName="error_outline"
                              />
                           </span>
                        )}{' '}
                     </p>
                  </div>
                  {toolTipImage(sectionId)}
                  <div className="col m7 s7 l2">
                     <FileUpload
                        onFileUploaded={onFileUploaded}
                        onFileSelected={file => onFileSelected(file, sectionId)}
                        sectionId={sectionId}
                        isUploadingFile={
                           uploadingSectionId.includes(sectionId)
                              ? isUploading
                              : false
                        }
                     />
                  </div>
               </div>
               <div className="uploaded-files">
                  {uploadedItems &&
                     uploadedItems.map(item => {
                        return (
                           <>
                              <div
                                 className={`uploaded-file-item ${
                                    item.isFileScanFailed ? ' border-red' : ''
                                 }`}
                              >
                                 <span
                                    className="uploaded-file-icon-and-name"
                                    onClick={() =>
                                       !item.isFileScanFailed &&
                                       StartDownload(item.fileID)
                                    }
                                 >
                                    <span>
                                       {processingFileId === item.fileID &&
                                       (isDownloading || isDeleting) ? (
                                          <button className="loading-button" />
                                       ) : (
                                          <Icon
                                             className="attach-icon"
                                             iconName={`${
                                                !item.isFileScanFailed
                                                   ? 'attach_file'
                                                   : 'warning'
                                             }`}
                                          />
                                       )}
                                    </span>
                                    {item.isFileScanFailed ? (
                                       <span>{item.fileName}</span>
                                    ) : (
                                       <TextLink
                                          id={`${sectionId}-download-link-${
                                             item.fileID
                                          }`}
                                          className="file-name link"
                                       >
                                          {item.fileName}
                                       </TextLink>
                                    )}
                                 </span>
                                 <span className="uploaded-file-delete right">
                                    <Icon
                                       className={`${
                                          isUploadingFile || isDeletingFile
                                             ? 'delete-button disabled right'
                                             : ' delete-button right'
                                       }`}
                                       iconName="delete"
                                       onClick={() =>
                                          deleteFile(
                                             item.fileID,
                                             item.fileName,
                                             item.isFileScanFailed
                                          )
                                       }
                                    />
                                 </span>
                              </div>
                              {item.isFileScanFailed && (
                                 <div className="invalid-file">
                                    File cannot be uploaded
                                 </div>
                              )}
                           </>
                        );
                     })}
               </div>
            </div>
         );
      });
   };

   const supportingDocumentsRequired = () => {
      return (
         <div className="important-note">
            <div className="important-note-header">
               <b>Document format</b>
            </div>
            <div className="important-note-content">
               <p>
                  Format: PDF, PNG, JPEG, JPG <br /> Size: Maximum 10MB
               </p>
               <p className="application-fee-title">Fitness to practice</p>
               <p>{`If you answer 'yes' to a question about your fitness to practice 
               please attach any relevant documents about the matter(s). 
               This could include:`}</p>
               <ul>
                  <li>
                     <FiberManualRecordIcon />
                     {`court documents`}
                  </li>
                  <li>
                     <FiberManualRecordIcon />
                     {`copy of charges`}
                  </li>
                  <li>
                     <FiberManualRecordIcon />
                     {`legal correspondence`}
                  </li>
                  <li>
                     <FiberManualRecordIcon />
                     {`correspondence from regulatory authrority(ies)`}
                  </li>
                  <li>
                     <FiberManualRecordIcon />
                     {`decision letters`}
                  </li>
                  <li>
                     <FiberManualRecordIcon />
                     {`police certificates`}
                  </li>
                  <li>
                     <FiberManualRecordIcon />
                     {`health certificates`}
                  </li>
               </ul>

               <p className="application-fee-title">
                  What is a certified copy?
               </p>
               <p>{`A certified copy of a document has been declared as correct. You will need to take
               your qualification to a lawyer, Justice of the Peace(JP) or a Court Registrar and have 
               them certify it.`}</p>

               <p>{`They will check the photocopy against the original, and then sign the photocopy to say
               it is true and correct copy of the original. This service is usually free of charge.`}</p>

               <p>{`All certified copies must include the date, name, occupation and signature of the 
               JP/lawyer/Court Registrar certifying the document.`}</p>

               <p className="important-note-footer">
                  If you need help, call us on our free phone{' '}
                  <span className="important-note-phone">
                     <a href="tel:0800743262" rel="nofollow">
                        0800 743 262
                     </a>{' '}
                  </span>
                  or email{' '}
                  <a
                     className="activeblue-text important-note-email"
                     href="mailto:registration@pgdb.co.nz"
                  >
                     registration@pgdb.co.nz
                  </a>
               </p>
            </div>
         </div>
      );
   };

   return (
      <main onClick={() => (isHovering ? setIsHovering(false) : null)}>
         <Loading isLoading={isLoading}>
            <div className="section no-pad-bot" id="index-banner">
               <div className="container registration-container">
                  <div className="row no-margin flex">
                     <div className="col l9 m8 s12">
                        <div className="col m8 offset-l1 s11">
                           <h4 className="columnheader upload-document-header">
                              Registration Application
                           </h4>
                           <h6 className="subheader">
                              Section 3/3: UPLOAD ALL DOCUMENTS
                           </h6>
                        </div>
                        <div className="col m10 l10 offset-l1 s11">
                           <div className="fit-and-proper-title">
                              <p>
                                 Please upload documents required to support
                                 your application. To find out what you need to
                                 provide{' '}
                                 <ExternalLink
                                    className="link"
                                    href={
                                       'https://www.pgdb.co.nz/media/a5lfrymz/documents-required-when-applying-for-registration.pdf'
                                    }
                                 >
                                    click here
                                 </ExternalLink>
                              </p>
                           </div>
                           <div className="registration-list">
                              {renderDocumentsToUpload()}
                           </div>

                           <div className="row profile-row next-button-registration right">
                              <div className="navigate-buttons reg-application-uploaddoc-button">
                                 <button
                                    type="button"
                                    className="waves-light btn btn-inactive"
                                    onClick={() =>
                                       Navigator.toRegistrationFitAndProper()
                                    }
                                 >
                                    BACK
                                 </button>
                                 <button
                                    type="button"
                                    disabled={
                                       isUploadingFile ||
                                       isDeletingFile ||
                                       anyScanFailed()
                                    }
                                    className="waves-effect waves-light button-margin btn"
                                    onClick={() =>
                                       Navigator.toRegistrationReviewAndSubmit()
                                    }
                                 >
                                    SAVE DRAFT AND NEXT
                                 </button>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col l3 m4 side-note-background">
                        {supportingDocumentsRequired()}
                     </div>
                  </div>
               </div>
            </div>
         </Loading>
      </main>
   );
};

const mapStateToProps = state => {
   return {
      registrationFile: getRegistrationFile(state),
      registrationFilesByApplication: getRegistrationFilesByApplication(state),
      token: getToken(state),
      userRegistration: state.registration,
      isFetchingRegistration: isFetchingRegistration(
         'getUserRegistration',
         state
      ),
      isUploadingFile: isFetchingRegistration('putRegistrationFile', state),
      isDeletingFile: isFetchingRegistration('deleteRegistrationFile', state),
      isDownloadingFile: isFetchingRegistration(
         'getRegistrationFileDownload',
         state
      ),
      isUploaded: isFetchingRegistrationSuccess('putRegistrationFile', state),
      isUploadFailed: isFetchingRegistrationFailure(
         'putRegistrationFile',
         state
      ),
      isDownloaded: isFetchingRegistrationSuccess(
         'getRegistrationFileDownload',
         state
      ),
      isFileDeleted: isFetchingRegistrationSuccess(
         'deleteRegistrationFile',
         state
      ),
      fileDownloaded: state.registration.registrationFileDownload,
   };
};

const mapDispatchToProps = {
   getRegistrationFileRequest: RegistrationActions.getRegistrationFileRequest,
   getRegistrationFilesByApplicationRequest:
      RegistrationActions.getRegistrationFilesByApplicationRequest,
   putRegistrationFileRequest: RegistrationActions.putRegistrationFileRequest,
   getUserRegistrationRequest: RegistrationActions.getUserRegistrationRequest,
   getRegistrationFileDownloadRequest:
      RegistrationActions.getRegistrationFileDownloadRequest,
   getRegistrationFileDownloadReset:
      RegistrationActions.getRegistrationFileDownloadReset,
   deleteRegistrationFileRequest:
      RegistrationActions.deleteRegistrationFileRequest,
};

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(RegistrationUploadDocuments);
