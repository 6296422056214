import { createReducer, createActions } from 'reduxsauce';

/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
   authenticated: false,
   checked: false,
   invalid: false,
   token: null,
   id_token: null,
   user: {},
};

/* ------------- Reducers ------------- */

const getSessionSuccess = (state, action) => {
   return {
      user: { ...state.user },
      authenticated: true,
      checked: true,
      invalid: false,
      token: action.token,
      id_token: action.id_token,
   };
};

const getSessionError = (state, action) => {
   return {
      ...state,
      user: { ...state.user },
      authenticated: false,
      checked: true,
   };
};

const getUserSessionSuccess = (state, action) => {
   return { ...state, user: action.user };
};

const getUserSessionError = (state, action) => {
   return { ...state, user: {} };
};

const invalidSession = (state, action) => {
   return {
      user: {},
      checked: true,
      authenticated: false,
      invalid: true,
   };
};

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
   getSessionSuccess: ['token', 'id_token'],
   getSessionError: [],
   getUserSessionSuccess: ['user'],
   getUserSessionError: [],
   invalidSession: [],
});

export const ResultTypes = Types;
export default Creators;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
   [Types.GET_SESSION_SUCCESS]: getSessionSuccess,
   [Types.GET_SESSION_ERROR]: getSessionError,
   [Types.GET_USER_SESSION_SUCCESS]: getUserSessionSuccess,
   [Types.GET_USER_SESSION_ERROR]: getUserSessionError,
   [Types.INVALID_SESSION]: invalidSession,
});

/* ------------- Selectors ------------- */
export const getToken = state => {
   return state.session.token;
};

export const getIdToken = state => {
   return state.session.id_token;
};
