import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { object, bool } from 'prop-types';
import './RegistrationFitAndProper.scss';
import RegistrationActions, {
   isFetchingRegistration,
} from 'pgdb-data-layer/lib/Redux/RegistrationRedux';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import M from 'materialize-css';
import FitAndProperActions, {
   isFetchingQA,
   isSaveQASuccess,
} from 'pgdb-data-layer/lib/Redux/FitAndProperRedux';
import RadioButtons from '../../../Components/RadioButtons';
import Loading from '../../../Components/Loading';
import { getToken } from '../../../Session/SessionRedux';
import Navigator from '../../../Navigation/Navigator';
import CountryListMultiSelect from '../../../Components/CountryListMultiSelect';
import ExternalLink from '../../../Components/ExternalLink';

export const QUESTION_TYPE_REGISTRATION = 1;
export const QUESTION_TYPE_COUNTRY = '13';

const RegistrationFitAndProper = ({
   token,
   questionsAndAnswersRequest,
   questionsAndAnswersReset,
   getUserRegistrationRequest,
   saveAnswerListRequest,
   isSaveSuccess,
   userRegistration,
   isFetchingRegistration,
}) => {
   const [hasDispatched, setHasDispatched] = useState(false);
   const [selectedCountriesList, setSelectedCountriesList] = useState([]);
   const [saveSubmitted, setSaveSubmitted] = useState(false);
   const [answerData, setAnswerData] = useState({});
   const [fitAndProperQuestions, setFitAndProperQuestions] = useState([]);
   const [checkAllQuestionsAnswered, setCheckAllQuestionsAnswer] = useState(
      false
   );
   const isContinueButtonDisabled = !checkAllQuestionsAnswered;

   const OPTION_YES = 'Yes';
   const OPTION_NO = 'No';

   const isLoading = !hasDispatched || isFetchingRegistration;

   const checkAllQuestions = () => {
      const allChecked = fitAndProperQuestions.every(question => {
         const answer = answerData[question.id];
         return (
            answer && (answer.checkbox === true || answer.checkbox === false)
         );
      });
      setCheckAllQuestionsAnswer(allChecked);
   };

   useEffect(() => {
      checkAllQuestions();
   }, [answerData]);

   const loadFitAndProperData = () => {
      questionsAndAnswersReset();
      questionsAndAnswersRequest(token, QUESTION_TYPE_REGISTRATION);
   };

   useEffect(() => {
      loadFitAndProperData();
   }, []);

   useEffect(() => {
      if (userRegistration.registration.fitAndProper) {
         setFitAndProperQuestions(userRegistration.registration.fitAndProper);
      }
   }, [userRegistration]);

   useEffect(() => {
      const newAnswers = { ...answerData };
      // eslint-disable-next-line array-callback-return
      fitAndProperQuestions.map((q, i) => {
         newAnswers[q.id] = {
            checkbox: q.latestAnswer,
            text: q.latestOtherAnswer,
         };
      });

      setAnswerData({ ...newAnswers });

      if (newAnswers && newAnswers[QUESTION_TYPE_COUNTRY]) {
         const textCountry = newAnswers[QUESTION_TYPE_COUNTRY].text;
         setSelectedCountriesList(
            textCountry && textCountry !== null ? JSON.parse(textCountry) : []
         );
      }
   }, [fitAndProperQuestions]);

   useEffect(() => {
      getUserRegistrationRequest(token);
      setHasDispatched(true);
   }, [token]);

   const onValueChange = (groupName, val) => {
      // set answerData only if empty.
      const newAnswers = { ...answerData };
      newAnswers[groupName].checkbox = val === OPTION_YES;
      setSaveSubmitted(false);
      if (groupName === +QUESTION_TYPE_COUNTRY && val === OPTION_NO) {
         newAnswers[groupName].text = '';
         setSelectedCountriesList([]);
      }
      setAnswerData({ ...newAnswers });
   };

   const trySaveFitAndProper = () => {
      // converts from object to array of objects
      const postData = [];
      for (const key in answerData) {
         postData.push({
            id: parseInt(key),
            questionText: '',
            latestAnswer: answerData[key].checkbox,
            latestOtherAnswer:
               key === QUESTION_TYPE_COUNTRY && answerData[key].checkbox
                  ? JSON.stringify(selectedCountriesList)
                  : '',
         });
      }

      const country = postData.filter(data => {
         return data.id === +QUESTION_TYPE_COUNTRY;
      });

      if (country[0] && country[0].latestAnswer) {
         const countryList = JSON.parse(country[0].latestOtherAnswer);
         if (countryList === undefined || countryList.length === 0) {
            M.toast({
               html: 'Please select at least one country',
               classes: 'error',
            });
            return false;
         }
      }

      saveAnswerListRequest(
         token,
         JSON.stringify(postData),
         QUESTION_TYPE_REGISTRATION
      );
      setSaveSubmitted(true);
      return true;
   };

   useEffect(() => {
      if (saveSubmitted && isSaveSuccess) {
         M.toast({
            html: 'Your Fit & Proper Questions are being saved',
            classes: 'success',
         });

         Navigator.toRegistrationUploadDocuments(); // after save has been completed,  move onto next page
         window.scrollTo(0, 0);
      }
   }, [saveSubmitted, isSaveSuccess]);

   const displayExtraComponent = id => {
      if (id === 6) {
         return (
            <div className="s10 subheading-container">
               Note: Disclosure is required even if the criminal proceedings
               resulted in discharge without conviction or a similar finding
               {` (`}for NZ applicants, please note your rights under the
               Criminal Records {`(Clean Slate)`} Act 2004 before providing
               details of any criminal record{`)`}. Information about the
               Criminal Records Act 2004 can be found{' '}
               <ExternalLink href="https://www.justice.govt.nz/criminal-records/clean-slate">
                  here
               </ExternalLink>
            </div>
         );
      }

      if (
         id === +QUESTION_TYPE_COUNTRY &&
         answerData[id] &&
         answerData[id].checkbox
      ) {
         return (
            <CountryListMultiSelect
               selectedCountries={selectedCountriesList}
               onUpdateSelectedCountries={setSelectedCountriesList}
            />
         );
      }
      return undefined;
   };
   const importantNote = () => {
      return (
         <div className="important-note">
            <div className="important-note-header">
               <ErrorOutlineIcon />
               <b>Important Note</b>
            </div>
            <div className="important-note-content">
               <div>
                  <p>
                     If you answer {`'yes'`} to one of these questions you will
                     need to provide us with:
                  </p>
               </div>
               <ul>
                  <li>
                     <FiberManualRecordIcon />A written explanation of the
                     matter{`(s)`}. If relevant, please include the date of
                     incident, place of incident, incident summary, outcome and
                     date of outcome.
                  </li>
                  <li>
                     <FiberManualRecordIcon />
                     Any relevant documents about the matter{`(s)`} e.g. court
                     documents, copy of charges, legal correspondence,
                     correspondence from regulatory authority{`(ies)`},
                     decisions, police or health certificates
                  </li>
               </ul>
               <div className="document-note-content">
                  <p className="document-format">Document format</p>
                  <p>Format: PDF</p>
                  <p>Size: Maximum 10MB</p>
               </div>
               <p className="important-note-footer">
                  If you need help, call us on our free phone{' '}
                  <span className="important-note-phone">
                     <a href="tel:0800743262" rel="nofollow">
                        0800 743 262
                     </a>{' '}
                  </span>
                  or email{' '}
                  <a
                     className="activeblue-text important-note-email"
                     href="mailto:registration@pgdb.co.nz"
                  >
                     registration@pgdb.co.nz
                  </a>
               </p>
            </div>
         </div>
      );
   };

   return (
      <main>
         <Loading isLoading={isLoading}>
            <div className="section no-pad-bot" id="index-banner">
               <div className="container registration-container">
                  <div className="row no-margin">
                     <div className="col l9 m8 s12">
                        <div className="col m8 offset-l1 s12">
                           <h4 className="columnheader">
                              Registration Application
                           </h4>
                           <h6 className="subheader">
                              Section 2/3: FIT AND PROPER INFORMATION
                           </h6>
                        </div>
                        <div className="col m10 l9 offset-l1 s12">
                           <div className="fit-and-proper-title">
                              <p>Please answer the following questions:</p>
                           </div>

                           <div className="questions-page-divider">
                              <div className="questions-section">
                                 <div>
                                    {fitAndProperQuestions.map(
                                       (question, i) => {
                                          const answer = question.latestAnswer;
                                          const answerValue =
                                             answer === true
                                                ? OPTION_YES
                                                : answer === false
                                                ? OPTION_NO
                                                : '';

                                          return (
                                             <div key={i}>
                                                <div>
                                                   <RadioButtons
                                                      radioOptions={[
                                                         OPTION_YES,
                                                         OPTION_NO,
                                                      ]}
                                                      title={
                                                         question.questionText
                                                      }
                                                      groupName={question.id}
                                                      onChange={onValueChange}
                                                      value={answerValue}
                                                      index={`${i + 1}) `}
                                                      radioLabel="radio-label s10 registration-fit-and-proper-question-text"
                                                      radioDisplay="s2 radio-display registration-fit-and-proper-radio-options"
                                                      fitAndProperRadioButtons="registration-fit-and-proper-radio-buttons"
                                                      subHeadingComponent={() =>
                                                         displayExtraComponent(
                                                            question.id
                                                         )
                                                      }
                                                   />
                                                </div>
                                             </div>
                                          );
                                       }
                                    )}
                                 </div>
                              </div>
                           </div>
                           <div className="row profile-row next-button-registration right">
                              <div className="navigate-buttons reg-fit-and-prop-button-container">
                                 <button
                                    type="button"
                                    className="waves-light btn btn-inactive"
                                    onClick={() =>
                                       Navigator.toRegistrationApplicantDetails()
                                    }
                                 >
                                    BACK
                                 </button>
                                 <button
                                    type="button"
                                    className={`waves-effect waves-light btn btn-ml-30 fit-proper-next-btn
                                    ${saveSubmitted ? 'loading-button' : ''}`}
                                    onClick={() => trySaveFitAndProper()}
                                    disabled={isContinueButtonDisabled}
                                 >
                                    SAVE DRAFT AND NEXT
                                 </button>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col l3 m4 side-note-background">
                        {importantNote()}
                     </div>
                  </div>
               </div>
            </div>
         </Loading>
      </main>
   );
};

RegistrationFitAndProper.propTypes = {
   userLicence: object,
   isLoading: bool,
   isSaveSuccess: bool,
   userContactData: object,
};

const mapStateToProps = state => {
   return {
      userRegistration: state.registration,
      isFetchingRegistration: isFetchingRegistration(
         'getUserRegistration',
         state
      ),
      isSaveSuccess: isSaveQASuccess('saveAnswerList', state),
      token: getToken(state),
      isLoading: isFetchingQA(state),
   };
};

const mapDispatchToProps = {
   questionsAndAnswersRequest: FitAndProperActions.questionsAndAnswersRequest,
   questionsAndAnswersReset: FitAndProperActions.questionsAndAnswersReset,
   getUserRegistrationRequest: RegistrationActions.getUserRegistrationRequest,
   saveAnswerListRequest: FitAndProperActions.saveAnswerListRequest,
};

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(RegistrationFitAndProper);
