import React from 'react';
import { string, func, node } from 'prop-types';
import { isEmpty } from 'lodash';

import TableRow from '../../../../Components/GenericTable/TableRow';
import TableCell from '../../../../Components/GenericTable/TableCell';

import { contactType } from '../types';

import PrimarySearchRow from './PrimarySearchRow';
import ContactDetails from './ContactDetails';
import CurrentSupervision from './CurrentSupervision';
import CurrentLicences from './CurrentLicences';
import LicenceHistory from './LicenceHistory';
import RegistrationHistory from './RegistrationHistory';
import DisciplinaryHistory from './DisciplinaryHistory';

// count of data (ignores button in first cell)
const TABLE_COL_COUNT = 3;

export const DetailedRow = ({ children }) => {
   return (
      <TableRow className="pr-row-no-border pr-row-no-top-pad pr-highlighted-result">
         <TableCell className="pr-row-no-vert-pad" />
         <TableCell
            className="pr-row-no-vert-pad pr-row-right-pad"
            colspan={TABLE_COL_COUNT}
         >
            {children}
         </TableCell>
      </TableRow>
   );
};

DetailedRow.propTypes = {
   children: node.isRequired,
};

export const DetailedRowContent = ({ children }) => {
   return (
      <div className="row pr-row-left-indent small pr-row-small-mb">
         {children}
      </div>
   );
};

DetailedRowContent.propTypes = {
   children: node.isRequired,
};

export const SectionHeader = ({ label }) => {
   return (
      <div className="row pr-row-section-header bold">
         <div className="col s12">
            <span>{label}</span>
         </div>
      </div>
   );
};

SectionHeader.propTypes = {
   label: string.isRequired,
};

// empty content so that we have spacing to the next result (or end of results)
const DetailedRowFooter = () => {
   return (
      <TableRow className="pr-row-no-top-pad pr-highlighted-result">
         <TableCell
            className="pr-row-no-vert-pad pr-row-right-pad"
            colspan={TABLE_COL_COUNT + 1} // +1 for the extra column containing expand/collapse
         >
            <div className="pr-advanced-row-footer" />
         </TableCell>
      </TableRow>
   );
};

const DetailedSearchRow = ({ contact, onCollapse }) => {
   return (
      <>
         <PrimarySearchRow
            contact={contact}
            icon="expand_less"
            onToggle={onCollapse}
            rowStyle="pr-row-no-border pr-highlighted-result pr-row-hover"
         />
         {!isEmpty(contact.licences) && (
            <CurrentLicences licences={contact.licences} />
         )}
         {contact.disciplinaryHistory &&
            !isEmpty(contact.disciplinaryHistory.history) && (
               <DisciplinaryHistory
                  disciplinaryHistory={contact.disciplinaryHistory.history}
               />
            )}
         {!isEmpty(contact.licences) && <ContactDetails contact={contact} />}
         {!isEmpty(contact.registrationHistory) && (
            <RegistrationHistory history={contact.registrationHistory} />
         )}
         {!isEmpty(contact.licenceHistory) && (
            <LicenceHistory history={contact.licenceHistory} />
         )}
         {!isEmpty(contact.supervisees) && (
            <CurrentSupervision
               firstName={contact.firstName}
               supervisees={contact.supervisees}
            />
         )}
         <DetailedRowFooter />
      </>
   );
};

DetailedSearchRow.propTypes = {
   contact: contactType.isRequired,
   onCollapse: func.isRequired,
};

export default DetailedSearchRow;
