import React, { Fragment } from 'react';
import Table from '../../../../Components/GenericTable/Table';
import TableRow from '../../../../Components/GenericTable/TableRow';
import TableCell from '../../../../Components/GenericTable/TableCell';
import TableBody from '../../../../Components/GenericTable/TableBody';
import { getStylingForSupervisionStatus } from './StylingHelper';
import * as StatusCode from '../../Shared/StatusCodes';
import { CERTIFYING_LICENCE_TYPES } from 'pgdb-data-layer/lib/Constants/LicenceTypes';

const NOMINATE_MESSAGE = 'Nomiate a Supervisor';

const NominateASupervisorTable = ({ tableData }) => {
   const generateTableRows = () => {
      return tableData
         .filter(f => f.userRenewalAction === StatusCode.USER_ACTION_RENEW)
         .map((app, index) => {
            // skip the line for certifiers
            if (CERTIFYING_LICENCE_TYPES[app.licenceTypeCode]) {
               return <Fragment key={index} />;
            }

            const licenceValues = getStylingForSupervisionStatus(
               app.supervisionStatus
            );

            let supervisorMessage =
               app.supervisionStatus === StatusCode.SUPERVISION_STATUS_PENDING
                  ? 'Supervisor needs to accept your nomination'
                  : app.supervisionStatus ===
                    StatusCode.SUPERVISION_STATUS_DECLINED
                  ? 'Supervisor has declined your nomination'
                  : app.supervisionStatus ===
                    StatusCode.SUPERVISION_STATUS_ACCEPTED
                  ? app.supervisorLicenceStatus ===
                    StatusCode.LICENCE_STATUS_ACTIVE
                     ? 'Accepted'
                     : 'Supervisor needs to renew their licence'
                  : NOMINATE_MESSAGE;

            return (
               <TableRow key={index} className="subrow-no-btm">
                  <TableCell
                     className="smaller roman-font licence-number-cell"
                     color="grey"
                  >
                     Licence {index + 1}
                  </TableCell>
                  <TableCell
                     className="supervisor-licence-cell"
                     icon={licenceValues.icon}
                     color={licenceValues.color}
                  >
                     {app.description}
                  </TableCell>
                  <TableCell
                     className="supervisor-status-cell"
                     icon={licenceValues.icon}
                     color={
                        supervisorMessage === NOMINATE_MESSAGE
                           ? 'activeblue'
                           : licenceValues.color
                     }
                  >
                     {supervisorMessage}
                  </TableCell>
               </TableRow>
            );
         });
   };
   return (
      <Table className="table-head-compressed borderless supervisor-table">
         <TableBody>{generateTableRows()}</TableBody>
      </Table>
   );
};

export default NominateASupervisorTable;
