import React from 'react';
import { Provider } from 'react-redux';
import * as session from '../Session';
import createStore from '../Redux';
import Main from './main';
import './style.scss';
import './materializeOverride.css';

require('dotenv').config();

const store = createStore();
session.initSessionService(store);

const App = () => (
   <Provider store={store}>
      <Main />
   </Provider>
);

export default App;
