import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ReCaptcha } from 'react-recaptcha-v3';
import PropTypes from 'prop-types';
import M from 'materialize-css';
import { isEqual, cloneDeep } from 'lodash';
import {
   LICENCE_TYPES_FOR_DISCIPLINARY_FEE,
   CERTIFYING_LICENCE_TYPES,
   TRAINEE_LICENCE_TYPES,
   EXEMPT_LICENCE_TYPES,
   LICENCE_TYPES
} from 'pgdb-data-layer/lib/Constants/LicenceTypes';
import Navigator from '../../../../Navigation/Navigator';
import Validator from '../../../../Utilities/Validator';
import TextInput from '../../../../Components/TextInput/index';
import TextLink from '../../../../Components/TextLink';
import {
   Table,
   TableHead,
   TableHeaderCell,
   TableRow,
   TableBody,
   TableCell,
   TableTextBoxCell,
} from '../../../../Components/GenericTable';
import Icon from '../../../../Components/Icon';
import * as helper from '../../../../Helpers/Helpers';
import './style.css';
import { getToken } from '../../../../Session/SessionRedux';

class AddLicencesAndNominate extends Component {
   currencyCell = <TableCell className="currency-cell">$</TableCell>;

   constructor(props) {
      super(props);
      this.state = {
         registrationNumber: '',
         registrationNumberValidity: true,
         loadRecaptcha: false,
         isVerified: false,
         recaptchaToken: '',
         companyContacts: [],
         total: 0.0,
         supervisorRegistrationNumberToSearch: '',
         supervisorSearchSubmitted: false,
         supervisorNumber: false,
         supervisorSearchError: undefined,
         registrationNumberSearchingSupervisor: 0,
         applicationIdSearchingSupervisor: 0,
      };
   }

   componentDidMount() {
      const { isBulkRenewal } = this.props;
      // If check if bulk renewal (or) company renewal ?
      if (isBulkRenewal) {
         this.addContact();
      } else {
         this.handleCompanyRenewMount();
      }
   }

   componentDidUpdate(prevProps, prevState) {
      const { isBulkRenewal } = this.props;

      if (isBulkRenewal) {
         this.handleBulkRenewUpdate(prevProps, prevState);
      } else {
         this.handleCompanyRenewUpdate(prevProps, prevState);
      }
   }

   getRenewalsFromProps(props) {
      const { isBulkRenewal, companyRenewal, bulkRenewal } = props;
      return isBulkRenewal ? bulkRenewal : companyRenewal;
   }

   handleCompanyRenewMount() {
      if (!this.getRenewalsFromProps(this.props).hasSaved) {
         Navigator.to(this.props.backUrl);
      } else {
         this.removeCompanySaveStatus();
      }
   }

   handleBulkRenewUpdate(prevProps, prevState) {
      const isNumberValid = this.isValidNumber(prevProps, prevState);
      this.processSupervision(prevProps, prevState);
      if (!isEqual(prevProps, this.props) && isNumberValid) {
         this.addContact();
      }
   }

   handleCompanyRenewUpdate(prevProps, prevState) {
      if (this.getRenewalsFromProps(this.props).hasSaved) {
         Navigator.to(this.props.nextUrl);
         return;
      }

      this.processSupervision(prevProps, prevState);
      if (
         !isEqual(prevProps, this.props) &&
         (prevProps.companyRenewal && this.props.companyRenewal)
      ) {
         const isNumberValid = this.isValidNumber(prevProps, prevState);
         if (isNumberValid) {
            this.addContact();
         }
      }
   }

   removeCompanySaveStatus = () => {
      const { removeSavingStatus } = this.props;
      this.setState({ loadRecaptcha: true });
      this.props.dispatch(removeSavingStatus());
   };

   onSearch = async () => {
      M.Toast.dismissAll();
      const { registrationNumber, isVerified, recaptchaToken } = this.state;
      const { isBulkRenewal } = this.props;
      if (!isVerified && !isBulkRenewal) {
         M.toast({
            html: `Sorry we couldn't process the request, please try again later.`,
            classes: 'error',
         });
         this.setState({ registrationNumberValidity: false });
         return;
      }

      if (Validator.isNullOrWhiteSpace(registrationNumber)) {
         M.toast({
            html: 'Please enter a Licence Number',
            classes: 'error',
         });
         this.setState({ registrationNumberValidity: false });
         return;
      }

      this.setState({ registrationNumberValidity: true }, () => {
         const {
            getRenewalApplicationRequest,
            resetRenewalApplicationSearch,
            isBulkRenewal,
            token,
         } = this.props;

         if (!this.hasRegistrationNumberBeenSearched()) {
            if (isBulkRenewal) {
               const { userSession } = this.props;

               // check if the request for logged in user
               if (
                  Number(userSession.licence_id) === Number(registrationNumber)
               ) {
                  M.toast({
                     html: `Please enter different registration number.`,
                     classes: 'error',
                  });
                  this.setState({ registrationNumberValidity: false });
                  return;
               }

               this.props.dispatch(
                  getRenewalApplicationRequest(token, registrationNumber)
               );
            } else {
               this.props.dispatch(
                  getRenewalApplicationRequest(
                     registrationNumber,
                     recaptchaToken
                  )
               );
            }
         } else {
            this.props.dispatch(resetRenewalApplicationSearch());
            this.checkApplicationAndFee(registrationNumber);
         }
      });
   };

   onSupervisorSearch = async (registrationNumber, application) => {
      const {
         recaptchaToken,
         supervisorRegistrationNumberToSearch,
      } = this.state;

      const numberToSearch = Validator.isNullOrWhiteSpace(registrationNumber)
         ? supervisorRegistrationNumberToSearch
         : registrationNumber;

      // same supervisor ?
      if (
         Number(application.supervisorRegistrationNumber) ===
         Number(numberToSearch)
      ) {
         this.updateNominationTextboxVisibility(application, false);
         return;
      }

      if (Validator.isNullOrWhiteSpace(numberToSearch)) {
         this.setState({
            supervisorSearchError: 'Please enter a registration number',
            supervisorSearchSubmitted: false,
         });
         return;
      }

      if (!Validator.isValidLength(numberToSearch, 20)) {
         this.setState({
            supervisorSearchError:
               'Registration number cannot be no more than 20 characters',
            supervisorSearchSubmitted: false,
         });
         return;
      }

      this.setState(
         {
            supervisorSearchSubmitted: true,
            supervisorNumber: numberToSearch,
         },
         () => {
            const {
               getRenewalSearchSupervisorRequest,
               isBulkRenewal,
            } = this.props;
            const supervisor = this.getRenewalsFromProps(
               this.props
            ).supervisors.find(s => {
               return Number(s.registrationNumber) === Number(numberToSearch);
            });
            if (supervisor) {
               this.tryUpdateSupervisor();
            } else {
               if (isBulkRenewal) {
                  this.props.dispatch(
                     getRenewalSearchSupervisorRequest(
                        this.props.token,
                        numberToSearch
                     )
                  );
               } else {
                  this.props.dispatch(
                     getRenewalSearchSupervisorRequest(
                        numberToSearch,
                        recaptchaToken
                     )
                  );
               }
            }
         }
      );
   };

   getRenewalApplication(registrationNumber) {
      return this.getRenewalsFromProps(this.props).applications.find(app => {
         return Number(app.registrationNumber) === Number(registrationNumber);
      });
   }

   back = () => {
      // preserve the data if any:
      const { companyContacts } = this.state;
      if (companyContacts && companyContacts.length) {
         this.saveData();
         this.removeCompanySaveStatus();
      }
      Navigator.to(this.props.backUrl);
   };

   next = () => {
      M.Toast.dismissAll();
      const { companyContacts } = this.state;
      const foundInvalid = companyContacts.find(c => {
         return (
            !c.isDeleted &&
            c.applications.find(a => {
               return (
                  !!EXEMPT_LICENCE_TYPES[a.licenceTypeCode] &&
                  (!a.isDeleted &&
                     !a.isDisciplinaryFee &&
                     Validator.isNullOrWhiteSpace(a.supervisorName) &&
                     !a.supervisor)
               );
            })
         );
      });

      if (foundInvalid) {
         M.toast({
            html: `Nomination of supervisor is required for exempt applications`,
            classes: 'error',
         });
         return;
      }

      this.saveData();
   };

   saveData = () => {
      // saving to state and route to summary page
      const {
         savePayAndNominateDetails,
         isBulkRenewal,
         token,
         dispatch,
      } = this.props;
      if (isBulkRenewal) {
         dispatch(
            savePayAndNominateDetails(
               this.constructDataFromBulkRenewal(),
               token
            )
         );
         Navigator.to(this.props.nextUrl);
      } else {
         dispatch(
            savePayAndNominateDetails(this.constructDataFromCompanyRenewal())
         );
      }
   };

   constructDataFromBulkRenewal() {
      const { companyContacts } = this.state;
      if (!companyContacts || !companyContacts.length) {
         return;
      }
      let renewals = [];
      companyContacts
         .filter(cf => !cf.isDeleted)
         .forEach(c => {
            let applications = [];
            c.applications
               .filter(af => !af.isDeleted)
               .forEach(a => {
                  applications.push(a);
               });
            let item = cloneDeep(c);
            item.applications = applications;
            renewals.push(item);
         });
      const data = {
         selectedApplications: renewals,
         hasSaved: true,
      };
      return data;
   }

   constructDataFromCompanyRenewal() {
      const {
         businessName,
         businessEmail,
         contactName,
         phoneNumber,
         businessBillingAddress,
      } = this.props.companyRenewal;
      const { companyContacts } = this.state;
      if (!companyContacts || !companyContacts.length) {
         return;
      }
      let renewals = [];
      companyContacts
         .filter(cf => !cf.isDeleted)
         .forEach(c => {
            let applications = [];
            c.applications
               .filter(af => !af.isDeleted)
               .forEach(a => {
                  if (a.isDisciplinaryFee) {
                     a.supervisor = undefined;
                  }
                  applications.push(a);
               });
            let item = cloneDeep(c);
            item.applications = applications;
            renewals.push(item);
         });
      const data = {
         businessName,
         businessEmail,
         contactName,
         phoneNumber,
         businessBillingAddressAddressLine1:
            businessBillingAddress.address_line_1,
         businessBillingAddressAddressLine2:
            businessBillingAddress.address_line_2,
         businessBillingAddressSuburbTown: businessBillingAddress.suburb,
         businessBillingAddressPostalCode: businessBillingAddress.postcode,
         businessBillingAddressCity: businessBillingAddress.city,
         businessBillingAddressCountry: businessBillingAddress.country,
         businessBillingAddressCountryCode: businessBillingAddress.countryCode,
         businessBillingAddress,
         selectedApplications: renewals,
         hasSaved: true,
      };
      return data;
   }

   addContact = () => {
      const renewals = this.getRenewalsFromProps(this.props);
      const { companyContacts } = this.state;

      if (renewals.applications && renewals.applications.length) {
         const newItems =
            companyContacts && companyContacts.length
               ? cloneDeep(companyContacts)
               : [];
         renewals.applications.forEach(c => {
            const found = newItems.find(
               f => f.registrationNumber === c.registrationNumber
            );

            if (!found) {
               const item = cloneDeep(c);
               item.isDeleted = false;
               newItems.unshift(item);
               this.calculateTotal();
            }
         });
         this.setState({ companyContacts: newItems }, () =>
            this.calculateTotal()
         );
      }
   };

   verifyCallback = recaptchaToken => {
      this.setState({ isVerified: true, recaptchaToken });
   };

   calculateTotal = () => {
      let total = 0;
      const { companyContacts } = this.state;

      companyContacts
         .filter(f => !f.isDeleted)
         .forEach(ca => {
            ca.applications.forEach(a => {
               if (!a.fees || a.isDeleted) {
                  return;
               }
               a.fees
                  .filter(f => !f.isPaid)
                  .forEach(f => {
                     total += f.amount;
                  });
            });
         });

      this.setState({ total });
   };

   isValidApplicationSelected = () => {
      const { companyContacts } = this.state;
      const v = companyContacts.filter(f => !f.isDeleted);
      return v && v.length > 0;
   };

   renderTable = () => {
      let contactList = this.state.companyContacts.filter(
         c => c.isDeleted === false
      );

      if (
         !contactList ||
         !contactList.length ||
         !contactList.filter(a => a.applications.length > 0).length
      ) {
         return null;
      }

      return (
         <Table>
            <TableHead>
               <TableRow>
                  <TableHeaderCell />
                  <TableHeaderCell className="no-left-pad name-header-cell">
                     I'm Paying for
                  </TableHeaderCell>
                  <TableHeaderCell />
                  <TableHeaderCell />
                  <TableHeaderCell className="icon-left-pad licence-type-header-cell">
                     Type of Licence
                  </TableHeaderCell>
                  <TableHeaderCell />
                  <TableHeaderCell className="icon-left-pad">
                     Nominate a Supervisor
                  </TableHeaderCell>
                  <TableHeaderCell />
                  <TableHeaderCell className="icon-left-pad">
                     Amount to Pay
                  </TableHeaderCell>
               </TableRow>
            </TableHead>
            <TableBody>{this.renderTableRows(contactList)}</TableBody>
         </Table>
      );
   };

   renderTableRows = contacts => {
      let eleCount = 0;
      const tableItems = contacts
         .filter(c => !c.isDeleted)
         .map(contact => {
            eleCount += 1;
            return (
               <React.Fragment key={eleCount}>
                  {this.renderApplicationRow(contact)}
                  {this.renderCardFeesRow(contact)}
                  {this.renderDisciplinaryFeeRow(contact)}
               </React.Fragment>
            );
         });
      return (
         <>
            {tableItems}
            {this.renderTotalRow()}
         </>
      );
   };

   renderApplicationRow = contact => {
      if (!contact || !contact.applications || !contact.applications.length) {
         return null;
      }

      const showDisciplinaryFee = contact.applications.find(a => {
         return (
            LICENCE_TYPES_FOR_DISCIPLINARY_FEE.indexOf(a.licenceTypeCode) >=
               0 && !a.isDeleted
         );
      });

      let count = 0;
      return contact.applications
         .filter(f => !f.isDeleted && !f.isDisciplinaryFee)
         .map(app => {
            const rowStyle = count === 0 ? 'first-row' : '';
            const isCertifying = !!CERTIFYING_LICENCE_TYPES[
               app.licenceTypeCode
            ];
            const isTrainee = !!TRAINEE_LICENCE_TYPES[app.licenceTypeCode];
            const licenceColor =
               app.activeLicence || app.isDisciplinaryFee ? 'black' : 'orange';

            // Added app.fees.length === 0 check because only the first trainee licences raised by the script will have fees.
            // All subsequesnt trainee applications raised for the same person will not have fees as the tainee licence fee is 
            // charged only once in a year
            return (
               <React.Fragment key={count++}>
                  {(!app.isDisciplinaryFee || showDisciplinaryFee) &&
                     (app.fees.length === 0 || app.fees[0].feeRequestTypeCode !== 'LCFE') && 
                     (
                        <TableRow
                           className={`licence-row ${rowStyle}`}
                           color="activeblue"
                        >
                           <TableCell
                              color="activeblue"
                              className="link-button"
                              onClick={() => this.removeContact(contact)}
                           >
                              {count === 1 ? (
                                 <Icon iconName="delete_forever" />
                              ) : (
                                 ''
                              )}
                           </TableCell>
                           <TableCell className="name-cell">
                              {count === 1 ? contact.fullName : ''}
                           </TableCell>
                           {!app.isDisciplinaryFee ? (
                              <TableCell
                                 color="activeblue"
                                 className="link-button"
                                 onClick={() =>
                                    this.removeApplication(contact, app)
                                 }
                              >
                                 <Icon iconName="delete_forever" />
                              </TableCell>
                           ) : (
                              <TableCell />
                           )}
                           <TableCell color={licenceColor}>
                              {!app.isDisciplinaryFee && (
                                 <Icon
                                    iconName={
                                       app.activeLicence ? 'check' : 'sync'
                                    }
                                 />
                              )}
                           </TableCell>
                           <TableCell color={licenceColor}>
                              {!app.isDisciplinaryFee
                                 ? app.description
                                 : app.fees[0].description}
                           </TableCell>
                           <TableCell
                              color={
                                 app.hasSupervisorApproved && !app.supervisor
                                    ? 'black'
                                    : 'orange'
                              }
                           >
                              {!app.isDisciplinaryFee &&
                              !app.nominateSupervisor &&
                              app.supervisorName ? (
                                 <Icon
                                    iconName={
                                       app.hasSupervisorApproved &&
                                       !app.supervisor
                                          ? 'check'
                                          : 'sync'
                                    }
                                 />
                              ) : null}
                           </TableCell>
                           {this.renderNominationCell(
                              app,
                              isCertifying,
                              isTrainee
                           )}
                           {this.currencyCell}
                           <TableCell className="amount-cell">
                              {this.getTotalPerLicence(
                                 app,
                                 contact.applications
                              )}
                           </TableCell>
                        </TableRow>
                     )}
               </React.Fragment>
            );
         });
   };

   /*added by wziadia 2 Feb 2021  - Card Fees are Disciplinary Fee but treated as "not" Disciplinary Fee to avoid the extra handlings on the application selection*/
   renderCardFeesRow = contact => {
      if (!contact || !contact.applications || !contact.applications.length) {
         return null;
      }
      
      const showDisciplinaryFee = contact.applications.find(a => {
         return (
            LICENCE_TYPES[a.licenceTypeCode] !== "" && !a.isDeleted
         );
      });      
      let count = 0;
      return contact.applications
         .filter(f => !f.isDeleted && f.isDisciplinaryFee)
         .map(app => {
            const rowStyle = count === 0 ? 'first-row' : '';
            return (
               <React.Fragment key={count++}>
                  {(!app.isDisciplinaryFee || showDisciplinaryFee) &&
                     app.fees.length > 0 &&
                     app.fees[0].feeRequestTypeCode === 'LCFE' && (
                        <TableRow
                           className={`licence-row ${rowStyle}`}
                           color="activeblue"
                        >
                           <TableCell />
                           <TableCell />
                           <TableCell
                              color="activeblue"
                              className="link-button"
                              onClick={() =>
                                 this.removeApplication(contact, app)
                              }
                           >
                              <Icon iconName="delete_forever" />
                           </TableCell>
                           <TableCell />
                           <TableCell>
                              {!app.isDisciplinaryFee
                                 ? app.description
                                 : app.fees[0].description}
                           </TableCell>
                           <TableCell>
                              {!app.isDisciplinaryFee &&
                              !app.nominateSupervisor &&
                              app.supervisorName ? (
                                 <Icon
                                    iconName={
                                       app.hasSupervisorApproved &&
                                       !app.supervisor
                                          ? 'check'
                                          : 'sync'
                                    }
                                 />
                              ) : null}
                           </TableCell>
                           <TableCell />
                           {this.currencyCell}
                           <TableCell className="amount-cell">
                              {this.getTotalPerLicence(
                                 app,
                                 contact.applications
                              )}
                           </TableCell>
                        </TableRow>
                     )}
               </React.Fragment>
            );
         });
   };

   renderDisciplinaryFeeRow = contact => {
      if (!contact || !contact.applications || !contact.applications.length) {
         return null;
      }

      const showDisciplinaryFee = contact.applications.find(a => {
         return (
            LICENCE_TYPES_FOR_DISCIPLINARY_FEE.indexOf(a.licenceTypeCode) >= 
            0 && !a.isDeleted
         );
      });

      let count = 0;
      return contact.applications
         .filter(f => !f.isDeleted && f.isDisciplinaryFee)
         .map(app => {
            return (
               <React.Fragment key={count++}>
                  {(!app.isDisciplinaryFee || showDisciplinaryFee) &&
                     app.fees[0].feeRequestTypeCode !== 'LCFE' && (
                        <TableRow className="licence-row" color="activeblue">
                           <TableCell />
                           <TableCell />
                           <TableCell />
                           <TableCell />
                           <TableCell color="black">
                              {!app.isDisciplinaryFee
                                 ? app.description
                                 : app.fees[0].description}
                           </TableCell>
                           <TableCell />
                           <TableCell />
                           {this.currencyCell}
                           <TableCell className="amount-cell">
                              {this.getTotalPerLicence(app, [])}
                           </TableCell>
                        </TableRow>
                     )}
               </React.Fragment>
            );
         });
   };

   renderNominationLink = (application, isCertifying, isTrainee) => {
      if (application.isDisciplinaryFee) {
         return <TableCell />;
      }

      if (application.supervisorName || application.supervisor) {
         const supervisorName = application.supervisor
            ? application.supervisor.fullName
            : application.supervisorName;
         return (
            <TableCell>
               <span className="supervisor-name" title={supervisorName}>
                  {supervisorName}
               </span>
               {!isCertifying && (
                  <>
                     {this.renderNominationUndo(application)}
                     {this.renderNominationEdit(application, isTrainee)}
                  </>
               )}
            </TableCell>
         );
      }

      return (
         <TableCell>
            {!isCertifying && (
               <TextLink
                  onClick={() => {
                     this.updateNominationTextboxVisibility(application, true);
                  }}
               >
                  Nominate a Supervisor
               </TextLink>
            )}
         </TableCell>
      );
   };

   renderNominationCell = (application, isCertifying, isTrainee) => {
      return application.nominateSupervisor
         ? this.renderNominationTextbox(application)
         : this.renderNominationLink(application, isCertifying, isTrainee);
   };

   renderNominationTextbox = application => {
      const { supervisorSearchError } = this.state;
      if (application.isDisciplinaryFee) {
         return null;
      }
      return (
         <TableTextBoxCell
            isHeader={false}
            onSubmit={e => this.onSupervisorSearch(e, application)}
            onChange={e => {
               this.setState({
                  supervisorRegistrationNumberToSearch: e.target.value,
               });
            }}
            errorText={supervisorSearchError}
            inputValid={!supervisorSearchError}
         >
            <TextLink
               className="cancel-nomination"
               onClick={() => {
                  this.onSupervisorSearch(null, application);
               }}
            >
               Save
            </TextLink>
            <TextLink
               className="cancel-nomination"
               onClick={() => {
                  this.updateNominationTextboxVisibility(application, false);
               }}
            >
               Cancel
            </TextLink>
         </TableTextBoxCell>
      );
   };

   renderNominationEdit = (application, isTrainee) => {
      return (
         <>
            {(!isTrainee || !application.hasSupervisorApproved) && (
               <TextLink
                  onClick={() => {
                     this.updateNominationTextboxVisibility(application, true);
                  }}
               >
                  <Icon iconName="edit" />
               </TextLink>
            )}
         </>
      );
   };

   renderNominationUndo = application => {
      return (
         <>
            {application.supervisor && (
               <TextLink
                  onClick={() => {
                     this.undoSupervisorNomination(application);
                  }}
               >
                  <Icon iconName="undo" />
               </TextLink>
            )}
         </>
      );
   };

   removeContact = contact => {
      const response = window.confirm(
         `Do you want to delete details for '${contact.fullName}' ?`
      );
      if (!response) {
         return;
      }
      contact.isDeleted = true;
      this.calculateTotal();
   };

   removeApplication = (contact, application) => {
      const response = window.confirm(
         `Do you want to delete '${application.description}' details for '${
            contact.fullName
         }' ?`
      );
      if (!response) {
         return;
      }

      //Delete the application first before considering the available items
      application.isDeleted = true;

      let deletingPhysicalCardFee = application.fees[0].feeRequestTypeCode === 'LCFE';      
      const availabelItems = contact.applications.filter(f => !f.isDeleted);
      //Physical card fee is treated as DisciplinaryFee just for display purpose.
      //Hence, checking that feerequestTypeCode here
      //While deleting an application fee from the bulk or company portal, all application fees need to be removed if there are:
      //1. Two fees (Actual licence fee and Disciplinary Fee) OR
      //2. Two fees (Actual licence fee and Physical card fee Fee (LCFE)) OR
      //3. Three fees (Actual licence fee, Physical card fee Fee (LCFE) and and Disciplinary Fee)
      //Note: Do not delete other fee lines when a physical card fee is deleted. because, a practitioner may want to pay 
      //other fees except a physical card fee      
      if ( !deletingPhysicalCardFee && 
           !availabelItems.find(f => f.isDisciplinaryFee === false && f.fees.find(fe => fe.feeRequestTypeCode !== 'LCFE' && fe.isPaid === false))          
         ) 
      {
         contact.isDeleted = true;
         this.calculateTotal();
         return;
      }

      // remove the Disciplinary fee if no licenced applications found
      // ****Note****: 
      //Physical card fee is treated as DisciplinaryFee just for display purpose.
      //Hence, checking that feerequestTypeCode here
      let disciplinaryApplication = contact.applications.find(
         i => i.isDisciplinaryFee && 
         (i.fees.length > 0 && i.fees[0].feeRequestTypeCode !== 'LCFE') &&
         !i.isDeleted
      );
      if (disciplinaryApplication) {
         let certifiedApplicationCount = 0;
         contact.applications.forEach(f => {
            if (
               !EXEMPT_LICENCE_TYPES[f.licenceTypeCode] &&
               !TRAINEE_LICENCE_TYPES[f.licenceTypeCode] &&
               !f.isDeleted &&
               !f.isDisciplinaryFee
            ) {
               certifiedApplicationCount++;
            }
         });

         // no lincenced appication found
         if (!certifiedApplicationCount) {
            disciplinaryApplication.isDeleted = true;
         }
      }

      this.calculateTotal();
   };

   updateNominationTextboxVisibility = (app, visiblity) => {
      const { companyContacts } = this.state;
      let registrationNumber = 0;
      companyContacts
         .filter(f => !f.isDeleted)
         .forEach(ca => {
            ca.applications.forEach(a => {
               if (a.applicationId === app.applicationId) {
                  a.nominateSupervisor = visiblity;
                  registrationNumber = Number(ca.registrationNumber);
               } else {
                  a.nominateSupervisor = false;
               }
            });
         });

      if (!visiblity) {
         this.setState({
            companyContacts,
            supervisorSearchError: undefined,
            supervisorSearchSubmitted: false,
            registrationNumberSearchingSupervisor: 0,
            applicationIdSearchingSupervisor: 0,
         });

         const { resetRenewalSearchSupervisor } = this.props;
         this.props.dispatch(resetRenewalSearchSupervisor());
      } else {
         this.setState({
            companyContacts,
            supervisorSearchError: undefined,
            supervisorSearchSubmitted: false,
            registrationNumberSearchingSupervisor: registrationNumber,
            applicationIdSearchingSupervisor: app.applicationId,
         });
      }
   };

   undoSupervisorNomination = app => {
      const { companyContacts } = this.state;
      companyContacts
         .filter(f => !f.isDeleted)
         .forEach(ca => {
            ca.applications.forEach(a => {
               if (a.applicationId === app.applicationId) {
                  delete a.supervisor;
               }
            });
         });

      this.setState({
         companyContacts,
      });
   };

   getTotalPerLicence = (application, applications) => {
      let sum = 0;
      if (!application.fees) {
         return sum;
      }
      application.fees
         .filter(f => !f.isPaid)
         .forEach(f => {
            sum += f.amount;
         });

      let traineePaymentStatus = 'Per Trainee Fee';
      if (!!TRAINEE_LICENCE_TYPES[application.licenceTypeCode]) {
         applications.forEach(app => {
            if (TRAINEE_LICENCE_TYPES[app.licenceTypeCode]) {
               if (app.fees.some(f => f.isPaid)) {
                  traineePaymentStatus = 'Paid';
               }
            }
         });
      }

      return sum === 0
         ? !!TRAINEE_LICENCE_TYPES[application.licenceTypeCode]
            ? traineePaymentStatus
            : 'Paid'
         : helper.formatCurrency(sum, 2);
   };

   isValidNumber(prevProps, prevState) {
      const renewals = this.getRenewalsFromProps(this.props);
      const prevRenewals = this.getRenewalsFromProps(prevProps);

      const { registrationNumberValidity, registrationNumber } = this.state;
      const { resetRenewalApplicationSearch } = this.props;
      let valid = true;
      if (
         prevState.registrationNumberValidity &&
         registrationNumberValidity &&
         renewals.registrationNumberNotFound
      ) {
         if (
            typeof renewals.registrationNumberNotFoundMessage === 'string' &&
            !Validator.isNullOrWhiteSpace(
               renewals.registrationNumberNotFoundMessage
            )
         ) {
            M.toast({
               html: renewals.registrationNumberNotFoundMessage,
               classes: 'error',
            });
         } else {
            M.toast({
               html: `Invalid Licence Number`,
               classes: 'error',
            });
         }
         this.setState({ registrationNumberValidity: false });
         this.props.dispatch(resetRenewalApplicationSearch());
         valid = false;
      } else if (
         prevRenewals.applications.length !== renewals.applications.length
      ) {
         valid = this.checkApplicationAndFee(registrationNumber);
      }
      return valid;
   }

   processSupervision(prevProps, prevState) {
      const renewals = this.getRenewalsFromProps(this.props);
      const prevRenewals = this.getRenewalsFromProps(prevProps);

      if (prevState.supervisorSearchSubmitted && renewals.supervisorNotFound) {
         this.setState({
            supervisorSearchError: `Invalid licence number`,
            supervisorSearchSubmitted: false,
         });
      } else if (
         prevRenewals.supervisors.length !== renewals.supervisors.length
      ) {
         this.tryUpdateSupervisor();
      }
   }

   generateCannotSuperviseError(supervisor) {
      this.setState({
         supervisorSearchError: `${
            supervisor.fullName
         } cannot be nominated at this time, nominate another Certifier or contact the licensing team on 0800 743 262 for assistance.`,
         supervisorSearchSubmitted: false,
      });
   }

   tryUpdateSupervisor() {
      const { companyContacts } = this.state;
      const renewals = this.getRenewalsFromProps(this.props);
      const {
         supervisorNumber,
         registrationNumberSearchingSupervisor,
         applicationIdSearchingSupervisor,
      } = this.state;

      const application = this.getRenewalApplication(
         registrationNumberSearchingSupervisor
      ).applications.find(a => {
         return a.applicationId === applicationIdSearchingSupervisor;
      });

      const supervisor = renewals.supervisors.find(s => {
         return Number(s.registrationNumber) === Number(supervisorNumber);
      });

      if (!supervisor.isValid) {
         this.setState({
            supervisorSearchError: supervisor.errorMessage,
            supervisorSearchSubmitted: false,
         });
         return;
      }

      const isValidSupervisor = supervisor.activeCertifyingRegistrations.find(
         r => {
            return r.disciplineCode === application.licenceDisciplineCode;
         }
      );

      if (!isValidSupervisor) {
         this.setState({
            supervisorSearchError: `${
               supervisor.fullName
            } is not a registered Certifier for this licence type. Please choose another supervisor.`,
            supervisorSearchSubmitted: false,
         });
         return;
      }

      if (!isValidSupervisor.canSupervise) {
         this.generateCannotSuperviseError(supervisor);
         return;
      }
      
      companyContacts
         .filter(f => !f.isDeleted)
         .forEach(ca => {
            ca.applications.forEach(a => {
               if (a.applicationId === application.applicationId) {
                  a.supervisor = supervisor;
                  a.nominateSupervisor = false;
               }
            });
         });

      this.setState({
         companyContacts,
         supervisorSearchError: undefined,
         supervisorSearchSubmitted: false,
         registrationNumberSearchingSupervisor: 0,
         applicationIdSearchingSupervisor: 0,
      });

      const { resetRenewalSearchSupervisor } = this.props;
      resetRenewalSearchSupervisor();
   }

   hasRegistrationNumberBeenSearched() {
      const renewals = this.getRenewalsFromProps(this.props);
      const { registrationNumber, companyContacts } = this.state;
      const application = renewals.applications.find(app => {
         return Number(app.registrationNumber) === Number(registrationNumber);
      });

      // check if the aplication is in local state ?
      if (application) {
         const contact = companyContacts.find(ca => {
            return (
               Number(ca.registrationNumber) === Number(registrationNumber) &&
               ca.isDeleted
            );
         });

         // if not found in local state ? then add it.
         if (contact) {
            let item = cloneDeep(contact);
            companyContacts.splice(companyContacts.indexOf(contact), 1);

            item.applications.forEach(a => {
               a.isDeleted = false;
            });
            item.isDeleted = false;
            companyContacts.unshift(item);
            this.calculateTotal();
         }
      }
      return application;
   }

   checkApplicationAndFee(registrationNumber) {
      const renewal = this.getRenewalApplication(registrationNumber);
      let notFound = false;
      if (renewal && renewal.applications.length === 0) {
         notFound = true;
      } else {
         const feeCount = renewal.applications.reduce(
            (count, a) => count + a.fees.length
         );
         if (feeCount === 0) {
            notFound = true;
         }
      }

      if (notFound) {
         M.toast({
            html: `The application(s)/fee(s) under given registration number
               can't be found. Please contact the Plumbers, Gasfitters and Drainlayers Board.`,
            classes: 'error',
         });
         this.setState({ registrationNumberValidity: false });
      } else {
         this.setState({ registrationNumber: '' });
      }

      return !notFound;
   }

   renderTotalRow = () => {
      const total = this.state.total
         ? helper.formatCurrency(this.state.total, 2)
         : 0.0;

      const emptyCells = (
         <>
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
         </>
      );

      return (
         <>
            <TableRow className="total-row">
               {emptyCells}
               <TableCell>Total</TableCell>
               {this.currencyCell}
               <TableCell className="amount-cell">{total}</TableCell>
            </TableRow>
         </>
      );
   };

   render() {
      const {
         registrationNumber,
         loadRecaptcha,
         registrationNumberValidity,
         total,
      } = this.state;

      let tableContent = this.renderTable();

      return (
         <React.Fragment>
            <main>
               <div className="section no-pad-bot" id="index-banner">
                  <div className="container pay-and-nominate">
                     <div className="row">
                        <div className="col s12 m12 l6 offset-l1">
                           <div className="col s12 m12 l12">
                              <p className="columnheader">
                                 Who would you like to pay and <br />
                                 nominate supervisors for?
                              </p>
                           </div>
                           <div className="col s12 m12 l12">
                              <span className="sub-heading">
                                 Type the 5-digit Licence number of the person
                                 you would like to perform an action for
                              </span>
                           </div>
                           <div className="col s12 m12 l4">
                              <TextInput
                                 type="text"
                                 id="registration_number"
                                 placeholder="Licence #"
                                 label=" "
                                 value={registrationNumber}
                                 onChange={e => {
                                    this.setState({
                                       registrationNumber: e.target.value,
                                    });
                                 }}
                                 maxLength={20}
                                 inputValid={registrationNumberValidity}
                                 onSubmit={this.onSearch}
                              />
                              {loadRecaptcha && (
                                 <ReCaptcha
                                    sitekey={
                                       process.env.REACT_APP_RECAPTCHA_KEY
                                    }
                                    action="renewal_add_licence_and_nominate"
                                    verifyCallback={this.verifyCallback}
                                 />
                              )}
                           </div>
                           <div className="col s12 m12 l4">
                              <button
                                 type="button"
                                 className="waves-effect waves-light btn btn-ml-30 licence-search-button"
                                 onClick={() => this.onSearch()}
                              >
                                 Search
                              </button>
                           </div>
                        </div>
                     </div>
                     <div className="row">
                        <div className="col s12 m12 l10 offset-l1 application-table">
                           {tableContent}
                        </div>
                     </div>
                     <div className="row">
                        <div className="col l8 offset-l1 person-table">
                           <div className="col s12 m12 l12 control-row">
                              <br />
                              <br />
                              <button
                                 type="button"
                                 className="waves-effect waves-light btn btn-inactive"
                                 onClick={() => this.back()}
                              >
                                 Back
                              </button>
                              {tableContent && total > 0 ? (
                                 <button
                                    type="button"
                                    className="waves-effect waves-light btn btn-ml-30"
                                    onClick={() => this.next()}
                                 >
                                    Next
                                 </button>
                              ) : null}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </main>
         </React.Fragment>
      );
   }
}

AddLicencesAndNominate.propTypes = {
   isBulkRenewal: PropTypes.bool.isRequired,
   nextUrl: PropTypes.string.isRequired,
   backUrl: PropTypes.string.isRequired,
   // Generic callbacks will be used from Company renewal & Bulk renewal
   getRenewalApplicationRequest: PropTypes.func.isRequired,
   resetRenewalApplicationSearch: PropTypes.func.isRequired,
   removeSavingStatus: PropTypes.func.isRequired,
   getRenewalSearchSupervisorRequest: PropTypes.func.isRequired,
   resetRenewalSearchSupervisor: PropTypes.func.isRequired,
   savePayAndNominateDetails: PropTypes.func.isRequired,
   token: PropTypes.string,
};

AddLicencesAndNominate.defaultProps = {
   token: null,
};

const mapStateToProps = state => {
   return {
      companyRenewal: state.companyRenewal,
      bulkRenewal: state.bulkRenewal,
      userSession: state.session.user,
      token: getToken(state),
   };
};

export default connect(mapStateToProps)(AddLicencesAndNominate);
