import React from 'react';
import { arrayOf, func, number } from 'prop-types';
import TablePagination from '@material-ui/core/TablePagination';
import { DefaultPagingModel } from 'pgdb-data-layer/lib/Helpers/Others/PagedQuery';
import { contactResult } from '../../Containers/Pages/PublicRegister/types';
import TablePaginationActions from './TablePaginationActions';

const Pagination = ({
   colspan,
   contacts,
   paging,
   onChangePage,
   onChangeRowsPerPage,
}) => {
   return (
      <TablePagination
         colSpan={colspan}
         rowsPerPageOptions={[5, 10, 20, 50, 100]}
         count={contacts[0].totalCount}
         rowsPerPage={paging.pageSize}
         page={paging.pageNumber}
         onChangePage={onChangePage}
         onChangeRowsPerPage={onChangeRowsPerPage}
         ActionsComponent={TablePaginationActions}
      />
   );
};
Pagination.propTypes = {
   contacts: arrayOf(contactResult).isRequired,
   onChangeRowsPerPage: func,
   onChangePage: func,
   paging: arrayOf(DefaultPagingModel),
   colspan: number,
};

export default Pagination;
