import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Moment from 'moment';
import Table from '../../../Components/GenericTable/Table';
import TableRow from '../../../Components/GenericTable/TableRow';
import TableCell from '../../../Components/GenericTable/TableCell';
import TableHeaderCell from '../../../Components/GenericTable/TableHeaderCell';
import SupervisionActions from 'pgdb-data-layer/lib/Redux/SupervisionRedux';
import { SUPERVISION_STATUS } from 'pgdb-data-layer/lib/Constants/Supervision';
import TableHead from '../../../Components/GenericTable/TableHead';
import TableBody from '../../../Components/GenericTable/TableBody';
import TextLink from '../../../Components/TextLink';

const PendingSuperviseesTable = ({ tableData, openModal, dispatch }) => {
   const generateTableRows = () => {
      return tableData.map(value => {
         const isSupervisionPending =
            value.supervisionStatus === SUPERVISION_STATUS.PENDING;

         return (
            <TableRow key={value.supervisionId}>
               <TableCell icon="sync" color="orange">
                  {value.licenceNumber}
               </TableCell>
               <TableCell color="orange">{value.name}</TableCell>
               <TableCell
                  className="small medium-font table-set-size"
                  color="orange"
               >
                  {value.licenceDescription}
                  {!isSupervisionPending && (
                     <>
                        <br />
                        <span className="bold">
                           Please renew your licence so that your supervisee can
                           be issued their licence
                        </span>
                     </>
                  )}
               </TableCell>
               <TableCell className="small" color="orange">
                  {Moment(value.licenceYearEnds).format('DD/MM/YYYY')}
               </TableCell>
               {isSupervisionPending ? (
                  <>
                     <TableCell color="activeblue">
                        <TextLink
                           onClick={() => {
                              dispatch(
                                 SupervisionActions.openModal('Accept', value)
                              );
                           }}
                        >
                           Approve
                        </TextLink>
                     </TableCell>
                     <TableCell color="grey">
                        <TextLink
                           onClick={() => {
                              dispatch(
                                 SupervisionActions.openModal('Decline', value)
                              );
                           }}
                           className="grey-text"
                        >
                           Decline
                        </TextLink>
                     </TableCell>
                  </>
               ) : (
                  <>
                     <TableCell />
                     <TableCell />
                  </>
               )}
            </TableRow>
         );
      });
   };
   return (
      <>
         <Table>
            <TableHead>
               <TableRow>
                  <TableHeaderCell className="icon-left-pad">
                     Licence. #
                  </TableHeaderCell>
                  <TableHeaderCell className="no-left-pad">
                     Name
                  </TableHeaderCell>
                  <TableHeaderCell className="no-left-pad">
                     Type of Licence
                  </TableHeaderCell>
                  <TableHeaderCell className="no-left-pad">
                     Licence Year Ends
                  </TableHeaderCell>
                  <TableHeaderCell />
                  <TableHeaderCell />
               </TableRow>
            </TableHead>

            <TableBody>{generateTableRows()}</TableBody>
         </Table>
      </>
   );
};

const { array, func } = PropTypes;

PendingSuperviseesTable.propTypes = {
   tableData: array.isRequired,
   openModal: func,
};

export default connect()(PendingSuperviseesTable);
