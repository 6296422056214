import React, { Component } from 'react';
import { Prompt } from 'react-router';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { isEqual, isEmpty, isNull } from 'lodash';
import M from 'materialize-css';
import UserActions, {
   getUserInfoData,
   isFetchingUserInfoSuccess,
   isFetchingUserInfo,
} from 'pgdb-data-layer/lib/Redux/UserInfoRedux';

import GenderActions, {
   getGenderData,
} from 'pgdb-data-layer/lib/Redux/GenderRedux';
import EthnicityActions, {
   getEthnicityData,
} from 'pgdb-data-layer/lib/Redux/EthnicityRedux';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import * as PROFILE_HELPERS from 'pgdb-data-layer/lib/Helpers/Profile';
import ExternalLink from '../../../Components/ExternalLink';
import TextInput from '../../../Components/TextInput/index';
import AddressInput from '../../../Components/AddressInput/index';
import './Profile.scss';
import Navigator from '../../../Navigation/Navigator';
import { getToken } from '../../../Session/SessionRedux';
import Loading from '../../../Components/Loading';

import Dropdown from '../../../Components/Dropdown';

class ProfileEntry extends Component {
   constructor(props) {
      super(props);
      this.lastSavedState = {
         contactName: '',
         contactEmail: '',
         contactPhoneNumber: '',
         contactMobileNumber: '',
         mailingAddress: '',
         physicalAddress: '',
         businessPhoneNumber: '',
         businessEmail: '',
         businessAddress: '',
         isRegistered: false,
         allFieldsValid: true,
         isRenewal: false,
         formValidity: {
            contactEmail: true,
            contactNumber: true,
            mailingAddress: true,
            physicalAddress: true,
            businessPhoneNumber: true,
            businessEmail: true,
            businessAddress: true,
            genderSelected: true,
            ethnicitySelected: true,
         },
         saveSubmitted: false,
         gender: {},
         selectedGender: '',
         ethnicity: {},
         selectedEthinicity: '',
         isRegistration: false,
      };
      this.state = Object.assign({}, this.lastSavedState);
   }

   componentDidMount() {
      // reset the save on every load
      this.props.dispatch(
         UserActions.saveUserContactInfoReset(this.props.token)
      );

      this.props.dispatch(GenderActions.genderRequest(this.props.token));

      this.props.dispatch(EthnicityActions.ethnicityRequest(this.props.token));

      this.setState({ saveSubmitted: false });
      this.props.dispatch(
         UserActions.getUserContactInfoRequest(this.props.token)
      );

      if (!this.props.routeBackUrl) {
         window.onbeforeunload = () => {
            return !isEqual(this.state, this.lastSavedState);
         };
      }
   }

   componentDidUpdate(prevProps) {
      // Clean up conditions later
      const { saveSubmitted } = this.state;
      const {
         isSaved,
         userContactData,
         token,
         routeBackUrl,
         dispatch,
         readyToPreformFooterOpertation,
         gender,
         ethnicity,
      } = this.props;

      if (isSaved && saveSubmitted) {
         if (readyToPreformFooterOpertation) readyToPreformFooterOpertation();
      }

      if (isSaved && routeBackUrl && saveSubmitted) {
         Navigator.to(`/${routeBackUrl}`);
      }

      if (isEmpty(userContactData)) {
         dispatch(UserActions.getUserContactInfoRequest(token));
      } else if (userContactData !== prevProps.userContactData) {
         this.mapPropsToState();
      }

      //setting gender once dropdown is pupulated
      if (gender !== prevProps.gender) {
         this.setState({ gender: gender });
      }

      //setting ethnicity once dropdown is pupulated
      if (ethnicity !== prevProps.ethnicity) {
         this.setState({ ethnicity: ethnicity });
      }
   }

   componentWillUnmount() {
      // Clear listener for onbeforeunload
      window.onbeforeunload = null;
   }

   validateFormFields() {
      const {
         contactEmail,
         contactMobileNumber,
         contactPhoneNumber,
         mailingAddress,
         physicalAddress,
         isRegistered,
         businessPhoneNumber,
         businessEmail,
      } = this.state;

      const { userContactData } = this.props;

      const valid = {
         contactEmail: PROFILE_HELPERS.checkValidEmail(contactEmail),
         contactMobileNumber: PROFILE_HELPERS.checkValidNumber(
            contactMobileNumber
         ),
         // Either contactPhoneNumber is empty, or its valid
         contactPhoneNumber:
            PROFILE_HELPERS.checkValidNumber(contactPhoneNumber) ||
            !contactPhoneNumber,
         mailingAddress: PROFILE_HELPERS.checkValidAddress(mailingAddress),
         physicalAddress: PROFILE_HELPERS.checkMailingAddressIsPostbox(
            mailingAddress
         )
            ? PROFILE_HELPERS.checkValidAddress(physicalAddress)
            : true,
         genderSelected: !!this.state.selectedGender,
         ethnicitySelected: !!this.state.selectedEthinicity,
      };

      if (isRegistered) {
         if (!userContactData.excludeContactDetailsFromRegister) {
            valid.businessPhoneNumber = PROFILE_HELPERS.checkValidNumber(
               businessPhoneNumber
            );
         }

         valid.businessEmail =
            PROFILE_HELPERS.checkValidEmail(businessEmail) ||
            businessEmail === '' ||
            businessEmail === null;
      }

      const allFieldsValid = Object.values(valid).every(v => !!v);
      this.setState({ formValidity: valid, allFieldsValid });
      return { formValidity: valid, allFieldsValid };
   }

   trySaveUserInfo() {
      const validity = this.validateFormFields();
      M.Toast.dismissAll();

      if (validity.allFieldsValid) {
         const {
            contactPhoneNumber,
            contactMobileNumber,
            contactEmail,
            businessPhoneNumber,
            businessEmail,
            mailingAddress,
            physicalAddress,
            isRegistered,
            businessAddress,
            selectedGender,
            selectedEthinicity,
         } = this.state;

         const data = {
            phoneNumber: contactPhoneNumber,
            mobileNumber: contactMobileNumber,
            emailAddress: contactEmail,
            publicPhone: businessPhoneNumber,
            publicEmail: businessEmail,
            mailingAddressLine1: mailingAddress.address_line_1,
            mailingAddressLine2: mailingAddress.address_line_2,
            mailingSuburbTown: mailingAddress.suburb,
            mailingPostalCode: mailingAddress.postcode,
            mailingCity: mailingAddress.city,
            mailingCountry: mailingAddress.country,
            mailingCountryCode: mailingAddress.countryCode,
            mailingAddress,
            physicalAddressLine1: physicalAddress.address_line_1,
            physicalAddressLine2: physicalAddress.address_line_2,
            physicalSuburbTown: physicalAddress.suburb,
            physicalPostalCode: physicalAddress.postcode,
            physicalCity: physicalAddress.city,
            physicalCountry: physicalAddress.country,
            physicalCountryCode: physicalAddress.countryCode,
            physicalAddress,
            EthnicityId: selectedEthinicity,
            GenderId: selectedGender,
         };

         if (
            isRegistered &&
            PROFILE_HELPERS.checkValidAddress(businessAddress)
         ) {
            data.businessAddressLine1 = businessAddress.address_line_1;
            data.businessAddressLine2 = businessAddress.address_line_2;
            data.businessSuburbTown = businessAddress.suburb;
            data.businessPostalCode = businessAddress.postcode;
            data.businessCity = businessAddress.city;
            data.businessCountry = businessAddress.country;
            data.businessCountryCode = businessAddress.countryCode;
            data.businessAddress = businessAddress;
         } else {
            data.businessAddressLine1 = null;
            data.businessAddressLine2 = null;
            data.businessSuburbTown = null;
            data.businessPostalCode = null;
            data.businessCity = null;
            data.businessCountry = null;
            data.businessCountryCode = null;
            data.businessAddress = null;
         }

         const { dispatch, userContactData, token, gender } = this.props;
         this.setState({ saveSubmitted: true });
         dispatch(
            UserActions.saveUserContactInfoRequest(
               token,
               userContactData.contactId,
               data
            )
         );

         this.lastSavedState = Object.assign({}, this.state, {
            ...validity,
            saveSubmitted: true,
         });
         M.toast({
            html: 'Your changes are being saved',
            classes: 'success',
         });
      } else {
         M.toast({
            html: 'Please correctly fill in all mandatory fields',
            classes: 'error',
         });
      }

      return validity.allFieldsValid;
   }

   mapPropsToState() {
      const { userContactData, gender, ethnicity } = this.props;
      const newState = {};
      newState.contactName = userContactData.fullName;
      newState.contactEmail = userContactData.emailAddress;
      newState.contactPhoneNumber = userContactData.phoneNumber;
      newState.contactMobileNumber = userContactData.mobileNumber;

      newState.physicalAddress = PROFILE_HELPERS.getUserAddress(
         userContactData.physicalAddressLine1,
         userContactData.physicalAddressLine2,
         userContactData.physicalSuburbTown,
         userContactData.physicalCity,
         userContactData.physicalPostalCode,
         userContactData.physicalCountryCode,
         userContactData.physicalCountry
      );

      newState.mailingAddress = PROFILE_HELPERS.getUserAddress(
         userContactData.mailingAddressLine1,
         userContactData.mailingAddressLine2,
         userContactData.mailingSuburbTown,
         userContactData.mailingCity,
         userContactData.mailingPostalCode,
         userContactData.mailingCountryCode,
         userContactData.mailingCountry
      );

      newState.businessAddress = PROFILE_HELPERS.getUserAddress(
         userContactData.businessAddressLine1,
         userContactData.businessAddressLine2,
         userContactData.businessSuburbTown,
         userContactData.businessCity,
         userContactData.businessPostalCode,
         userContactData.businessCountryCode,
         userContactData.businessCountry
      );

      newState.businessPhoneNumber = userContactData.publicPhone;
      newState.businessEmail = userContactData.publicEmail;
      newState.isRegistered = PROFILE_HELPERS.isUserRegistered(userContactData);
      newState.contactPhoto = PROFILE_HELPERS.getUserContactPhoto(
         userContactData
      );
      newState.isPhotoStatusPending = PROFILE_HELPERS.isPhotoStatusPending(
         userContactData
      );

      if (!isNull(userContactData.ethnicity)) {
         newState.selectedEthinicity = userContactData.ethnicity.id;
      }
      if (!isNull(userContactData.gender)) {
         newState.selectedGender = userContactData.gender.id;
      }

      newState.gender = gender;
      newState.ethnicity = ethnicity;
      newState.profileLoaded = true;
      this.lastSavedState = Object.assign({}, this.state, newState);
      this.setState(newState);
   }

   onGenderChange = value => {
      this.setState({ selectedGender: value });
   };

   onEthnicityChange = value => {
      this.setState({ selectedEthinicity: value });
   };

   render() {
      const {
         formValidity,
         profileLoaded,
         mailingAddress,
         businessAddress,
         physicalAddress,
         contactName,
         contactPhoto,
         contactPhoneNumber,
         contactMobileNumber,
         contactEmail,
         isRegistered,
         businessPhoneNumber,
         businessEmail,
         isPhotoStatusPending,
         gender,
         ethnicity,
      } = this.state;

      const {
         header,
         footer,
         location,
         isRenewal,
         userContactData,
         routeBackUrl,
         returnUrl,
         saveInProgress,
         saveConfirmationInProgress,
         isRegistration,
      } = this.props;

      const PHOTO_STATUS_REJECTED = '3';

      // set Declined status
      const isDeclined =
         userContactData &&
         userContactData.contactPhoto &&
         userContactData.contactPhoto.status === PHOTO_STATUS_REJECTED;

      const isLoading =
         !profileLoaded || saveInProgress || saveConfirmationInProgress;

      return (
         <Loading isLoading={isLoading}>
            {!isLoading && (
               <>
                  <Prompt
                     when={
                        !routeBackUrl &&
                        !isEqual(this.state, this.lastSavedState)
                     }
                     message="You have unsaved changes. Are you sure you wish to leave the page and lose your changes?"
                  />
                  <div className="section no-pad-bot" id="index-banner">
                     <div className="row no-margin flex">
                        <div className="container">
                           <div className="col l9">
                              <div className="row profile-row">
                                 {header}
                                 <div className="col s3 m8 l8 offset-l1">
                                    <div className="row profile-row">
                                       <div className="col l10">
                                          <span className="section-header sub-heading">
                                             {contactName}
                                          </span>
                                       </div>
                                    </div>
                                    <div className="row profile-row photo-button-wrapper">
                                       <div className="col l4">
                                          <span className="grey2-text input-header">
                                             Photo
                                          </span>
                                          <div
                                             className="upload-photo profile-image"
                                             style={{
                                                backgroundImage: `url(${contactPhoto})`,
                                             }}
                                          >
                                                <Link
                                                   to={{
                                                      pathname:
                                                         '/photocriteria',
                                                      returnUrl:
                                                         returnUrl ||
                                                         location.pathname,
                                                   }}
                                                   style={{
                                                      display: contactPhoto
                                                         ? 'none'
                                                         : 'table-cell',
                                                   }}
                                                >
                                                   {`Upload${
                                                      isPhotoStatusPending
                                                         ? ' New'
                                                         : ''
                                                   } Photo`}
                                                </Link>
                                             <label
                                                style={{
                                                   display: !isPhotoStatusPending
                                                      ? 'none'
                                                      : 'table-cell',
                                                }}
                                                id="photo-decline-label"
                                             >
                                                {isDeclined
                                                   ? 'Your photo has been Rejected. Please upload a new Photo'
                                                   : 'Pending Approval'}
                                             </label>
                                          </div>
                                       </div>
                                       <div className="col l8 photo-description-wrapper">
                                          <span className="photo-description medium-font">
                                             Photo only needs to be updated
                                             every 10 years
                                          </span>
                                       </div>
                                    </div>
                                    <div className="row profile-row">
                                       <div className="col l6">
                                          <TextInput
                                             type="text"
                                             id="contact_phone_number"
                                             value={contactPhoneNumber}
                                             inputValid={
                                                formValidity.contactPhoneNumber
                                             }
                                             placeholder="Best contact number"
                                             onChange={e => {
                                                this.setState({
                                                   contactPhoneNumber:
                                                      e.target.value,
                                                });
                                             }}
                                             label="Contact Phone Number"
                                          />
                                       </div>
                                       <div className="col l6">
                                          <TextInput
                                             type="text"
                                             id="contact_mobile_number"
                                             value={contactMobileNumber}
                                             inputValid={
                                                formValidity.contactMobileNumber
                                             }
                                             placeholder="Your mobile number"
                                             onChange={e => {
                                                this.setState({
                                                   contactMobileNumber:
                                                      e.target.value,
                                                });
                                             }}
                                             label="Contact Mobile Number"
                                             mandatory
                                          />
                                       </div>
                                    </div>
                                    <div className="row profile-row">
                                       <div className="col l12">
                                          <TextInput
                                             type="text"
                                             id="contact_email"
                                             inputValid={
                                                formValidity.contactEmail
                                             }
                                             value={contactEmail}
                                             placeholder="Private email address"
                                             onChange={e => {
                                                this.setState({
                                                   contactEmail: e.target.value,
                                                });
                                             }}
                                             label="Contact Email"
                                             mandatory
                                          />
                                       </div>
                                    </div>

                                    <div className="row profile-row">
                                       <div className="col l12">
                                          <AddressInput
                                             inputValid={
                                                formValidity.mailingAddress
                                             }
                                             onSubmit={value => {
                                                this.setState({
                                                   mailingAddress: value,
                                                });
                                             }}
                                             onChange={value => {
                                                this.setState({
                                                   mailingAddress: value,
                                                });
                                             }}
                                             name="mailingAddress"
                                             value={mailingAddress}
                                             type="text"
                                             id="mailing_address"
                                             placeholder="Start typing to search for your address"
                                             label="Mailing Address"
                                             mandatory
                                             postalCheckRequired
                                          />
                                       </div>
                                    </div>
                                    <div className="row profile-row">
                                       <div className="col l12">
                                          <AddressInput
                                             inputValid={
                                                formValidity.physicalAddress
                                             }
                                             onSubmit={value => {
                                                this.setState({
                                                   physicalAddress: value,
                                                });
                                             }}
                                             onChange={value => {
                                                this.setState({
                                                   physicalAddress: value,
                                                });
                                             }}
                                             name="physicalAddress"
                                             type="text"
                                             id="physical_address"
                                             value={physicalAddress}
                                             placeholder="PO Box and Private Bag addresses are not accepted"
                                             label="Physical Address"
                                             addressParams={{ post_box: '0' }}
                                             mandatory={PROFILE_HELPERS.checkMailingAddressIsPostbox(
                                                mailingAddress
                                             )}
                                          />
                                       </div>
                                    </div>
                                    <div className="row profile-row">
                                       <div className="drop-down col l6">
                                          <div className="">
                                             <Dropdown
                                                value={
                                                   this.state.selectedGender
                                                      ? this.state
                                                           .selectedGender
                                                      : ''
                                                }
                                                values={gender}
                                                placeholder="Select your Gender"
                                                label="Gender"
                                                mandatory
                                                onChange={e =>
                                                   this.onGenderChange(
                                                      e.target.value
                                                   )
                                                }
                                                inputValid={
                                                   formValidity.genderSelected
                                                }
                                             />
                                          </div>
                                       </div>

                                       <div className="drop-down col l6">
                                          <div className="">
                                             <Dropdown
                                                value={
                                                   this.state.selectedEthinicity
                                                      ? this.state
                                                           .selectedEthinicity
                                                      : ''
                                                }
                                                values={ethnicity}
                                                placeholder="Select your Ethnicity"
                                                label="Ethnicity"
                                                mandatory
                                                onChange={e =>
                                                   this.onEthnicityChange(
                                                      e.target.value
                                                   )
                                                }
                                                inputValid={
                                                   formValidity.genderSelected
                                                }
                                             />
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col s3 m8 l8 offset-l1">
                                    {isRegistered && (
                                       <div>
                                          <div className="row profile-row">
                                             <div className="col l12">
                                                <span className="section-header section-break">
                                                   My Public Register Details
                                                </span>
                                             </div>
                                          </div>
                                          <div className="row profile-row">
                                             <div className="col l6">
                                                <TextInput
                                                   type="text"
                                                   id="business_number"
                                                   inputValid={
                                                      formValidity.businessPhoneNumber
                                                   }
                                                   value={businessPhoneNumber}
                                                   onChange={e => {
                                                      this.setState({
                                                         businessPhoneNumber:
                                                            e.target.value,
                                                      });
                                                   }}
                                                   placeholder="Public Phone Number"
                                                   label="Business Phone Number"
                                                   mandatory
                                                />
                                             </div>
                                             <div className="col l6">
                                                <TextInput
                                                   type="text"
                                                   id="business_email"
                                                   inputValid={
                                                      formValidity.businessEmail
                                                   }
                                                   value={businessEmail}
                                                   onChange={e => {
                                                      this.setState({
                                                         businessEmail:
                                                            e.target.value,
                                                      });
                                                   }}
                                                   placeholder="Public Business Email"
                                                   label="Business Email"
                                                />
                                             </div>
                                          </div>
                                          <div className="row profile-row">
                                             <div className="col l12">
                                                <AddressInput
                                                   name="businessAddress"
                                                   inputValid={
                                                      formValidity.businessAddress
                                                   }
                                                   value={businessAddress}
                                                   onSubmit={value => {
                                                      this.setState({
                                                         businessAddress: value,
                                                      });
                                                   }}
                                                   onChange={value => {
                                                      this.setState({
                                                         businessAddress: value,
                                                      });
                                                   }}
                                                   type="text"
                                                   id="business_address"
                                                   placeholder="Start typing to search for your business address"
                                                   label="Business Address"
                                                />
                                             </div>
                                          </div>
                                          {!isRenewal && (
                                             <div className="row profile-row">
                                                <div className="col l12 link-wrapper">
                                                   <ExternalLink
                                                      href="/public-register"
                                                      className="medium-font"
                                                   >
                                                      View my Public Register
                                                      listing
                                                   </ExternalLink>
                                                </div>
                                             </div>
                                          )}
                                       </div>
                                    )}
                                 </div>
                                 {footer(() => this.trySaveUserInfo())}
                              </div>
                           </div>

                           {isRegistration && (
                              <div className="note-right col l3">
                                 <div className="important-note">
                                    <h2 />
                                    <div className="important-note-header">
                                       <b>Public Register - Contact Details</b>
                                    </div>
                                    <p>
                                       <ExternalLink
                                          href="https://www.legislation.govt.nz/act/public/2006/0074/latest/DLM397166.html"
                                          className="medium-font"
                                       >
                                          Our Act
                                       </ExternalLink>
                                       {` requires us to have a public record of all registered Plumbers Gasfitters and Drainlayers. 
                              This helps the public to hire people who are allowed to do restricted work. Your entry on the public 
                              register must have:`}
                                    </p>
                                    <div className="important-note-content">
                                       <ul>
                                          <li>
                                             <FiberManualRecordIcon />
                                             {`your name`}
                                          </li>
                                          <li>
                                             <FiberManualRecordIcon />
                                             {`current licence(s)`}
                                          </li>
                                          <li>
                                             <FiberManualRecordIcon />
                                             {`registration history`}
                                          </li>
                                          <li>
                                             <FiberManualRecordIcon />
                                             {`people you supervise (if relevant)`}
                                          </li>
                                          <li>
                                             <FiberManualRecordIcon />
                                             {`a contact phone number`}
                                          </li>
                                          <li>
                                             <FiberManualRecordIcon />
                                             {`a work email address (if you have one)`}
                                          </li>
                                       </ul>
                                    </div>
                                    <p className="important-note-footer">
                                       {`If you need help, call us on our free phone `}
                                       <ExternalLink
                                          href="tel: 0800743262"
                                          className="medium-font"
                                       >
                                          0800 743 262
                                       </ExternalLink>
                                       {` or email`}
                                       <ExternalLink
                                          href="mailto: registration@pgdb.co.nz"
                                          className="medium-font"
                                       >
                                          {` registration@pgdb.co.nz`}
                                       </ExternalLink>
                                    </p>
                                 </div>
                              </div>
                           )}
                        </div>
                     </div>
                  </div>
               </>
            )}
         </Loading>
      );
   }
}

const mapStateToProps = state => {
   return {
      userContactData: getUserInfoData(state.user),
      isSaved: isFetchingUserInfoSuccess('saveUserContactInfo', state),
      saveInProgress: isFetchingUserInfo('saveUserContactInfo', state),
      token: getToken(state),
      gender: getGenderData(state),
      ethnicity: getEthnicityData(state),
   };
};

export default connect(mapStateToProps)(ProfileEntry);
