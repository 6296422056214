import React, { Component } from 'react';
// import Konami from 'react-konami-code';
import M from 'materialize-css';
import { NavLink, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { REFRESH_INTERVAL } from 'pgdb-data-layer/lib/Constants/Notification';
import UserActions, {
   getUserInfoData,
} from 'pgdb-data-layer/lib/Redux/UserInfoRedux';
import { getUserContactPhoto } from 'pgdb-data-layer/lib/Helpers/Profile';
import DashboardActions, {
   getDashboardNotifications,
   getGlobalNotification,
} from 'pgdb-data-layer/lib/Redux/DashboardRedux';
import Img from 'react-image';
import Icon from '../../Components/Icon';
import ExternalLink from '../../Components/ExternalLink';
import LogoutButton from '../../Components/Buttons/LogoutButton/button';
import { getToken } from '../../Session/SessionRedux';
import './Header.scss';
import NotificationIcon from '../../Components/NotificationIcon';
import * as session from '../../Session';
import Navigator from '../../Navigation/Navigator';
import { DEFAULT_PHOTO_PATH } from '../Pages/Profile/ProfilePhoto/ProfilePhotoEditorConstants';

const GLOBAL_NOTIFICATION_CHECK_INTERVAL = 5 * 1000; // 5 seconds
const TOGGLE_NOTIFICATION_KEY = 'display-global-notification';

class Header extends Component {
   constructor(props) {
      super(props);

      if (!sessionStorage.getItem(TOGGLE_NOTIFICATION_KEY)) {
         sessionStorage.setItem(TOGGLE_NOTIFICATION_KEY, 'true');
      }

      this.state = {
         notificationRefresh: null,
         globalNotificationRefresh: null,
         globalNotificationCheck: null,
         displayGlobalNotification: sessionStorage.getItem(
            TOGGLE_NOTIFICATION_KEY
         ),
      };
   }

   async componentDidMount() {
      M.AutoInit();

      const hasSessionExpired = await session.hasSessionExpired();

      if (hasSessionExpired === false) {
         if (this.props.authenticated && !this.props.contactData.contactId) {
            this.props.dispatch(
               UserActions.getUserContactInfoRequest(this.props.token)
            );
         }

         if (this.props.token) {
            this.props.dispatch(
               DashboardActions.getNotificationsRequest(this.props.token)
            );
            this.props.dispatch(
               DashboardActions.getGlobalNotificationRequest(this.props.token)
            );
         }
      }

      this.setState({
         ...this.state,
         notificationRefresh: setInterval(() => {
            if (this.props.token) {
               this.props.dispatch(
                  DashboardActions.getNotificationsRequest(this.props.token)
               );
            }
         }, REFRESH_INTERVAL),
         globalNotificationRefresh: setInterval(() => {
            if (this.props.token) {
               this.props.dispatch(
                  DashboardActions.getGlobalNotificationRequest()
               );
            }
         }, REFRESH_INTERVAL),
         globalNotificationCheck: setInterval(() => {
            if (this.props.globalNotification.forceLogout) {
               Navigator.toLogout();
            }
         }, GLOBAL_NOTIFICATION_CHECK_INTERVAL),
      });
   }

   componentDidUpdate() {
      M.AutoInit();
   }

   componentWillUnmount() {
      const { notificationRefresh } = this.state;
      if (notificationRefresh || notificationRefresh === 0)
         clearInterval(notificationRefresh);
   }

   getUserFirstName = () => {
      const name = this.props.userSession.name;
      if (name) {
         const firstName = name.split(' ');
         return firstName[0];
      }
      return name;
   };

   closeNotification = () => {
      if (sessionStorage.getItem(TOGGLE_NOTIFICATION_KEY)) {
         sessionStorage.setItem(TOGGLE_NOTIFICATION_KEY, 'false');
      }

      this.setState({ ...this.state, displayGlobalNotification: 'false' });
   };

   render() {
      const activeLink = {
         fontWeight: 'bold',
         color: 'red',
      };

      const defaultMenuItems = (
         <>
            <li>
               <NavLink
                  to="/dashboard"
                  strict
                  activeStyle={activeLink}
                  className="grey2-text"
               >
                  Home
               </NavLink>
            </li>
            <li>
               <NavLink
                  to="/licences"
                  strict
                  activeStyle={activeLink}
                  className="grey2-text"
               >
                  Licences
               </NavLink>
            </li>
            <li>
               <NavLink
                  to="/supervision"
                  strict
                  activeStyle={activeLink}
                  className="grey2-text"
               >
                  Supervision
               </NavLink>
            </li>
            <li>
               <NavLink
                  to="/cpd"
                  strict
                  activeStyle={activeLink}
                  className="grey2-text"
               >
                  CPD
               </NavLink>
            </li>
            <li>
               <NavLink
                  to="/fit2practice"
                  strict
                  activeStyle={activeLink}
                  className="grey2-text"
               >
                  Fit & Proper Questions
               </NavLink>
            </li>
            <li>
               <NavLink
                  to="/registration"
                  strict
                  activeStyle={activeLink}
                  className="grey2-text"
               >
                  Registration
               </NavLink>
            </li>
            <li>
               <NavLink
                  to="/exams"
                  strict
                  activeStyle={activeLink}
                  className="grey2-text"
               >
                  Exams
               </NavLink>
            </li>
            <li>
               <ExternalLink
                  href="https://www.pgdb.co.nz/about/contact.html"
                  className="grey2-text"
               >
                  Contact Us
               </ExternalLink>
            </li>
         </>
      );

      const {
         contactData,
         authenticated,
         userSession,
         notifications,
         globalNotification,
      } = this.props;

      const { displayGlobalNotification } = this.state;

      const profileImageUrl = getUserContactPhoto(contactData);

      return (
         <>
            <header id="pgdb-content">
               <nav className="white" role="navigation">
                  <ExternalLink
                     href="https://www.pgdb.co.nz"
                     id="logo-container"
                     className="far-left brand-logo grey2-text"
                  >
                     <img
                        alt="pgdb-logo"
                        className="logo"
                        src="/images/logo.svg"
                     />
                  </ExternalLink>

                  <ul id="nav-mobile" className="sidenav">
                     {defaultMenuItems}
                  </ul>
                  <a
                     href="#!"
                     data-target="nav-mobile"
                     className="left-nav-menu sidenav-trigger black-text"
                  >
                     <Icon iconName="menu" />
                  </a>

                  <div className="nav-wrapper container">
                     <div className="row">
                        <div className="col l11 offset-l1 hide-on-tablet-and-down">
                           {authenticated ? (
                              <ul className="left grey2-text hide-on-tablet-and-down">
                                 {defaultMenuItems}
                              </ul>
                           ) : (
                              <ul className="left grey2-text l11 offset-l1">
                                 <li>
                                    <NavLink to="/login" className="grey2-text">
                                       Login
                                    </NavLink>
                                 </li>
                                 <li>
                                    <ExternalLink
                                       href="https://www.pgdb.co.nz/about/contact.html"
                                       className="grey2-text"
                                    >
                                       Contact Us
                                    </ExternalLink>
                                 </li>
                              </ul>
                           )}
                        </div>
                     </div>
                  </div>

                  {authenticated && (
                     <div className="right-nav-panel">
                        <ul id="dropdown1" className="dropdown-content">
                           <li>
                              <Link to="/profile">
                                 <Icon
                                    className="profile-dropdown-icons"
                                    iconName="assignment_ind"
                                 />
                                 Profile
                              </Link>
                           </li>
                           <li>
                              <Link to="/receipts">
                                 <Icon
                                    className="profile-dropdown-icons"
                                    iconName="attach_money"
                                 />
                                 Receipts
                              </Link>
                           </li>
                           <li>
                              <LogoutButton />
                           </li>
                        </ul>
                        <div>
                           <div className="flex-vertical-center grey2-text header-dropdown">
                              <a
                                 className="dropdown-trigger profile-link grey2-text flex-vertical-center medium-font"
                                 href="#!"
                                 data-target="dropdown1"
                              >
                                 <span className="hide-on-tablet-and-down">
                                    Hi{' '}
                                    {userSession
                                       ? this.getUserFirstName()
                                       : 'PGDB User'}
                                 </span>
                                 <Img
                                    src={[profileImageUrl, DEFAULT_PHOTO_PATH]}
                                    className="circle profile"
                                    alt="Profile"
                                 />
                                 <Icon
                                    className="right profile-right-drop-down"
                                    iconName="arrow_drop_down"
                                 />
                              </a>
                              <NotificationIcon
                                 notifications={notifications}
                                 targetUrl="/dashboard"
                              />
                           </div>
                        </div>
                     </div>
                  )}
               </nav>
            </header>
            {displayGlobalNotification === 'true' && globalNotification.title && (
               <div className="container">
                  <div className="row center-align no-margin-bottom">
                     <div className="col l12">
                        <div className="card-panel close-panel-padding lighten-2 orange no-margin-bottom">
                           <a
                              onClick={this.closeNotification}
                              href="#hide"
                              className="black-text"
                           >
                              <i className="material-icons right">close</i>
                           </a>
                           <h5>{globalNotification.title}</h5>
                           <span>{globalNotification.description}</span>
                        </div>
                     </div>
                  </div>
               </div>
            )}
         </>
      );
   }
}

const mapStateToProps = state => {
   return {
      userSession: state.session.user,
      contactData: getUserInfoData(state.user),
      notifications: getDashboardNotifications(state.dashboard),
      globalNotification: getGlobalNotification(state.dashboard),
      token: getToken(state),
   };
};

export default connect(mapStateToProps)(Header);
