import React from 'react';
import { string, array } from 'prop-types';
import Icon from '../Icon';

const ActiveNotificationIcon = ({ targetUrl }) => {
   return (
      <a className="text-red" href={targetUrl}>
         <Icon
            className="notification-bell"
            iconName="notification_important"
         />
      </a>
   );
};

ActiveNotificationIcon.propTypes = {
   targetUrl: string.isRequired,
};

const InactiveNotificationIcon = ({ targetUrl }) => {
   return (
      <a className="grey-text" href={targetUrl}>
         <Icon className="notification-bell" iconName="notifications" />
      </a>
   );
};

InactiveNotificationIcon.propTypes = {
   targetUrl: string.isRequired,
};

const NotificationIcon = ({ notifications, targetUrl }) => {
   const notif = notifications || [];
   return notif.length > 0 ? (
      <ActiveNotificationIcon targetUrl={targetUrl} />
   ) : (
      <InactiveNotificationIcon targetUrl={targetUrl} />
   );
};

NotificationIcon.propTypes = {
   notifications: array,
   targetUrl: string.isRequired,
};

NotificationIcon.defaultProps = {
   notifications: [],
};

export default NotificationIcon;
