import * as ADDRESS from 'pgdb-data-layer/lib/Constants/Address';

class AddressFinder {
   static getObject(
      address_line_1,
      address_line_2,
      suburb,
      city,
      postcode,
      countryCode,
      country
   ) {
      const addressArray = [
         address_line_1,
         address_line_2,
         suburb,
         city,
         postcode,
      ];
      if (countryCode !== ADDRESS.DEFAULT_COUNTRY_CODE) {
         addressArray.push(country);
      }
      const fullAddress = addressArray.filter(val => val).join(', ');
      return {
         address_line_1,
         address_line_2,
         suburb,
         city,
         postcode,
         countryCode,
         fullAddress,
      };
   }
}

export default AddressFinder;
