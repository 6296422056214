import React from 'react';
import './RegistrationApply.scss';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Loading from '../../../Components/Loading';
import Navigator from '../../../Navigation/Navigator';

const RegistrationApply = () => {
   const isLoading = false;

   const supportingDocumentsRequired = () => {
      return (
         <div className="important-note">
            <div className="important-note-header">
               <b>Supporting documents required</b>
            </div>
            <div className="important-note-content">
               <ul>
                  <li>
                     <FiberManualRecordIcon />
                     {`A certified copy of your
                     qualification (not required if applying for certifier)`}
                  </li>
                  <li>
                     <FiberManualRecordIcon />A copy of your Ministry of Justice
                     Record dated within 1 month
                  </li>
               </ul>
               <p>Additional documents you may need to supply:</p>
               <ul>
                  <li>
                     <FiberManualRecordIcon />
                     Documents about any convictions, health conditions or
                     disciplinary matters.{' '}
                     <a
                        href="https://www.justice.govt.nz/criminal-records/"
                        target="_blank"
                        rel="noopener noreferrer"
                     >
                        Find out more{' '}
                     </a>
                  </li>
               </ul>
               <div className="application-fees">
                  <p className="application-fee-title">Application fees</p>
                  <p>Please check below link for fees</p>
                  <p>
                     <a
                        href="https://www.pgdb.co.nz/apply_for_registration/fees/"
                        target="_blank"
                        rel="noopener noreferrer"
                     >
                        View Full Fees
                     </a>
                  </p>
               </div>

               <b>After you submit your application</b>
               <ol>
                  <li>
                     Your application will take up to 10 working days to review.
                     If we need any more information, we will contact you by
                     email.
                  </li>
                  <li>
                     When your application is complete we will ask you to pay.
                  </li>
                  <li>
                     After you pay, your application will need to be approved by
                     the Registrar. This takes an additional 5 working days.
                  </li>
               </ol>
               <p className="important-note-footer">
                  If you need help, call us on our free phone{' '}
                  <span className="important-note-phone">
                     <a href="tel:0800743262" rel="nofollow">
                        0800 743 262
                     </a>{' '}
                  </span>
                  or email{' '}
                  <a
                     className="activeblue-text important-note-email"
                     href="mailto:registration@pgdb.co.nz"
                  >
                     registration@pgdb.co.nz
                  </a>
               </p>
            </div>
         </div>
      );
   };

   return (
      <main>
         <Loading isLoading={isLoading}>
            <div className="section no-pad-bot" id="index-banner">
               <div className="container registration-container">
                  <div className="row no-margin flex">
                     <div className="col l9 m8 s12">
                        <div className="col m8 offset-l1 s11">
                           <h4 className="columnheader registration-header">
                              Apply for Registration
                           </h4>
                        </div>
                        <div className="col m10 l9 offset-l1 s11">
                           <h6 className="registration-subheading">
                              Registration application steps
                           </h6>
                           <div className="registration-list">
                              <p>1. Choose your registration class</p>
                              <p>2. Fill in your contact information</p>
                              <p>
                                 3. Answer questions about your fitness to
                                 practice
                              </p>
                              <p>4. Upload your documents </p>
                              <p>5. Review and submit your application</p>
                           </div>
                           <p className="application-notice">
                              You can save your application and return to it
                              later. You cannot edit or withdraw your
                              application once you submit it.
                           </p>

                           <div className="row profile-row next-button-registration right">
                              <div className="navigate-buttons">
                                 <button
                                    type="button"
                                    className="waves-effect waves-light button-margin registration-application-button"
                                    onClick={() => {
                                       Navigator.toRegistrationType();
                                    }}
                                 >
                                    START THE APPLICATION
                                 </button>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col l3 m4 side-note-background">
                        {supportingDocumentsRequired()}
                     </div>
                  </div>
               </div>
            </div>
         </Loading>
      </main>
   );
};

export default RegistrationApply;
