import React from 'react';
import { string, func } from 'prop-types';
import { NavLink } from 'react-router-dom';
import * as LICENCE_CARD_HELPERS from 'pgdb-data-layer/lib/Helpers/LicenceCard';
import TextLink from '../TextLink';

const ViewLicenceLink = ({ openCard }) => {
   return (
      <TextLink className="vLicence link" onClick={openCard}>
         View Licence
      </TextLink>
   );
};

ViewLicenceLink.propTypes = {
   openCard: func.isRequired,
};

const DownloadLicenceLink = ({ downloadCard, disabled }) => {
   return (
      <TextLink
         className={`vLicence ${disabled ? 'disabled' : 'link'}`}
         onClick={downloadCard}
      >
         Download Licence
      </TextLink>
   );
};

DownloadLicenceLink.propTypes = {
   downloadCard: func.isRequired,
};

const RenewLicenceLink = () => {
   return (
      <NavLink to="/renew" className="vLicence link">
         Renew
      </NavLink>
   );
};

export const CardFooterLink = ({ status, openCard, downloadCard }) => {
   const action = LICENCE_CARD_HELPERS.getStatusViewAction(status);

   return (
      <div className="vLicence-div">
         {action === 'View' && (
            <>
               <ViewLicenceLink openCard={openCard} />
               <br />
               <DownloadLicenceLink downloadCard={downloadCard} />
            </>
         )}
         {action === 'Renew' && <RenewLicenceLink />}
      </div>
   );
};

CardFooterLink.propTypes = {
   status: string.isRequired,
   openCard: func.isRequired,
   downloadCard: func.isRequired,
};
