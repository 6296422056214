import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoadingScreen from 'react-loading-screen';
import * as session from '../../Session';
import { getIdToken } from '../../Session/SessionRedux';

class Logout extends Component {
   componentDidMount() {
      session
         .deleteSession()
         .then(() => {
            session.deleteUser().then(() => {
               /* eslint-disable camelcase */
               const { id_token } = this.props;
               // Newer versions of Edge retain session data
               // expiring the session cookie to resolve this
               sessionStorage.clear();
               localStorage.clear();
               this.deleteCookies();
               session.logout(id_token);
            });
         })
         .catch(err => {
            console.error('Could not log the user out. ' + err);
            throw err;
         });
   }

   componentWillUnmount() {
      // This is just necessary in the case that the screen is closed before the
      // timeout fires, otherwise it would cause a memory leak that would trigger
      // the transition regardless, breaking the user experience.
      clearTimeout(this.timeoutHandle);
   }

   deleteCookies = () => {
      const cookies = document.cookie.split(';');

      for (let i = 0; i < cookies.length; i++) {
         const cookie = cookies[i];
         const eqPos = cookie.indexOf('=');
         const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
         document.cookie = `${name}  =;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
         console.log(`cleared cookie  ${document.cookie}`);
      }
   };

   render() {
      return (
         <LoadingScreen
            loading
            bgColor="#F4F4F4"
            textColor="#676767"
            logoSrc="/images/loading.gif"
            text="Logging out..."
         >
            <div />
         </LoadingScreen>
      );
   }
}

const mapStateToProps = state => {
   return {
      id_token: getIdToken(state),
   };
};

export default connect(mapStateToProps)(Logout);
