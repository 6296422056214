import React from 'react';
import { node, string } from 'prop-types';

const ExternalLink = ({ href, id, className, children }) => {
   return (
      <a
         href={href}
         id={id}
         className={className}
         target="_blank"
         rel="noopener noreferrer"
      >
         {children}
      </a>
   );
};

ExternalLink.propTypes = {
   id: string,
   className: string,
   href: string,
   children: node.isRequired,
};

ExternalLink.defaultProps = {
   id: null,
   className: null,
};

export default ExternalLink;
