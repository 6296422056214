import React from 'react';
import Barcode from 'react-barcode';
import './LicenceIdCard.scss';
import { DEFAULT_PHOTO_PATH } from '../../Containers/Pages/Profile/ProfilePhoto/ProfilePhotoEditorConstants';

const MiniLicenceCard = ({ discipline }) => {
   return (
      <div className={`mini-licence-tile ${discipline.toLowerCase()}-tile`}>
         <img
            src={`/images/icon-${discipline.toLowerCase()}-white.svg`}
            alt={`${discipline.toLowerCase()}-icon`}
            className="mini-card-icons"
         />
         <div className="mini-icon-text">{discipline}</div>
      </div>
   );
};

const LicenceCardBack = ({ details }) => {
   const miniCards = [];
   const disciplineList = [];

   const plumberDisc = details.Trades.find(e => e.includes('Plumb'));
   if (plumberDisc) {
      miniCards.push(<MiniLicenceCard discipline="Plumber" />);
      disciplineList.push(
         <div className="licence-type-container">
            <img className="licence-type-logo" src="/images/icon-plumber.svg"/>
            <p className="licence-type-text">{plumberDisc}</p>
         </div>
      );
   }

   const gasfitterDisc = details.Trades.find(e => e.includes('Gas'));
   if (details.Trades.some(e => e.includes('Gas'))) {
      miniCards.push(<MiniLicenceCard discipline="Gasfitter" />);
      disciplineList.push(
         <div className="licence-type-container">
            <img className="licence-type-logo" src="/images/icon-gasfitter.svg"/>
            <p className="licence-type-text">{gasfitterDisc}</p>
         </div>
      );
   }

   const drainlayerDisc = details.Trades.find(e => e.includes('Drain'));
   if (details.Trades.some(e => e.includes('Drain'))) {
      miniCards.push(<MiniLicenceCard discipline="Drainlayer" />);
      disciplineList.push(
         <div className="licence-type-container">
            <img className="licence-type-logo" src="/images/icon-drainlayer.svg"/>
            <p className="licence-type-text">{drainlayerDisc}</p>
         </div>
      );
   }

   const returnFirstName = () => {
      let firstName = details.FirstName.trim().toUpperCase()
      return firstName.length > 22 ? firstName.slice(0, 22) : firstName
   }

   const returnLastName = () => {
      let lastName = details.LastName.trim().toUpperCase()
      return lastName.length > 22 ? lastName.slice(0, 22) : lastName
   }

   return (
      <div className="canvas-card card-back">
         <div className="detail-wrapper">
            <div className="photoContainer">
               <img
               src={details.Image ? details.Image : DEFAULT_PHOTO_PATH}
               alt="card-profile"
               className="card-profile-photo"
               />
               {!details.Image && (
                  <div className="no-photo-supplied">
                     NO
                     <br />
                     PHOTO
                     <br />
                     SUPPLIED
                  </div>
               )}
            </div>
            <div className="details-container">
               <div className="name-container">
                  <p className="card-firstname">{returnFirstName()}</p>
                  <p className="card-lastname">{returnLastName()}</p>
                  <div className="disc-container">
                     <div className="disc-wrapper">{disciplineList}</div>
                  </div>
               </div>
               
               <div className="back-barcode-container">
                  <div className="barcode-container">
                     <Barcode
                        value={details.Barcode.toString().padStart(5, 0)}
                        format="CODE128"
                        width={1.3}
                        background="transparent"
                        fontSize={14}
                        height={35}
                        displayValue={"false"}
                        margin={0}
                     />
                  </div>
                  <div className="barcode-details-container">
                     <p className="licence-number-text">{details.Barcode.toString().padStart(5, 0)}</p>
                     <p className="expiry-text">{`EXPIRES ${details.Expire}`}</p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default LicenceCardBack;
