import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextInput from '../TextInput';

const TableTextBoxCell = ({
   className,
   onSubmit,
   errorText,
   inputValid,
   children,
   isHeader,
   onChange = null,
   registrationPaymentFontSize,
}) => {
   const messageClass = `position-absolute input-error ${
      inputValid ? 'hide-element' : ''
   }`;
   const [value, setValue] = useState('');

   const content = () => {
      return (
         <>
            {errorText && <span className="text-red bold">Error</span>}
            <div
               className={`medium-font-size table-text-box ${registrationPaymentFontSize}`}
            >
               <TextInput
                  maxLength={20}
                  type="text"
                  inputValid={inputValid}
                  onSubmit={() => {
                     onSubmit(value);
                  }}
                  value={value}
                  onChange={e => {
                     setValue(e.target.value);
                     if (onChange) {
                        onChange(e);
                     }
                  }}
               />
               {children}
            </div>
            <span className={messageClass}>{errorText}</span>
         </>
      );
   };

   return isHeader ? (
      <th className={`grey-text table-heading ${className}`}>{content()}</th>
   ) : (
      <td className={`${className}`}>{content()}</td>
   );
};

const { string, node, func, bool } = PropTypes;

TableTextBoxCell.defaultProps = {
   className: '',
   errorText: '',
   inputValid: true,
   children: null,
   isHeader: true,
   onChange: null,
};

TableTextBoxCell.propTypes = {
   className: string,
   onSubmit: func.isRequired,
   errorText: string,
   inputValid: bool,
   children: node,
   isHeader: bool,
   onChange: func,
};

export default TableTextBoxCell;
