import React, { useState, useEffect } from 'react';
import './radiostyle.scss';
import Icon from '../Icon';

const RadioButtons = ({
   onChange,
   title,
   groupName,
   radioOptions,
   value,
   disabled,
   iconName,
   index,
   radioLabel = 'radio-label col s10',
   radioDisplay = 'col s2 radio-display',
   fitAndProperRadioButtons,
   subHeadingComponent,
   conditionalComponent,
   condition
}) => {
   const [isInitialLoad, setIsInitialLoad] = useState(true);

   useEffect(() => {
      if (isInitialLoad === true) {
         setIsInitialLoad(false);
      }
   }, []);

   const onValueChange = (groupName, val) => {
      onChange(groupName, val);
   };

   const radioValues = [];

   for (let i = 0; i < radioOptions.length; i++) {
      const isChecked =
         isInitialLoad && radioOptions[i] === value
            ? {
               checked: true,
            }
            : undefined;

      radioValues.push(
         <div className="radio-option" key={i}>
            <label className="bold black-text">
               <input
                  id={radioOptions[i]}
                  radioGroup={groupName}
                  className="with-gap"
                  name={groupName}
                  type="radio"
                  onChange={() => onValueChange(groupName, radioOptions[i])}
                  disabled={disabled}
                  {...isChecked}
               />
               <span className="radio-option-label">{radioOptions[i]}</span>
               <span />
            </label>
            {condition !== undefined && condition.includes(radioOptions[i]) ? conditionalComponent() : null}
         </div>
      );
   }

   return (
      <div className="col small-btm radio-groups">
         <div className={`row ${fitAndProperRadioButtons}`}>
            {/* Text of the Radio */}
            <div className={radioLabel}>
               {iconName && (
                  <span>
                     <Icon className="label-icon" iconName={iconName} />
                  </span>
               )}
               {index && <span style={{ marginRight: '10px' }}>{index}</span>}
               {title}
            </div>
            {/* Options available to the user */}
            <div className={radioDisplay}>{radioValues}</div>
         </div>
         {subHeadingComponent && <div>{subHeadingComponent()}</div>}
      </div>
   );
};

export default RadioButtons;
