import React from 'react';
import { connect } from 'react-redux';
import Moment from 'moment';
import SupervisionActions from 'pgdb-data-layer/lib/Redux/SupervisionRedux';
import { getToken } from '../../../Session/SessionRedux';

const EndImmediatelyModalBody = ({ modalState, dispatch, token }) => {
   const onModalConfirm = () => {
      dispatch(
         SupervisionActions.changeSupervisionEndDateRequest(token, {
            supervisionId: modalState.selectedRow.supervisionId,
            endDate: Moment().format('MMM DD YYYY'),
            endImmediately: true,
         })
      );
      dispatch(SupervisionActions.closeModal());
   };

   return (
      <>
         <div className="modal-content-padding">
            <div className="medium-font-size modal-content-line-height">
               <p>
                  You confirm your supervision of {modalState.selectedRow.name}{' '}
                  will end on{' '}
                  <span className="activeblue-text">
                     {Moment().format('DD/MM/YYYY')}
                  </span>
                  ?
               </p>
            </div>
         </div>
         <div id="modal-footer" className="modal-footer center">
            <button
               type="submit"
               onClick={() => {
                  dispatch(SupervisionActions.closeModal());
               }}
               className="waves-effect waves-light btn btn-outline button-margin"
            >
               Back
            </button>
            <button
               type="submit"
               onClick={() => onModalConfirm()}
               className="waves-effect waves-light btn button-margin"
            >
               Yes
            </button>
         </div>
      </>
   );
};

const mapStateToProps = state => {
   return {
      modalState: state.supervision.modalState,
      token: getToken(state),
   };
};

export default connect(mapStateToProps)(EndImmediatelyModalBody);
