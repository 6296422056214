import React from 'react';
import './SupervisionModalAlert.scss';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import SupervisionActions from 'pgdb-data-layer/lib/Redux/SupervisionRedux';
import AcceptModalBody from './SupervisionModalContent/AcceptModalBody';
import DeclineModalBody from './SupervisionModalContent/DeclineModalBody';
import EndImmediatelyModalBody from './SupervisionModalContent/EndImmediatelyModalBody';
import ChangeDateModalBody from './SupervisionModalContent/ChangeDateModalBody';
import ChangeSupervisorModalBody from './SupervisionModalContent/ChangeSupervisorModalBody';
import NewSupervisorModalBody from './SupervisionModalContent/NewSupervisorModalBody';
import NewDifferentCitySupervisorModalBody from './SupervisionModalContent/NewDifferentCitySupervisorModalBody';
import ChangeDifferentCitySupervisorModalBody from './SupervisionModalContent/ChangeDifferentCitySupervisorModalBody';

class SupervisionModalAlert extends React.Component {
   getModalContent = () => {
      const { modalState } = this.props;
      let modalBody;

      switch (modalState.actionType) {
         case 'Decline':
            modalBody = <DeclineModalBody />;
            break;
         case 'Accept':
            modalBody = <AcceptModalBody />;
            break;
         case 'Change':
            modalBody = <ChangeDateModalBody />;
            break;
         case 'ChangeSupervisor':
            modalBody = <ChangeSupervisorModalBody />;
            break;
         case 'NewSupervisor':
            modalBody = <NewSupervisorModalBody />;
            break;
         case 'EndImmediately':
            modalBody = <EndImmediatelyModalBody />;
            break;
         case 'NewDifferentCitySupervisor':
            modalBody = <NewDifferentCitySupervisorModalBody />;
            break;
         case 'ChangeDifferentCitySupervisor':
            modalBody = <ChangeDifferentCitySupervisorModalBody />;
            break;
         default:
            modalBody = 'Incorrect Type';
      }
      return modalBody;
   };

   render() {
      const { dispatch } = this.props;
      const modalBody = this.getModalContent();
      return (
         <div id="supervision-modal">
            <Modal
               isOpen
               onRequestClose={() => {
                  dispatch(SupervisionActions.closeModal());
               }}
               className="generic-modal"
               overlayClassName="generic-modal-overlay"
               appElement={document.getElementById('root')}
            >
               {modalBody}
            </Modal>
         </div>
      );
   }
}

const mapStateToProps = state => {
   return {
      modalState: state.supervision.modalState,
   };
};

export default connect(mapStateToProps)(SupervisionModalAlert);
