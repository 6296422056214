import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AddLicencesAndNominate } from '../Shared';
import CompanyRenewalActions from 'pgdb-data-layer/lib/Redux/CompanyRenewalRedux';

class PayAndNominate extends Component {
   render() {
      return (
         <AddLicencesAndNominate
            backUrl="/renew-company-contact-details"
            nextUrl="/company-renewal-summary"
            getRenewalApplicationRequest={
               CompanyRenewalActions.getRenewalApplicationRequest
            }
            resetRenewalApplicationSearch={
               CompanyRenewalActions.resetRenewalApplicationSearch
            }
            removeSavingStatus={CompanyRenewalActions.removeSavingStatus}
            getRenewalSearchSupervisorRequest={
               CompanyRenewalActions.getCompanyRenewalSearchSupervisorRequest
            }
            resetRenewalSearchSupervisor={
               CompanyRenewalActions.resetCompanyRenewalSearchSupervisor
            }
            savePayAndNominateDetails={
               CompanyRenewalActions.savePayAndNominateDetails
            }
            isBulkRenewal={false}
         />
      );
   }
}

export default connect()(PayAndNominate);
