import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import '../RegistrationReviewAndSubmit/RegistrationReviewAndSubmit.scss';
import RegistrationActions from 'pgdb-data-layer/lib/Redux/RegistrationRedux';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Loading from '../../../Components/Loading';
import { getToken } from '../../../Session/SessionRedux';
import Navigator from '../../../Navigation/Navigator';

const RegistrationSuccess = ({ getUserRegistrationRequest, token }) => {
   const [hasDispatched, setHasDispatched] = useState(false);
   const isLoading = !hasDispatched;

   useEffect(() => {
      getUserRegistrationRequest(token);
      setHasDispatched(true);
   }, [token]);

   const importantNote = () => {
      return (
         <div className="important-note">
            <div className="important-note-header">
               <ErrorOutlineIcon />
               <b>Important Note</b>
            </div>
            <div className="important-note-content">
               <p>
                  Please check your email and your dashboard regularly to avoid
                  missing any important update.
               </p>
            </div>
            <footer className="important-note-footer">
               If you need help, call us on our free phone{' '}
               <span className="important-note-phone">
                  <a href="tel:0800743262" rel="nofollow">
                     0800 743 262
                  </a>{' '}
               </span>
               or email{' '}
               <a
                  className="activeblue-text important-note-email"
                  href="mailto:registration@pgdb.co.nz"
               >
                  registration@pgdb.co.nz
               </a>
            </footer>
         </div>
      );
   };

   return (
      <main>
         <Loading isLoading={isLoading}>
            <div className="section no-pad-bot" id="index-banner">
               <div className="container registration-container">
                  <div className="row no-margin">
                     <div className="col l9 m8 s12">
                        <div className="col m9 offset-l1 s12">
                           <h4 className="columnheader registration-header">
                              Thank you for submitting your application
                           </h4>
                        </div>
                        <div className="col m10 l9 offset-l1 s11">
                           <h6 className="registration-subheading">
                              What happens next?
                           </h6>

                           <div className="success-page-description">
                              <p>
                                 Your application will be reviewed by our team
                                 within 10 working days. We will contact you by
                                 email and text message if we need more
                                 information from you.
                              </p>
                              <p>
                                 If your application is complete we will send
                                 you instructions on how to pay by email and
                                 text message.
                              </p>
                           </div>
                           <div className="row registration-type-profile-row next-button-registration right">
                              <div className="navigate-buttons">
                                 <button
                                    type="button"
                                    className="waves-effect waves-light button-margin btn"
                                    onClick={() => Navigator.toDashboard()}
                                 >
                                    HOME
                                 </button>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col l3 m4 side-note-background">
                        {importantNote()}
                     </div>
                  </div>
               </div>
            </div>
         </Loading>
      </main>
   );
};

const mapStateToProps = state => {
   return {
      token: getToken(state),
   };
};

const mapDispatchToProps = {
   getUserRegistrationRequest: RegistrationActions.getUserRegistrationRequest,
};

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(RegistrationSuccess);
