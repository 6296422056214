import DashboardActions, {
   getDashboardUsefulLinks,
   getDashboardNotifications,
   getSpecialUser,
} from 'pgdb-data-layer/lib/Redux/DashboardRedux';
import React, { useEffect, useState } from 'react';
import { arrayOf, shape, string, func, array } from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import CPDCard from '../../Components/DashboardCards/CPDCard';
import ExamCard from '../../Components/DashboardCards/ExamCard';
import SupervisionCard from '../../Components/DashboardCards/SupervisionCard';
import RenewalCard from '../../Components/DashboardCards/RenewalCard';
import RegistrationCard from '../../Components/DashboardCards/RegistrationCard';
import Icon from '../../Components/Icon';
import ExternalLink from '../../Components/ExternalLink';
import Notifications from './Notifications';
import { getToken } from '../../Session/SessionRedux';
import Navigator from '../../Navigation/Navigator';
import {
   APPLICATION_STATUS_PLIC,
   REGISTRATION_LICENCE_APPLICATIONS,
} from 'pgdb-data-layer/lib/Constants/Application';
import ApplicationActions, {
   getApplications,
} from 'pgdb-data-layer/lib/Redux/ApplicationsRedux';

const UsefulExternalLink = ({ target, description }) => {
   return (
      <li>
         <ExternalLink className="link" href={target}>
            <Icon className="arrow" iconName="arrow_forward" /> {description}
         </ExternalLink>
      </li>
   );
};

UsefulExternalLink.propTypes = {
   target: string.isRequired,
   description: string.isRequired,
};

const UsefulLink = ({ target, description }) => {
   // if we have an external link, we can't use <Link /> for generating an <a> tag
   if (target.indexOf('http://') === 0 || target.indexOf('https://') === 0)
      return <UsefulExternalLink target={target} description={description} />;

   return (
      <li>
         <Link className="link" to={target}>
            <Icon className="arrow" iconName="arrow_forward" /> {description}
         </Link>
      </li>
   );
};

UsefulLink.propTypes = {
   target: string.isRequired,
   description: string.isRequired,
};

const StandardsNZLibraryLink = ({ generateLink }) => {
   return (
      <li>
         <Link className="link" onClick={e => generateLink(e)} to="/">
            <Icon className="arrow" iconName="arrow_forward" /> View Standards
            NZ Library
         </Link>
      </li>
   );
};

StandardsNZLibraryLink.propTypes = {
   generateLink: func.isRequired,
};

const usefulLinkType = shape({
   target: string.isRequired,
   description: string.isRequired,
});

const Home = ({
   dispatch,
   usefulLinks = [],
   notifications,
   token,
   specialUser,
   applications,
}) => {
   useEffect(() => {
      if (token) {
         dispatch(DashboardActions.getNotificationsRequest(token));
         dispatch(DashboardActions.getGlobalNotificationRequest());
         dispatch(DashboardActions.specialUserRequest(token));
      }
   }, []);

   const [requestSubmitted, setRequestSubmitted] = useState(false);

   if (!requestSubmitted && usefulLinks[0] !== undefined) {
      window.open(usefulLinks[0].target, '_blank');
      setRequestSubmitted(true);
   }

   const getUsefulLinks = async e => {
      setRequestSubmitted(false);
      e.preventDefault();
      dispatch(DashboardActions.getUsefulLinksRequest(token));
   };

   const licenceRegApplicationBanner = () => {
      return (
         <div className="licence-application-banner">
            <div>
               <h2 className="licence-application-title">
                  Registration and Licence Application almost complete
               </h2>
               <p className="licence-application-title">
                  Select the Start button to complete your licence application
               </p>
            </div>
            <button
               type="button"
               onClick={() => {
                  Navigator.toLicenceRegApplicationContactDetails();
               }}
               className="waves-effect waves-light button-margin licence-application-button"
            >
               Start
            </button>
         </div>
      );
   };

   const hasPendingRegApplication =
      applications.filter(
         x =>
            x.licence === null &&
            x.applicationStatusCode === APPLICATION_STATUS_PLIC &&
            REGISTRATION_LICENCE_APPLICATIONS.includes(x.applicationTypeCode)
      ).length > 0;

   return (
      <main>
         <div className="section no-pad-bot" id="index-banner">
            <div className="container">
               <div className="row s12 m12 l10 license-application-container">
                  {hasPendingRegApplication
                     ? licenceRegApplicationBanner()
                     : null}
               </div>
               <div className="row">
                  <div className="title-container col l10 offset-l1">

                     <h5 className="dashboard-link-header">
                        My Current Status
                     </h5>
                  </div>
               </div>
               <div className="row">
                  <div className="col s12 m12 l12">
                     <RenewalCard />
                     <SupervisionCard />
                     <CPDCard />
                     <ExamCard />
                     <RegistrationCard />

                  </div>
               </div>
               {specialUser && specialUser.isSpecialUser && (
                  <div className="row">
                     <div className="col s12 m12 l10 offset-l1">
                        <h6 className="dashboard-special-user-message">
                           Only a limited number of features of this application
                           are available at the moment. Contact the licensing
                           team for more information at licensing@pgdb.co.nz.
                        </h6>
                     </div>
                  </div>
               )}
               {notifications && (
                  <div className="row">
                     <div className="col s12 m12 l10 offset-l1">
                        <Notifications notifications={notifications} />
                     </div>
                  </div>
               )}
               <div className="row">
                  <div className="col s12 m12 l10 offset-l1">
                     <ul>
                        <li>
                           <h5 className="dashboard-link-header">
                              Useful Links
                           </h5>
                        </li>
                        <StandardsNZLibraryLink generateLink={getUsefulLinks} />
                        <UsefulLink
                           target="https://www.building.govt.nz/building-code-compliance/"
                           description="Building Code Compliance Docs"
                        />
                        <UsefulLink
                           target="https://worksafe.govt.nz/publications-and-resources/FilterSearchForm?Search=&Topic=&Industry=Building+and+construction&PublicationType=&action_resultsWithFilter=GoWithFilter"
                           description="WorkSafe Resources"
                        />
                        <UsefulLink
                           target="/profile"
                           description="Update My Profile"
                        />
                        <UsefulLink
                           target="/receipts"
                           description="View My Receipts"
                        />
                        <UsefulExternalLink
                           target="/public-register"
                           description="Search the Public Register"
                        />
                     </ul>
                  </div>
               </div>
               <br />
               <br />
            </div>
         </div>
      </main>
   );
};

Home.propTypes = {
   usefulLinks: arrayOf(usefulLinkType).isRequired,
   dispatch: func.isRequired,
   notifications: array,
   token: string.isRequired,
};

const mapState = state => ({
   usefulLinks: getDashboardUsefulLinks(state.dashboard),
   notifications: getDashboardNotifications(state.dashboard),
   token: getToken(state),
   specialUser: getSpecialUser(state.dashboard),
   applications: getApplications(state),
});

export default connect(mapState)(Home);
