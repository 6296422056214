import RegistrationActions, {
   isFetchingRegistration,
} from 'pgdb-data-layer/lib/Redux/RegistrationRedux';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as STATUS from 'pgdb-data-layer/lib/Constants/Contact';
import * as REGISTRATION from 'pgdb-data-layer/lib/Constants/Registration';
import PropTypes from 'prop-types';
import DashboardCard from '../index';
import { getToken } from '../../../Session/SessionRedux';

const RegistrationCard = ({
   userContactData,
   getUserRegistrationRequest,
   isFetchingRegistration,
   token,
   userRegistration,
}) => {
   const userCurrentStatus = userContactData.contactStatusCode;
   const [hasDispatched, setHasDispatched] = useState(false);
   const isLoading = !hasDispatched || isFetchingRegistration;
   const [isFullyCertified, setIsFullCertified] = useState(false);
   const [isAbleToRegister, setIsAbleToRegister] = useState(false);

   const checkIfAbleToRegister = () => {
      if (userCurrentStatus === undefined) {
         setIsAbleToRegister(false);
      } else if (
         userCurrentStatus !== STATUS.CONTACT_STATUS_DECEASED &&
         userCurrentStatus !== STATUS.CONTACT_STATUS_RETIRED
      ) {
         setIsAbleToRegister(true);
      } else setIsAbleToRegister(false);
   };

   useEffect(() => {
      getUserRegistrationRequest(token);
      setHasDispatched(true);
      checkIfAbleToRegister();
   }, [token, userCurrentStatus]);

   useEffect(() => {
      if (userRegistration && userRegistration.registration) {
         if (userRegistration.registration.registrations) {
            const { registrations } = userRegistration.registration;
            const certifyingPlumberMet = registrations.find(
               reg =>
                  reg.registrationTypeCode ===
                  REGISTRATION.CERTIFYING_PLUMBER_CODE
            );
            const certifyingGasfitterMet = registrations.find(
               reg =>
                  reg.registrationTypeCode ===
                  REGISTRATION.CERTIFYING_GASFITTER_CODE
            );
            const certifyingDrainlayerMet = registrations.find(
               reg =>
                  reg.registrationTypeCode ===
                  REGISTRATION.CERTIFYING_DRAINLAYER_CODE
            );
            setIsFullCertified(
               certifyingPlumberMet !== undefined &&
                  certifyingGasfitterMet !== undefined &&
                  certifyingDrainlayerMet !== undefined
            );
         }
      }
   }, [userRegistration]);

   return isAbleToRegister && !isFullyCertified ? (
      <DashboardCard
         header="Registration"
         icon="note_add"
         iconStyle="grey2-text"
         visible={!isLoading}
         disabled={!isLoading && !isAbleToRegister}
         description="Apply for registration"
         navLink={{
            href: 'registration',
            text: 'Apply',
         }}
      />
   ) : null;
};

RegistrationCard.defaultProps = {
   isFetchingRegistration: false,
};

RegistrationCard.propTypes = {
   userContactData: PropTypes.any,
   userRegistration: PropTypes.any,
   getUserRegistrationRequest: PropTypes.func,
   token: PropTypes.string.isRequired,
   isFetchingRegistration: PropTypes.bool,
};

const mapStateToProps = state => {
   return {
      userContactData: state.user.contactData,
      userRegistration: state.registration,
      token: getToken(state),
      isFetchingRegistration: isFetchingRegistration(
         'getUserRegistration',
         state
      ),
   };
};

const mapDispatchToProps = {
   getUserRegistrationRequest: RegistrationActions.getUserRegistrationRequest,
};

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(RegistrationCard);
