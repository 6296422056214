import ApplicationActions, {
   isFetchingApplicationSuccess,
   getApplications,
} from 'pgdb-data-layer/lib/Redux/ApplicationsRedux';
import { APPLICATION_RENEWAL_STATE } from 'pgdb-data-layer/lib/Constants/Application';
import {
   getDescription,
   calculateUserRenewals,
} from 'pgdb-data-layer/lib/Helpers/DashboardCards';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DashboardCard from '../index';
import { getToken } from '../../../Session/SessionRedux';

class RenewalCard extends Component {
   constructor(props) {
      super(props);
      this.state = {
         renewalState: APPLICATION_RENEWAL_STATE.NotStarted,
      };
   }

   componentDidMount() {
      const { dispatch, token } = this.props;
      dispatch(ApplicationActions.getApplicationsByUserRequest(token));
   }

   componentDidUpdate(prevProps) {
      const { applications } = this.props;
      if (applications !== prevProps.applications) {
         this.calculateUserRenewals();
      }
   }

   calculateUserRenewals() {
      const { applications } = this.props;
      const { renewalState } = this.state;
      const newRenewalState = calculateUserRenewals(applications, renewalState);
      this.setState({
         renewalState: newRenewalState,
         applications,
      });
   }

   render() {
      const { renewalState, applications } = this.state;
      const startRenewNavLink = {
         href: 'renew',
         text: 'Renew',
      };
      const completedRenewNavLink = {
         href: 'licences',
         text: 'View Details',
      };
      const { isLoading } = this.props;

      return (
         <DashboardCard
            header="Licensing"
            icon="style"
            iconStyle="grey2-text"
            description={getDescription(applications, renewalState)}
            navLink={
               renewalState === APPLICATION_RENEWAL_STATE.NotStarted ||
               renewalState === APPLICATION_RENEWAL_STATE.InProgress
                  ? startRenewNavLink
                  : completedRenewNavLink
            }
            visible={!isLoading}
            disabled={renewalState === APPLICATION_RENEWAL_STATE.NotRequired}
         />
      );
   }
}

RenewalCard.defaultProps = {
   isLoading: true,
};

RenewalCard.propTypes = {
   applications: PropTypes.array,
   dispatch: PropTypes.func.isRequired,
   token: PropTypes.string.isRequired,
   isLoading: PropTypes.bool,
};

const mapStateToProps = state => {
   return {
      applications: getApplications(state),
      token: getToken(state),
      isLoading: !isFetchingApplicationSuccess('getApplicationsByUser', state),
   };
};

export default connect(mapStateToProps)(RenewalCard);
