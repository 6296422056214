import React from 'react';
import PropTypes from 'prop-types';
import './TextLink.scss';

const { string, func, node } = PropTypes;

const onEnterPressed = (e, handler) => {
   if (!handler) return;
   if (e.key === 'Enter') handler();
};

const TextLink = ({ onClick, children, className }) => {
   const classes = `activeblue-text text-link ${className}`;
   return (
      <span
         className={classes}
         onClick={onClick}
         onKeyPress={e => onEnterPressed(e, onClick)}
         role="button"
         tabIndex="0"
      >
         {children}
      </span>
   );
};

TextLink.propTypes = {
   children: node.isRequired,
   onClick: func,
   className: string,
};

TextLink.defaultProps = {
   onClick: null,
   className: '',
};

export default TextLink;
