import React from 'react';
import './Button.scss';
import { uniqueId } from 'lodash';

// interface props {
//    id?: string;
//    type: 'primary' | 'secondary' | 'tertiary' | 'disabled' | 'loading';
//    size: 'btn-large' | 'btn-small';
//    onClick: () => void;
//    text: string;
//    className?: string;
// }

const Button = props => {
   let styling = props.className ? props.className + ' ' : '';
   if (props.type === 'primary') {
      styling += 'waves-effect waves-light button-margin ';
   }
   styling +=
      (props.size ? props.size + ' ' : '') +
      (props.type ? 'btn-' + props.type : '');
   const buttonID = props.id ? props.id : uniqueId();
   return (
      <button id={buttonID} className={styling} onClick={props.onClick}>
         {props.text}
      </button>
   );
};

export default Button;
