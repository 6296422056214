import React from 'react';
import { connect } from 'react-redux';
import SupervisionActions from 'pgdb-data-layer/lib/Redux/SupervisionRedux';
import { getToken } from '../../../Session/SessionRedux';

const DeclineModalBody = ({ modalState, dispatch, token }) => {
   const onModalConfirm = () => {
      dispatch(
         SupervisionActions.declineSupervisionRequest(
            token,
            modalState.selectedRow.supervisionId
         )
      );
      dispatch(SupervisionActions.closeModal());
   };

   return (
      <>
         <div className="modal-content-padding">
            <div className="medium-font-size modal-content-line-height">
               <p>
                  Do you decline your supervision of{' '}
                  {modalState.selectedRow.name}?
               </p>
            </div>
         </div>
         <div id="modal-footer" className="modal-footer center">
            <button
               type="submit"
               onClick={() => {
                  dispatch(SupervisionActions.closeModal());
               }}
               className="waves-effect waves-light btn btn-outline button-margin"
            >
               Back
            </button>
            <button
               type="submit"
               onClick={() => onModalConfirm()}
               className="waves-effect waves-light btn button-margin"
            >
               Yes
            </button>
         </div>
      </>
   );
};

const mapStateToProps = state => {
   return {
      modalState: state.supervision.modalState,
      token: getToken(state),
   };
};

export default connect(mapStateToProps)(DeclineModalBody);
