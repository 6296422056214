import { UserManager } from 'oidc-client';
import SessionService from './SessionService';

const settings = {
   authority: process.env.REACT_APP_IDENTITY_AUTHORITY,
   client_id: 'implicit',
   redirect_uri: process.env.REACT_APP_REDIRECT_URL,
   silent_redirect_uri: process.env.REACT_APP_SILENT_REDIRECT_URL,
   post_logout_redirect_uri: process.env.REACT_APP_POSTLOGOUT_REDIRECT_URL,
   automaticSilentRenew: true,
   response_type: 'id_token token',
   scope: 'openid profile email pgdbAPI.full_access',
   filterProtocolClaims: true,
   loadUserInfo: true,
};

export const userManager = new UserManager(settings);

userManager.events.addUserLoaded(async () => {
   const response = await userManager.getUser();
   await SessionService.saveSession(response);
   await SessionService.saveUser(response.profile);
});

userManager.events.addUserSignedOut(() => {
   SessionService.deleteSession()
      .then(() => {
         SessionService.deleteUser().then(() => {
            // Newer versions of Edge retain session data
            // expiring the session cookie to resolve this
            sessionStorage.clear();
            localStorage.clear();
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
               const cookie = cookies[i];
               const eqPos = cookie.indexOf('=');
               const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
               document.cookie = `${name}  =;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
            }
            window.location.href = window.location.origin;
         });
      })
      .catch(err => {
         console.error(`Could not log the user out. ${err}`);
         throw err;
      });
});

export const hasSessionExpired = async () => {
   const user = await userManager.getUser();
   if (user) {
      return user.expired;
   }
   return null;
};

export const login = async () => {
   return userManager.signinRedirect();
};

export const signinRedirectCallback = async () => {
   return userManager.signinRedirectCallback();
};

export const initSessionService = async store => {
   const response = await userManager.getUser();
   SessionService.initSessionService(
      store,
      { driver: 'COOKIES' },
      response ? response.id_token : null
   );
};

export const getUser = () => {
   return userManager.getUser();
};

export const renewToken = () => {
   return userManager.signinSilent();
};

/* eslint-disable camelcase */
export const logout = id_token => {
   userManager.removeUser();
   return userManager.signoutRedirect({ id_token_hint: id_token });
};

export const logoutCallback = () => {
   return userManager.signoutRedirectCallback();
};

export const deleteUser = () => {
   return SessionService.deleteUser();
};

export const deleteSession = () => {
   return SessionService.deleteSession();
};

export const getToken = () => {
   return SessionService.loadSession();
};
