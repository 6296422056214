import React from 'react';
import { string, func, object, shape, arrayOf } from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import DashboardActions from 'pgdb-data-layer/lib/Redux/DashboardRedux';
import Icon from '../../Components/Icon';
import { getToken } from '../../Session/SessionRedux';

const relatedLinkType = shape({
   target: string.isRequired,
   description: string.isRequired,
});

const notificationType = shape({
   notificationId: string.isRequired,
   message: string.isRequired,
   relatedLink: relatedLinkType,
});

// empty for now, but could be filler
// e.g. 'No notifications, hurray!'
const EmptyNotifications = () => (
   <table>
      <thead>
         <tr>
            <th>
               <h5 className="dashboard-link-header">My Notifications</h5>
            </th>
         </tr>
      </thead>
      <tbody>
         <tr>
            <td>You have no messages</td>
         </tr>
      </tbody>
   </table>
);

const NotificationEntry = ({ notification, onActioned }) => {
   return (
      <tr>
         <td>
            <span className="notification-icon" />
            {notification.message}
         </td>
         <td>
            {notification.relatedLink && (
               <Link
                  to={notification.relatedLink.target}
                  className="link"
                  onClick={() => onActioned(notification.notificationId)}
               >
                  {notification.relatedLink.description}
               </Link>
            )}
         </td>
         <td
            onClick={() => onActioned(notification.notificationId)}
            onKeyPress={() => onActioned(notification.notificationId)}
            className="cursor-pointer"
         >
            <Icon iconName="clear" />
         </td>
      </tr>
   );
};

NotificationEntry.propTypes = {
   notification: notificationType.isRequired,
   onActioned: func.isRequired,
};

const Notifications = ({ token, user, notifications, dispatch }) => {
   if (notifications.length === 0) return <EmptyNotifications />;

   const onActioned = notificationId => {
      if (user && user.user_id) {
         dispatch(
            DashboardActions.removeNotificationRequest(token, notificationId)
         );
      }
   };

   const tableRows = notifications.map(n => (
      <NotificationEntry
         key={n.notificationId}
         notification={n}
         onActioned={onActioned}
      />
   ));

   return (
      <table>
         <thead>
            <tr>
               <th>
                  <h5 className="dashboard-link-header">My Notifications</h5>
               </th>
               <th />
               <th />
            </tr>
         </thead>
         <tbody>{tableRows}</tbody>
      </table>
   );
};

Notifications.propTypes = {
   token: string,
   user: object.isRequired,
   notifications: arrayOf(notificationType).isRequired,
   dispatch: func.isRequired,
};

const mapState = state => ({
   token: getToken(state),
   user: state.session.user,
});

export default connect(mapState)(Notifications);
