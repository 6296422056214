import React, { Component } from 'react';
import './Summary.css';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-v3';
import M from 'materialize-css';
import { has, isEmpty } from 'lodash';
import ExamActions from 'pgdb-data-layer/lib/Redux/ExaminationRedux';
import PaymentActions from 'pgdb-data-layer/lib/Redux/PaymentRedux';
import PropTypes from 'prop-types';
import { EXAM_PAYMENT } from 'pgdb-data-layer/lib/Constants/PaymentTransaction';
import {
   Table,
   TableBody,
   TableRow,
   TableCell,
} from '../../../../Components/GenericTable';
import ExternalLink from '../../../../Components/ExternalLink';
import * as helper from '../../../../Helpers/Helpers';
import Validator from '../../../../Utilities/Validator';
import Callout from '../../../../Components/Callout';
import ExamPaymentTable from './Views/ExamPaymentTable';
import { getToken } from '../../../../Session/SessionRedux';
import Icon from '../../../../Components/Icon';

class ExamPaymentSummary extends Component {
   constructor(props) {
      super(props);
      this.state = {
         disableProceedButton: true,
         total: 0,
         getPaymentUrlRequestSubmitted: false,
         loadRecaptcha: false,
         isVerified: false,
         selectionDetails: [],
         userExam: [],
         venues: [],
         selectedExams: [],
         proceedToPayment: false,
      };

      this.recaptchaRef = React.createRef();
   }

   componentDidMount() {
      const { selectionDetails, userExam, venues } = this.props;

      const selectedExams = selectionDetails.map(contact => {
         const exam = userExam.filter(
            c => c.examinationId === contact.examinationId
         )[0];
         const examdata = Object.assign(
            {
               venue: venues.filter(
                  c => c.examinationVenueId === contact.examinationVenueId
               )[0],
            },
            exam
         );

         return examdata;
      });
      this.setState({ selectedExams });
      loadReCaptcha(process.env.REACT_APP_RECAPTCHA_KEY);
   }

   componentDidUpdate(prevProps, prevState) {
      this.setSummaryState();
      const {
         examination,
         getPaymentUrlRequest,
         user,
         paymentDetails,
      } = this.props;
      const { getPaymentUrlRequestSubmitted } = this.state;
      if (
         !Validator.isNullOrWhiteSpace(examination.transactionNumber) &&
         prevProps.examination.transactionNumber !==
            examination.transactionNumber &&
         !getPaymentUrlRequestSubmitted
      ) {
         const { recaptchaToken } = this.state;
         this.setState({ getPaymentUrlRequestSubmitted: true }, () => {
            getPaymentUrlRequest(
               {
                  transactionNumber: examination.transactionNumber,
                  urlFail: `${window.location.origin.toString()}/payment-fail`,
                  urlSuccess: `${window.location.origin.toString()}/payment-success/exam-confirmation`,
                  contactId: user.contactData.contactId,
                  transactionType: EXAM_PAYMENT,
               },
               recaptchaToken
            );
         });
         this.recaptchaRef.execute();
      }

      if (getPaymentUrlRequestSubmitted && has(paymentDetails, 'isError')) {
         if (!paymentDetails.isError) {
            window.location = paymentDetails.response;
         } else {
            this.setState({ getPaymentUrlRequestSubmitted: false }, () => {
               M.toast({
                  html: `Sorry we couldn't process the payment.
                  Please contact the Plumbers, Gasfitters and Drainlayers Board.`,
                  classes: 'error',
               });
            });
         }
      }
   }

   setSummaryState() {
      const { selectedExams, loadRecaptcha } = this.state;

      if (!loadRecaptcha) {
         let amount = 0;
         selectedExams.forEach(a => {
            amount += a.course.courseFee;
         });
         this.setState(
            {
               total: amount,
               loadRecaptcha: true,
            },
            () => {
               const elems = document.querySelectorAll('.collapsible');
               M.Collapsible.init(elems);
            }
         );
      }
   }

   proceedPayment = () => {
      M.Toast.dismissAll();
      const { isVerified } = this.state;
      const { selectionDetails, examDelivery, userExam } = this.props;

      if (!isVerified) {
         M.toast({
            html: `Sorry we couldn't process the request, please try again later.`,
            classes: 'error',
         });
         return;
      }

      if (selectionDetails.length === 0) {
         M.toast({
            html: `Sorry we couldn't process the payment.
            Please contact the Plumbers, Gasfitters and Drainlayers Board.`,
            classes: 'error',
         });
         return;
      }

      const selectedExams = selectionDetails.map(contact => {
         const exam = userExam.filter(
            c => c.examinationId === contact.examinationId
         )[0];
         return {
            ExaminationId: contact.examinationId,
            ExaminationVenueId: contact.examinationVenueId,
            Amount: exam.course.courseFee,
         };
      });

      let address = examDelivery.examDeliveryAddress.address_line_1;
      if (examDelivery.examDeliveryAddress.address_line_2 !== '') {
         address = `${examDelivery.examDeliveryAddress.address_line_1}, ${
            examDelivery.examDeliveryAddress.address_line_2
         }`;
      }

      const dataToSubmit = {
         EmailAddress: examDelivery.examDeliveryEmail,
         ExaminationResultsAddressId: null, // For the moment
         AddressLine: address,
         SuburbTown: examDelivery.examDeliveryAddress.suburb,
         PostalCode: examDelivery.examDeliveryAddress.postcode,
         City: examDelivery.examDeliveryAddress.city,
         ExaminationDetails: selectedExams,
      };

      const { saveExamApplicationDataRequest, token } = this.props;
      this.setState({ proceedToPayment: true }, () => {
         saveExamApplicationDataRequest(token, dataToSubmit);
      });
   };

   verifyCallback = recaptchaToken => {
      this.setState({ isVerified: true, recaptchaToken });
   };

   renderContactRows = () => {
      const { examDelivery } = this.props;
      return (
         <>
            <div className="row shorter">
               <div className="col s3 subtitle">
                  Delivery Address for Exam Papers
               </div>
               <NavLink
                  exact
                  to="/exam-delivery-address"
                  className="activeblue-text"
               >
                  <div className="col description">
                     {examDelivery.examDeliveryAddress
                        ? examDelivery.examDeliveryAddress.fullAddress
                        : 'None'}
                  </div>
               </NavLink>
            </div>

            <div className="row shorter">
               <div className="col s3 subtitle">
                  Email Address for Exam GST Receipt
               </div>
               <NavLink
                  exact
                  to="/exam-delivery-address"
                  className="activeblue-text"
               >
                  <div className="col description">
                     {examDelivery.examDeliveryEmail
                        ? examDelivery.examDeliveryEmail
                        : 'None'}
                  </div>
               </NavLink>
            </div>
         </>
      );
   };

   renderContactDetails = () => {
      return (
         <div className="row collapsible drawer">
            <li className="active">
               <div className="collapsible-header drawer-title">
                  <div className="header-title">My Delivery Details</div>
                  <NavLink
                     exact
                     to="/exam-delivery-address"
                     className="edit-btn"
                  >
                     <span className="inner-edit-btn">Edit</span>
                  </NavLink>
                  <Icon iconName="keyboard_arrow_down" className="caret" />
               </div>
               <div className="collapsible-body drawer-outer-body">
                  <div className="drawer-inner-body">
                     {this.renderContactRows()}
                  </div>
               </div>
            </li>
         </div>
      );
   };

   deleteCheck = id => {
      const { selectedExams } = this.state;
      if (window.confirm('Are you sure you want to delete this exam?')) {
         const filteredArray = selectedExams.filter(
            item => item.examinationId !== id
         );
         this.setState({ selectedExams: filteredArray });
         let amount = 0;
         filteredArray.forEach(a => {
            amount += a.course.courseFee;
         });
         this.setState({
            total: amount,
         });
      }
   };

   renderLicenceDetails = () => {
      const { selectedExams } = this.state;

      return (
         <div className="row collapsible drawer">
            <li className="active">
               <div className="collapsible-header drawer-title">
                  <div className="header-title">My Exams</div>
                  <NavLink exact to="/exams" className="edit-btn">
                     <span className="inner-edit-btn">Edit</span>
                  </NavLink>
                  <Icon iconName="keyboard_arrow_down" className="caret" />
               </div>
               <div className="collapsible-body drawer-outer-body">
                  <div className="drawer-inner-body table-container">
                     {!isEmpty(selectedExams) && (
                        <>
                           <ExamPaymentTable
                              tableData={selectedExams}
                              onDelete={this.deleteCheck}
                           />
                        </>
                     )}
                  </div>
               </div>
            </li>
         </div>
      );
   };

   renderTotalSection = () => {
      const { total } = this.state;
      const totalAmount = total ? helper.formatCurrency(total, 2) : 0.0;

      return (
         <Table className="table-head-compressed borderless total-table">
            <TableBody>
               <TableRow className="total-row borderless">
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell>Total</TableCell>
                  <TableCell className="total-cell">${totalAmount}</TableCell>
               </TableRow>
            </TableBody>
         </Table>
      );
   };

   renderDeclaration = () => {
      const { loadRecaptcha, total, proceedToPayment } = this.state;

      return (
         <div className="row drawer">
            <li className="active">
               <div className=" drawer-outer-body">
                  <div className="drawer-inner-body table-container">
                     <div className="row mock-collapsible-header">
                        <label>
                           <input
                              type="checkbox"
                              className="filled-in checkbox-blue"
                              onChange={e => this.handleDeclarationCheck(e)}
                              disabled={total <= 0}
                           />
                           <span className="black-text terms-and-cond">
                              I declare that all information provided is true
                              and correct; and that I acknowledge and accept the
                              Plumbers, Gasfitters, and Drainlayers Board&apos;s
                              Terms of Use,{' '}
                              <ExternalLink href="https://www.pgdb.co.nz/trade/legislation-hub/policies/">
                                 Official Information and Privacy Policy
                              </ExternalLink>
                              <br />I also understand and acknowledge that all
                              fees are expressed in New Zealand Dollars and are
                              inclusive of Goods and Services Tax (GST); that
                              payments are made to the Plumbers, Gasfitters, and
                              Drainlayers Board; and that they can only be
                              completed by either Visa or Mastercard credit or
                              debit cards, or Account2Account transfers from
                              eligible banks.
                           </span>
                        </label>

                        <div className="btn-search-margin">
                           {loadRecaptcha && (
                              <ReCaptcha
                                 ref={ref => (this.recaptchaRef = ref)}
                                 sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                                 action="examination_renewal_summary"
                                 verifyCallback={this.verifyCallback}
                              />
                           )}
                           <button
                              type="button"
                              disabled={
                                 this.state.disableProceedButton ||
                                 proceedToPayment
                              }
                              className={`waves-effect waves-light btn btn-ml-30 ${
                                 proceedToPayment ? 'loading-button' : ''
                              }`}
                              onClick={this.proceedPayment}
                           >
                              Proceed to Payment
                           </button>
                        </div>
                     </div>
                  </div>
               </div>
            </li>
         </div>
      );
   };

   handleDeclarationCheck = e => {
      if (e.target.checked) {
         this.setState({ disableProceedButton: false });
      } else {
         this.setState({ disableProceedButton: true });
      }
   };

   render() {
      return (
         <main className="exam-root">
            <div className="section no-pad-bot">
               <div className="container summary">
                  <div className="row">
                     <div className="col s12">
                        <h4 className="columnheader left">My Exams</h4>
                     </div>
                  </div>
                  <Callout>
                     Please review the following information before proceeding
                     with payment
                  </Callout>

                  <hr />

                  {/* SECTION 1: My Contact Details */}
                  {this.renderContactDetails()}
                  <hr />

                  {/* SECTION 2: My Exams */}
                  {this.renderLicenceDetails()}
                  <hr />

                  {/* SECTION 3: Total Section */}
                  {this.renderTotalSection()}

                  <hr className="thick" />

                  {/* SECTION 4: Ts&Cs */}
                  {this.renderDeclaration()}
               </div>
            </div>
         </main>
      );
   }
}

ExamPaymentSummary.propTypes = {
   saveExamApplicationDataRequest: PropTypes.func.isRequired,
   getPaymentUrlRequest: PropTypes.func.isRequired,
   paymentDetails: PropTypes.object,
   examination: PropTypes.object,
   user: PropTypes.object,
   token: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
   return {
      examination: state.examination,
      user: state.user,
      paymentDetails: state.payment.paymentDetails,
      examDelivery: state.examination.examDeliveryDetails,
      selectionDetails: state.examination.examSelectionDetails,
      userExam: state.examination.examinations.userExaminations,
      venues: state.examination.availableVenues,
      token: getToken(state),
   };
};

const mapDispatchToProps = {
   saveExamApplicationDataRequest: ExamActions.saveExamApplicationDataRequest,
   getPaymentUrlRequest: PaymentActions.getPaymentUrlRequest,
};

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(ExamPaymentSummary);
