import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from '../../../../Components/Buttons/DefaultButton/button';
import './ApplicationStatus.scss';

export const ApplyRegistrationSection = () => {
   const history = useHistory();
   return (
      <>
         <div className="row">
            <div className="col s11 offset-l1">
               <h4 className="columnheader" id="registration-section-header">
                  Apply for Registration
               </h4>
            </div>
         </div>
         <div className="row complete">
            <div className="col s12 m8 l8 offset-l1 vertical-align">
               <p>
               Apply for registration as a plumber, gasfitter or drainlayer
               </p>
            </div>
            <div className="col s12 m8 l8 offset-l1 vertical-align">
               <Button
                  id="apply-registration"
                  onClick={() => history.push('/registration-apply')}
                  size="btn-small"
                  text="APPLY HERE"
                  type="primary"
                  className="min-width-170 registration-click-here-button"
               />
            </div>
         </div>
      </>
   );
};

const mapStateToProps = state => {
   return {};
};

export default connect(mapStateToProps)(ApplyRegistrationSection);
