import * as StatusCode from '../../Shared/StatusCodes';

export const statusThemeSelector = (icon, color, message) => {
   return { icon, color, message };
};

export const getStylingForLicenceStatus = (
   applicationStatus,
   licenceStatus
) => {
   return applicationStatus === StatusCode.USER_ACTION_RENEW
      ? [
           StatusCode.LICENCE_STATUS_ACTIVE,
           StatusCode.LICENCE_STATUS_READY_TO_PRINT,
           StatusCode.LICENCE_STATUS_REPRINT,
        ].indexOf(licenceStatus) > -1
         ? statusThemeSelector('check', 'black')
         : statusThemeSelector('sync', 'orange')
      : statusThemeSelector('clear', 'red');
};

export const getStylingForRenewingStatus = applicationStatus => {
   return applicationStatus === StatusCode.USER_ACTION_RENEW
      ? statusThemeSelector('check', 'black')
      : statusThemeSelector('clear', 'red');
};

export const getStylingForSupervisionStatus = status => {
   return status === StatusCode.SUPERVISION_STATUS_ACCEPTED
      ? statusThemeSelector('check', 'black')
      : status === StatusCode.SUPERVISION_STATUS_PENDING
      ? statusThemeSelector('sync', 'orange')
      : statusThemeSelector('', 'black');
};

export const cpdRequirementsMet = status => {
   return status
      ? statusThemeSelector(
           'check',
           'black',
           "You've met your CPD requirements"
        )
      : statusThemeSelector(
           'clear',
           'red',
           "You've not met your CPD requirements"
        );
};
