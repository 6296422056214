import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import './RegistrationReviewAndSubmit.scss';
import RegistrationActions, {
   isFetchingRegistration,
} from 'pgdb-data-layer/lib/Redux/RegistrationRedux';
import UserActions, {
   isFetchingUserInfo,
} from 'pgdb-data-layer/lib/Redux/UserInfoRedux';
import ApplicationActions, {
   isFetchingApplication,
   isFetchingApplicationSuccess,
   isFetchingApplicationFailure,
} from 'pgdb-data-layer/lib/Redux/ApplicationsRedux';
import M from 'materialize-css';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import FitAndProperActions from 'pgdb-data-layer/lib/Redux/FitAndProperRedux';
import Navigator from '../../../Navigation/Navigator';
import RegistrationTypeReview from './Components/RegistrationTypeReview';
import RegistrationFitAndProperReview from './Components/RegistrationFitAndProperReview';
import RegistrationUploadDocumentsReview from './Components/RegistrationUploadDocumentsReview';
import { getToken } from '../../../Session/SessionRedux';
import Loading from '../../../Components/Loading';
import RegistrationApplicantDetailsReview from './Components/RegistrationApplicantDetailsReview';
import Immutable from 'seamless-immutable';

export const QUESTION_TYPE_REGISTRATION = 1;
export const QUESTION_TYPE_COUNTRY = '13';

const RegistrationReviewAndSubmit = ({
   getUserRegistrationRequest,
   isFetchingRegistration,
   isFetchingApplicantDetails,
   isFetchingApplication,
   questionsAndAnswersRequest,
   questionsAndAnswersReset,
   token,
   applicantDetails,
   questionsAndAnswers,
   uploadedDocuments,
   currentApplicationRegistration,
   submitRegistration,
   submittedSuccess,
   submittedError,
   getUserContactInfoRequest,
   submittingApplication,
}) => {
   const [hasDispatched, setHasDispatched] = useState(false);
   const [isDisabled, setIsDisabled] = useState(true);
   const [isChecked1, setIsChecked1] = useState(false);
   const [isChecked2, setIsChecked2] = useState(false);
   const [listApplication, setListApplication] = useState([]);
   const [sortUploadedDocuments, setSortUploadedDocuments] = useState([]);

   useEffect(() => {
      if (currentApplicationRegistration) {
         setListApplication(
            currentApplicationRegistration.filter(x => x.isOnTheFly === true)
         );
      }
      sortDocuments();
   }, [currentApplicationRegistration]);

   const sortDocuments = () => {
      if (uploadedDocuments) {
         let mutableDocuments = Immutable.asMutable(uploadedDocuments);
         let sortedDocuments = mutableDocuments.sort(
            (a, b) => a.fileTypeID - b.fileTypeID
         );
         setSortUploadedDocuments(sortedDocuments);
      }
   };

   const isLoading =
      !hasDispatched ||
      isFetchingRegistration ||
      isFetchingApplicantDetails ||
      isFetchingApplication ||
      listApplication.length === 0;

   const loadFitAndProperData = () => {
      questionsAndAnswersReset();
      questionsAndAnswersRequest(token, QUESTION_TYPE_REGISTRATION);
   };
   useEffect(() => {
      getUserRegistrationRequest(token);
      getUserContactInfoRequest(token);
      setHasDispatched(true);
   }, [token]);

   useEffect(() => {
      loadFitAndProperData();
   }, []);

   const saveAndSubmit = () => {
      const valid = true; // Add Validation if that is required
      if (valid) {
         submitRegistration(token, {
            ContactId: applicantDetails.contactId,
            listApplications: listApplication.map(x => x.applicationId),
         });
         setIsDisabled(true);
         M.toast({
            html: 'Your application is being submitted. Please wait.',
            classes: 'success',
         });
      }
   };
   useEffect(() => {
      if (submittedSuccess) {
         Navigator.toRegistrationSuccess();
      }
      if (submittedError) {
         M.toast({
            html: 'Error: Your application did not submit',
            classes: 'error',
         });
      }
   }, [submittedSuccess, submittedError]);

   const handleCheckboxChange1 = () => {
      setIsChecked1(!isChecked1);
   };

   const handleCheckboxChange2 = () => {
      setIsChecked2(!isChecked2);
   };

   useEffect(() => {
      if (isChecked1 && isChecked2) {
         setIsDisabled(false);
      } else {
         setIsDisabled(true);
      }
   }, [isChecked1, isChecked2]);

   const importantNote = () => {
      return (
         <div className="important-note">
            <div className="important-note-header">
               <ErrorOutlineIcon />
               <b>Important Note</b>
            </div>
            <div className="important-note-content">
               <p>
                  Please check that the information you have given us is correct
               </p>
               <p>
                  Once you submit your application you will not be able to edit
                  or withdraw it.
               </p>
            </div>
            <footer className="important-note-footer">
               If you need help, call us on our free phone{' '}
               <span className="important-note-phone">
                  <a href="tel:0800743262" rel="nofollow">
                     0800 743 262
                  </a>{' '}
               </span>
               or email{' '}
               <a
                  className="activeblue-text important-note-email"
                  href="mailto:registration@pgdb.co.nz"
               >
                  registration@pgdb.co.nz
               </a>
            </footer>
         </div>
      );
   };

   return (
      <main>
         <Loading isLoading={isLoading}>
            <div className="section no-pad-bot" id="index-banner">
               <div className="container registration-container">
                  <div className="row no-margin">
                     <div className="col l9 m8 s12">
                        <div className="col m8 offset-l1 s12">
                           <h4
                              className="columnheader"
                              id="registration-section-header"
                           >
                              Review your Application
                           </h4>
                        </div>
                        <div className="col m12 l9 offset-l1 s11">
                           <div className="fit-and-proper-title">
                              <p>
                                 Please make sure your information is correct.
                                 Please click on edit to change the details.
                              </p>
                           </div>

                           <RegistrationTypeReview
                              applicationPayload={listApplication}
                           />

                           <RegistrationApplicantDetailsReview
                              applicantDetails={applicantDetails}
                           />

                           <RegistrationFitAndProperReview
                              questionsAndAnswers={questionsAndAnswers}
                           />

                           <RegistrationUploadDocumentsReview
                              uploadedDocuments={sortUploadedDocuments}
                           />

                           <div>
                              <div>
                                 <h5>Declaration</h5>
                              </div>
                              <div className="review-checkbox-label">
                                 <p>
                                    <label>
                                       <input
                                          type="checkbox"
                                          checked={isChecked1}
                                          onChange={handleCheckboxChange1}
                                          className="checkbox-input"
                                       />
                                       I have attached all the required
                                       documents
                                    </label>
                                 </p>
                                 <p>
                                    <label>
                                       <input
                                          type="checkbox"
                                          checked={isChecked2}
                                          onChange={handleCheckboxChange2}
                                          className="checkbox-input"
                                       />
                                       I declare that the information I have
                                       provided on this application is true and
                                       correct. I am aware that this information
                                       is being collected by the Plumbers,
                                       Gasfitters, and Drainlayers Board (The
                                       Board) for the purpose of assessing my
                                       application. This information may also be
                                       used for any other purposes required or
                                       permitted by the Plumbers, Gasfitters,
                                       and Drainlayers Act 2006 or the Privacy
                                       Act 2020. I understand that this
                                       information will not be disclosed to
                                       anyone else without my permission and
                                       that I can access this information on
                                       request (Privacy Act 2020)
                                    </label>
                                 </p>
                              </div>
                           </div>
                           <div className="row registration-type-profile-row next-button-registration right">
                              <div className="navigate-buttons">
                                 <button
                                    type="button"
                                    className="waves-light btn btn-inactive"
                                    onClick={() =>
                                       Navigator.toRegistrationUploadDocuments()
                                    }
                                 >
                                    BACK
                                 </button>
                                 <button
                                    type="button"
                                    className={`waves-effect waves-light button-margin btn ${
                                       submittingApplication
                                          ? 'loading-button'
                                          : ''
                                    }`}
                                    onClick={() => saveAndSubmit()}
                                    disabled={isDisabled}
                                 >
                                    SUBMIT
                                 </button>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col l3 side-note-background">
                        {importantNote()}
                     </div>
                  </div>
               </div>
            </div>
         </Loading>
      </main>
   );
};

const mapStateToProps = state => {
   return {
      userRegistration: state.registration,
      uploadedDocuments: state.registration.registration.applicationFiles,
      applicantDetails: state.user.contactData,
      currentApplicationRegistration:
         state.registration.registration.applicationRegistrations,
      isFetchingRegistration: isFetchingRegistration(
         'getUserRegistration',
         state
      ),
      isFetchingApplicantDetails: isFetchingUserInfo(
         'getUserContactInfo',
         state
      ),
      isFetchingApplication: isFetchingApplication(
         'getApplicationsByUser',
         state
      ),
      token: getToken(state),
      questionsAndAnswers: state.fitAndProper.questionsAndAnswers,
      submittedSuccess: isFetchingApplicationSuccess(
         'submitRegistration',
         state
      ),
      submittingApplication: isFetchingApplication('submitRegistration', state),
      submittedError: isFetchingApplicationFailure('submitRegistration', state),
   };
};

const mapDispatchToProps = {
   getUserContactInfoRequest: UserActions.getUserContactInfoRequest,
   getUserRegistrationRequest: RegistrationActions.getUserRegistrationRequest,
   questionsAndAnswersRequest: FitAndProperActions.questionsAndAnswersRequest,
   questionsAndAnswersReset: FitAndProperActions.questionsAndAnswersReset,
   submitRegistration: ApplicationActions.submitRegistrationRequest,
};

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(RegistrationReviewAndSubmit);
