import React, { Component } from 'react';
import { connect } from 'react-redux';
import M from 'materialize-css';
import { find } from 'lodash';
import queryString from 'query-string';
import ExaminationActions, {
   isFetchingExamination,
   isFetchingExaminationSuccess,
} from 'pgdb-data-layer/lib/Redux/ExaminationRedux';
import { DEFAULT_COUNTRY } from 'pgdb-data-layer/lib/Constants/Address';
import PropTypes from 'prop-types';
import TextInput from '../../../Components/TextInput/index';
import AddressInput from '../../../Components/AddressInput/index';
import Validator from '../../../Utilities/Validator';
import AddressFinder from '../../../Utilities/AddressFinder';
import Callout from '../../../Components/Callout';
import Navigator from '../../../Navigation/Navigator';
import { getToken } from '../../../Session/SessionRedux';

class UpdateExamDeliveryAddress extends Component {
   constructor(props) {
      super(props);
      const parsedStrings = queryString.parse(props.location.search);
      this.details = this.findExaminationDetails(parsedStrings.exam);
      let initialState = {};
      if (this.details) {
         initialState = this.getInitialState(
            this.details.examinationResultsAddress
         );
      } else {
         Navigator.to('/exams');
      }

      this.state = Object.assign(
         {
            examDeliveryEmail: '',
            examDeliveryAddress: {},
            allFieldsValid: false,
            formValidity: {
               examDeliveryEmail: true,
               examDeliveryAddress: true,
            },
            isSubmitted: false,
         },
         initialState
      );
   }

   componentDidUpdate(prevProps) {
      const { isSubmitted } = this.state;
      const { isFetching, isSuccess } = this.props;

      if (isSubmitted && !isFetching) {
         M.Toast.dismissAll();
         if (isSuccess) {
            M.toast({
               html: 'Address updated successfully',
               classes: 'success',
            });
            Navigator.toBackward();
         } else {
            M.toast({
               html: 'Error updating details',
               classes: 'error',
            });
         }
         this.setState({ isSubmitted: false });
      }
   }

   findExaminationDetails = id => {
      const { examinationDetails } = this.props;
      return find(examinationDetails, e => {
         return (
            e.examinationResultsAddress.examinationResultsAddressId ===
            parseInt(id, 10)
         );
      });
   };

   next = () => {
      const { examDeliveryEmail, examDeliveryAddress } = this.state;
      const { token, updateExaminationResultsAddress } = this.props;
      const addressLine = examDeliveryAddress.address_line_2
         ? examDeliveryAddress.address_line_1 +
           ', ' +
           examDeliveryAddress.address_line_2
         : examDeliveryAddress.address_line_1;

      const payload = {
         addressLine1: addressLine,
         suburbTown: examDeliveryAddress.suburb,
         city: examDeliveryAddress.city,
         postalCode: examDeliveryAddress.postcode,
         country: examDeliveryAddress.country || DEFAULT_COUNTRY,
         emailAddress: examDeliveryEmail,
      };

      updateExaminationResultsAddress(
         token,
         Object.assign({}, this.details.examinationResultsAddress, payload)
      );
      this.setState({ isSubmitted: true });
   };

   back = () => {
      Navigator.toBackward();
   };

   getInitialState = examResultsAddress => {
      const newState = {};
      const addressObject = AddressFinder.getObject(
         examResultsAddress.addressLine1,
         null,
         examResultsAddress.suburbTown,
         examResultsAddress.city,
         examResultsAddress.postalCode,
         examResultsAddress.country
      );
      newState.examDeliveryEmail = examResultsAddress.emailAddress;
      newState.examDeliveryAddress = addressObject;

      newState.allFieldsValid = true;
      newState.formValidity = {
         examDeliveryEmail: true,
         examDeliveryAddress: true,
         contactName: true,
         phoneNumber: true,
         businessPostalAddress: true,
         businessPhysicalAddress: true,
      };
      return newState;
   };

   validateFormFields = () => {
      const { examDeliveryEmail, examDeliveryAddress } = this.state;

      const validity = {
         examDeliveryEmail:
            Validator.isValidEmail(examDeliveryEmail) &&
            Validator.isValidLength(examDeliveryEmail, 100),
         examDeliveryAddress: Validator.isValidAddress(examDeliveryAddress),
      };

      const allFieldsValid = Object.values(validity).every(v => !!v);
      this.setState({ formValidity: validity, allFieldsValid });
      return { formValidity: validity, allFieldsValid };
   };

   render() {
      const {
         examDeliveryEmail,
         examDeliveryAddress,
         formValidity,
      } = this.state;

      return (
         <main>
            <div className="section no-pad-bot" id="index-banner">
               <div className="container company-contact-details">
                  <div className="row">
                     <div className="col s12 m12 l6 offset-l1">
                        <div className="col s12 m12 l12">
                           <p className="columnheader">Update exam details</p>
                        </div>
                        <div className="col s12 m12 l12">
                           <span className="grey2-text sub-text">
                              Return all my Certifier exam papers and results by
                              courier to:
                           </span>
                        </div>
                        <div className="col s12 m12 l12">
                           {
                              <AddressInput
                                 inputValid={formValidity.examDeliveryAddress}
                                 onSubmit={value => {
                                    this.setState({
                                       examDeliveryAddress: value,
                                    });
                                 }}
                                 onChange={value => {
                                    this.setState({
                                       examDeliveryAddress: value,
                                    });
                                 }}
                                 name="examDeliveryAddress"
                                 value={examDeliveryAddress}
                                 type="text"
                                 id="exam_delivery_address"
                                 placeholder="Start typing to search for your exam delivery address"
                                 label="Delivery Address for Exam Papers"
                                 mandatory
                                 postalCheckRequired={false}
                              />
                           }
                        </div>
                        <div className="col s12 m12 l12">
                           <TextInput
                              type="text"
                              id="exam_email"
                              placeholder="Enter the Exam Email"
                              label="Email Address for Exam GST Receipt"
                              mandatory
                              value={examDeliveryEmail}
                              inputValid={formValidity.examDeliveryEmail}
                              onChange={e => {
                                 this.setState({
                                    examDeliveryEmail: e.target.value,
                                 });
                              }}
                           />
                        </div>
                        <div className="col s12 m12 l12 control-row">
                           <br />
                           <br />
                           <button
                              type="button"
                              className="waves-effect waves-light btn btn-inactive"
                              onClick={() => this.back()}
                           >
                              Back
                           </button>
                           <button
                              type="button"
                              className="waves-effect waves-light btn btn-ml-30"
                              onClick={() => this.next()}
                           >
                              Update
                           </button>
                        </div>
                        <div className="col s12 m12 l12 control-row">
                           <Callout>
                              If you change your delivery or email address, it
                              will not affect your current profile details.
                           </Callout>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </main>
      );
   }
}

UpdateExamDeliveryAddress.propTypes = {
   updateExaminationResultsAddress: PropTypes.func.isRequired,
   token: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
   return {
      token: getToken(state),
      examinationDetails: state.examination.examinationDetails,
      isFetching: isFetchingExamination(
         'updateExaminationResultsAddress',
         state
      ),
      isSuccess: isFetchingExaminationSuccess(
         'updateExaminationResultsAddress',
         state
      ),
   };
};

const mapDispatchToProps = {
   updateExaminationResultsAddress:
      ExaminationActions.updateExaminationResultsAddressRequest,
};

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(UpdateExamDeliveryAddress);
