import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';

const TableCell = ({
   className,
   children,
   icon,
   color,
   onClick,
   colspan,
   iconClick,
}) => {
   if (icon) {
      return (
         <td className={`table-data ${className}`} colSpan={colspan}>
            <Icon
               className={`inline-icon ${color}-text`}
               iconName={icon}
               onClick={iconClick}
            />
            {onClick ? (
               <span
                  className={`block-text height-adjust ${color}-text`}
                  onClick={onClick}
                  role="button"
                  tabIndex={0}
                  onKeyPress={onClick}
               >
                  {children}
               </span>
            ) : (
               <span className={`block-text height-adjust ${color}-text bold`}>
                  {children}
               </span>
            )}
         </td>
      );
   }
   return (
      <td className={`table-data ${className} ${color}-text`} colSpan={colspan}>
         {onClick ? (
            <div
               className={`${color}-text`}
               onClick={onClick}
               role="button"
               tabIndex={0}
               onKeyPress={onClick}
            >
               <span>{children}</span>
            </div>
         ) : (
            <span>{children}</span>
         )}
      </td>
   );
};

const { string, node, func, number } = PropTypes;

TableCell.defaultProps = {
   className: '',
   icon: null,
   color: 'black',
   onClick: null,
   children: null,
   colspan: null,
};

TableCell.propTypes = {
   className: string,
   icon: string,
   children: node,
   color: string,
   onClick: func,
   colspan: number,
   iconClick: func
};

export default TableCell;
