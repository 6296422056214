import React from 'react';
import PropTypes from 'prop-types';

// simple helper component to show a validation message if one is present
const ConditionalValidationMessage = ({ message }) => {
   return (
      <>
         {message && (
            <p className="invalid-message-block">
               <span className="invalid-message">{message}</span>
            </p>
         )}
      </>
   );
};

const { string } = PropTypes;

ConditionalValidationMessage.propTypes = {
   message: string,
};

ConditionalValidationMessage.defaultProps = {
   message: '',
};

export default ConditionalValidationMessage;
