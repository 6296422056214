import React from 'react';
import { func, string } from 'prop-types';
import { TableRow, TableCell } from '../../../../Components/GenericTable';
import Icon from '../../../../Components/Icon';
import { contactType } from '../types';
import RegistrationsCell from './RegistrationsCell';
import LicencesCell from './LicencesCell';

const PrimarySearchRow = ({ contact, icon, onToggle, rowStyle }) => {
   return (
      <TableRow className={rowStyle} onClick={onToggle}>
         <TableCell className="cell-valign min-cell-width">
            <button
               className="btn-flat btn-toggle activeblue-text"
               type="button"
            >
               <Icon iconName={icon} />
            </button>
         </TableCell>
         <TableCell className="cell-valign Body1">{contact.fullName}</TableCell>
         <TableCell className="cell-valign">
            <RegistrationsCell
               contactType={contact.contactType}
               registrations={contact.registrations}
            />
         </TableCell>
         <TableCell className="cell-valign">
            <LicencesCell licences={contact.licences} />
         </TableCell>
      </TableRow>
   );
};

PrimarySearchRow.propTypes = {
   contact: contactType.isRequired,
   icon: string.isRequired,
   onToggle: func.isRequired,
   rowStyle: string,
};

PrimarySearchRow.defaultProps = {
   rowStyle: '',
};

export default PrimarySearchRow;
