import React from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import Icon from '../../../Components/Icon';
import './RegistrationPayment.scss';

const RegistrationPaymentFailed = ({ onChange, isModalOpen }) => {
   const PaymentDeclined = () => {
      return (
         <>
            <div className="fit-modal-body">
               <h4>Payment Unsuccessful</h4>

               <p>Your payment could not be made.</p>
               <p>Please review your details and try again.</p>

               <p>If you continue to have problems, contact your bank.</p>
            </div>
            <div className="close-modal-button">
               <button
                  type="button"
                  className="close-payment-button waves-effect waves-light"
                  onClick={() => onChange()}
               >
                  BACK TO PAYMENT METHODS
               </button>
            </div>
         </>
      );
   };

   return (
      <>
         <Modal
            isOpen={isModalOpen}
            onRequestClose={() => {
               onChange();
            }}
            className="delete-modal"
            overlayClassName="generic-modal-overlay"
            appElement={document.getElementById('root')}
         >
            <Icon
               className="fit-model-icon material-icons right"
               iconName="close"
               onClick={() => onChange()}
            />
            <br />

            <PaymentDeclined />
         </Modal>
      </>
   );
};

RegistrationPaymentFailed.propTypes = {};

const mapStateToProps = state => {
   return {};
};

const mapDispatchToProps = {};

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(RegistrationPaymentFailed);
