import React from 'react';
import PropTypes from 'prop-types';

const TableHeaderCell = ({ className, children }) => {
   return (
      <th className={`grey-text table-heading ${className}`}>{children}</th>
   );
};

const { string, node } = PropTypes;

TableHeaderCell.defaultProps = {
   className: '',
   children: null,
};

TableHeaderCell.propTypes = {
   className: string,
   children: node,
};

export default TableHeaderCell;
