export const callsToHandled = [
   // dashboard
   'dashboard.async.getUsefulLinks',
   'dashboard.async.getNotifications',
   'dashboard.async.removeNotification',
   // user
   'user.async.getUserContactInfo',
   'user.async.saveUserContactInfo',
   // application
   'application.async.getApplicationData',
   'application.async.saveApplicationData',
   'application.async.getApplicationsByUser',
   // licence
   'licence.async.get',
   'licence.async.post',
   'licence.async.remove',
   'licence.async.getSelectedApplications',
   // public register
   'publicRegister.async.getPublicRegisterContacts',
   // result
   'result.async.getResultsByUserId',
   'result.async.getExaminationResults',
   // course
   'course.async.getAvailableCourses',
   'course.async.getCoursesByLicenceYear',
   'course.async.getHowIOurlSSO',
   // supervision
   'supervision.async.getSupervisionUser',
   'supervision.async.acceptSupervision',
   'supervision.async.declineSupervision',
   'supervision.async.searchSupervision',
   'supervision.async.changeSupervisor',
   'supervision.async.newSupervisor',
   'supervision.async.changeSupervisionEndDate',
   'supervision.async.getUserApplications',
   'supervision.async.getUserApplicationRenewal',
   'supervision.async.getUserSupervisionTasksComplete',
   // receipt
   'receipt.async.getUserReceipt',
   'receipt.async.getUserReceiptDetails',
   'receipt.async.getUserReceiptDetailsFromReference',
   // renewal
   'renewal.async.getCourseUrlAndDate',
   'renewal.async.saveApplicationRenewal',
   'renewal.async.saveContactDetailsConfirmation',
   'renewal.async.saveRenewalApplicationData',
   'renewal.async.getUnmetApplicationCriteria',

   // examination
   'examination.async.getUserEligibleExams',
   'examination.async.getUserExams',
   'examination.async.getUserExaminationDetails',
   'examination.async.getUserExamEligibilityReport',
   'examination.async.getExamCycles',
   'examination.async.getAvailableExamVenues',
   'examination.async.getExamVenues',
   'examination.async.postExamDetails',
   'examination.async.saveExamApplicationData',
   'examination.async.getExaminationResultAddress',
   'examination.async.withdrawUserFromExamination',
   'examination.async.updateExaminationResultsAddress',
   // payment
   'payment.async.getPaymentUrl',
   'payment.async.getPaymentResponse',
   'payment.async.getPaymentProcessingStatus',
   // renewal summary
   'renewalSummary.async.getRenewalSummary',
   // bulk renewal
   'bulkRenewal.async.saveBulkRenewalApplicationData',
];

export const callsRequiredRTRChecks = [
   'bulkRenewal.async.saveBulkRenewalApplicationData',
   'renewal.async.saveApplicationRenewal',
   'renewal.async.saveContactDetailsConfirmation',
   'renewal.async.saveRenewalApplicationData',
   'supervision.async.acceptSupervision',
   'supervision.async.declineSupervision',
   'supervision.async.changeSupervisor',
   'supervision.async.newSupervisor',
   'supervision.async.changeSupervisionEndDate',
];
