import React from 'react';
import PropTypes from 'prop-types';

const TableBody = ({ className, children }) => {
   return <tbody className={`${className}`}>{children}</tbody>;
};

const { string, node } = PropTypes;

TableBody.defaultProps = {
   className: '',
};

TableBody.propTypes = {
   className: string,
   children: node.isRequired,
};

export default TableBody;
