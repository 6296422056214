import React from 'react';
import { string, func } from 'prop-types';

const Icon = ({ className, iconName, onClick }) => {
   const classes = `material-icons ${className}`;
   return (
      <i onClick={onClick} className={classes} aria-hidden="true">
         {iconName}
      </i>
   );
};


Icon.propTypes = {
   iconName: string.isRequired,
   className: string,
   onClick: func,
};

Icon.defaultProps = {
   className: null,
};

export default Icon;
