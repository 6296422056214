// Supervision status
export const SUPERVISION_STATUS_ACCEPTED = 'Accepted';
export const SUPERVISION_STATUS_DECLINED = 'Declined';
export const SUPERVISION_STATUS_PENDING = 'Pending';
export const SUPERVISION_STATUS_CANCELLED = 'Cancelled';
export const SUPERVISION_STATUS_NOTTRACKED = 'NotTracked';
export const SUPERVISION_NONE = '';

// Licence Status
export const LICENCE_STATUS_ACTIVE = 'ACTV';
export const LICENCE_STATUS_READY_TO_PRINT = 'RDYP';
export const LICENCE_STATUS_REPRINT = 'RPNT';
export const LICENCE_STATUS_PENDING = 'PEND';

// User action Status
export const USER_ACTION_RENEWLATER = 'RenewingLater';
export const USER_ACTION_RENEW = 'Renewed';
export const USER_ACTION_NOT_RENEW_FOREVER = 'NotRenewingEver';
export const USER_ACTION_NOT_RENEW_FOR_PERIOD = 'NotRenewingForPeriod';
