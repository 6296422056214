import React from 'react';
import { CERTIFYING_LICENCE_TYPES } from 'pgdb-data-layer/lib/Constants/LicenceTypes';
import {
   Table,
   TableBody,
   TableRow,
   TableCell,
} from '../../../../Components/GenericTable';
import * as helper from '../../../../Helpers/Helpers';
import TextLink from '../../../../Components/TextLink';

const NOMINATE_MESSAGE = 'Nominate a Supervisor';

const PayAndNominateTable = ({ renewalItems, onEdit }) => {
   const generateRow = (contact, app, appIndex) => {
      const name = !appIndex ? contact.fullName : '';
      let appTotal = 0;
      app.fees.filter(f => !f.isPaid).forEach(s => (appTotal += s.amount));

      const isCertifying = !!CERTIFYING_LICENCE_TYPES[app.licenceTypeCode];
      const supervisorName = app.supervisor
         ? app.supervisor.fullName
         : app.supervisorName;
      const hasAcceptedSupervisor = app.hasSupervisorApproved && supervisorName;

      const supervisorIcon =
         app.isDisciplinaryFee || isCertifying
            ? ''
            : hasAcceptedSupervisor && !app.supervisor
            ? 'check'
            : supervisorName || app.supervisor
            ? 'sync'
            : '';
      const supervisorColor =
         hasAcceptedSupervisor && supervisorName && !app.supervisor
            ? 'black'
            : supervisorName || app.supervisor
            ? 'orange'
            : 'activeblue';
      const licenceColor =
         app.isDisciplinaryFee || app.activeLicence ? 'black' : 'orange';
      const licenceIcon = app.isDisciplinaryFee
         ? ''
         : app.activeLicence
         ? 'check'
         : 'sync';

      const supervisorText =
         app.isDisciplinaryFee || isCertifying ? (
            ''
         ) : supervisorName ? (
            supervisorName
         ) : (
            <TextLink onClick={onEdit}>{NOMINATE_MESSAGE}</TextLink>
         );

      const totalText = app.fees.filter(f => !f.isPaid).length
         ? helper.formatCurrency(appTotal, 2)
         : 'Paid';

      return (
         <React.Fragment key={appIndex++}>
            <TableRow key={app.applicationId} className="main-row">
               <TableCell className="name-cell" color="black">
                  {name}
               </TableCell>
               <TableCell
                  icon={licenceIcon}
                  color={licenceColor}
                  className={`licence-cell ${
                     !licenceIcon ? 'icon-left-pad' : ''
                  }`}
               >
                  {app.description}
               </TableCell>
               <TableCell
                  icon={supervisorIcon}
                  color={supervisorColor}
                  className="supervisor-cell"
               >
                  {supervisorText}
               </TableCell>
               <TableCell color="black" className="currency-cell bold">
                  <span>$</span>
               </TableCell>
               <TableCell
                  color="black"
                  className="bold text-right width-reduction amount-cell"
                  amount="1"
               >
                  {totalText}
               </TableCell>
            </TableRow>
         </React.Fragment>
      );
   };

   const generateTableRows = () => {
      let eleCount = 0;
      return renewalItems.map(contact => {
         let appIndex = 0;
         const feeRows = contact.applications
            .filter(f => !f.isDisciplinaryFee)
            .map(app => {
               const row = generateRow(contact, app, appIndex);
               appIndex += 1;
               return row;
            });

         const disciplinaryFeeRows = contact.applications
            .filter(f => f.isDisciplinaryFee)
            .map(app => {
               const row = generateRow(contact, app, appIndex);
               appIndex += 1;
               return row;
            });

         eleCount += 1;
         return (
            <React.Fragment key={eleCount}>
               {feeRows}
               {disciplinaryFeeRows}
            </React.Fragment>
         );
      });
   };

   return (
      <Table className="table-head-compressed borderless licence-table">
         <TableBody>{generateTableRows()}</TableBody>
      </Table>
   );
};

export default PayAndNominateTable;
