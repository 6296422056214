import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import M from 'materialize-css';
import * as ADDRESS from 'pgdb-data-layer/lib/Constants/Address';
import * as ADDRESS_HELPERS from 'pgdb-data-layer/lib/Helpers/Address';
import TextInput from '../TextInput';
import CheckboxInput from '../CheckboxInput';
import CountryDropdown from '../CountryDropdown';
import CityDropdown from '../CityDropdown';
import TextLink from '../TextLink';
import EnumActions, {
   isFetchingEnum,
} from 'pgdb-data-layer/lib/Redux/EnumRedux';
import { getToken } from '../../Session/SessionRedux';

class AddressInput extends Component {
   constructor(props) {
      super(props);
      this.state = {
         // added checks for the warning about a component is changing
         // an uncontrolled input of type text to be controlled
         addressField: props.value.fullAddress || '',
         allowManualAddressSelection: false,
         formValidity: {
            manualAddressLine1: true,
            manualAddressLine2: true,
            manualSuburb: true,
            manualCity: true,
            manualCountry: true,
            manualPostCode: true,
         },
         isManualAddress: false,
         manualAddressLine1: '',
         manualAddressLine2: '',
         manualSuburb: '',
         manualCity: '',
         manualCheck: false,
         manualCountryCode: ADDRESS.DEFAULT_COUNTRY_CODE,
         manualCountry: ADDRESS.DEFAULT_COUNTRY,
         manualPostCode: '',
         resultSelected: true,
         widget: null,
      };
   }

   componentDidMount() {
      this.loadWidget();
      this.loadCities();
   }

   onChange(changedValue) {
      this.setState({ addressField: changedValue });
      const { onChange } = this.props;
      onChange(changedValue);
   }

   cancelManual() {
      this.clearManualFields();
      this.setState({ isManualAddress: false });
   }

   clearAddressField() {
      const { resultSelected } = this.state;
      if (!resultSelected) {
         this.setState({ addressField: '' });

         const { onChange } = this.props;
         onChange('');
      }
   }

   validateManualPostCode() {
      const { postCodeCities } = this.props;
      const { manualPostCode, manualCity, manualCountryCode } = this.state;

      // validate post code only for New Zealand
      if (manualCountryCode === 'NZL') {
         const formValidityManualPostCode =
            postCodeCities.filter(
               x => x.postCode === manualPostCode && x.city === manualCity
            ).length > 0;
         return formValidityManualPostCode;
      }

      return true;
   }

   isInvalidManualPostCode() {
      this.setState({
         formValidity: { manualPostCode: this.validateManualPostCode() },
      });
   }

   clearManualFields(fullAddress = '') {
      this.setState({
         addressField: fullAddress,
         resultSelected: true,
         isManualAddress: false,
         manualCheck: false,
         allowManualAddressSelection: false,
         manualAddressLine1: '',
         manualAddressLine2: '',
         manualSuburb: '',
         manualCity: '',
         manualCountryCode: ADDRESS.DEFAULT_COUNTRY_CODE,
         manualCountry: ADDRESS.DEFAULT_COUNTRY,
         manualPostCode: '',
      });

      const { onChange } = this.props;
      onChange('');
   }

   loadCities() {
      const { dispatch, token, postCodeCities, cities } = this.props;

      if (!postCodeCities || postCodeCities.length === 0) {
         dispatch(EnumActions.getPostCodeCitiesRequest(token));
      }
      if (!cities || cities.length === 0) {
         dispatch(EnumActions.getCitiesRequest(token));
      }
   }
   /*
  Loads the address finder widget and associates all actions
  List of available actions can be found here https://addressfinder.nz/docs/widget_docs/
  */
   loadWidget() {
      const { id, addressParams, onChange, onSubmit } = this.props;

      const widget = new window.AddressFinder.Widget(
         document.getElementById(id),
         // key is okay to be held locally as addressfinder uses domain source to clear access
         ADDRESS.ADDRESS_FINDER_KEY,
         ADDRESS.ADDRESS_FINDER_DEFAULT_COUNTRY,
         {
            address_params: addressParams,
            manual_style: true, // allow manual style injection (from styles.css)
            byline: false, // don't show powered by AddressFinder in the footer in production
            empty_content: ADDRESS.NO_RESULTS_USER_STRING,
         }
      );
      widget.on(ADDRESS.WIDGET_NO_RESULTS_SELECTED, () => {
         // no results were found
         this.setState({
            resultSelected: false,
            allowManualAddressSelection: true,
         });
         // clear parent state data
         onChange('');
      });
      widget.on(ADDRESS.WIDGET_UPDATED_AND_NO_RESULTS_SELECTED, () => {
         // results were updated but not selected
         this.setState({ resultSelected: false });
         onChange('');
      });
      widget.on(ADDRESS.WIDGET_RESULT_SELECTED, (fullAddress, metaData) => {
         // selection registered. Store in local state
         const selected = new window.AddressFinder.NZSelectedAddress(
            fullAddress,
            metaData
         );
         // addressfinder wraps values in functions, pull these out and set them to a local obj
         const address = {
            address_line_1: selected.address_line_1(),
            address_line_2: selected.address_line_2(),
            suburb: selected.suburb(),
            city: selected.city(),
            postcode: selected.postcode(),
            fullAddress: selected.fullAddress,
         };
         this.setState({
            addressField: selected.fullAddress,
            allowManualAddressSelection: false,
            resultSelected: true,
         });

         // send resulting selection including all address finder fields up to parent for processing
         onSubmit(address);
      });
      this.setState({ widget });
   }

   saveManualFields() {
      // check all fields have a selected value
      const validationCheck = this.validateFormFields();
      if (validationCheck) {
         const {
            manualAddressLine1,
            manualAddressLine2,
            manualSuburb,
            manualCity,
            manualPostCode,
            manualCountryCode,
            manualCountry,
         } = this.state;

         // create address object using filled in fields
         const address = {
            address_line_1: ADDRESS_HELPERS.normalizeManualInput(
               manualAddressLine1
            ),
            address_line_2: ADDRESS_HELPERS.normalizeManualInput(
               manualAddressLine2
            ),
            suburb: ADDRESS_HELPERS.normalizeManualInput(manualSuburb),
            city: ADDRESS_HELPERS.normalizeManualInput(manualCity),
            postcode: ADDRESS_HELPERS.normalizeManualInput(manualPostCode),
            countryCode: manualCountryCode,
            country: manualCountry,
         };

         const addressArray = [
            address.address_line_1,
            address.address_line_2,
            address.suburb,
            address.city,
            address.postcode,
         ];
         const addressFilter = addressArray.filter(
            val => (val || '').length > 0
         );
         address.fullAddress =
            !Array.isArray(addressFilter) || !addressFilter.length
               ? ''
               : addressFilter.reduce((prev, next) => {
                    return `${prev}, ${next}`;
                 });

         if (manualCountryCode !== ADDRESS.DEFAULT_COUNTRY_CODE) {
            address.fullAddress += ` ${manualCountry}`;
         }

         this.clearManualFields(address.fullAddress);
         const { onSubmit } = this.props;
         onSubmit(address);
         // this will save the field to the original input field and hide the manual address fields
      } else {
         let message = 'Please correctly fill in all mandatory fields';
         if (!this.state.formValidity.manualPostCode) {
            message = 'Please enter a valid Postcode';
         }
         M.toast({
            html: message,
            classes: 'error',
         });
      }
   }

   validateFormFields() {
      const { addressParams } = this.props;
      const {
         manualAddressLine1,
         manualAddressLine2,
         manualCity,
         manualCountryCode,
         manualPostCode,
      } = this.state;
      const valid = {
         manualAddressLine1:
            addressParams != null && addressParams.post_box === '0'
               ? ADDRESS_HELPERS.validateNoPostBox(manualAddressLine1)
               : ADDRESS_HELPERS.validateAddressLine(manualAddressLine1),
         manualAddressLine2: ADDRESS_HELPERS.validateOptionalAddressLine(
            manualAddressLine2
         ),
         manualCity: ADDRESS_HELPERS.validateCityLine(manualCity),
         manualCountryCode: ADDRESS_HELPERS.validateFormValue(
            manualCountryCode
         ),
         manualPostCode:
            ADDRESS_HELPERS.validatePostCode(manualPostCode) &&
            this.validateManualPostCode(),
      };

      const allFieldsValid = Object.values(valid).every(v => !!v);
      this.setState({ formValidity: valid, allFieldsValid });
      return allFieldsValid;
   }

   render() {
      const {
         label,
         mandatory,
         id,
         inputValid,
         placeholder,
         type,
         postalCheckRequired,
         cities,
      } = this.props;

      const {
         formValidity,
         allowManualAddressSelection,
         addressField,
         isManualAddress,
         manualAddressLine1,
         manualAddressLine2,
         manualSuburb,
         manualCity,
         manualPostCode,
         manualCountryCode,
         manualCheck,
      } = this.state;

      return (
         <>
            {label && (
               <span className="grey2-text input-header">
                  {label}
                  {mandatory && <span className="text-red"> *</span>}
               </span>
            )}
            <input
               value={addressField}
               className={`form-control input-validation-error text-input${
                  inputValid ? '' : ' input-invalid'
               }`}
               onChange={e => this.onChange(e.target.value)}
               disabled={isManualAddress}
               onBlur={() => this.clearAddressField()}
               id={id}
               placeholder={placeholder}
               type={type}
            />
            {allowManualAddressSelection && (
               <div className="row">
                  <div className="col l12">
                     <TextLink
                        id={`${id}-manual-address`}
                        className="manual-address-link"
                        onClick={() =>
                           this.setState({
                              isManualAddress: !isManualAddress,
                           })
                        }
                     >
                        Enter Address Manually
                     </TextLink>
                  </div>
               </div>
            )}
            {isManualAddress && (
               <div className="row manual-address-fields">
                  <h4>{label}</h4>
                  <div className="col l10 push-l1">
                     <TextInput
                        name="address_line_1"
                        type="text"
                        id={`${id}-manual-line1`}
                        inputValid={formValidity.manualAddressLine1}
                        value={manualAddressLine1}
                        placeholder="Address Line 1"
                        label="Address Line 1"
                        onChange={e =>
                           this.setState({
                              manualAddressLine1: e.target.value,
                           })
                        }
                        mandatory
                     />
                  </div>
                  <div className="col l10 push-l1">
                     <TextInput
                        name="address_line_2"
                        type="text"
                        id={`${id}-manual-line2`}
                        inputValid={formValidity.manualAddressLine2}
                        value={manualAddressLine2}
                        placeholder="Address Line 2"
                        label="Address Line 2"
                        onChange={e =>
                           this.setState({
                              manualAddressLine2: e.target.value,
                           })
                        }
                     />
                  </div>
                  <div className="col l5 push-l1">
                     <TextInput
                        name={`${id}suburb`}
                        type="text"
                        id={`${id}-manual-suburb`}
                        value={manualSuburb}
                        placeholder="Suburb"
                        label="Suburb"
                        onChange={e =>
                           this.setState({
                              manualSuburb: e.target.value,
                           })
                        }
                     />
                  </div>
                  <div className="col l5 push-l1">
                     {this.state.manualCountryCode === 'NZL' && (
                        <CityDropdown
                           name={`${id}city`}
                           type="text"
                           id={`${id}-manual-city`}
                           inputValid={formValidity.manualCity}
                           value={manualCity}
                           placeholder="City"
                           label="City"
                           onChange={e =>
                              this.setState({
                                 manualCity: e.target.value,
                              })
                           }
                           listDropdown={cities}
                           mandatory
                        />
                     )}
                     {this.state.manualCountryCode !== 'NZL' && (
                        <TextInput
                           name={`${id}city`}
                           type="text"
                           id={`${id}-manual-city`}
                           inputValid={formValidity.manualCity}
                           value={manualCity}
                           placeholder="City"
                           label="City"
                           onChange={e =>
                              this.setState({
                                 manualCity: e.target.value,
                              })
                           }
                           mandatory
                        />
                     )}
                  </div>
                  <div className="col l5 push-l1">
                     <TextInput
                        name={`${id}postcode`}
                        type="text"
                        id={`${id}-manual-postcode`}
                        inputValid={formValidity.manualPostCode}
                        value={manualPostCode}
                        placeholder="Postcode"
                        label="Postcode"
                        onChange={e =>
                           this.setState({
                              manualPostCode: e.target.value,
                           })
                        }
                        onBlur={() => this.isInvalidManualPostCode()}
                        mandatory
                     />
                  </div>
                  <div className="col l5 push-l1">
                     <CountryDropdown
                        name={`${id}country`}
                        id={`${id}-manual-country`}
                        inputValid={formValidity.manualCountryCode}
                        value={manualCountryCode}
                        placeholder="Country"
                        label="Country"
                        onChange={e =>
                           this.setState({
                              manualCountryCode: e.target.value,
                              manualCountry:
                                 e.target.options[e.target.selectedIndex].text,
                           })
                        }
                        mandatory
                     />
                  </div>
                  {postalCheckRequired && (
                     <div className="col l11 push-l1">
                        <div className="section">
                           <CheckboxInput
                              name={`${id}check`}
                              type="checkbox"
                              id={`${id}-check`}
                              defaultValue={false}
                              value={manualCheck}
                              label="I confirm that the specified address can receive postal mail"
                              onChange={e =>
                                 this.setState({
                                    manualCheck: e.target.checked,
                                 })
                              }
                              mandatory
                           />
                        </div>
                     </div>
                  )}
                  <div className="row">
                     <div className="col l12 center">
                        <button
                           id={`${id}-save`}
                           className="btn-small manual-save"
                           disabled={!manualCheck && postalCheckRequired}
                           onClick={() => this.saveManualFields()}
                           type="submit"
                        >
                           Save Address
                        </button>
                        <button
                           id={`${id}-cancel`}
                           className="btn-small manual-cancel"
                           onClick={() => this.cancelManual()}
                           type="submit"
                        >
                           Cancel
                        </button>
                     </div>
                  </div>
               </div>
            )}
         </>
      );
   }
}

AddressInput.propTypes = {
   type: PropTypes.string.isRequired,
   placeholder: PropTypes.string,
   id: PropTypes.string,
   defaultValue: PropTypes.object,
   mandatory: PropTypes.bool,
   inputValid: PropTypes.bool,
   postalCheckRequired: PropTypes.bool,
   onChange: PropTypes.func,
   onSubmit: PropTypes.func,
   value: PropTypes.object,
};

AddressInput.defaultProps = {
   id: '',
   defaultValue: {},
   placeholder: '',
   mandatory: false,
   inputValid: true,
   postalCheckRequired: false,
   onChange: null,
   onSubmit: null,
};

const mapStateToProps = state => {
   return {
      postCodeCities: state.enum.postCodeCities,
      cities: state.enum.cities,
      token: getToken(state),
   };
};

export default connect(mapStateToProps)(AddressInput);
