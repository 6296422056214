import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import RegistrationActions, {isFetchingRegistration} from 'pgdb-data-layer/lib/Redux/RegistrationRedux';
import { getToken } from '../../Session/SessionRedux';
import M from 'materialize-css';
import './FileUpload.scss'
import { Button } from '@material-ui/core';

const FileUpload = ({ uploadedFileCount, 
    token, 
    putRegistrationFileRequest, 
    user, 
    sectionId, 
    onFileUploaded, 
    onFileSelected,
    isDisabled,
    isUploadingFile }) => {

    const fileInputRef = useRef(null);
    const maxFileLimit = 10;
    const maxFileSize = 10000000;
    
    const parentClassName = () => {
        return `upload-button-container ${uploadedFileCount >= 5 ? "hideContainer" : null}`
    }

    const isFileValid = (fileItem) => {    
        const acceptableFileType = ['jpg', 'jpeg', 'pdf', 'png']
        const fileExtension = fileItem.name.split('.').pop().toLowerCase();

        if (!acceptableFileType.includes(fileExtension)) {
            M.toast({
                html: "File was not accepted. File type invalid.",
                classes: 'error',
             });
            return false
        } else if (fileItem.size > maxFileSize) {
            M.toast({
                html: "File was not accepted. File size exceeded 10MB.",
                classes: 'error',
             });
            return false
        } else {
            return true
        }
    }

    const onFileChange = (event) => {
        const file = event.target.files[0];

        if (!isFileValid(file)) {
            return;
        }
        if (!onFileSelected(file)) {
            return;
        }
        const blob = new Blob([file], { type: file.type });
        const data = new FormData();
        data.append("ApplicationFile.FileTypeID", sectionId);
        data.append("ApplicationFile.ContactID", user.contactData.contactId);
        data.append("ApplicationFile.FileName", file.name);
        data.append("data", blob);
        putRegistrationFileRequest(token, data);
        onFileUploaded();
    }

    const selectFile = () => {
        fileInputRef.current.click();
    }

    return (
        <div className={parentClassName()}>
            <div className="file-upload-container">
                <input 
                    type="file" 
                    ref={fileInputRef} 
                    onChange={onFileChange}
                    style={{ display: 'none' }} 
                    accept=".jpg, .jpeg, .pdf, .png"
                    disabled={isUploadingFile}
                />
                <label 
                    onClick={selectFile} 
                    className={`upload-document-button ${isUploadingFile ? 'loading-button': ''}`}
                >
                    Upload
                </label>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        token: getToken(state),
        user: state.user
    };
};

const mapDispatchToProps = {
    putRegistrationFileRequest: RegistrationActions.putRegistrationFileRequest
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FileUpload);