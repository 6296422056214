import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import '../Exams.css';
import * as COURSE from 'pgdb-data-layer/lib/Constants/Course';
import Moment from 'moment';
import {
   Table,
   TableHead,
   TableHeaderCell,
   TableRow,
   TableBody,
   TableCell,
} from '../../../../Components/GenericTable';

const ExamResultsTable = ({ results }) => {
   const renderTableContent = () => {
      return results.map(e => {
         if (e.resultStatusCode === 'DECL') return null;

         return (
            <TableRow key={e.resultId}>
               <TableCell />
               <TableCell className="no-left-pad">
                  {e && e.course ? e.course.courseName : '-'}
               </TableCell>
               <TableCell className="no-left-pad">
                  {e && e.resultDate
                     ? Moment(e.resultDate).format('DD MMMM YYYY')
                     : '-'}
               </TableCell>
               <TableCell
                  className="no-left-pad"
                  color={e.passFailCode === COURSE.PASS_CODE ? 'green' : 'red'}
               >
                  {e.passFailCode}
               </TableCell>
               <TableCell className="no-left-pad">
                  {e.mark ? e.mark : '-'}
               </TableCell>
            </TableRow>
         );
      });
   };

   const renderTable = () => {
      return (
         <>
            <Table>
               <colgroup>
                  <col width="5%" />
                  <col width="45%" />
                  <col width="20%" />
                  <col width="20%" />
                  <col width="10%" />
               </colgroup>
               <TableHead>
                  <TableRow>
                     <TableHeaderCell />
                     <TableHeaderCell className="no-left-pad">
                        Exam Type
                     </TableHeaderCell>
                     <TableHeaderCell className="no-left-pad small-right-pad">
                        Result Date
                     </TableHeaderCell>
                     <TableHeaderCell className="no-left-pad">
                        Result
                     </TableHeaderCell>
                     <TableHeaderCell className="no-left-pad">
                        Mark
                     </TableHeaderCell>
                     <TableHeaderCell className="no-left-pad" />
                  </TableRow>
               </TableHead>
               <TableBody>{renderTableContent()}</TableBody>
            </Table>
         </>
      );
   };

   return (
      <>
         {!isEmpty(results) && (
            <div className="row">
               <div className="col l10 offset-l1">
                  <h5 className="subheader">Results for Exams</h5>
                  {renderTable()}
               </div>
            </div>
         )}
      </>
   );
};

ExamResultsTable.propTypes = {
   results: PropTypes.any,
};

ExamResultsTable.defaultProps = {
   results: {},
};

const mapStateToProps = state => {
   return {
      results: state.result.examination,
   };
};

export default connect(mapStateToProps)(ExamResultsTable);
