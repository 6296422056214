import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AddLicencesAndNominate } from '../Shared';
import BulkRenewalActions from 'pgdb-data-layer/lib/Redux/BulkRenewalRedux';

class BulkRenewPayAndNominate extends Component {
   render() {
      return (
         <AddLicencesAndNominate 
            backUrl='/renew-supervisors' 
            nextUrl='/renew-summary'
            getRenewalApplicationRequest={BulkRenewalActions.getBulkRenewalApplicationRequest}
            resetRenewalApplicationSearch={BulkRenewalActions.resetRenewalApplicationSearch}
            removeSavingStatus={BulkRenewalActions.removeBulkSavingStatus}
            getRenewalSearchSupervisorRequest={BulkRenewalActions.getBulkRenewalSearchSupervisorRequest}
            resetRenewalSearchSupervisor={BulkRenewalActions.resetBulkRenewalSearchSupervisor}
            savePayAndNominateDetails={BulkRenewalActions.saveBulkPayAndNominateDetails}
            isBulkRenewal={true}
         />
      );
   }
}

export default connect()(BulkRenewPayAndNominate);