import React from 'react';
import PropTypes from 'prop-types';
import '../../Containers/Pages/Receipts.scss';
import TextLink from '../TextLink';

const Receipt = ({
   rowStyling,
   datePaid,
   name,
   method,
   amount,
   paymentId,
   link,
   handler,
}) => {
   return (
      <tr className={rowStyling}>
         <td>
            <p className="table-item black-text helvetica-bold">{datePaid}</p>
         </td>
         <td>
            <p className="table-item black-text">{name}</p>
         </td>
         <td>
            <p className="table-item payment-column">{method}</p>
         </td>
         <td>
            <p className="table-item black-text helvetica-bold disable-wordwrap">
               $ {amount}
            </p>
         </td>
         <td>
            <TextLink onClick={() => handler(paymentId)} className="link">
               {link}
            </TextLink>
         </td>
      </tr>
   );
};

const { number, string, func } = PropTypes;

Receipt.propTypes = {
   rowStyling: string.isRequired,
   datePaid: string.isRequired,
   name: string.isRequired,
   method: string.isRequired,
   amount: number.isRequired,
   paymentId: number.isRequired,
   link: string.isRequired,
   handler: func.isRequired,
};

export default Receipt;
