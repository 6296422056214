import React from 'react';
import PropTypes from 'prop-types';

const { string, object: obj, func, bool } = PropTypes;

const OptionGroup = ({ label, values }) => {
   const optionEntries = Object.keys(values).map((key, _) => {
      return (
         <option key={key} value={key}>
            {values[key]}
         </option>
      );
   });

   return <optgroup label={label}>{optionEntries}</optgroup>;
};

OptionGroup.propTypes = {
   label: string.isRequired,
   values: obj.isRequired,
};

// values should be in the form of:
// {
//    group1Name: { val1: "val1Text", val2: "val2Text", val3: "val3Text" },
//    group2Name: { val1: "val1Text", val2: "val2Text", val3: "val3Text" },
//    group3Name: { val1: "val1Text", val2: "val2Text", val3: "val3Text" },
// }

const GroupedDropdown = ({
   id,
   placeholder,
   values,
   disabled,
   value = undefined,
   highlighted = false,
   keepPlaceholder = false,
   onBlur = null,
   onChange = null,
   label = null,
}) => {
   const inputClasses = `browser-default form-control input-validation-error select-input${
      highlighted ? ' select-highlighted' : ''
   }${disabled ? ' input-disabled' : ''}`;

   const optionGroups = Object.keys(values).map((key, _) => {
      const groupValues = values[key];
      return <OptionGroup key={key} label={key} values={groupValues} />;
   });

   const dropdownValue = value === undefined ? '' : value;

   return (
      <>
         {label && <span className="grey2-text input-header">{label}</span>}
         <select
            className={inputClasses}
            id={id}
            value={dropdownValue}
            placeholder={placeholder}
            onBlur={onBlur}
            onChange={onChange}
            required
         >
            {placeholder !== null && (
               <option
                  value=""
                  disabled={!keepPlaceholder}
                  hidden={!keepPlaceholder}
               >
                  {placeholder}
               </option>
            )}
            {optionGroups}
         </select>
      </>
   );
};

GroupedDropdown.propTypes = {
   id: string,
   placeholder: string,
   values: obj.isRequired,
   disabled: bool,
   value: string,
   highlighted: bool,
   keepPlaceholder: bool,
   onBlur: func,
   onChange: func,
   label: string,
};

GroupedDropdown.defaultProps = {
   id: '',
   placeholder: '',
   disabled: false,
   value: undefined,
   highlighted: false,
   keepPlaceholder: false,
   onBlur: null,
   onChange: null,
   label: null,
};

export default GroupedDropdown;
