import React, { Fragment } from 'react';
import Moment from 'moment';
import { NavLink } from 'react-router-dom';
import Table from '../../../../../Components/GenericTable/Table';
import TableRow from '../../../../../Components/GenericTable/TableRow';
import TableCell from '../../../../../Components/GenericTable/TableCell';
import TableHeaderCell from '../../../../../Components/GenericTable/TableHeaderCell';
import TableHead from '../../../../../Components/GenericTable/TableHead';
import TableBody from '../../../../../Components/GenericTable/TableBody';
import * as helper from '../../../../../Helpers/Helpers';

const ExamPaymentTable = ({ tableData, onDelete }) => {
   const generateTableRows = () => {
      return tableData.map((value, index) => {
         return (
            <Fragment key={index}>
               <TableRow key={value.examinationId} className="subrow-no-btm">
                  <TableCell
                     color="activeblue"
                     icon="delete_forever"
                     iconClick={() => onDelete(value.examinationId)}
                  >
                     <NavLink exact to="/exams" className="activeblue-text">
                        {value.course.courseName}
                     </NavLink>
                  </TableCell>
                  <TableCell>
                     {Moment(value.examinationDate).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell>{value.venue.examinationVenue}</TableCell>
                  <TableCell
                     color="black"
                     className="bold text-right width-reduction amount-cell"
                     amount="1"
                  >
                     ${helper.formatCurrency(value.course.courseFee, 2)}
                  </TableCell>
               </TableRow>
            </Fragment>
         );
      });
   };
   return (
      <Table className="table-head-compressed borderless">
         <TableHead>
            <TableRow>
               <TableHeaderCell className="icon-left-pad">
                  Exam Type
               </TableHeaderCell>
               <TableHeaderCell className="no-left-pad">
                  Exam Date
               </TableHeaderCell>
               <TableHeaderCell className="no-left-pad">
                  Exam Venue
               </TableHeaderCell>
               <TableHeaderCell className="no-left-pad amount-header">
                  Cost
               </TableHeaderCell>
            </TableRow>
         </TableHead>

         <TableBody>{generateTableRows()}</TableBody>
      </Table>
   );
};

export default ExamPaymentTable;
