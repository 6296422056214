import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

/**
 * Create the App Insights Telemetry Service
 * @return {{reactPlugin: ReactPlugin, appInsights: Object, initialize: Function}} - Object
 */
const createTelemetryService = () => {
   let reactPlugin = null;
   let appInsights = null;
   /**
    * Initialize the Application Insights class
    * @param {string} instrumentationKey - Application Insights Instrumentation Key
    * @param {Object} browserHistory - client's browser history, supplied by the withRouter HOC
    * @return {void}
    */
   const initialize = (instrumentationKey, browserHistory) => {
      if (!instrumentationKey || !browserHistory) {
         throw new Error('Could not initialize Telemetry Service');
      }
      reactPlugin = new ReactPlugin();

      const LOG_LEVEL_DEFAULT = 0; // OFF

      appInsights = new ApplicationInsights({
         config: {
            instrumentationKey: instrumentationKey,
            maxBatchInterval: 0,
            disableFetchTracking: false,
            disableCorrelationHeaders: false,
            disableAjaxTracking: false,
            extensions: [reactPlugin],
            enableCorsCorrelation: true,
            correlationHeaderExcludedDomains: process.env
               .REACT_APP_INSIGHTS_EXCLUSION_DOMAINS
               ? process.env.REACT_APP_INSIGHTS_EXCLUSION_DOMAINS.split(',')
               : [],
            loggingLevelConsole: process.env
               .REACT_APP_INSIGHTS_LOG_LEVEL_CONSOLE
               ? process.env.REACT_APP_INSIGHTS_LOG_LEVEL_CONSOLE
               : LOG_LEVEL_DEFAULT,
            loggingLevelTelemetry: process.env
               .REACT_APP_INSIGHTS_LOG_LEVEL_TELEMETRY
               ? process.env.REACT_APP_INSIGHTS_LOG_LEVEL_TELEMETRY
               : LOG_LEVEL_DEFAULT,
            enableRequestHeaderTracking: true,
            enableResponseHeaderTracking: true,
            enableAutoRouteTracking: true,
            distributedTracingMode: 0, // AI Tracking mode
            extensionConfig: {
               [reactPlugin.identifier]: {
                  history: browserHistory,
               },
            },
         },
      });

      appInsights.loadAppInsights();
      return appInsights;
   };
   return { reactPlugin, appInsights, initialize };
};

export const ai = createTelemetryService();
