import React, { useState } from 'react';
import Button from '../../../Components/Buttons/DefaultButton/button';
import CountryListMultiSelect from '../../../Components/CountryListMultiSelect';

const StylingPage = () => {
   const [selectedCountriesList, setSelectedCountriesList] = useState([]);

   return (
      <div className="container registration-container">
         <div className="row">
            <h3>StylingPage</h3>
            <h2>Buttons</h2>
         </div>
         <div className="row">
            <Button
               id={'primary-test'}
               onClick={() => alert('Click')}
               text="Submit"
               size="btn-large"
               type="primary"
            />

            <Button
               id={'secondary-test'}
               onClick={() => alert('Click')}
               text="Submit"
               size="btn-large"
               type="secondary"
            />
         </div>

         <div>
            <div className="row">
               <h2>Country Multi Select</h2>
            </div>
            <div className="row">
               <div className="col">
                  <CountryListMultiSelect 
                        selectedCountries={selectedCountriesList} 
                        onUpdateSelectedCountries={setSelectedCountriesList}
                  />
               </div>
            </div>
         </div>
      </div>

   );
};

export default StylingPage;
