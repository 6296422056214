import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

const { string, func, number, bool } = PropTypes;

const onEnterPressed = (e, handler) => {
   if (!handler) return;
   if (e.key === 'Enter') handler();
};

const TextInput = ({
   type,
   id,
   placeholder,
   disabled,
   value,
   maxLength = null,
   onBlur = null,
   onChange = null,
   onSubmit = null,
   label = null,
   inputValid = true,
   mandatory = false,
}) => {
   const inputClasses = `form-control input-validation-error text-input ${
      disabled ? 'input-disabled' : ''
   }${inputValid ? '' : 'input-invalid'}`;

   return (
      <div>
         {label && (
            <span className="grey2-text input-header">
               {label}
               {mandatory && <span className="text-red"> *</span>}
            </span>
         )}
         <input
            className={inputClasses}
            value={value}
            id={id}
            placeholder={placeholder}
            type={type}
            maxLength={maxLength}
            disabled={disabled}
            onChange={onChange}
            onBlur={onBlur}
            onKeyPress={e => onEnterPressed(e, onSubmit)}
         />
      </div>
   );
};

TextInput.propTypes = {
   type: string.isRequired,
   placeholder: string,
   id: string,
   onChange: func,
   onBlur: func,
   onSubmit: func,
   disabled: bool,
   maxLength: number,
   value: string,
   label: string,
   inputValid: bool,
   mandatory: bool,
};

TextInput.defaultProps = {
   id: '',
   placeholder: '',
   disabled: false,
   maxLength: null,
   value: '',
   onBlur: null,
   onChange: null,
   onSubmit: null,
   label: '',
   inputValid: true,
   mandatory: false,
};

export default TextInput;
