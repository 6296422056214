import React, { useState } from 'react';
import { connect } from 'react-redux';
import { func, object, bool, string } from 'prop-types';
import RenewActions, {
   isFetchingRenewal,
} from 'pgdb-data-layer/lib/Redux/RenewalRedux';
import { getToken } from '../../Session/SessionRedux';

import './Profile/Profile.scss';
import ProfileEntry from './Profile/ProfileEntry';
import Navigator from '../../Navigation/Navigator';

const RenewContactDetailsHeader = () => {
   return (
      <div className="col s3 m6 l9 offset-l1">
         <div className="row profile-row">
            <div className="col l12">
               <p className="columnheader">
                  Are these your current contact details?
               </p>
            </div>
         </div>
      </div>
   );
};

const footerGenerator = (onBackAction, onNextAction) => {
   return onSaveHandler => {
      return (
         <>
            <div className="col s3 m6 l9 offset-l1">
               <div className="row profile-row next-button-wrapper">
                  <div className="col l6">
                     <button
                        type="button"
                        className="waves-effect waves-light btn btn-inactive"
                        onClick={() => {
                           let saved = false;
                           if (onSaveHandler) saved = onSaveHandler();
                           if (saved && onBackAction) onBackAction();
                        }}
                     >
                        Back
                     </button>
                     <button
                        type="button"
                        className="waves-effect waves-light btn btn-ml-30"
                        onClick={() => {
                           let saved = false;
                           if (onSaveHandler) saved = onSaveHandler();
                           if (saved && onNextAction) onNextAction();
                        }}
                     >
                        Next
                     </button>
                  </div>
               </div>
            </div>
         </>
      );
   };
};

const RenewContactDetails = ({
   dispatch,
   hasConfirmed,
   location,
   token,
   saveInProgress,
}) => {
   const [navigateTo, setNavigateTo] = useState('');
   const [performedFooterOperation, setPerformedFooterOperation] = useState(
      false
   );

   if (hasConfirmed) {
      if (navigateTo === 'back') {
         Navigator.to('/renew');
      }

      if (navigateTo === 'next') {
         Navigator.to('/fit2practice-wizard');
      }
   }

   const readyToPreformFooterOpertation = () => {
      if (!performedFooterOperation) {
         if (navigateTo === 'back') {
            dispatch(RenewActions.saveContactDetailsConfirmationRequest(token));
         }

         if (navigateTo === 'next') {
            dispatch(RenewActions.saveContactDetailsConfirmationRequest(token));
         }
         setPerformedFooterOperation(true);
      }
   };

   return (
      <main>
         <div className="section no-pad-bot" id="index-banner">
            <ProfileEntry
               saveConfirmationInProgress={saveInProgress}
               header={<RenewContactDetailsHeader />}
               footer={footerGenerator(
                  () => {
                     setNavigateTo('back');
                  },
                  () => {
                     setNavigateTo('next');
                  }
               )}
               location={location}
               isRenewal
               returnUrl={'/renew-contact-details'}
               readyToPreformFooterOpertation={readyToPreformFooterOpertation}
            />
         </div>
      </main>
   );
};

RenewContactDetails.propTypes = {
   dispatch: func.isRequired,
   hasConfirmed: bool,
   location: object,
   token: string.isRequired,
};

RenewContactDetails.defaultProps = {
   hasConfirmed: false,
};

const mapStateToProps = state => {
   return {
      token: getToken(state),
      hasConfirmed: state.renewal.contactDetailsConfirmed,
      saveInProgress: isFetchingRenewal(
         'saveContactDetailsConfirmation',
         state
      ),
   };
};

export default connect(mapStateToProps)(RenewContactDetails);
