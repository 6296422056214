import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import Icon from '../Icon';

const { string, object: obj, bool } = PropTypes;

const DashboardCard = ({
   header,
   headerStyle,
   icon,
   iconStyle,
   description,
   navLink,
   disabled = true,
   visible = false,
}) => {
   const cardStyleClass = `card-panel white dashboard-panels center`;
   const cardLayoutClass = `col s12 m6 l3 card-layout scale-transition ${
      visible ? ' scale-in' : ' scale-out'
   } ${disabled ? ' hide' : ''}`;
   const cardHeaderClass = `card-header ${headerStyle}`;
   const cardIconClass = `round large ${iconStyle}`;
   const seperatorClass = `card-line center ${iconStyle}`;

   return (
      <div className={cardLayoutClass}>
         <div className={cardStyleClass}>
            <h6 className={cardHeaderClass}>{header}</h6>

            <Icon className={cardIconClass} iconName={icon} />

            <div className={seperatorClass} />

            <span className="grey2-text">{description}</span>
            <br />
            <br />
            {navLink && (
               <NavLink
                  exact
                  to={`/${navLink.href}`}
                  className="activeblue-text"
               >
                  {navLink.text}
               </NavLink>
            )}
         </div>
      </div>
   );
};

DashboardCard.propTypes = {
   header: string,
   headerStyle: string,
   icon: string,
   iconStyle: string,
   description: string,
   navLink: obj,
   disabled: bool,
   visible: bool,
};

DashboardCard.defaultProps = {
   header: '',
   headerStyle: '',
   icon: 'check',
   iconStyle: '',
   description: '',
   navLink: null,
   disabled: false,
};

export default DashboardCard;
