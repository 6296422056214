import { arrayOf, shape, string, number } from 'prop-types';

export const superviseeType = shape({
   contactId: number.isRequired,
   superviseeName: string.isRequired,
   licenceTypeCodes: arrayOf(string.isRequired).isRequired,
   licenceTypeNames: arrayOf(string.isRequired).isRequired,
});

export const registrationType = shape({
   registrationId: number.isRequired,
   registrationStatusCode: string.isRequired,
   registrationStatusName: string.isRequired,
   registrationTypeCode: string.isRequired,
   registrationTypeName: string.isRequired,
   registeredDate: string.isRequired,
});

export const registrationHistoryType = shape({
   active: registrationType,
   history: registrationType,
});

// not exported, only defined so that we can use it for composition
// in contactDetailsType and contactType
const contactDetailsShape = {
   registrationNumber: number.isRequired,
   firstName: string.isRequired,
   lastName: string,
   fullName: string.isRequired,
   businessAddressLine1: string,
   businessAddressLine2: string,
   businessSuburbTown: string,
   businessPostalCode: string,
   businessCity: string,
   businessCountryCode: string,
   url: string,
   publicPhone: string,
   publicEmail: string,
   contactType: string.isRequired,
};

export const contactDetailsType = shape(contactDetailsShape);

export const supervisionType = shape({
   supervisionId: number.isRequired,
   startDate: string.isRequired,
   endDate: string.isRequired,
   supervisor: contactDetailsType,
});

export const licenceType = shape({
   licenceId: number.isRequired,
   licenceTypeCode: string.isRequired,
   licenceTypeName: string.isRequired,
   licenceStatusCode: string.isRequired,
   licenceStatusName: string.isRequired,
   contactId: number.isRequired,
   startDate: string.isRequired,
   expiryDate: string.isRequired,
   supervision: supervisionType,
});

export const historicalLicenceType = shape({
   discipline: string.isRequired,
   licenceTypeCode: string.isRequired,
   licenceTypeName: string.isRequired,
   licenceStatusCode: string.isRequired,
   licenceStatusName: string.isRequired,
   licenceYears: arrayOf(string.isRequired).isRequired,
});

export const licenceHistoryType = shape({
   current: historicalLicenceType,
   previous: historicalLicenceType,
});

export const disciplinaryHistoryType = shape({
   history: arrayOf(string),
});

export const contactType = shape({
   ...contactDetailsShape,

   registrations: arrayOf(registrationType).isRequired,
   licences: arrayOf(licenceType).isRequired,
   registrationHistory: arrayOf(registrationHistoryType).isRequired,
   licenceHistory: arrayOf(licenceHistoryType).isRequired,
   supervisees: arrayOf(superviseeType).isRequired,
   disciplinaryHistory: disciplinaryHistoryType,
});

export const contactResult = shape({
   publicRegisterContacts: arrayOf(contactType),
   totalCount: number,
});
