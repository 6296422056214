import React, { Component } from 'react';
import { connect } from 'react-redux';
import Moment from 'moment';
import { isEqual } from 'lodash';
import FileSaver from 'file-saver';
import ReceiptsActions, {
   isFetchingReceiptsSuccess,
   getReceipts,
   getReceiptsDetails,
} from 'pgdb-data-layer/lib/Redux/ReceiptsRedux';
import Receipt from '../../Components/Receipt/index';
import './Receipts.scss';
import { getToken } from '../../Session/SessionRedux';

const EmptyReceipts = () => {
   return (
      <div className="row">
         <h4 className="columnheader left">No receipts found</h4>
      </div>
   );
};

const LoadingReceipts = () => {
   return (
      <main>
         <div className="section" id="index-banner">
            <div className="container">
               <div className="row">
                  <div className="col l12 center">
                     <div className="section">
                        <img alt="Loading" src="images/loading.gif" />
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </main>
   );
};

class Receipts extends Component {
   componentDidMount() {
      this.props.dispatch(
         ReceiptsActions.getUserReceiptsRequest(this.props.token)
      );
   }

   componentDidUpdate(prevProps) {
      if (
         !isEqual(prevProps.userReceiptDetails, this.props.userReceiptDetails)
      ) {
         this.downloadPDF();
      }
   }

   componentWillUnmount() {
      // Set receiptDetail to null
   }

   getReceiptDetails(paymentId) {
      const { token, dispatch } = this.props;
      if (token) {
         dispatch(
            ReceiptsActions.getUserReceiptDetailsRequest(token, paymentId)
         );
      }
   }

   downloadPDF() {
      const blob = new Blob([this.props.userReceiptDetails], {
         type: 'application/pdf',
      });

      FileSaver.saveAs(blob, 'Receipt.pdf');
   }

   getYearFromReceipt(receipt) {
      return Moment(receipt.paymentDate).year();
   }

   getDateFromReceipt(receipt) {
      return Moment(receipt.paymentDate).format('DD/MM/YYYY');
   }

   getReceiptStyling(receipts, i, yearOfPayment) {
      var receipt = receipts[i];
      var prevReceipt = receipts[i - 1];
      var nextReceipt = receipts[i + 1];
      var prevDate, nextDate;

      if (prevReceipt != null) {
         prevDate = this.getYearFromReceipt(receipts[i - 1]);
      }
      if (nextReceipt != null) {
         nextDate = this.getYearFromReceipt(receipts[i + 1]);
      }

      var date = this.getYearFromReceipt(receipt);

      // Give appropriate styling the receipt depending on year it was found
      var rowStyling = '';
      if (date === nextDate && (prevDate > date || !prevDate)) {
         rowStyling = 'receipt-item-first';
      } else if (date === prevDate && (date > nextDate || !nextDate)) {
         rowStyling = 'receipt-item-last';
      } else if (date === prevDate && date === nextDate) {
         rowStyling = 'receipt-item-middle';
      }

      return rowStyling;
   }

   checkReceipts = () => {
      return (
         this.props.userReceipts != null && this.props.userReceipts.length !== 0
      );
   };

   renderReceipts(receipts) {
      // Return if there are no receipts
      if (!this.checkReceipts()) return <EmptyReceipts />;

      // Make a list of receipt components
      const receiptList = receipts.map((receipt, i) => {
         return (
            <Receipt
               key={i}
               rowStyling={this.getReceiptStyling(receipts, i)}
               datePaid={this.getDateFromReceipt(receipt)}
               name={receipt.payee}
               method={receipt.paymentMethod}
               amount={receipt.paidAmount}
               paymentId={receipt.paymentId}
               link="Download"
               handler={paymentId => this.getReceiptDetails(paymentId)}
            />
         );
      });

      return (
         <>
            <div className="row">
               <h4 className="columnheader left offset">My Receipts</h4>
            </div>
            <div className="row">
               <table>
                  <thead>
                     <tr>
                        <th className="grey-text exam-header table-header ">
                           Date Paid
                        </th>
                        <th className="grey-text exam-header table-header ">
                           Name of Payee
                        </th>
                        <th className="grey-text exam-header table-header ">
                           Payment Method
                        </th>
                        <th className="grey-text exam-header table-header ">
                           Amount Paid
                        </th>
                        <th className="grey-text exam-header table-header ">
                           Receipt
                        </th>
                     </tr>
                  </thead>
                  <tbody className="offset">{receiptList}</tbody>
               </table>
            </div>
         </>
      );
   }

   render() {
      const { hasLoadedReceipts } = this.props;
      if (!hasLoadedReceipts) return <LoadingReceipts />;

      return (
         <main>
            <div className="section no-pad-bot" id="index-banner">
               <div className="container">
                  <div className="row center-align">
                     <div className="col s3 m6 l10 offset-l1">
                        <br />
                        <br />
                        {this.renderReceipts(this.props.userReceipts)}
                        <br />
                        <br />
                     </div>
                  </div>
               </div>
            </div>
         </main>
      );
   }
}

const mapStateToProps = state => {
   return {
      hasLoadedReceipts: isFetchingReceiptsSuccess('getUserReceipts', state),
      userReceipts: getReceipts(state.receipt),
      userReceiptDetails: getReceiptsDetails(state.receipt),
      token: getToken(state),
   };
};

export default connect(mapStateToProps)(Receipts);
