import React from 'react';
import { connect } from 'react-redux';
import Moment from 'moment';
import M from 'materialize-css';
import './DatePicker.scss';

class DatePicker extends React.Component {
   constructor(props) {
      super(props);
      this.datePicker = React.createRef();
   }

   componentDidMount() {
      const { pickerStartDate, pickerEndDate } = this.props;
      const options = {
         onClose: this.updateEndDate,
         minDate: Moment(pickerStartDate, 'YYYY-MM-DD').toDate(),
         maxDate: Moment(pickerEndDate, 'YYYY-MM-DD').toDate(),
      };
      M.Datepicker.init(this.datePicker.current, options);
   }

   updateEndDate = e => {
      const { callback } = this.props;
      const date = Moment(this.datePicker.current.value)
         .add(23, 'hours')
         .add(59, 'minutes')
         .format('MMM DD YYYY HH:mm:ss');
      callback(date);
   };

   render() {
      return (
         <input
            type="text"
            className="datepicker blue-link-design"
            placeholder="Click to select the date"
            ref={this.datePicker}
         />
      );
   }
}

export default connect()(DatePicker);
