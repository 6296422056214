import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import UserActions, {
   getUserInfoData,
} from 'pgdb-data-layer/lib/Redux/UserInfoRedux';
import { includes } from 'lodash';
import Navigator from '../../Navigation/Navigator';
import history from '../../Navigation/History';
import { getToken } from '../../Session/SessionRedux';

const CanPerformOnlineHandler = ({
   userContactData,
   authenticated,
   userSession,
   children,
   token,
   dispatch,
}) => {
   const listOfPathToMonitor = [
      '/renew',
      '/renew-contact-details',
      '/renew-licences',
      '/renew-cpd',
      '/renew-supervisors',
      '/bulk-renew-landing',
      '/bulk-renew-pay-and-nominate',
      '/renew-summary',
   ];

   const [canPerformOnline, setCanPerformOnline] = useState(false);
   const [requiredCheck, setRequiredCheck] = useState(
      includes(listOfPathToMonitor, history.location.pathname)
   );

   const performCheck = () => {
      if (
         userContactData &&
         (userContactData.cannotTransactOnline !== undefined ||
            userContactData.unpaidFine !== undefined)
         // explicitly checking with undefined here as null is
         // also part of the value in the property
      ) {
         if (
            requiredCheck &&
            (userContactData.cannotTransactOnline === true ||
               userContactData.unpaidFine === true)
         ) {
            Navigator.toUnableToPerformOnline();
         } else {
            setCanPerformOnline(true);
         }
      } else if (
         userContactData &&
         (userContactData.cannotTransactOnline === null ||
            userContactData.cannotTransactOnline === false) &&
         (userContactData.unpaidFine === null ||
            userContactData.unpaidFine === false)
      ) {
         setCanPerformOnline(true);
      }
   };

   useEffect(() => {
      const unlisten = history.listen((location, action) => {
         setRequiredCheck(includes(listOfPathToMonitor, location.pathname));
      });
      return () => {
         unlisten();
      };
   }, []);

   useEffect(() => {
      if (
         requiredCheck &&
         !userContactData.contactId &&
         authenticated &&
         userSession.user
      ) {
         dispatch(UserActions.getUserContactInfoRequest(token));
      }
   }, [userSession]);

   useEffect(() => {
      performCheck();
   }, [requiredCheck]);

   useEffect(() => {
      performCheck();
   }, [userContactData]);

   return <>{requiredCheck ? <>{canPerformOnline && children}</> : children}</>;
};

const mapStateToProps = state => {
   return {
      userContactData: getUserInfoData(state.user),
      userSession: state.session,
      token: getToken(state),
      authenticated: state.session.authenticated,
   };
};

export default connect(mapStateToProps)(CanPerformOnlineHandler);
