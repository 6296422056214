import React, { useState, useCallback } from 'react';
import { bool, arrayOf, func } from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { DefaultPagingModel } from 'pgdb-data-layer/lib/Helpers/Others/PagedQuery';
import {
   isFetchingPublicRegister,
   getPublicRegisterData,
} from 'pgdb-data-layer/lib/Redux/PublicRegisterRedux';
import Dropdown from '../../Components/Dropdown';
import GroupedDropdown from '../../Components/GroupedDropdown';
import TextInput from '../../Components/TextInput';
import SubmitButton from '../../Components/Buttons/SubmitButton';
import './PublicRegister.scss';

import {
   SEARCH_TYPES,
   SIMPLE_SEARCH_VALUES,
   SEARCH_LICENCE_TYPE_OPTIONS,
   INITIAL_BASIC_SEARCH_STATE,
   SEARCH_ACTION,
   getSearchTypeDescription,
   getInputPlaceholder,
   getInputMaxLength,
   normalizeSearchValues,
} from './PublicRegister/data';
import {
   validateSearchRequest,
   getFormattedSearchLicenceNumber,
} from './PublicRegister/validation';
import { contactType } from './PublicRegister/types';
import SearchingPlaceholder from './PublicRegister/SearchingPlaceholder';
import SearchResults from './PublicRegister/SearchResults';

const INITIAL_SEARCH_TYPE = '';
const INITIAL_TEXT_VALUE = '';
const INITIAL_HAS_SEARCHED = false;
const INITIAL_SCROLL_TO = false;
const INITIAL_VALIDATION_MESSAGE = '';
const DEFAULT_PAGESIZE = DefaultPagingModel.pageSize;
const DEFAULT_PAGENUMBER = DefaultPagingModel.pageNumber;

const PublicRegister = ({ isLoading, contacts, dispatch }) => {
   const [searchType, setSearchType] = useState(INITIAL_SEARCH_TYPE);
   const [paging, setPaging] = useState(DefaultPagingModel);
   const [searchValue, setSearchValue] = useState(INITIAL_BASIC_SEARCH_STATE);
   const [textValue, setTextValue] = useState(INITIAL_TEXT_VALUE);
   const [categoryHighlighted, setCategoryHighlighted] = useState(true);
   const [validationMessage, setValidationMessage] = useState(
      INITIAL_VALIDATION_MESSAGE
   );

   const [hasSearched, setHasSearched] = useState(INITIAL_HAS_SEARCHED);
   const [searchedType, setSearchedType] = useState(INITIAL_SEARCH_TYPE);
   const [searchedValue, setSearchedValue] = useState(
      INITIAL_BASIC_SEARCH_STATE
   );
   const [shouldScrollTo, setShouldScrollTo] = useState(INITIAL_SCROLL_TO);

   const onSearchTypeChange = value => {
      setCategoryHighlighted(false);
      setSearchType(value);
      setValidationMessage(INITIAL_VALIDATION_MESSAGE);
      setTextValue(INITIAL_TEXT_VALUE);
      setSearchValue(INITIAL_BASIC_SEARCH_STATE);
      setHasSearched(INITIAL_HAS_SEARCHED);
      setShouldScrollTo(INITIAL_SCROLL_TO);
   };
   const updateSearchValue = (key, value) => {
      const updated = { ...searchValue };
      updated[key] = value;
      setSearchValue(updated);
   };

   const updatePagingValue = (key, value) => {
      const updated = { ...paging };
      updated[key] = value;
      setPaging(updated);
   };
   const onSearchValueChange = value => {
      // format licences for searching and validation
      if (searchType === SEARCH_TYPES.LICENCE) {
         const formattedLicence = getFormattedSearchLicenceNumber(value);
         const newSearchValue =
            formattedLicence.length !== 0 ? formattedLicence : value;
         updateSearchValue(SEARCH_TYPES.SEARCH_VALUE, newSearchValue);
      } else {
         updateSearchValue(SEARCH_TYPES.SEARCH_VALUE, value);
      }

      setTextValue(value);
      // clear existing validation message and avoid scrolling to search results if available
      setValidationMessage(INITIAL_VALIDATION_MESSAGE);
      setShouldScrollTo(INITIAL_SCROLL_TO);
   };

   const showSearchResults = hasSearched && !isLoading;

   const onChangePage = useCallback((event, page) => {
      paging.pageNumber = page;
      searchValue.pageNumber = page;
      const newValues = normalizeSearchValues(searchValue, SEARCH_TYPES.BASIC);
      dispatch(SEARCH_ACTION(searchType, newValues));
   });

   const onChangeRowsPerPage = useCallback(event => {
      paging.pageSize = event.target.value;
      searchValue.pageSize = event.target.value;

      const newValues = normalizeSearchValues(searchValue, SEARCH_TYPES.BASIC);
      dispatch(SEARCH_ACTION(searchType, newValues));
   });

   const onSubmitButtonClicked = () => {
      const validation = validateSearchRequest(searchType, searchValue);
      setValidationMessage(validation.message);
      setHasSearched(validation.valid);
      if (validation.valid) {
         const trimmedValue = searchValue.searchValue.trim();

         setShouldScrollTo(true);
         setSearchedType(searchType);
         setSearchedValue(trimmedValue);
         searchValue.pageSize = DEFAULT_PAGESIZE;
         searchValue.pageNumber = DEFAULT_PAGENUMBER;
         const newValues = normalizeSearchValues(
            searchValue,
            SEARCH_TYPES.BASIC
         );

         paging.pageSize = DEFAULT_PAGESIZE;
         paging.pageNumber = DEFAULT_PAGENUMBER;
         updatePagingValue(paging.pageNumber, paging);
         updatePagingValue(paging.pageSize, paging);

         dispatch(SEARCH_ACTION(searchType, newValues));
      }
   };

   const searchPlaceholder = getInputPlaceholder(searchType);
   const searchMaxLength = getInputMaxLength(searchType);
   const textFieldDisabled = !getSearchTypeDescription(searchType);
   const hasLicenceTypeDropdown = searchType === SEARCH_TYPES.LICENCE_TYPE;

   return (
      <main>
         <div className="section no-pad-bot">
            <div className="container">
               <div className="row">
                  <div className="col s12">
                     <h4 className="columnheader left">Search</h4>
                  </div>
               </div>
               <br />
               <div className="row">
                  <div className="col s12">
                     <h5 className="subheader sub-text">Public Register</h5>
                  </div>
               </div>
               <br />
               <div className="row">
                  <div className="drop-down col s12 m4">
                     <Dropdown
                        value={searchType}
                        values={SIMPLE_SEARCH_VALUES}
                        placeholder="Select a Category"
                        highlighted={categoryHighlighted}
                        onChange={e => onSearchTypeChange(e.target.value)}
                     />
                  </div>
                  <div className="drop-down col s12 m5">
                     {hasLicenceTypeDropdown ? (
                        <GroupedDropdown
                           value={textValue}
                           values={SEARCH_LICENCE_TYPE_OPTIONS}
                           placeholder={searchPlaceholder}
                           onChange={e => onSearchValueChange(e.target.value)}
                        />
                     ) : (
                        <TextInput
                           type="text"
                           value={textValue}
                           placeholder={searchPlaceholder}
                           maxLength={searchMaxLength}
                           disabled={textFieldDisabled}
                           onChange={e => onSearchValueChange(e.target.value)}
                           onSubmit={onSubmitButtonClicked}
                        />
                     )}
                  </div>
                  <div className="col s3 m1">
                     <SubmitButton onClick={onSubmitButtonClicked} />
                  </div>
               </div>
               {validationMessage && (
                  <div className="row">
                     <div className="col s12">
                        <p>
                           <span className="invalid-message">
                              {validationMessage}
                           </span>
                        </p>
                     </div>
                  </div>
               )}
               <div className="row">
                  <div className="col s12">
                     <p>
                        <Link
                           to="/public-register/advanced"
                           className="search-switch-link"
                        >
                           Advanced Search
                        </Link>
                     </p>
                  </div>
               </div>
               {isLoading && <SearchingPlaceholder />}
               {showSearchResults && (
                  <SearchResults
                     searchType={searchedType}
                     searchValue={searchedValue}
                     contacts={contacts}
                     scrollToResults={shouldScrollTo}
                     dispatch
                     onChangeRowsPerPage={onChangeRowsPerPage}
                     onChangePage={onChangePage}
                     paging={paging}
                  />
               )}
            </div>
         </div>
      </main>
   );
};

PublicRegister.propTypes = {
   isLoading: bool.isRequired,
   contacts: arrayOf(contactType).isRequired,
   dispatch: func.isRequired,
};

const mapStateToProps = state => {
   return {
      contacts: getPublicRegisterData(state.publicRegister),
      isLoading: isFetchingPublicRegister(state),
   };
};

export default connect(mapStateToProps)(PublicRegister);
