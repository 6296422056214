import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoadingScreen from 'react-loading-screen';
import * as session from '../../Session';

class Login extends Component {
   constructor(props, context) {
      super(props, context);
      this.state = {
         text: 'Loading...',
         loading: true,
      };
   }

   componentDidMount() {
      session.login().catch(e =>
         this.setState({
            text: 'Could not login. Please try again later',
            loading: false,
         })
      );
   }

   render() {
      const { loading, text } = this.state;

      return (
         <LoadingScreen
            loading={loading}
            bgColor="#F4F4F4"
            textColor="#676767"
            logoSrc="/images/loading.gif"
            text={text}
         >
            <div />
         </LoadingScreen>
      );
   }
}

export default connect()(Login);
