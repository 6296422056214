import React from 'react';
import PropTypes from 'prop-types';

const Table = ({ className, children }) => {
   return <table className={`generic-table ${className}`}>{children}</table>;
};

const { string, node } = PropTypes;

Table.defaultProps = {
   className: '',
};

Table.propTypes = {
   className: string,
   children: node.isRequired,
};

export default Table;
