import React from 'react';
import { arrayOf } from 'prop-types';
import * as LICENCE from 'pgdb-data-layer/lib/Constants/Licence';
import {
   CERTIFYING_LICENCE_TYPES,
   EMPLOYER_LICENCE_TYPE,
} from 'pgdb-data-layer/lib/Constants/LicenceTypes';
import Icon from '../../../../Components/Icon';
import { licenceType } from '../types';

const ExemptionFittingOfWaterHeaters= "GE53";
const ExemptionSpecificPlumbingEquipment = "GE56";
const ExemptionSpecificGasfittingEquipment = "GE57";

const EmptyLicencesCell = () => {
   return (
      <span className="block-text text-red bold">
         <Icon className="inline-icon" iconName="clear" />
         No Current Licences Held
      </span>
   );
};

const LicencesCell = ({ licences }) => {
   const activeLicences = licences.filter((l, _) =>
      LICENCE.isCurrentLicenceStatus(l.licenceStatusCode)
   );

   if (activeLicences.length === 0) return <EmptyLicencesCell />;

   const licenceLines = activeLicences.map(l => {
      const isSupervised = l.supervision !== null;

      const requiresSupervision =
         !Object.keys(CERTIFYING_LICENCE_TYPES).includes(
            l.licenceTypeCode.trim()
         ) && l.licenceTypeCode !== EMPLOYER_LICENCE_TYPE
         && l.licenceTypeCode !== ExemptionFittingOfWaterHeaters
         && l.licenceTypeCode !== ExemptionSpecificGasfittingEquipment
         && l.licenceTypeCode !== ExemptionSpecificPlumbingEquipment;

      const missingRequiredSupervision = requiresSupervision && !isSupervised;
     
      let textColouring =
         l.licenceStatusCode === LICENCE.LICENCE_STATUS_PENDING || missingRequiredSupervision
            ? 'orange-text'
            : 'pr-text-active';

      const iconName = missingRequiredSupervision ? 'sync' : 'check';

      return (
         <div key={l.licenceId} className="block-text multi-line-cell">
            <span className={`bold ${textColouring}`}>
               <Icon className="inline-icon" iconName={iconName} />
               {l.licenceTypeName}
            </span>
            {isSupervised && (
               <span className="pr-text-active"> (supervised)</span>
            )}
         </div>
      );
   });

   return <>{licenceLines}</>;
};

LicencesCell.propTypes = {
   licences: arrayOf(licenceType).isRequired,
};

export default LicencesCell;
