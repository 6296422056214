import React from 'react';
import { bool, func } from 'prop-types';
import { contactType } from '../types';

import PrimarySearchRow from './PrimarySearchRow';
import DetailedSearchRow from './DetailedSearchRow';

const SearchRow = ({ contact, showDetailed, onExpand, onCollapse }) => {
   return !showDetailed ? (
      <PrimarySearchRow
         rowStyle="pr-row-hover"
         contact={contact}
         icon="expand_more"
         onToggle={onExpand}
      />
   ) : (
      <DetailedSearchRow contact={contact} onCollapse={onCollapse} />
   );
};

SearchRow.propTypes = {
   contact: contactType.isRequired,
   showDetailed: bool.isRequired,
   onExpand: func.isRequired,
   onCollapse: func.isRequired,
};

export default SearchRow;
