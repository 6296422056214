import { useEffect, useState } from 'react';
import Icon from '../Icon';
import './radiostyle.scss';
import React  from 'react';

const RadioButtonsLeftSide = ({
   onClick, 
   title, 
   groupName, 
   radioOptions, 
   value, 
   disabled, 
   iconName, 
   index, 
   radioLabel = 'radio-label col s10', 
   radioDisplay = 'col s2 radio-display', 
   toDisable
}) => {
   const onValueChange = (groupName, val) => {
      onClick(groupName, val);
   }

   const radioValues = [];
      
      for(let i = 0; i < radioOptions.length; i++) {
         const isChecked = radioOptions[i] === value ? { checked: true } : undefined;

            radioValues.push(
            <div className="radio-option" key={i}>
               <label className="bold black-text">
                    <input
                        id={radioOptions[i]}
                        radioGroup={groupName}
                        className="with-gap"
                        name={groupName}
                        type="radio"
                        onClick={() => onValueChange(groupName, radioOptions[i])}
                        disabled={toDisable.includes(radioOptions[i]) ? true : false}
                        {...isChecked}
                     />
                  <span className="radio-option-label">{radioOptions[i]}</span>

                  <span />
               </label>
            </div>
         );
      }
   
   return (
         <div className="row small-btm radio-left-groups">
            <div className={radioLabel}>
               {iconName && <span>
                  <Icon className="label-icon" iconName={iconName} />
               </span>}
               {index && <span style={{marginRight: '10px'}}>
                  {index}
               </span>}
               {title}
               </div>
            <div className={radioDisplay}>
               {radioValues}
            </div>
         </div>
   );
};


export default RadioButtonsLeftSide;
