import React, { useState, useEffect } from 'react';
import './ApplicationStatus.scss';
import moment from 'moment';
import Modal from 'react-modal';
import Button from '../../../../Components/Buttons/DefaultButton/button';
import Navigator from '../../../../Navigation/Navigator';
import Icon from '../../../../Components/Icon';
import ModalDialog from '../../../../Components/ModalDialog';

export const CONDITION_DESCRIPTION_MISSING = {
   CBL: (
      <li>
         You have not completed CPD. Go to your CPD tab to complete or contact{' '}
         <a className="activeblue-text" href="mailto:cpd@pgdb.co.nz">
            CPD team
         </a>
      </li>
   ),
   CSC: (
      <li>
         The Board needs to confirm you and your supervisor are in the same
         region
      </li>
   ),
   SCV: <li>Your supervisor needs to renew their certifying licence</li>,
   SDSS: <li>Your supervisor needs to approve the supervision nomination</li>,
};

const ApplicationStatusRow = ({
   registration,
   onDelete,
   isDeleting,
   isDeleteSuccess,
   onContinue,
}) => {
   const [isOpenInfo, setIsOpenInfo] = useState(false);

   const actionByStatus = {
      DRFT: {
         statusText: 'DRAFT',
         text: 'CONTINUE',
         action: () => {
            onContinue();
            Navigator.toRegistrationApplicantDetails();
         },
      },
      DEL: {
         statusText: 'DELETE',
         text: 'DELETE',
         action: () => setIsOpenInfo(true),
      },
      N: {
         statusText: 'SUBMITTED',
         text: '',
         action: () => {},
      },
      REVW: {
         statusText: 'UNDER REVIEW',
         text: '',
         action: () => {},
      },
      PDOC: {
         statusText: 'ON HOLD PENDING DOCUMENT',
         text: 'UPLOAD DOCUMENT',
         action: () => {
            onContinue();
            Navigator.toPendingDocuments(registration.applicationId);
         },
      },
      DSUB: {
         statusText: 'DOCUMENTS SUBMITTED',
         text: '',
         action: () => {},
      },
      PREG: {
         statusText: 'ON HOLD PENDING REGISTRAR',
         text: '',
         action: () => {},
      },
      PPAY: {
         statusText: 'ON HOLD PENDING PAYMENT',
         text: 'MAKE PAYMENT',
         action: () =>
            Navigator.toRegistrationPayment(
               registration.applicationId,
               registration.contactId
            ),
      },
      PBAP: {
         statusText: 'PENDING BOARD APPROVAL',
         text: '',
         action: () => {},
      },
      DECL: {
         statusText: 'DECLINED',
         text: '',
         action: () => {},
      },
      BIP: {
         statusText: 'PENDING',
         text: '',
         action: () => {},
      },
   };

   useEffect(() => {
      if (isDeleteSuccess) {
         setIsOpenInfo(false);
      }
   }, [isDeleteSuccess]);

   const ItemCondition = ({ conditionCode }) =>
      CONDITION_DESCRIPTION_MISSING[conditionCode] ? (
         CONDITION_DESCRIPTION_MISSING[conditionCode]
      ) : (
         <></>
      );
   const renderPopUpMissedCondition = conditions => {
      const conditionsFilter = conditions
         ? conditions.filter(
              x => CONDITION_DESCRIPTION_MISSING[x.conditionCode]
           )
         : [];
      return (
         <>
            <Icon
               className="fit-model-icon material-icons right"
               iconName="close"
               onClick={() => setMissingConditionPopup(undefined)}
            />
            <br />
            <h4 className="title-message-pop-up">Outstanding Conditions</h4>
            <ol className="outstanding-conditions">
               {conditionsFilter.length > 0 ? (
                  conditionsFilter.map(condition => (
                     <ItemCondition
                        key={condition.conditionCode}
                        conditionCode={condition.conditionCode}
                     />
                  ))
               ) : (
                  <li>
                     The Board needs to complete the review of your application
                  </li>
               )}
            </ol>
         </>
      );
   };

   const statusInfo = actionByStatus[registration.applicationStatusCode];

   const [missingConditionPopup, setMissingConditionPopup] = React.useState();

   return (
      <div className="row">
         <div className="col l10 m12 offset-l1 s12 header-column">
            <div className="application-status-row">
               <div className="row m-0 application-status-details">
                  <p className="col s3 m3 l3 center-align">
                     {registration.applicationTypeDescription}
                  </p>
                  <p className="col s2 m2 l2 center-align">
                     {moment(registration.enteredDate).format('DD/MM/YYYY')}
                  </p>
                  <p className=" col s3 m3 l3  center-align">
                     {statusInfo.statusText}
                  </p>

                  <div className="col s4 m4 l4 center-align application-action-buttons">
                     {statusInfo.text !== '' ? (
                        <Button
                           size="btn-small"
                           type="primary"
                           className={`action-buttons ${
                              statusInfo.statusText === 'DRAFT'
                                 ? 'draft-action-buttons'
                                 : ''
                           }${
                              statusInfo.statusText ===
                              'ON HOLD PENDING PAYMENT'
                                 ? ' override-margin'
                                 : ''
                           }
                           ${
                              statusInfo.statusText ===
                              'ON HOLD PENDING DOCUMENT'
                                 ? ' override-margin-large'
                                 : ''
                           }`}
                           id={`row-status-${registration.applicationTypeCode}`}
                           text={statusInfo.text}
                           onClick={statusInfo.action}
                        />
                     ) : null}
                     {statusInfo.statusText === 'DRAFT' && (
                        <Button
                           size="btn-small"
                           type="secondary"
                           className="waves-effect waves-light draft-action-buttons"
                           id={`row-status-${registration.applicationTypeCode}`}
                           text={actionByStatus.DEL.text}
                           onClick={actionByStatus.DEL.action}
                        />
                     )}
                     {statusInfo.statusText === 'PENDING' && (
                        <Icon
                           className="fit-model-icon material-icons missing-condition"
                           //iconName="details"
                           iconName="info"
                           onClick={() =>
                              setMissingConditionPopup(
                                 registration.conditionsNotMet
                              )
                           }
                        />
                     )}
                  </div>
                  <ModalDialog
                     isOpen={!!missingConditionPopup}
                     onRequestClose={() => setMissingConditionPopup(undefined)}
                     body={renderPopUpMissedCondition(missingConditionPopup)}
                     shouldCloseOnOverlayClick
                  />
                  <Modal
                     isOpen={isOpenInfo}
                     onRequestClose={() => {
                        setIsOpenInfo(false);
                     }}
                     className="delete-modal"
                     overlayClassName="generic-modal-overlay"
                     appElement={document.getElementById('root')}
                  >
                     <Icon
                        className="fit-model-icon material-icons right"
                        iconName="close"
                        onClick={() => setIsOpenInfo(false)}
                     />
                     <br />
                     <div className="fit-modal-body">
                        <h4>
                           Are you sure you want to delete this application?
                        </h4>
                        <p>
                           Are you sure you want to delete this draft
                           application? This process cannot be undone.
                        </p>
                     </div>
                     <div className="delete-application-buttons">
                        <button
                           type="button"
                           className="modal-cancel-button"
                           onClick={() => setIsOpenInfo(false)}
                        >
                           NO
                        </button>
                        <button
                           type="button"
                           disabled={isDeleting}
                           className={`waves-effect modal-delete-button ${
                              isDeleting ? ' loading-button' : ''
                           }`}
                           onClick={onDelete}
                        >
                           YES
                        </button>
                     </div>
                  </Modal>
               </div>
            </div>
         </div>
      </div>
   );
};

export default ApplicationStatusRow;
