import React from 'react';
import './style.scss';
import { bool, any } from 'prop-types';

const Loading = ({ children, isLoading, overlayOnChildren }) => {
   return (
      <>
         <div className="loading-wrapper">
            {isLoading &&
               (overlayOnChildren ? (
                  <div className="loading-overlay-container loading-fade-in">
                     {children}
                     <div className="loading-overlay-animator" />
                  </div>
               ) : (
                  <div className="loading-wrapper-main">
                     <div className="loading-wrapper-content">
                        <img src="/images/loading.svg" alt="loading" />
                     </div>
                  </div>
               ))}
            {!isLoading && <div className="loading-fade-in">{children}</div>}
         </div>
      </>
   );
};

Loading.propTypes = {
   children: any,
   isLoading: bool,
   overlayOnChildren: bool,
};

export default Loading;
