import React from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import Icon from '../../../../Components/Icon';
import '../RegistrationPayment.scss';

const RegistrationPaymentPopup = ({
   onChange,
   isModalOpen,
   onWindcavePayment,
   onPolyPayment,
}) => {
   const SelectPaymentMethod = () => {
      return (
         <>
            <div className="fit-modal-body">
               <h4>Select a Payment Method </h4>
               <p>
                  After selecting a payment method, you will be redirected to
                  the third party site to complete your payment.
               </p>

               <button
                  type="button"
                  className="poli-pay-button payment-cursor"
                  onClick={onPolyPayment}
               >
                  <img
                     src="/images/polipay-icon.png"
                     alt="Payment Processor"
                     width="117"
                     height="75"
                  />
                  <div>
                     <p className="payment-title">POLi Payment</p>
                     <p className="payment-text">
                        Fund your payment instantly by using POLi
                     </p>
                  </div>
               </button>

               <button
                  type="button"
                  className="windcave-pay-button payment-cursor"
                  onClick={onWindcavePayment}
               >
                  <img
                     src="/images/windcave-red-logo.svg"
                     alt="Payment Processor"
                     width="200"
                     height="75"
                  />
                  <div>
                     <p className="payment-title">WindCave Payment</p>
                     <p className="payment-text">
                        Fund your payment instantly by using WindCave
                     </p>
                  </div>
               </button>
            </div>
            <div className="close-modal-button">
               <button
                  type="button"
                  className="close-payment-button waves-effect waves-light"
                  onClick={() => onChange()}
               >
                  CLOSE
               </button>
            </div>
         </>
      );
   };

   return (
      <>
         <Modal
            isOpen={isModalOpen}
            onRequestClose={() => {
               onChange();
            }}
            className="delete-modal"
            overlayClassName="generic-modal-overlay"
            appElement={document.getElementById('root')}
         >
            <Icon
               className="fit-model-icon material-icons right"
               iconName="close"
               onClick={() => onChange()}
            />
            <br />

            {<SelectPaymentMethod />}
         </Modal>
      </>
   );
};

RegistrationPaymentPopup.propTypes = {};

const mapStateToProps = state => {
   return {};
};

const mapDispatchToProps = {};

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(RegistrationPaymentPopup);
