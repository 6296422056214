import React from 'react';
import '../Exams.css';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import Moment from 'moment';
import ExaminationActions from 'pgdb-data-layer/lib/Redux/ExaminationRedux';
import {
   Table,
   TableHead,
   TableHeaderCell,
   TableRow,
   TableBody,
   TableCell,
} from '../../../../Components/GenericTable';
import Navigator from '../../../../Navigation/Navigator';

const ExamEnrolledTable = ({ examinationDetails, results, dispatch }) => {
   const examinationDetailsWithoutResults = examinationDetails.filter(
      detail => {
         return (
            !detail.isDeleted &&
            (isEmpty(results) ||
               !results.some(
                  result => result.examinationId === detail.examinationId
               )) &&
            Moment(detail.examinationDate).isAfter(Moment().subtract(1, 'w'))
         );
      }
   );

   if (isEmpty(examinationDetailsWithoutResults)) return <></>;

   const renderActionButtons = examination => {
      if (examination.canWithdraw) {
         return (
            <>
               <TableCell className="no-left-pad">
                  <button
                     type="submit"
                     onClick={() =>
                        Navigator.to(
                           `/update-exam-details?exam=${
                              examination.examinationResultsAddress
                                 .examinationResultsAddressId
                           }`
                        )
                     }
                     className="waves-effect waves-light btn btn-outline button-margin"
                  >
                     Update
                  </button>
               </TableCell>
               <TableCell className="no-left-pad">
                  <button
                     type="submit"
                     className="waves-effect waves-light btn btn-outline button-margin"
                     onClick={() =>
                        dispatch(
                           ExaminationActions.openModal(
                              'Withdrawal',
                              examination.examinationId
                           )
                        )
                     }
                  >
                     Withdraw
                  </button>
               </TableCell>
            </>
         );
      }

      return (
         <>
            <TableCell className="no-left-pad">
               <button
                  type="submit"
                  onClick={() =>
                     Navigator.to(
                        `/update-exam-details?exam=${
                           examination.examinationResultsAddress
                              .examinationResultsAddressId
                        }`
                     )
                  }
                  className="waves-effect waves-light btn btn-outline button-margin"
               >
                  Update
               </button>
            </TableCell>
            <TableCell className="no-left-pad" />
         </>
      );
   };

   const renderTableContent = () => {
      return examinationDetailsWithoutResults.map(examination => {
         return (
            <TableRow color="activeblue" key={examination.examinationId}>
               <TableCell />
               <TableCell className="no-left-pad">
                  {examination.examination.course.courseName}
               </TableCell>
               <TableCell className="no-left-pad small-right-pad">
                  {examination.examinationVenue
                     ? examination.examinationVenue.examinationVenue
                     : '-'}
               </TableCell>
               <TableCell className="no-left-pad">
                  {Moment(examination.examinationDate).format('DD MMMM YYYY')}
               </TableCell>
               {renderActionButtons(examination)}
            </TableRow>
         );
      });
   };

   return (
      !isEmpty(examinationDetailsWithoutResults) && (
         <div className="row">
            <div className="col l10 offset-l1">
               <h5 className="subheader">Exams you are Enrolled for</h5>
               <Table>
                  <colgroup>
                     <col width="5%" />
                     <col width="30%" />
                     <col width="35%" />
                     <col width="20%" />
                     <col width="5%" />
                     <col width="5%" />
                  </colgroup>
                  <TableHead>
                     <TableRow>
                        <TableHeaderCell />
                        <TableHeaderCell className="no-left-pad">
                           Exam Type
                        </TableHeaderCell>
                        <TableHeaderCell className="no-left-pad">
                           Exam Venue
                        </TableHeaderCell>
                        <TableHeaderCell className="no-left-pad">
                           Exam Date
                        </TableHeaderCell>
                        <TableHeaderCell className="no-left-pad" />
                        <TableHeaderCell className="no-left-pad" />
                     </TableRow>
                  </TableHead>
                  <TableBody>{renderTableContent()}</TableBody>
               </Table>
            </div>
         </div>
      )
   );
};

ExamEnrolledTable.propTypes = {
   examinationDetails: PropTypes.any,
   results: PropTypes.any,
   dispatch: PropTypes.func.isRequired,
};

ExamEnrolledTable.defaultProps = {
   examinationDetails: {},
};

const mapStateToProps = state => {
   return {
      examinationDetails: state.examination.examinationDetails,
      results: state.result.examination,
      modalState: state.examination.modalState,
   };
};

export default connect(mapStateToProps)(ExamEnrolledTable);
