import React from 'react';

const Loading = () => {
   return (
      <main>
         <div className="section">
            <div className="container">
               <div className="row">
                  <div className="col l12 center">
                     <div className="section">
                        <img alt="Loading" src="images/loading.gif" />
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </main>
   );
};

export default Loading;
