import React from 'react';
import { CERTIFYING_LICENCE_TYPES } from 'pgdb-data-layer/lib/Constants/LicenceTypes';
import {
   Table,
   TableBody,
   TableRow,
   TableCell,
} from '../../../../Components/GenericTable';
import Callout from '../../../../Components/Callout';
import * as StatusCode from '../StatusCodes';
import './style.css';

const ACTIVE_SECTION = 1;
// const INACTIVE_SECTION = 2;

const ICON_CHECK = 'check';
const ICON_SYNC = 'sync';

const COLOR_ACTIVE = 'black';
const COLOR_INACTIVE = 'orange';
const COLOR_BLUE = 'activeblue';
const NOMINATED_TEXT = 'A supervisor is nominated';

const PaymentSuccessfulLicences = ({ tableData, sectionType }) => {
   if (!tableData) {
      return null;
   }

   const getLicenceIcon = app => {
      return sectionType === ACTIVE_SECTION ? ICON_CHECK : ICON_SYNC;
   };

   const getSupervisorIcon = (app, supervisorText) => {
      return sectionType === ACTIVE_SECTION &&
         (CERTIFYING_LICENCE_TYPES[app.licenceTypeCode] ||
            (app.hasSupervisorApproved &&
               app.supervisorName &&
               app.supervisorLicenceStatus ===
                  StatusCode.LICENCE_STATUS_ACTIVE))
         ? ICON_CHECK
         : '';
   };

   const getLicenceColor = app => {
      return sectionType === ACTIVE_SECTION ? COLOR_ACTIVE : COLOR_INACTIVE;
   };

   const getSupervisorColor = (app, supervisorText, icon) => {
      return CERTIFYING_LICENCE_TYPES[app.licenceTypeCode]
         ? ''
         : supervisorText === NOMINATED_TEXT
         ? COLOR_BLUE
         : sectionType === ACTIVE_SECTION || icon === ICON_CHECK
         ? COLOR_ACTIVE
         : COLOR_INACTIVE;
   };

   const generateTableRows = () => {
      if (!tableData) {
         return null;
      }
      return tableData.map(contact => {
         const name = contact.fullName;
         let index = 0;

         return contact.applications.map(app => {
            let supervisorText = `supervised by ${app.supervisorName}`;

            if (CERTIFYING_LICENCE_TYPES[app.licenceTypeCode]) {
               supervisorText = '';
            } else if (
               sectionType !== ACTIVE_SECTION &&
               (!app.hasSupervisorApproved ||
                  app.supervisorLicenceStatus !==
                     StatusCode.LICENCE_STATUS_ACTIVE)
            ) {
               supervisorText =
                  app.supervisorLicenceStatus ===
                  StatusCode.LICENCE_STATUS_ACTIVE
                     ? app.supervisorName
                        ? `${app.supervisorName} accepts your nomination`
                        : ''
                     : app.supervisorName
                     ? `${app.supervisorName} renews their licence`
                     : '';
            }

            const supervisorIcon = getSupervisorIcon(app, supervisorText);

            return (
               <TableRow key={app.applicationId} className="main-row">
                  <TableCell className="name-cell">
                     {!index++ ? name : ''}
                  </TableCell>
                  <TableCell
                     icon={getLicenceIcon(app)}
                     color={getLicenceColor(app)}
                  >
                     {app.description}
                  </TableCell>
                  {CERTIFYING_LICENCE_TYPES[app.licenceTypeCode] ||
                  sectionType !== ACTIVE_SECTION ? (
                     <TableCell />
                  ) : (
                     <TableCell
                        className="supervisor-cell"
                        icon={supervisorIcon}
                        color={getSupervisorColor(
                           app,
                           supervisorText,
                           supervisorIcon
                        )}
                     >
                        {supervisorText}
                     </TableCell>
                  )}
               </TableRow>
            );
         });
      });
   };

   return (
      <div className="licence-section">
         <h2>
            {sectionType === ACTIVE_SECTION
               ? 'Active Licence(s)'
               : 'Pending further action:'}
         </h2>
         {sectionType !== ACTIVE_SECTION && (
            <div className="row">
               <div className="col s12 offset-l1">
                  <Callout>
                     These need further actions from others. These actions may
                     include one or more of the following:
                     <br />
                     confirm/update contact details, confirm/approve supervision
                     arrangements, complete the relevant CPD course, provide the
                     board with Fit & Proper information if appropriate.
                     <br />
                     The individuals can complete these actions through their
                     PGDB mobile app or login.
                  </Callout>
               </div>
            </div>
         )}
         <Table className="table-head-compressed borderless licence-payment-view-table licence-table">
            <TableBody>{generateTableRows()}</TableBody>
         </Table>
      </div>
   );
};

export default PaymentSuccessfulLicences;
