import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import * as EXAMINATION from 'pgdb-data-layer/lib/Constants/Examination';
import {
   Table,
   TableHead,
   TableHeaderCell,
   TableRow,
   TableBody,
} from '../../../../Components/GenericTable';
import TradesmanExamRow from './TradesmanExamRow';

const TradesmanExamTable = ({ examination }) => {
   const tradesExam = examination.userExaminations.filter(exam =>
      EXAMINATION.isTradesmanCourse(exam.course.courseNumber.trim())
   );

   if (isEmpty(examination.userExaminations) || !tradesExam.length) {
      return <></>;
   }

   return (
      <>
         <h5 className="subheader">Tradesman</h5>
         <Table>
            <colgroup>
               <col width="5%" />
               <col width="30%" />
               <col width="30%" />
               <col width="35%" />
            </colgroup>
            <TableHead>
               <TableRow>
                  <TableHeaderCell />
                  <TableHeaderCell className="no-left-pad">
                     Exam Type
                  </TableHeaderCell>
                  <TableHeaderCell>Download</TableHeaderCell>
                  <TableHeaderCell className="no-left-pad">
                     Register
                  </TableHeaderCell>
               </TableRow>
            </TableHead>
            <TableBody>
               {tradesExam.map((value, i) => {
                  return (
                     <TradesmanExamRow
                        examination={value}
                        key={value.examinationId}
                     />
                  );
               })}
            </TableBody>
         </Table>
      </>
   );
};

TradesmanExamTable.propTypes = {
   examination: PropTypes.any,
};

TradesmanExamTable.defaultProps = {
   examination: {},
};

const mapStateToProps = state => {
   return {
      examination: state.examination.examinations,
   };
};

export default connect(mapStateToProps)(TradesmanExamTable);
