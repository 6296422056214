import React from 'react';
import PropTypes from 'prop-types';

const CityDropdown = ({
   id,
   placeholder,
   disabled,
   value,
   onBlur = null,
   onChange = null,
   label = null,
   inputValid = true,
   mandatory = false,
   listDropdown = [],
}) => {
   const inputClasses = `browser-default form-control input-validation-error select-input${
      disabled ? 'input-disabled' : ''
   }${inputValid ? '' : 'input-invalid'}`;

   return (
      <>
         {label && (
            <span className="grey2-text input-header">
               {label}
               {mandatory && <span className="text-red"> *</span>}
            </span>
         )}
         <select
            className={inputClasses}
            id={id}
            placeholder={placeholder}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
         >
            <option key={''} value={''}>
               {'Select a City'}
            </option>
            {listDropdown.map((element, _) => {
               return (
                  <option key={element.value} value={element.value}>
                     {element.name}
                  </option>
               );
            })}
         </select>
      </>
   );
};

const { string, bool, func } = PropTypes;

CityDropdown.propTypes = {
   placeholder: string,
   id: string,
   value: string,
   disabled: bool,
   onBlur: func,
   onChange: func,
   label: string,
   inputValid: bool,
   mandatory: bool,
   listDropdown: [], // OptionDropdown[]
};

CityDropdown.defaultProps = {
   placeholder: '',
   id: '',
   value: '',
   disabled: false,
   onBlur: null,
   label: '',
   onChange: null,
   inputValid: true,
   mandatory: false,
   listDropdown: [],
};

export default CityDropdown;
