import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Moment from 'moment';
import SupervisionActions from 'pgdb-data-layer/lib/Redux/SupervisionRedux';
import Table from '../../../Components/GenericTable/Table';
import TableRow from '../../../Components/GenericTable/TableRow';
import TableCell from '../../../Components/GenericTable/TableCell';
import TableHeaderCell from '../../../Components/GenericTable/TableHeaderCell';
import TableHead from '../../../Components/GenericTable/TableHead';
import TableBody from '../../../Components/GenericTable/TableBody';
import TextLink from '../../../Components/TextLink';

const ApprovedSuperviseesTable = ({ tableData, dispatch }) => {
   const generateTableRows = () => {
      return (
         <>
            {tableData.map(value => {
               // If supervision has not started show the row as orange
               const color = Moment().isAfter(value.supervisionStarts)
                  ? 'black'
                  : 'orange';
               const colorDescription = Moment().isAfter(
                  value.supervisionStarts
               )
                  ? 'darkgrey'
                  : 'orange';

               return (
                  <TableRow key={value.supervisionId}>
                     <TableCell icon="check" color={color}>
                        {value.licenceNumber}
                     </TableCell>
                     <TableCell color={colorDescription}>
                        {value.name}
                     </TableCell>
                     <TableCell
                        className="small medium-font"
                        color={colorDescription}
                     >
                        {value.licenceDescription}
                     </TableCell>
                     <TableCell color={color}>
                        {Moment(value.supervisionStarts).format('DD/MM/YYYY')}
                     </TableCell>
                     <TableCell color={color}>
                        {Moment(value.supervisionEnds).format('DD/MM/YYYY')}
                     </TableCell>
                     <TableCell color={color}>
                        {Moment(value.licenceYearEnds).format('DD/MM/YYYY')}
                     </TableCell>
                     <TableCell color="activeblue">
                        <TextLink
                           onClick={() => {
                              dispatch(
                                 SupervisionActions.openModal('Change', value)
                              );
                           }}
                        >
                           Change
                        </TextLink>
                     </TableCell>
                  </TableRow>
               );
            })}
         </>
      );
   };
   return (
      <>
         <Table>
            <TableHead>
               <TableRow>
                  <TableHeaderCell>Licence. #</TableHeaderCell>
                  <TableHeaderCell className="no-left-pad">
                     Name
                  </TableHeaderCell>
                  <TableHeaderCell className="no-left-pad">
                     Type of Licence
                  </TableHeaderCell>
                  <TableHeaderCell className="no-left-pad">
                     Supervision Starts
                  </TableHeaderCell>
                  <TableHeaderCell className="no-left-pad">
                     Supervision Ends
                  </TableHeaderCell>
                  <TableHeaderCell className="no-left-pad">
                     Licence Year Ends
                  </TableHeaderCell>
                  <TableHeaderCell />
               </TableRow>
            </TableHead>

            <TableBody>{generateTableRows()}</TableBody>
         </Table>
      </>
   );
};

const { array } = PropTypes;

ApprovedSuperviseesTable.propTypes = {
   tableData: array.isRequired,
};

export default connect()(ApprovedSuperviseesTable);
