import React from 'react';
import { object } from 'prop-types';
import './Profile.scss';
import ProfileEntry from './ProfileEntry';

const ProfilePageHeader = () => {
   return (
      <div className="col s3 m6 l7 offset-l1">
         <div className="row profile-row">
            <div className="col l12">
               <p className="columnheader">My Profile</p>
            </div>
         </div>
      </div>
   );
};

const onSaveClicked = (onSave, onPostSave) => {
   let saved = false;
   if (onSave) saved = onSave();
   if (saved && onPostSave) onPostSave();
};

const footerGenerator = onPostSave => {
   return onSaveHandler => {
      return (
         <div className="col s3 m6 l6 offset-l1">
            <div className="row profile-row next-button-wrapper">
               <div className="col l12">
                  <button
                     type="button"
                     className="waves-effect waves-light btn"
                     onClick={() => onSaveClicked(onSaveHandler, onPostSave)}
                  >
                     Save
                  </button>
               </div>
            </div>
         </div>
      );
   };
};

const Profile = ({ location }) => {
   const returnUrl = new URLSearchParams(location.search).get('returnurl');
   return (
      <main>
         <div className="section no-pad-bot" id="index-banner">
            <ProfileEntry
               header={<ProfilePageHeader />}
               footer={footerGenerator()}
               location={location}
               routeBackUrl={returnUrl}
            />
         </div>
      </main>
   );
};

Profile.propTypes = {
   location: object.isRequired,
};

export default Profile;
