import React from 'react';
import { arrayOf, string } from 'prop-types';

import {
   DetailedRow,
   DetailedRowContent,
   SectionHeader,
} from './DetailedSearchRow';

const DisciplinaryHistoryRow = ({ complaint }) => {
   return (
      <DetailedRowContent>
         <div className="col s12">
            <span>{complaint}</span>
         </div>
      </DetailedRowContent>
   );
};

DisciplinaryHistoryRow.propTypes = {
   complaint: string.isRequired,
};

const DisciplinaryHistory = ({ disciplinaryHistory }) => {
   const complaintRows = disciplinaryHistory.map((h, i) => {
      return <DisciplinaryHistoryRow key={i} complaint={h} />;
   });

   return (
      <DetailedRow>
         <SectionHeader label="Disciplinary Findings" />
         {complaintRows}
      </DetailedRow>
   );
};

DisciplinaryHistory.propTypes = {
   disciplinaryHistory: arrayOf(string).isRequired,
};

export default DisciplinaryHistory;
