import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PaymentActions from 'pgdb-data-layer/lib/Redux/PaymentRedux';
import CompanyActions from 'pgdb-data-layer/lib/Redux/CompanyRenewalRedux';
import { CERTIFYING_LICENCE_TYPES } from 'pgdb-data-layer/lib/Constants/LicenceTypes';
import FileSaver from 'file-saver';
import M from 'materialize-css';
import Callout from '../../../../Components/Callout';
import { PaymentSuccessfulFooter } from './Views';
import PaymentSuccessfulLicences from '../../Shared/PaymentViews/PaymentSuccessfulLicences';
import * as StatusCode from '../../Shared/StatusCodes';
import Navigator from '../../../../Navigation/Navigator';
import TextLink from '../../../../Components/TextLink';
import './style.css';

class PaymentSuccessful extends Component {
   constructor(props) {
      super(props);
      this.state = {
         requestSubmitted: false,
         applicationRequested: false,
         applicationLoaded: false,
         recaptchaToken: '',
         renewedApplications: [],
         businessEmail: '',
         isDownloaded: false,
      };
   }

   componentDidMount() {
      const { location, getPaymentResponseRequest } = this.props;

      if (!this.state.requestSubmitted) {
         const result = new URLSearchParams(location.search).get('result');
         this.setState({ requestSubmitted: true }, () =>
            getPaymentResponseRequest(result)
         );
      }
   }

   componentDidUpdate(prevProps) {
      const {
         payment,
         getCompanyRenewalApplicationsRequest,
         renewedApplications,
         receiptDetails,
      } = this.props;
      const { recaptchaToken } = this.state;

      if (
         !this.state.applicationRequested &&
         payment &&
         payment.paymentResponse &&
         payment.paymentResponse.applicationIds
      ) {
         // call the GetRenewedApplications to retrieve all applications:
         const apps = payment.paymentResponse.applicationIds.toString();
         this.setState({ applicationRequested: true }, () =>
            getCompanyRenewalApplicationsRequest(
               { applications: apps },
               recaptchaToken
            )
         );
      }

      // populate the renewed applications
      if (
         !this.state.applicationLoaded &&
         renewedApplications &&
         renewedApplications.renewals &&
         renewedApplications.renewals.length
      ) {
         this.setState({
            applicationLoaded: true,
            renewedApplications: renewedApplications.renewals,
            businessEmail: renewedApplications.businessEmail,
         });
      }

      // download receipt
      if (receiptDetails && !this.state.isDownloaded) {
         this.setState({ isDownloaded: true }, () => this.downloadPDF());
      }
   }

   renderLicences(licences, section) {
      return (
         <div className="row">
            <div className="col s12 offset-l1">
               <span className="section-header sub-heading" />
               <PaymentSuccessfulLicences
                  tableData={licences}
                  sectionType={section}
               />
            </div>
         </div>
      );
   }

   getLicenceItem = (contact, applications) => {
      const item = {};
      item.contactId = contact.contactId;
      item.fullName = contact.fullName;
      item.registrationNumber = contact.registrationNumber;
      item.applications = applications;
      return item;
   };

   getReceiptDetails = () => {
      const { payment, getCompanyPaymentReceipt } = this.props;
      const { recaptchaToken } = this.state;

      if (payment && payment.paymentResponse) {
         const paymentReference = payment.paymentResponse.referenceNumber;
         this.setState({ isDownloaded: false }, () =>
            getCompanyPaymentReceipt({ paymentReference }, recaptchaToken)
         );
      } else {
         M.toast({
            html: `Sorry there seems to be an issue downloading your receipt at the moment.
            Please contact the Plumbers, Gasfitters and Drainlayers Board.`,
            classes: 'error',
         });
      }
   };

   downloadPDF = () => {
      const { receiptDetails } = this.props;
      if (!receiptDetails) {
         return;
      }
      const blob = new Blob([receiptDetails], {
         type: 'application/pdf',
      });

      FileSaver.saveAs(blob, 'Receipt.pdf');
   };

   render() {
      const activeLicences = [];
      const inActiveLicences = [];

      this.state.renewedApplications.forEach(c => {
         const activeApps = [];
         const inActiveApps = [];
         c.applications.forEach(a => {
            if (
               a.activeLicence &&
               ((a.hasSupervisorApproved &&
                  a.supervisorLicenceStatus ===
                     StatusCode.LICENCE_STATUS_ACTIVE) ||
                  CERTIFYING_LICENCE_TYPES[a.licenceTypeCode])
            ) {
               activeApps.push(a);
            } else {
               inActiveApps.push(a);
            }
         });
         if (activeApps.length) {
            activeLicences.push(this.getLicenceItem(c, activeApps));
         }
         if (inActiveApps.length) {
            inActiveLicences.push(this.getLicenceItem(c, inActiveApps));
         }
      });

      return (
         <main>
            <div
               className="section no-pad-bot payment-success"
               id="index-banner"
            >
               <div className="container">
                  <div className="row">
                     <div className="col s12 m12 offset-l1">
                        <p className="columnheader">Payment Complete</p>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col s12 offset-l1">
                        <Callout>
                           Thank you for your payment. We&apos;ve emailed you a
                           receipt but you can also{' '}
                           <TextLink onClick={() => this.getReceiptDetails()}>
                              download it here.
                           </TextLink>
                        </Callout>
                     </div>
                  </div>
                  {activeLicences &&
                     activeLicences.length > 0 &&
                     this.renderLicences(activeLicences, 1)}
                  {inActiveLicences &&
                     inActiveLicences.length > 0 &&
                     this.renderLicences(inActiveLicences, 2)}
                  <PaymentSuccessfulFooter
                     emailAddress={this.state.businessEmail}
                  />
                  <div className="row">
                     <div className="col s12 offset-l1">
                        <button
                           type="button"
                           className="waves-effect waves-light btn btn-inactive"
                           onClick={() => Navigator.toDashboard()}
                        >
                           Done
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </main>
      );
   }
}

PaymentSuccessful.propTypes = {
   payment: PropTypes.object.isRequired,
   getPaymentResponseRequest: PropTypes.func.isRequired,
   location: PropTypes.object.isRequired,
};

const mapState = state => ({
   payment: state.payment,
   renewedApplications: state.companyRenewal.renewedApplications,
   receiptDetails: state.companyRenewal.receiptDetails,
});

const mapDispatchToProps = {
   getPaymentResponseRequest: PaymentActions.getPaymentResponseRequest,
   getCompanyRenewalApplicationsRequest:
      CompanyActions.getCompanyRenewalApplicationsRequest,
   getCompanyPaymentReceipt: CompanyActions.getCompanyPaymentReceiptRequest,
};

export default connect(
   mapState,
   mapDispatchToProps
)(PaymentSuccessful);
