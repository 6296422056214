/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import RenewActions, {
   getRenewalCourseData,
} from 'pgdb-data-layer/lib/Redux/RenewalRedux';
import * as RENEWAL_HELPERS from 'pgdb-data-layer/lib/Helpers/Renewal';
import Callout from '../../Components/Callout';
import { getToken } from '../../Session/SessionRedux';

const CompanyBubbleContent = ({ year, yearRange, url }) => {
   return (
      <>
         Before you start your staff licence renewals for the {yearRange} year,
         please ensure that;
         <br />
         <ul className="bullet">
            <li>you have their licence numbers and their Supervisors</li>
            <li>
               and you have your companys debit/credit card or internet banking
               details
            </li>
         </ul>
      </>
   );
};

const IndividualBubbleContent = ({ year, yearRange, url }) => {
   return (
      <Callout>
         Before starting your licence renewal for the {yearRange} year, please
         ensure that you:
         <br />
         <ul className="bullet">
            <li>can provide the Licence Number of your Supervisor</li>
            <li>have your debit/credit card or internet banking details</li>
         </ul>
      </Callout>
   );
};

class Renew extends Component {
   constructor(props) {
      super(props);
      this.state = {
         isCompany: this.props.isCompany != null && this.props.isCompany,
      };
   }

   componentDidMount() {
      this.props.dispatch(
         RenewActions.getCourseUrlAndDateRequest(this.props.token)
      );
   }

   render() {
      const { renewalData } = this.props;
      const { isCompany } = this.state;

      const headerMessage = isCompany ? 'Welcome' : 'Licence Renewal';
      const nextUrl = isCompany
         ? '/renew-company-contact-details'
         : '/renew-contact-details';

      let year = '';
      let url = '';
      if (renewalData) {
         year = renewalData[0];
         url = renewalData[1];
      }
      const yearRange = RENEWAL_HELPERS.getYearRange(year);

      const bubbleContent = isCompany ? (
         <CompanyBubbleContent year={year} yearRange={yearRange} url={url} />
      ) : (
         <IndividualBubbleContent year={year} yearRange={yearRange} url={url} />
      );

      return (
         <main>
            <div className="section no-pad-bot" id="index-banner">
               <div className="container">
                  <div className="row">
                     <div className="col l5 offset-l1">
                        <h4 className="columnheader left">{headerMessage}</h4>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col l5 offset-l1">
                        {bubbleContent}
                        {isCompany && (
                           <Callout>
                              In this process you&apos;ll not be able to:
                              <br />
                              <ul className="cross">
                                 <li>check CPD requirements</li>
                                 <li>update a person&apos;s contact details</li>
                                 <li>accept Supervision</li>
                                 <li>answer fit and proper questions</li>
                              </ul>
                              <br />
                              Each individual must complete these actions
                              through their own logins
                           </Callout>
                        )}
                        <Link
                           to={nextUrl}
                           className="waves-effect waves-light btn"
                        >
                           Let&apos;s Get Started
                        </Link>
                     </div>
                  </div>
               </div>
            </div>
         </main>
      );
   }
}

const mapStateToProps = state => {
   return {
      userLicence: state.licence.licences,
      renewalData: getRenewalCourseData(state.renewal),
      token: getToken(state),
   };
};

export default connect(mapStateToProps)(Renew);
