export const GetIconPath = (status, discipline) => {
   const disabledStatuses = ['not renewed', 'pending', 'error'];
   const loweredStatus = status.toLowerCase();

   const prefix = `/images/icon-${discipline.trim().toLowerCase()}`;
   const suffix = disabledStatuses.includes(loweredStatus) ? 'black' : 'white';

   return `${prefix}-${suffix}.svg`;
};
export default GetIconPath;
