import React from 'react';

import Navigator from '../../Navigation/Navigator';

import './Profile/Profile.scss';
import CPD from './CPD';

const CPDFooter = () => {
   const previousUrl = '/renew-licences';
   const nextUrl = '/renew-supervisors';

   return (
      <div className="col s3 m6 l6 offset-l1">
         <div className="row profile-row next-button-wrapper">
            <div className="col l6">
               <button
                  type="button"
                  className="waves-effect waves-light btn btn-inactive"
                  onClick={() => {
                     Navigator.to(previousUrl);
                  }}
               >
                  Back
               </button>
               <button
                  type="button"
                  className="waves-effect waves-light btn btn-ml-30"
                  onClick={() => {
                     Navigator.to(nextUrl);
                  }}
               >
                  Next
               </button>
            </div>
         </div>
      </div>
   );
};

const RenewCPD = () => {
   return (
      <main>
         <div className="section no-pad-bot" id="index-banner">
            <CPD renew footer={<CPDFooter />} />
         </div>
      </main>
   );
};

export default RenewCPD;
