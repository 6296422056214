/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { arrayOf, func } from 'prop-types';

import { DefaultPagingModel } from 'pgdb-data-layer/lib/Helpers/Others/PagedQuery';
import {
   isFetchingPublicRegister,
   getPublicRegisterData,
} from 'pgdb-data-layer/lib/Redux/PublicRegisterRedux';

import Table from '../../../../Components/GenericTable/Table';
import TableRow from '../../../../Components/GenericTable/TableRow';
import TableHeaderCell from '../../../../Components/GenericTable/TableHeaderCell';
import TableHead from '../../../../Components/GenericTable/TableHead';
import TableBody from '../../../../Components/GenericTable/TableBody';
import TableFooter from '../../../../Components/GenericTable/TableFooter';

import { INITIAL_SEARCH_STATE } from '../data';
import SearchRow from './SearchRow';
import { contactResult } from '../types';
import TablePagination from '../../../../Components/TablePagination/Pagination';

const SearchResultTable = ({
   contacts,
   searchValue,
   dispatch,
   onChangeRowsPerPage,
   onChangePage,
   paging,
}) => {
   const [activeContact, setActiveContact] = useState(
      contacts[0].totalCount === 1
         ? contacts[0].publicRegisterContacts[0].registrationNumber
         : null
   );
   const contactRows = contacts[0].publicRegisterContacts.map(c => {
      return (
         <SearchRow
            key={c.registrationNumber}
            contact={c}
            showDetailed={activeContact === c.registrationNumber}
            onExpand={() => setActiveContact(c.registrationNumber)}
            onCollapse={() => setActiveContact(null)}
         />
      );
   });

   return (
      <div className="row">
         <div className="col s12">
            <Table className="pr-table">
               <TableHead className="pr-table-header">
                  <TableRow>
                     <TableHeaderCell className="pr-sticky-table-header" />
                     <TableHeaderCell className="no-left-pad pr-sticky-table-header">
                        Name
                     </TableHeaderCell>
                     <TableHeaderCell className="icon-left-pad pr-sticky-table-header">
                        Registration
                     </TableHeaderCell>
                     <TableHeaderCell className="icon-left-pad pr-sticky-table-header">
                        Licence(s)
                     </TableHeaderCell>
                  </TableRow>
               </TableHead>
               <TableBody>{contactRows}</TableBody>
               <TableFooter className="pr-table-header">
                  <TableRow>
                     <TablePagination
                        colspan={4}
                        contacts={contacts}
                        onChangeRowsPerPage={onChangeRowsPerPage}
                        onChangePage={onChangePage}
                        paging={paging}
                     />
                  </TableRow>
               </TableFooter>
            </Table>
         </div>
      </div>
   );
};

SearchResultTable.propTypes = {
   contacts: arrayOf(contactResult).isRequired,
   searchValue: arrayOf(INITIAL_SEARCH_STATE),
   dispatch: func.isRequired,
   onChangeRowsPerPage: func,
   onChangePage: func,
   paging: arrayOf(DefaultPagingModel),
};
const mapStateToProps = state => {
   return {
      contacts: getPublicRegisterData(state.publicRegister),
      isLoading: isFetchingPublicRegister(state),
   };
};

export default connect(mapStateToProps)(SearchResultTable);
