import React, { Fragment } from 'react';
import * as PUBLIC_REGISTER_HELPERS from 'pgdb-data-layer/lib/Helpers/PublicRegister';
import {
   DetailedRow,
   DetailedRowContent,
   SectionHeader,
} from './DetailedSearchRow';
import { contactDetailsType } from '../types';

const getAddress = contact => {
   const addrLines = PUBLIC_REGISTER_HELPERS.getAddressLines(contact);

   if (addrLines.length === 0) return null;

   return addrLines.map((item, i, addr) => {
      const hasBreak = i < addr.length - 1;
      return (
         <Fragment key={i}>
            {item}
            {hasBreak && <br />}
         </Fragment>
      );
   });
};

const ContactDetails = ({ contact }) => {
   const contactData = [];

   if (contact.publicPhone) {
      const phoneData = (
         <DetailedRowContent>
            <div className="col s12">
               <span className="bold">{contact.publicPhone}</span>
            </div>
         </DetailedRowContent>
      );
      contactData.push(phoneData);
   }

   if (contact.publicEmail) {
      const emailData = (
         <DetailedRowContent>
            <div className="col s12">{contact.publicEmail}</div>
         </DetailedRowContent>
      );
      contactData.push(emailData);
   }

   const address = getAddress(contact);
   if (address) {
      const addressContent = (
         <DetailedRowContent>
            <div className="col s12">{address}</div>
         </DetailedRowContent>
      );
      contactData.push(addressContent);
   }

   if (contactData.length === 0) return <></>;

   const contactElements = contactData.map((c, i) => {
      return <Fragment key={i}>{c}</Fragment>;
   });

   return (
      <DetailedRow>
         <SectionHeader label="Contact Details" />
         {contactElements}
      </DetailedRow>
   );
};

ContactDetails.propTypes = {
   contact: contactDetailsType.isRequired,
};

export default ContactDetails;
