import React from 'react';
import PropTypes from 'prop-types';

const TableHead = ({ className, children }) => {
   return <thead className={`${className}`}>{children}</thead>;
};

const { string, node } = PropTypes;

TableHead.defaultProps = {
   className: '',
};

TableHead.propTypes = {
   className: string,
   children: node.isRequired,
};

export default TableHead;
