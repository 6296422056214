import React from 'react';
import { string, arrayOf, bool, func } from 'prop-types';

import SearchResultHeader from './SearchResults/SearchResultHeader';
import SearchResultTable from './SearchResults/SearchResultTable';
import { INITIAL_SEARCH_STATE } from './data';
import { DefaultPagingModel } from 'pgdb-data-layer/lib/Helpers/Others/PagedQuery';

import { contactResult } from './types';

const SearchResults = ({
   searchType,
   searchValue,
   contacts,
   scrollToResults,
   onChangeRowsPerPage,
   onChangePage,
   paging,
}) => {
   var totalCount = 0;
   if (
      contacts != null &&
      contacts.length > 0 &&
      contacts[0].totalCount > 0 &&
      contacts[0].publicRegisterContacts.length > 0
   ) {
      totalCount = contacts[0].totalCount;
   }

   return (
      <>
         <SearchResultHeader
            searchType={searchType}
            searchValue={searchValue}
            count={totalCount}
            scrollToResults={scrollToResults}
         />
         {totalCount !== 0 && (
            <SearchResultTable
               contacts={contacts}
               searchValue={searchValue}
               dispatch
               onChangeRowsPerPage={onChangeRowsPerPage}
               onChangePage={onChangePage}
               paging={paging}
            />
         )}
      </>
   );
};

SearchResults.propTypes = {
   searchType: string.isRequired,
   searchValue: arrayOf(INITIAL_SEARCH_STATE),
   contacts: arrayOf(contactResult).isRequired,
   scrollToResults: bool.isRequired,
   dispatch: func.isRequired,
   onChangeRowsPerPage: func,
   onChangePage: func,
   paging: arrayOf(DefaultPagingModel),
};

SearchResults.defaultProps = {
   searchValue: '',
};

export default SearchResults;
