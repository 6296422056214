import React from 'react';

const SearchingPlaceholder = () => {
   return (
      <div className="row">
         <div className="col s12">
            <h4 className="columnheader pr-loading-spinner">Searching&hellip;</h4>
         </div>
      </div>
   );
};

export default SearchingPlaceholder;
