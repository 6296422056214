import React, { useEffect, useState } from 'react';
import './RegistrationReviewComponents.scss';
import { NavLink } from 'react-router-dom';
import CountryListMultiSelect from '../../../../Components/CountryListMultiSelect';

export const QUESTION_TYPE_COUNTRY = '13';

const RegistrationFitAndProperReview = ({ questionsAndAnswers }) => {
   const [selectedCountriesList, setSelectedCountriesList] = useState([]);
   const [answerData, setAnswerData] = useState({});

   useEffect(() => {
      const newAnswers = { ...answerData };
      // eslint-disable-next-line array-callback-return
      questionsAndAnswers.map((q, i) => {
         newAnswers[q.id] = {
            checkbox: q.latestAnswer,
            text: q.latestOtherAnswer,
         };
      });

      setAnswerData({ ...newAnswers });

      if (newAnswers && newAnswers[QUESTION_TYPE_COUNTRY]) {
         const textCountry = newAnswers[QUESTION_TYPE_COUNTRY].text;
         setSelectedCountriesList(
            textCountry && textCountry !== null ? JSON.parse(textCountry) : []
         );
      }
   }, [questionsAndAnswers]);

   const displayExtraComponent = id => {
      // reusing display country component from FitAndProper page with prop readOnly
      if (
         id === +QUESTION_TYPE_COUNTRY &&
         answerData[id] &&
         answerData[id].checkbox
      ) {
         return (
            <CountryListMultiSelect
               selectedCountries={selectedCountriesList}
               readOnly
            />
         );
      }
      return undefined;
   };

   return (
      <div className="registration-review-container">
         <div className="review-heading">
            <h6 className="review-title">FIT AND PROPER PERSON INFORMATION</h6>
            <div className="review-edit-section">
               <NavLink
                  to="/registration-fit-and-proper"
                  className="review-edit-button"
               >
                  <i className="material-icons edit-icon">create</i>
                  EDIT
               </NavLink>
            </div>
         </div>
         <div className="review-details">
            {questionsAndAnswers.map((question, index) => {
               const answer = question.latestAnswer;
               const answerValue =
                  answer === true ? 'Yes' : answer === false ? 'No' : '';

               return (
                  <div>
                     <p className="fit-and-proper-review-questions">
                        <span className="fit-and-proper-review-index">
                           {index + 1}
                           {`)`}
                        </span>

                        {question.questionText}
                     </p>
                     <p className="fit-and-proper-review-answers">
                        {answerValue}
                     </p>
                     {displayExtraComponent(question.id)}
                  </div>
               );
            })}
         </div>
      </div>
   );
};

export default RegistrationFitAndProperReview;
