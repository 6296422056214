import React from 'react';
import { connect } from 'react-redux';
import { any, string } from 'prop-types';
import Moment from 'moment';
import ExaminationActions from 'pgdb-data-layer/lib/Redux/ExaminationRedux';
import Navigator from '../../../../Navigation/Navigator';
import TableRow from '../../../../Components/GenericTable/TableRow';
import TableCell from '../../../../Components/GenericTable/TableCell';
import Dropdown from '../../../../Components/Dropdown';
import { getToken } from '../../../../Session/SessionRedux';

class CertifierSelectionRow extends React.Component {
   constructor(props) {
      super(props);
      this.state = { checked: false, selectedVenue: 0 };
      this.handleChange = this.handleChange.bind(this);
   }

   componentDidMount() {
      const { examination, selectionDetails, venues } = this.props;

      const exam = selectionDetails.filter(
         c => c.examinationId === examination.examinationId
      )[0];

      if (exam) {
         const venueIndex = venues.findIndex(
            c => c.examinationVenueId === exam.examinationVenueId
         );

         this.setState({ checked: true });
         this.setState({ selectedVenue: venueIndex });
      }
   }

   handleChange(e) {
      const { checked, selectedVenue } = this.state;
      const { dispatch, examination, selectionDetails, venues } = this.props;
      const myData = {
         examinationId: examination.examinationId,
         examinationVenueId: venues[selectedVenue].examinationVenueId,
      };

      let currentData = selectionDetails.asMutable();
      if (!checked) {
         currentData.push(myData);
      } else {
         this.setState({ selectedVenue: 0 });
         currentData = currentData.filter(
            obj => obj.examinationId !== myData.examinationId
         );
      }
      dispatch(ExaminationActions.saveExamSelectionDetails(currentData));
      this.setState({ checked: !checked });
   }

   selectionChange(e) {
      const { dispatch, examination, selectionDetails, venues } = this.props;
      const { value } = e.target;

      this.setState({ selectedVenue: value });

      const myData = {
         examinationId: examination.examinationId,
         examinationVenueId: venues[value].examinationVenueId,
      };

      // Clone
      let currentData = selectionDetails.asMutable();
      // Remove exsisting
      currentData = currentData.filter(
         obj => obj.examinationId !== myData.examinationId
      );
      // Add edited
      currentData.push(myData);
      // Push changes
      dispatch(ExaminationActions.saveExamSelectionDetails(currentData));
   }

   next() {
      Navigator.to('/exam-delivery-address');
   }

   render() {
      const { checked, selectedVenue } = this.state;
      const { examination, venues } = this.props;
      const examVenues = venues.map(venue => venue.examinationVenue);

      return (
         <TableRow>
            <td className="table-data small status-cell">
               <label>
                  {examination.isBookable && (
                     <input
                        type="checkbox"
                        className="filled-in checkbox-blue"
                        onChange={this.handleChange}
                        checked={checked}
                     />
                  )}
                  <span />
               </label>
            </td>
            <TableCell>{examination.course.courseName}</TableCell>
            <TableCell>
               {Moment(examination.examinationDate).format('DD MMMM YYYY')}
            </TableCell>
            <TableCell>
               {checked && examVenues && (
                  <Dropdown
                     value={selectedVenue}
                     values={examVenues}
                     onChange={e => this.selectionChange(e)}
                     placeholder="Select a Venue"
                  />
               )}
            </TableCell>
            <TableCell className="center">
               {checked && (
                  <button
                     className="btn-small"
                     disabled={!checked}
                     onClick={() => this.next()}
                     type="submit"
                  >
                     Next
                  </button>
               )}
            </TableCell>
         </TableRow>
      );
   }
}

CertifierSelectionRow.propTypes = {
   examination: any,
   selectionDetails: any,
   token: string.isRequired,
};

CertifierSelectionRow.defaultProps = {
   examination: {},
   selectionDetails: [],
};

const mapStateToProps = state => {
   return {
      venues: state.examination.availableVenues,
      selectionDetails: state.examination.examSelectionDetails,
      cycles: state.examination.cycles,
      token: getToken(state),
   };
};

export default connect(mapStateToProps)(CertifierSelectionRow);
