import React, { Component } from 'react';
import { connect } from 'react-redux';
import M from 'materialize-css';
import './Profile.scss';
import * as PROFILE_HELPERS from 'pgdb-data-layer/lib/Helpers/Profile';
import UserActions from 'pgdb-data-layer/lib/Redux/UserInfoRedux';
import {
   FILE_EXTENSION,
   MAX_FILE_SIZE_IN_BYTE,
   MAX_FILE_SIZE_IN_MB,
   MIN_PHOTO_WIDTH,
   MAX_PHOTO_WIDTH,
   MIN_PHOTO_HEIGHT,
   MAX_PHOTO_HEIGHT,
} from 'pgdb-data-layer/lib/Constants/User';
import Navigator from '../../../Navigation/Navigator';
import Callout from '../../../Components/Callout';
import { getToken } from '../../../Session/SessionRedux';

class ProfilePhotoCriteria extends Component {
   constructor(props) {
      super(props);
      this.state = {
         buttonVisible: false,
      };

      this.inputOpenFileRef = React.createRef();
   }

   componentDidMount() {
      this.setState({
         buttonVisible: false,
      });
   }

   checkChange = e => {
      this.setState({
         buttonVisible: e.target.checked,
      });
   };

   showOpenFileDlg = () => {
      this.inputOpenFileRef.current.click();
   };

   fileValueChanged = async e => {
      const { photoSelected } = this.props;
      const file = e.target.files[0];
      if (file) {
         if (await this.validateFile(file)) {
            photoSelected(URL.createObjectURL(file));
            Navigator.to({
               pathname: '/profile-photo-editor',
               returnUrl:
                  this.props.location && this.props.location.returnUrl
                     ? this.props.location.returnUrl
                     : '',
            });
         }
      }
   };

   validateFile = async file => {
      const fileExtension = file.name
         .substring(file.name.lastIndexOf('.') + 1)
         .toLowerCase();
      if (FILE_EXTENSION.indexOf(fileExtension) < 0) {
         M.toast({
            html: `The selected file is not supported. Please make sure that the file extension must be ${PROFILE_HELPERS.getSupportedFileExtension()}.`,
            classes: 'error',
         });
         return false;
      }

      if (file.size > MAX_FILE_SIZE_IN_BYTE) {
         M.toast({
            html: `File size must be less than ${MAX_FILE_SIZE_IN_MB}MB. Please select again.`,
            classes: 'error',
         });
         return false;
      }

      // check the image size
      const img = await PROFILE_HELPERS.loadImage(URL.createObjectURL(file));

      if (
         img.naturalWidth < MIN_PHOTO_WIDTH ||
         img.naturalWidth > MAX_PHOTO_WIDTH ||
         img.naturalHeight < MIN_PHOTO_HEIGHT ||
         img.naturalHeight > MAX_PHOTO_HEIGHT
      ) {
         M.toast({
            html: `Image size should be between ${MIN_PHOTO_WIDTH}x${MIN_PHOTO_HEIGHT} to ${MAX_PHOTO_WIDTH}x${MAX_PHOTO_HEIGHT} pixels`,
            classes: 'error',
         });
         return false;
      }

      return true;
   };

   render() {
      const { buttonVisible } = this.state;

      return (
         <main>
            <div
               className="section no-pad-bot photo-upload-section"
               id="index-banner"
            >
               <div className="container">
                  <div className="row profile-row">
                     <div className="col s12 m12 l7 offset-l1">
                        <div className="row profile-row">
                           <div className="col l12">
                              <p className="columnheader">My Profile</p>
                           </div>
                        </div>
                     </div>
                     <div className="col s12 m12 l6 offset-l1">
                        <div className="row">
                           <div className="col l12">
                              <span className="section-header sub-heading">
                                 Upload Photo
                              </span>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="row profile-row small">
                     <div className="col s12 m12 l4 offset-l1">
                        <Callout>
                           <>
                              <p>
                                 Before you upload, please ensure that your
                                 photo meets the following criteria:
                              </p>
                              <ul className="bullet">
                                 <li>
                                    Portrait head and shoulder shot (like a
                                    passport photo)
                                 </li>
                                 <li>Taken within the last 6 months</li>
                                 <li>
                                    Face in full view (no hats, hoods, glasses
                                    or sunglasses)
                                 </li>
                                 <li>
                                    Plain background without shadows (best to
                                    take it in daylight to ensure a clear image)
                                 </li>
                                 <li>
                                    Photos must be:{' '}
                                    {PROFILE_HELPERS.getSupportedFileExtension()}{' '}
                                    files less than {MAX_FILE_SIZE_IN_MB}MB; and
                                    a minimum of {MIN_PHOTO_WIDTH} pixels wide x{' '}
                                    {MIN_PHOTO_HEIGHT} pixels high
                                 </li>
                              </ul>
                           </>
                        </Callout>
                     </div>
                  </div>
                  <div className="row tnc-row">
                     <div className="col s12 m12 l4 offset-l1">
                        <label>
                           <input
                              onChange={this.checkChange}
                              type="checkbox"
                              className="filled-in checkbox-blue"
                           />
                           <span className="black-text">
                              I confirm that this photo is a true likeness of
                              myself and I understand it will be subject to
                              manual assessment by a PGDB staff member
                           </span>
                        </label>
                     </div>
                  </div>
                  <div className="row control-row">
                     <div className="col s12 m12 l4 offset-l1">
                        <input
                           ref={this.inputOpenFileRef}
                           type="file"
                           style={{ display: 'none' }}
                           onChange={this.fileValueChanged}
                        />
                        <button
                           type="button"
                           className="waves-effect waves-light btn"
                           style={{ display: buttonVisible ? 'block' : 'none' }}
                           onClick={this.showOpenFileDlg}
                        >
                           Choose a Photo
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </main>
      );
   }
}

const mapStateToProps = state => {
   return {
      token: getToken(state),
      profilePhoto: state.profilePhoto,
   };
};

const mapDispatchToProps = {
   photoSelected: UserActions.setUserPhotoSelection,
};

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(ProfilePhotoCriteria);
