import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';

import RegistrationActions, {
   isFetchingRegistration,
   getRegistrationFile,
   getRegistrationFilesByApplication,
   getAnotherFilesToUploadRequest,
   filesToBeUpload,
   isFetchingRegistrationSuccess,
   isFetchingRegistrationFailure,
} from 'pgdb-data-layer/lib/Redux/RegistrationRedux';
import FileSaver from 'file-saver';
import M from 'materialize-css';
import Loading from '../../../Components/Loading';
import FileUpload from '../../../Components/FileUpload';
import { getToken } from '../../../Session/SessionRedux';
import Icon from '../../../Components/Icon';
import './RegistrationPendingDocuments.scss';
import TextLink from '../../../Components/TextLink';
import Navigator from '../../../Navigation/Navigator';

const OTHER_DOCUMENTS_SECTION = 4;

const RegistrationPendingDocuments = ({
   token,
   getUserRegistrationRequest,
   deleteRegistrationFileRequest,
   getAnotherFilesToUploadRequest,
   isFetchingFilesToBeUpload,
   filesToBeUpload,
   submitAnotherFilesToUploadRequest,
   getRegistrationFileDownloadRequest,
   getRegistrationFileDownloadReset,
   isFetchingFiles,
   userRegistration,
   isDownloaded,
   fileDownloaded,
   isFileDeleted,
   isUploaded,
   isUploadFailed,
   isDeletingFile,
   isDownloadingFile,
   isUploadingFile,
}) => {
   const [hasDispatched, setHasDispatched] = useState(false);
   const [pageReload, setPageReload] = useState(true);
   const [saveFile, setSaveFile] = useState(true);
   const [isUploading, setIsUploading] = useState(false);
   const [isDeleting, setIsDeleting] = useState(false);
   const [isDownloading, setIsDownloading] = useState(false);
   const [processingFileId, setProcessingFileId] = useState(0);
   const [uploadingSectionId, setUploadingSectionId] = useState([]);
   const [isUploadTriggered, setIsUploadTriggered] = useState(false);
   const [isHovering, setIsHovering] = useState(false);
   const [registrationDocument, setRegistrationDocument] = useState([]);
   const [hasUploadedFile, setHasUploadedFile] = useState(false);

   const { applicationId } = useParams();

   useEffect(() => {
      if (applicationId) {
         getAnotherFilesToUploadRequest(token, applicationId);
      }
   }, [applicationId]);

   useEffect(() => {
      if (filesToBeUpload) {
         setRegistrationDocument(filesToBeUpload);
      }
   }, [filesToBeUpload]);

   useEffect(() => {
      if (pageReload) {
         getUserRegistrationRequest(token);
         setPageReload(false);
      }
      setHasDispatched(true);
   }, [token, pageReload]);

   useEffect(() => {
      setIsDeleting(isDeletingFile);
   }, [isDeletingFile]);

   useEffect(() => {
      setIsDownloading(isDownloadingFile);
   }, [isDownloadingFile]);

   useEffect(() => {
      setIsUploading(isUploadingFile);
   }, [isUploadingFile]);

   useEffect(() => {
      if (isFileDeleted) {
         getUserRegistrationRequest(token);
      }
   }, [isFileDeleted]);

   useEffect(() => {
      if (isDownloaded && saveFile) {
         saveFileLocal();
         setProcessingFileId(0);
         setSaveFile(false);
      }
   }, [isDownloaded, saveFile]);

   useEffect(() => {
      if (isUploaded && isUploadTriggered) {
         M.toast({
            html: 'File Uploaded Successfully',
            classes: 'success',
         });
         getUserRegistrationRequest(token);
         setUploadingSectionId([]);
      }
   }, [isUploaded, isUploadTriggered]);

   useEffect(() => {
      if (isUploadFailed && isUploadTriggered) {
         M.toast({
            html: 'File Uploaded Failed',
            classes: 'error',
         });
         setIsUploadTriggered(false);
      }
   }, [isUploadFailed, isUploadTriggered]);

   const anyScanFailed = () => {
      if (
         userRegistration.registration &&
         userRegistration.registration.applicationFiles
      ) {
         const found = userRegistration.registration.applicationFiles.find(
            f => f.isFileScanFailed === true
         );
         if (found) {
            return true;
         }
      }

      return false;
   };

   const getFilesUploaded = () => {
      if (
         userRegistration.registration &&
         userRegistration.registration.applicationFiles
      ) {
         const applicationFiles =
            userRegistration.registration.applicationFiles;
         return applicationFiles.filter(
            f =>
               f.fileTypeID === OTHER_DOCUMENTS_SECTION &&
               f.hasBeenSubmitted === false
         );
      }
   };

   const onFileUploaded = () => {
      setIsUploadTriggered(true);
   };

   const StartDownload = fileId => {
      setProcessingFileId(fileId);
      getRegistrationFileDownloadRequest(token, fileId);
      setSaveFile(true);
   };

   const saveFileLocal = () => {
      if (fileDownloaded) {
         FileSaver.saveAs(fileDownloaded);
         getRegistrationFileDownloadReset();
         setPageReload(true);
      }
   };

   const deleteFile = (fileId, fileName, isFileScanFailed) => {
      if (isUploadingFile || isDeletingFile) return;

      if (!isFileScanFailed) {
         if (
            window.confirm(`Do you want to delete the file : ${fileName}`) !==
            true
         ) {
            return;
         }
      }
      setProcessingFileId(fileId);
      deleteRegistrationFileRequest(token, fileId);
   };

   const onFileSelected = file => {
      const uploadedFiles = getFilesUploaded();

      if (uploadedFiles && uploadedFiles.length >= 10) {
         M.toast({
            html: `You have reached maximum number of files allowed.`,
            classes: 'error',
         });
         return false;
      }

      if (uploadedFiles) {
         const duplicateFile = uploadedFiles.find(
            f => f.fileName === file.name
         );

         if (duplicateFile) {
            M.toast({
               html: `File '${file.name}' is already uploaded`,
               classes: 'error',
            });
            return false;
         }
      }

      setUploadingSectionId(uploadingSectionId => [
         ...uploadingSectionId,
         OTHER_DOCUMENTS_SECTION,
      ]);
      return true;
   };

   useEffect(() => {
      const uploadedItems = getFilesUploaded();

      if (uploadedItems) {
         if (uploadedItems.length !== 0) {
            setHasUploadedFile(true);
         } else if (uploadedItems.length === 0) {
            setHasUploadedFile(false);
         }
      } else {
         setHasUploadedFile(false);
      }
   }, [getFilesUploaded, deleteFile]);

   const renderDocumentsToUpload = () => {
      const uploadedItems = getFilesUploaded();

      return (
         <div className="upload-section">
            <div className="upload-document-container">
               <div className="col document-type-container m12 s12">
                  <p className="section-title">
                     <ol>
                        {registrationDocument.map(document => (
                           <li>{document.description} </li>
                        ))}
                     </ol>
                  </p>
               </div>
               <div className="col m7 s7">
                  <FileUpload
                     onFileUploaded={onFileUploaded}
                     onFileSelected={file => onFileSelected(file)}
                     sectionId={OTHER_DOCUMENTS_SECTION}
                     isUploadingFile={
                        uploadingSectionId.includes(OTHER_DOCUMENTS_SECTION)
                           ? isUploading
                           : false
                     }
                  />
               </div>
            </div>
            <div className="uploaded-files">
               {uploadedItems &&
                  uploadedItems.map(item => {
                     return (
                        <>
                           <div
                              className={`uploaded-file-item ${
                                 item.isFileScanFailed ? ' border-red' : ''
                              }`}
                           >
                              <span
                                 className="uploaded-file-icon-and-name"
                                 onClick={() =>
                                    !item.isFileScanFailed &&
                                    StartDownload(item.fileID)
                                 }
                              >
                                 <span>
                                    {processingFileId === item.fileID &&
                                    (isDownloading || isDeleting) ? (
                                       <button className="loading-button" />
                                    ) : (
                                       <Icon
                                          className="attach-icon"
                                          iconName={`${
                                             !item.isFileScanFailed
                                                ? 'attach_file'
                                                : 'warning'
                                          }`}
                                       />
                                    )}
                                 </span>
                                 {item.isFileScanFailed ? (
                                    <span>{item.fileName}</span>
                                 ) : (
                                    <TextLink
                                       id={`download-link-${item.fileID}`}
                                       className="file-name link"
                                    >
                                       {item.fileName}
                                    </TextLink>
                                 )}
                              </span>
                              <span className="uploaded-file-delete right">
                                 <Icon
                                    className={`${
                                       isUploadingFile || isDeletingFile
                                          ? 'delete-button disabled right'
                                          : ' delete-button right'
                                    }`}
                                    iconName="delete"
                                    onClick={() =>
                                       deleteFile(
                                          item.fileID,
                                          item.fileName,
                                          item.isFileScanFailed
                                       )
                                    }
                                 />
                              </span>
                           </div>
                           {item.isFileScanFailed && (
                              <div className="invalid-file">
                                 Invalid File! Please delete and upload the
                                 valid file.
                              </div>
                           )}
                        </>
                     );
                  })}
            </div>
         </div>
      );
   };

   const supportingDocumentsRequired = () => {
      return (
         <div className="important-note">
            <div className="important-note-header">
               <b>Document format</b>
            </div>
            <div className="important-note-content">
               <p>
                  Format: PDF, PNG, JPEG, JPG <br /> Size: Maximum 10MB
               </p>
               <p className="application-fee-title">
                  What is a certified copy?
               </p>
               <p>{`A certified copy of a document has been declared as correct. You will need to take
               your qualification to a lawyer, Justice of the Peace(JP) or a Court Registrar and have 
               them certify it.`}</p>

               <p>{`They will check the photocopy against the original, and then sign the photocopy to say
               it is true and correct copy of the original. This service is usually free of charge.`}</p>

               <p>{`All certified copies must include the date, name, occupation and signature of the 
               JP/lawyer/Court Registrar certifying the document.`}</p>

               <p className="important-note-footer">
                  If you need help, call us on our free phone{' '}
                  <span className="important-note-phone">
                     <a href="tel:0800743262" rel="nofollow">
                        0800 743 262
                     </a>{' '}
                  </span>
                  or email{' '}
                  <a
                     className="activeblue-text important-note-email"
                     href="mailto:registration@pgdb.co.nz"
                  >
                     registration@pgdb.co.nz
                  </a>
               </p>
            </div>
         </div>
      );
   };

   const submitUploadDocuments = () => {
      submitAnotherFilesToUploadRequest(token, applicationId);
      Navigator.toDashboard();
   };

   return (
      <main onClick={() => (isHovering ? setIsHovering(false) : null)}>
         <Loading
            isLoading={
               !hasDispatched && isFetchingFilesToBeUpload && isFetchingFiles
            }
         >
            <div className="section no-pad-bot" id="index-banner">
               <div className="container registration-container">
                  <div className="row no-margin flex">
                     <div className="col l9 m8 s12">
                        <div className="col m8 offset-l1 s11">
                           <h4 className="columnheader upload-document-header">
                              Please upload additional documents
                           </h4>
                        </div>
                        <div className="col m10 l10 offset-l1 s11">
                           <div>
                              <p>
                                 Your application is pending due to missing
                                 document(s). Please upload the following
                                 document(s).
                              </p>
                           </div>
                           <div className="registration-list">
                              {renderDocumentsToUpload()}
                           </div>

                           <div className="row profile-row next-button-registration right">
                              <div className="navigate-buttons">
                                 <button
                                    type="button"
                                    disabled={
                                       isUploadingFile ||
                                       isDeletingFile ||
                                       anyScanFailed() ||
                                       !hasUploadedFile
                                    }
                                    className="waves-effect waves-light button-margin btn"
                                    onClick={submitUploadDocuments}
                                 >
                                    SUBMIT
                                 </button>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col l3 m4 side-note-background">
                        {supportingDocumentsRequired()}
                     </div>
                  </div>
               </div>
            </div>
         </Loading>
      </main>
   );
};

const mapStateToProps = state => {
   return {
      registrationFile: getRegistrationFile(state),
      registrationFilesByApplication: getRegistrationFilesByApplication(state),
      token: getToken(state),
      userRegistration: state.registration,
      isFetchingRegistration: isFetchingRegistration(
         'getUserRegistration',
         state
      ),
      isUploadingFile: isFetchingRegistration('putRegistrationFile', state),
      isDeletingFile: isFetchingRegistration('deleteRegistrationFile', state),
      isDownloadingFile: isFetchingRegistration(
         'getRegistrationFileDownload',
         state
      ),
      isUploaded: isFetchingRegistrationSuccess('putRegistrationFile', state),
      isUploadFailed: isFetchingRegistrationFailure(
         'putRegistrationFile',
         state
      ),
      isDownloaded: isFetchingRegistrationSuccess(
         'getRegistrationFileDownload',
         state
      ),
      isFileDeleted: isFetchingRegistrationSuccess(
         'deleteRegistrationFile',
         state
      ),
      fileDownloaded: state.registration.registrationFileDownload,
      filesToBeUpload: filesToBeUpload(state),
      isFetchingFilesToBeUpload: isFetchingRegistration(
         'getAnotherFilesToUpload',
         state
      ),
      isFetchingFiles: isFetchingRegistration(
         'getRegistrationFilesByApplication',
         state
      ),
   };
};

const mapDispatchToProps = {
   getRegistrationFileRequest: RegistrationActions.getRegistrationFileRequest,
   getRegistrationFilesByApplicationRequest:
      RegistrationActions.getRegistrationFilesByApplicationRequest,
   putRegistrationFileRequest: RegistrationActions.putRegistrationFileRequest,
   getUserRegistrationRequest: RegistrationActions.getUserRegistrationRequest,
   getRegistrationFileDownloadRequest:
      RegistrationActions.getRegistrationFileDownloadRequest,
   getRegistrationFileDownloadReset:
      RegistrationActions.getRegistrationFileDownloadReset,
   deleteRegistrationFileRequest:
      RegistrationActions.deleteRegistrationFileRequest,
   getAnotherFilesToUploadRequest:
      RegistrationActions.getAnotherFilesToUploadRequest,
   submitAnotherFilesToUploadRequest:
      RegistrationActions.submitAnotherFilesToUploadRequest,
};

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(RegistrationPendingDocuments);
