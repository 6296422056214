import React from 'react';
import { connect } from 'react-redux';
import { string, object } from 'prop-types';
import moment from 'moment';
import * as REGISTRATION from 'pgdb-data-layer/lib/Constants/Registration';
import * as CONTACT from 'pgdb-data-layer/lib/Constants/Contact';
import Icon from '../../../Components/Icon';

const ApplicationSection = ({
   disciplineCode,
   userRegistration,
   statusCode,
   disciplineName,
}) => {
   if (
      !userRegistration ||
      !userRegistration.registration ||
      !userRegistration.registration.nextRegistrationLevelRequirements
   )
      return <></>;

   const nextLevelRegistration = userRegistration.registration.nextRegistrationLevelRequirements.find(
      reg => reg !== null && reg.disciplineCode === disciplineCode
   );

   if (!nextLevelRegistration) return <></>;

   if (
      !nextLevelRegistration.licenceTypeCode &&
      userRegistration.registration.registrations
   ) {
      // user doesn't have a next licence to reach and is deemed a certifier
      const certifiedRegistration = userRegistration.registration.registrations.find(
         reg =>
            reg.registrationDiscipline === disciplineCode &&
            reg.registrationTypeCode.includes('C')
      );
      if (!certifiedRegistration) return <></>;

      let disciplineImage = 'images/icon-plumber-white.svg';
      if (disciplineCode === REGISTRATION.DISCIPLINE_CODE_DRAINLAYER) {
         disciplineImage = 'images/icon-drainlayer-white.svg';
      } else if (disciplineCode === REGISTRATION.DISCIPLINE_CODE_GASFITTER) {
         disciplineImage = 'images/icon-gasfitter-white.svg';
      }
      return (
         <div className="row complete">
            <div className="col s11 offset-l1">
               <div className="certifier-complete">
                  <h3>Congratulations</h3>
                  <div className="trophy-img-section">
                     <img
                        className="trophy-img"
                        alt="Trophy"
                        src="images/Trophy.svg"
                     />
                     <img
                        className="discipline-img"
                        alt="Discipline"
                        src={disciplineImage}
                     />
                  </div>
                  <p>you are a </p>
                  <p className="registration-congratulations-text">
                     {certifiedRegistration.registrationDescription} as of
                  </p>
                  <p className="registration-congratulations-text">
                     {moment(certifiedRegistration.registeredDate).format(
                        'DD/MM/YYYY'
                     )}
                  </p>
               </div>
            </div>
         </div>
      );
   }

   const registrationRequirements = () => {
      if (!nextLevelRegistration.requirements) return <></>;
      return nextLevelRegistration.requirements.map(requirement => {
         return (
            <div className="row" key={requirement.name + disciplineCode}>
               <div className="col s2">
                  {requirement.isMet !== null ? (
                     <Icon
                        className={`round small ${
                           requirement.isMet ? 'light-green-text' : 'red-text'
                        }`}
                        iconName={requirement.isMet ? 'check' : 'clear'}
                     />
                  ) : (
                     <Icon className="round smaller" iconName="brightness_1" />
                  )}
               </div>
               <div className="col s10">
                  <p>{requirement.name}</p>
               </div>
            </div>
         );
      });
   };

   return statusCode === CONTACT.REGISTRATION_SUSPENDED_STATUS_CODE ? (
      <div className="row complete">
         <div className="col s11 offset-l1">
            <div className="certifier-suspended">
               <h3>
                  Your {disciplineName} registration is currently suspended
               </h3>
            </div>
         </div>
      </div>
   ) : statusCode === CONTACT.REGISTRATION_CANCELLED_STATUS_CODE ? (
      <div className="row complete">
         <div className="col s11 offset-l1">
            <div className="certifier-suspended">
               <h3>Your {disciplineName} registration has been cancelled</h3>
            </div>
         </div>
      </div>
   ) : (
      <div className="row">
         <div className="col s10 offset-l2">
            <p className="application-heading">What do you need to become a</p>
            <p className="application-name">
               {nextLevelRegistration.licenceTypeDescription}
            </p>
         </div>
         <div className="col s12 application-condition">
            {registrationRequirements()}
         </div>
         <div className="col s11 offset-l1" />
      </div>
   );
};

ApplicationSection.propTypes = {
   disciplineCode: string.isRequired,
   userRegistration: object,
   statusCode: string,
   disciplineName: string,
};

const mapStateToProps = state => {
   return {
      userRegistration: state.registration,
   };
};

export default connect(mapStateToProps)(ApplicationSection);
