import React, { Component } from 'react';
import { connect } from 'react-redux';
import AvatarEditor from 'react-avatar-editor';
import M from 'materialize-css';
import UserActions, {
   isFetchingUserInfo,
   isFetchingUserInfoSuccess,
   isFetchingUserInfoFailure,
} from 'pgdb-data-layer/lib/Redux/UserInfoRedux';
import {
   MIN_PHOTO_WIDTH,
   MAX_PHOTO_WIDTH,
   MIN_PHOTO_HEIGHT,
   MAX_PHOTO_HEIGHT,
} from 'pgdb-data-layer/lib/Constants/User';
import * as PROFILE_HELPERS from 'pgdb-data-layer/lib/Helpers/Profile';
import { isEqual } from 'lodash';
import LoadingScreen from 'react-loading-screen';
import Navigator from '../../../../Navigation/Navigator';
import ModalDialog from '../../../../Components/ModalDialog';
import Icon from '../../../../Components/Icon';
import * as PROFILE from './ProfilePhotoEditorConstants';
import LicenceCardBack from '../../../../Components/LicenceIdCard/LicenceCardBack';
import '../../../../Utilities/canvas-toBlob';
import './ProfilePhotoEditor.scss';
import { getToken } from '../../../../Session/SessionRedux';

const zoomMin = 1;
const zoomMax = 5;
const zoomIncrement = 0.2;
const rotateIncrement = 90;
const initialHeight = 277;
const initialWidth = 232;
const border = 51.5;

class ProfilePhotoEditor extends Component {
   setEditorRef = editor => (this.editor = editor);

   constructor(props) {
      super(props);
      this.state = {
         zoomVal: zoomMin,
         rotateVal: 0,
         height: initialHeight,
         width: initialWidth,
         isModalOpen: false,
         isPreviewOpen: false,
         saveClicked: false,
         person: {},
      };
   }

   componentDidMount() {
      const { user } = this.props;

      // route back to the profile page if photo is empty
      if (!user.editProfilePhoto || !user.editProfilePhoto.file) {
         Navigator.toProfile();
      }
   }

   componentDidUpdate(prevProps) {
      if (isEqual(this.props, prevProps)) {
         return;
      }
      // check the photo uplaode status
      const { isSaved, isFailed } = this.props;
      if (isSaved) {
         this.setState({ isModalOpen: true });
      } else if (isFailed) {
         M.toast({
            html: `Error uploading image! `,
            classes: 'error',
         });
         this.setState({ saveClicked: false });
      }
   }

   zoom = val => {
      const { zoomVal } = this.state;

      if ((val < 0 && zoomVal <= zoomMin) || (val > 0 && zoomVal >= zoomMax)) {
         return;
      }
      this.setState({ zoomVal: zoomVal + val });
   };

   rotate = val => {
      const { rotateVal } = this.state;
      const rval = rotateVal + val;
      this.setState({ rotateVal: rval });
      this.repositionCanvas(rval);
   };

   reset = () => {
      this.setState({ rotateVal: 0, zoomVal: zoomMin });
      this.repositionCanvas(0);
   };

   repositionCanvas = rval => {
      // swap the width & height on rotation if
      if (Math.abs(rval) !== 0 && Math.abs(rval) % 180 !== 0) {
         this.setState({ height: initialWidth, width: initialHeight });
      } else {
         this.setState({ height: initialHeight, width: initialWidth });
      }
   };

   save = async () => {
      if (this.editor) {
         const canvas = this.editor.getImage();
         const imageUrl = canvas.toDataURL();

         // validate the image size
         const img = await PROFILE_HELPERS.loadImage(imageUrl);
         if (
            img.width < MIN_PHOTO_WIDTH ||
            img.width > MAX_PHOTO_WIDTH ||
            img.height < MIN_PHOTO_HEIGHT ||
            img.height > MAX_PHOTO_HEIGHT
         ) {
            M.toast({
               html: `Image size should be between ${MIN_PHOTO_WIDTH}x${MIN_PHOTO_HEIGHT} to ${MAX_PHOTO_WIDTH}x${MAX_PHOTO_HEIGHT} pixels`,
               classes: 'error',
            });
            return;
         }

         this.setState({ saveClicked: true });

         // save the photo
         canvas.toBlob(
            blob => {
               const { savePhoto, user, token } = this.props;
               let data = new FormData();
               data.append('contactId', user.contactData.contactId);
               data.append('data', blob, 'filename.jpg');
               savePhoto(token, data);
            },
            'image/jpeg',
            1
         );
      }
   };

   renderModel = () => {
      const { isModalOpen } = this.state;

      const footer = (
         <button
            type="button"
            className="waves-effect waves-light btn"
            onClick={() =>
               this.props.location && this.props.location.returnUrl
                  ? Navigator.to(this.props.location.returnUrl)
                  : Navigator.toProfile()
            }
         >
            OK
         </button>
      );
      return (
         <ModalDialog
            isOpen={isModalOpen}
            body={PROFILE.SAVE_MESSAGE}
            footer={footer}
         />
      );
   };

   showPreview = () => {
      const { user } = this.props;
      const canvas = this.editor.getImage().toDataURL();

      this.setState({
         person: {
            Name: 'Jo Sample',
            Image: canvas || 'https://i.imgur.com/KXyKKhh.png',
            Barcode: user.registrationNumber
               ? user.registrationNumber
               : '12345',
            Expire: '31 MARCH 2019',
            Trades: ['XXX Plumber', 'XXX Gasfitter', 'XXX Drainlayer'],
            skipLicenceIcons: true,
         },
         isPreviewOpen: true,
      });
   };

   renderPreview = () => {
      if (!this.state.person) {
         return;
      }

      const preview = (
         <div className="row card-preview">
            <div className="col">
               <LicenceCardBack details={this.state.person} />
            </div>
         </div>
      );

      return (
         <ModalDialog
            isOpen={this.state.isPreviewOpen}
            body={preview}
            className="preview-modal"
            shouldCloseOnOverlayClick={true}
            onRequestClose={() => this.setState({ isPreviewOpen: false })}
         />
      );
   };

   render() {
      const { user, isLoading } = this.props;
      const { width, height, zoomVal, rotateVal } = this.state;

      if (isLoading) {
         return (
            <LoadingScreen
               loading={isLoading}
               bgColor="#F4F4F4"
               textColor="#676767"
               logoSrc="/images/loading.gif"
               text="Uploading photo..."
            >
               <div />
            </LoadingScreen>
         );
      }

      return (
         <main>
            <div className="container profile-photo-container">
               <div className="row profile-row">
                  <div className="col s3 m6 l7 offset-l1">
                     <p className="columnheader">{PROFILE.TITLE_MY_PROFILE}</p>
                  </div>
               </div>
               <div className="row">
                  <div className="col s3 m6 l7 offset-l1">
                     <p className="section-header sub-heading">
                        {PROFILE.TITLE_UPLOAD_PHOTO}
                     </p>
                  </div>
               </div>
               <div className="row photo-editor">
                  <div className="col offset-l1 avatar-frame">
                     <AvatarEditor
                        ref={this.setEditorRef}
                        image={user.editProfilePhoto.file}
                        width={width}
                        height={height}
                        scale={zoomVal}
                        rotate={rotateVal}
                        border={border}
                        disableBoundaryChecks={false}
                     />
                  </div>
                  <div className="col l5 avatar-control-frame">
                     <ul className="control-button-list">
                        <li
                           className="link"
                           onClick={() => this.zoom(zoomIncrement)}
                           onKeyPress={() => this.zoom(zoomIncrement)}
                        >
                           <Icon iconName="zoom_in" />
                           <span>Zoom In</span>
                        </li>
                        <li
                           className="link"
                           onClick={() => this.zoom(-zoomIncrement)}
                           onKeyPress={() => this.zoom(-zoomIncrement)}
                        >
                           <Icon iconName="zoom_out" />
                           <span>Zoom Out</span>
                        </li>
                        <li
                           className="link"
                           onClick={() => this.rotate(-rotateIncrement)}
                           onKeyPress={() => this.rotate(-rotateIncrement)}
                        >
                           <Icon iconName="rotate_left" />
                           <span>Rotate Left</span>
                        </li>
                        <li
                           className="link"
                           onClick={() => this.rotate(rotateIncrement)}
                           onKeyPress={() => this.rotate(rotateIncrement)}
                        >
                           <Icon iconName="rotate_right" />
                           <span>Rotate Right</span>
                        </li>
                        <li
                           className="link"
                           onClick={() => this.reset()}
                           onKeyPress={() => this.reset()}
                        >
                           <Icon iconName="undo" />
                           <span>Reset</span>
                        </li>
                        <li
                           className="link"
                           onClick={() => this.showPreview()}
                           onKeyPress={() => this.showPreview()}
                        >
                           <Icon iconName="image" />
                           <span>Preview</span>
                        </li>
                     </ul>
                  </div>
               </div>
               <div className="row">
                  <div className="col offset-l1">
                     {PROFILE.PHOTO_EDIT_INSTRUCTION}
                  </div>
               </div>
               <div className="row controls">
                  <div className="col offset-l1 button-group">
                     <button
                        type="button"
                        className="waves-effect waves-light btn"
                        onClick={async () => await this.save()}
                        disabled={this.state.saveClicked}
                     >
                        Save
                     </button>
                     <button
                        type="button"
                        className="waves-effect waves-light btn"
                        onClick={() =>
                           this.props.location && this.props.location.returnUrl
                              ? Navigator.to(this.props.location.returnUrl)
                              : Navigator.toProfile()
                        }
                     >
                        Cancel
                     </button>
                  </div>
               </div>
               {this.renderPreview()}
               {this.renderModel()}
            </div>
         </main>
      );
   }
}

const mapStateToProps = state => {
   return {
      user: state.user,
      isSaved: isFetchingUserInfoSuccess('saveUserPhotoInfo', state),
      isFailed: isFetchingUserInfoFailure('saveUserPhotoInfo', state),
      isLoading: isFetchingUserInfo('saveUserPhotoInfo', state),
      token: getToken(state),
   };
};

const mapDispatchToProps = {
   savePhoto: UserActions.saveUserPhotoInfoRequest,
};

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(ProfilePhotoEditor);
