import React from 'react';
import { NavLink } from 'react-router-dom';
import ExternalLink from '../../Components/ExternalLink';

const Footer = () => {
   return (
      <footer className="page-footer white">
         <div className="row">
            <div className="col l10 offset-l1">
               <div className="container black-text">
                  <div className="row">
                     <div className="col l6 grey-text text-darken-3">
                        <p className="footer">
                           If you need any help, call us on our freephone{' '}
                           <a href="tel:0800743262" rel="nofollow">
                              0800 743 262
                           </a>{' '}
                           or email{' '}
                           <a
                              className="activeblue-text"
                              href="mailto:licensing@pgdb.co.nz"
                           >
                              licensing@pgdb.co.nz
                           </a>
                        </p>
                     </div>
                     <div className="col l9 grey-text text-darken-3">
                        <div className="row footer-menu">
                           <div className="col l1">
                              <NavLink exact to="/" className="grey2-text">
                                 Home
                              </NavLink>
                           </div>
                           <div className="col l2">
                              <NavLink
                                 exact
                                 to="/public-register"
                                 className="grey2-text"
                              >
                                 Public Register
                              </NavLink>
                           </div>
                           <div className="col l2">
                              <ExternalLink
                                 href="https://www.pgdb.co.nz/about/contact.html"
                                 className="grey2-text"
                              >
                                 Contact Us
                              </ExternalLink>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="footer-copyright white">
                  <div className="container">
                     <p className="grey-text">
                        &copy; {new Date().getFullYear()} Plumbers, Gasfitters
                        and Drainlayers Board
                     </p>
                  </div>
               </div>
            </div>
         </div>
      </footer>
   );
};

export default Footer;