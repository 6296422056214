import React from 'react';
import { string, object } from 'prop-types';
import { LICENCE_TYPES } from 'pgdb-data-layer/lib/Constants/LicenceTypes';
import Callout from '../Callout';
import Icon from '../Icon';

export const PendingLicenceCallout = ({ licenceTypeCode }) => {
   const licenceCode = licenceTypeCode.trim();
   const licenceDescription = LICENCE_TYPES[licenceCode];

   return (
      <Callout>
         Before we can renew your {licenceDescription} licence, your supervisor
         has to;
         <br />
         <ul>
            <li>
               <Icon className="arrow" iconName="arrow_forward" /> Renewed their
               licence
            </li>
            <li>
               <Icon className="arrow" iconName="arrow_forward" /> Accepted your
               nomination
            </li>
         </ul>
         When you&apos;ve completed this, your licence will be active and you
         can work in the relevant trade.
      </Callout>
   );
};

PendingLicenceCallout.propTypes = {
   licenceTypeCode: string.isRequired,
};

const ConditionLine = ({ description }) => {
   return (
      <li>
         <Icon className="arrow" iconName="arrow_forward" /> {description}
      </li>
   );
};

ConditionLine.propTypes = {
   description: string.isRequired,
};

export const UnmetCriteriaCallout = ({ unmetCriteria }) => {
   if (unmetCriteria.descriptions.length === 0) return <></>;

   const licenceCode = unmetCriteria.licenceTypeCode.trim();
   const licenceDescription = LICENCE_TYPES[licenceCode];

   const conditions = unmetCriteria.descriptions.map((d, i) => (
      <ConditionLine key={i} description={d} />
   ));

   return (
      <Callout>
         Before we can renew your {licenceDescription} licence, you need to do
         the following:
         <br />
         <ul>{conditions}</ul>
         When you&apos;ve completed these steps, your licence will be active and
         you can work in the relevant trade.
      </Callout>
   );
};

UnmetCriteriaCallout.propTypes = {
   unmetCriteria: object.isRequired,
};
