import * as REGEX from 'pgdb-data-layer/lib/Constants/Regex';

class Validator {
   static isNullOrWhiteSpace(value) {
      return (value || '').trim().length === 0;
   }

   static isValidEmail(value) {
      if (!value) return false;
      return value.match(REGEX.IS_EMAIL);
   }

   static isValidNumber(value) {
      if (!value) return false;
      return value.match(REGEX.IS_NUMBER);
   }

   static isValidLength(value, length) {
      return (value || '').trim().length <= length;
   }

   static isValidAddress(address) {
      return (
         typeof address === 'object' &&
         !Validator.isNullOrWhiteSpace(address.address_line_1) &&
         !Validator.isNullOrWhiteSpace(address.city) &&
         !Validator.isNullOrWhiteSpace(address.fullAddress) &&
         !Validator.isNullOrWhiteSpace(address.postcode)
      );
   }
}

export default Validator;
