import React from 'react';
import './LicenceIdCard.scss';

const LicenceCardFront = ({ details }) => {
   return (
      <div className="canvas-card card-front">
      </div>
   );
};

export default LicenceCardFront;
