import React, { useState } from 'react';
import { arrayOf } from 'prop-types';
import Moment from 'moment';
import {
   CERTIFYING_LICENCE_TYPES,
   EMPLOYER_LICENCE_TYPE,
   UNSUPERVISED_EXEMPTION_LICENCE_TYPES,
} from 'pgdb-data-layer/lib/Constants/LicenceTypes';
import Modal from 'react-modal';
import Validator from '../../../../Utilities/Validator';
import TextLink from '../../../../Components/TextLink';

import {
   DetailedRow,
   DetailedRowContent,
   SectionHeader,
} from './DetailedSearchRow';
import { licenceType } from '../types';

const LONG_DATE_FORMAT = 'D MMM YYYY';

const CurrentLicenceRow = ({ licence }) => {
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [isSuspensionModalOpen, setIsSuspensionModalOpen] = useState(false);

   const isSupervised =
      licence.supervision !== null && licence.supervision.supervisor !== null;
   const requiresSupervision =
      !(Object.keys(CERTIFYING_LICENCE_TYPES).includes(licence.licenceTypeCode.trim()) || 
        Object.keys(UNSUPERVISED_EXEMPTION_LICENCE_TYPES).includes(licence.licenceTypeCode.trim())
        ) && licence.licenceTypeCode !== EMPLOYER_LICENCE_TYPE;
   const missingRequiredSupervision = requiresSupervision && !isSupervised;

   let supervisionText = '';
   if (missingRequiredSupervision) {
      supervisionText = '. Inactive pending supervision approval.';
   } else if (isSupervised) {
      supervisionText = `. Currently supervised by ${licence.supervision.supervisor.fullName.trim()}.`;
   }

   return (
      <DetailedRowContent>
         <div className="col s12">
            <span className={`bold black-text`}>{licence.licenceTypeName}</span>
            <br />
            <span className="pr-subtext">
               Licensed from{' '}
               {Moment(licence.startDate).format(LONG_DATE_FORMAT)} to{' '}
               {Moment(licence.expiryDate).format(LONG_DATE_FORMAT)}
               {supervisionText}
            </span>
            {!Validator.isNullOrWhiteSpace(licence.licenceConditions) && (
               <>
                  <br />
                  <span className="pr-subtext">
                     <TextLink
                        className="link"
                        onClick={() => setIsModalOpen(true)}
                     >
                        Conditions
                     </TextLink>{' '}
                     have been placed on this licence.
                  </span>
                  <Modal
                     isOpen={isModalOpen}
                     shouldCloseOnOverlayClick
                     onRequestClose={() => setIsModalOpen(false)}
                     className="generic-modal condition-modal"
                     overlayClassName="generic-modal-overlay"
                  >
                     <>
                        <div className="row">
                           <div className="col l10 push-l1 left-align">
                              <p>
                                 <h2>Conditions</h2>
                                 <br />
                                 {licence.licenceConditions}
                              </p>
                           </div>
                        </div>
                        <div className="row">
                           <div className="col l12">
                              <button
                                 type="button"
                                 className="waves-effect waves-light btn"
                                 onClick={() => setIsModalOpen(false)}
                              >
                                 OK
                              </button>
                              <br />
                           </div>
                        </div>
                     </>
                  </Modal>
               </>
            )}

            {!Validator.isNullOrWhiteSpace(licence.suspensionReason) && (
               <>
                  <br />
                  <span className="pr-subtext">
                     <TextLink
                        className="link"
                        onClick={() => setIsSuspensionModalOpen(true)}
                     >
                        Suspension Conditions
                     </TextLink>{' '}
                     have been placed on this licence from{' '}
                     {Moment(licence.suspensionStartDate).format(
                        LONG_DATE_FORMAT
                     )}{' '}
                     to{' '}
                     {Moment(licence.suspensionEndDate).format(
                        LONG_DATE_FORMAT
                     )}
                     .
                  </span>
                  <Modal
                     isOpen={isSuspensionModalOpen}
                     shouldCloseOnOverlayClick
                     onRequestClose={() => setIsSuspensionModalOpen(false)}
                     className="generic-modal condition-modal"
                     overlayClassName="generic-modal-overlay"
                  >
                     <>
                        <div className="row">
                           <div className="col l10 push-l1 left-align">
                              <p>
                                 <h2>Suspension Conditions</h2>
                                 <br />
                                 {licence.suspensionReason}
                              </p>
                           </div>
                        </div>
                        <div className="row">
                           <div className="col l12">
                              <button
                                 type="button"
                                 className="waves-effect waves-light btn"
                                 onClick={() => setIsSuspensionModalOpen(false)}
                              >
                                 OK
                              </button>
                              <br />
                           </div>
                        </div>
                     </>
                  </Modal>
               </>
            )}
         </div>
      </DetailedRowContent>
   );
};

CurrentLicenceRow.propTypes = {
   licence: licenceType.isRequired,
};

const CurrentLicences = ({ licences }) => {
   const licenceRows = licences.map(l => {
      return <CurrentLicenceRow key={l.licenceId} licence={l} />;
   });

   return (
      <DetailedRow>
         <SectionHeader label="Current Licences" />
         {licenceRows}
      </DetailedRow>
   );
};

CurrentLicences.propTypes = {
   licences: arrayOf(licenceType).isRequired,
};

export default CurrentLicences;
