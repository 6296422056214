import React from 'react';
import Navigator from '../../../Navigation/Navigator';
import Icon from '../../Icon';

const LogoutButton = () => (
   <a href="#!" onClick={() => Navigator.toLogout()}>
      <Icon className="profile-dropdown-icons" iconName="exit_to_app" />
      Logout
   </a>
);

export default LogoutButton;
