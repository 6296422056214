import React from 'react';
import { CERTIFYING_LICENCE_TYPES } from 'pgdb-data-layer/lib/Constants/LicenceTypes';
import {
   Table,
   TableBody,
   TableRow,
   TableCell,
} from '../../../../../Components/GenericTable';
import * as helper from '../../../../../Helpers/Helpers';
import TextLink from '../../../../../Components/TextLink';

const PAID_SECTION = 1;
const NOMINATE_MESSAGE = 'Nominate a Supervisor';

const CompanyLicenceRenewalTable = ({ renewalItems, sectionId, onEdit }) => {
   const generateRow = (name, app, appIndex) => {
      // 1. Skip the Paid section even if any fee is unpaid from the application
      // 2. Skip the Unpaid section if all the fees are paid from the application
      if (
         (sectionId === 1 && app.fees.filter(fee => !fee.isPaid).length) ||
         (sectionId === 2 &&
            app.fees.filter(fee => fee.isPaid).length === app.fees.length)
      ) {
         return null;
      }

      let appTotal = 0;
      app.fees.filter(f => !f.isPaid).forEach(s => (appTotal += s.amount));

      const isCertifying = !!CERTIFYING_LICENCE_TYPES[app.licenceTypeCode];
      const supervisorName = app.supervisor
         ? app.supervisor.fullName
         : app.supervisorName;
      const hasAcceptedSupervisor = app.hasSupervisorApproved && supervisorName;

      const supervisorIcon =
         app.isDisciplinaryFee || isCertifying
            ? ''
            : hasAcceptedSupervisor && !app.supervisor
            ? 'check'
            : supervisorName || app.supervisor
            ? 'sync'
            : '';
      const supervisorColor =
         hasAcceptedSupervisor && supervisorName && !app.supervisor
            ? 'black'
            : supervisorName || app.supervisor
            ? 'orange'
            : 'activeblue';
      const licenceColor =
         app.isDisciplinaryFee || app.activeLicence ? 'black' : 'orange';
      const licenceIcon = app.isDisciplinaryFee
         ? ''
         : app.activeLicence
         ? 'check'
         : 'sync';

      const supervisorText =
         app.isDisciplinaryFee || isCertifying ? (
            ''
         ) : supervisorName ? (
            supervisorName
         ) : (
            <TextLink onClick={onEdit}>{NOMINATE_MESSAGE}</TextLink>
         );

      const totalText =
         sectionId !== PAID_SECTION ? helper.formatCurrency(appTotal, 2) : '';

      const discPaddingStyle = app.isDisciplinaryFee ? 'icon-left-pad' : '';

      return (
         <React.Fragment key={appIndex}>
            <TableRow key={app.applicationId} className="main-row">
               <TableCell className="name-cell" color="black">
                  {name}
               </TableCell>
               <TableCell
                  icon={licenceIcon}
                  color={licenceColor}
                  className={discPaddingStyle}
               >
                  {app.description}
               </TableCell>
               <TableCell
                  icon={supervisorIcon}
                  color={supervisorColor}
                  className="supervisor-cell"
               >
                  {supervisorText}
               </TableCell>
               <TableCell color="black" className="currency-cell bold">
                  {sectionId !== PAID_SECTION ? (
                     <span>$</span>
                  ) : (
                     <span>&nbsp;&nbsp;</span>
                  )}
               </TableCell>
               <TableCell
                  color="black"
                  className="bold text-right width-reduction amount-cell"
                  amount="1"
               >
                  {totalText}
               </TableCell>
            </TableRow>
         </React.Fragment>
      );
   };

   const generateTableRows = () => {
      let eleCount = 0;
      return renewalItems.map(contact => {
         let name = contact.fullName;
         let appIndex = 0;
         const feeRows = contact.applications
            .filter(f => !f.isDisciplinaryFee)
            .map(app => {
               const row = generateRow(name, app, appIndex);
               if (row !== null) name = '';
               appIndex += 1;
               return row;
            });

         const disciplinaryFeeRows = contact.applications
            .filter(f => f.isDisciplinaryFee)
            .map(app => {
               const row = generateRow(name, app, appIndex);
               if (row !== null) name = '';
               appIndex += 1;
               return row;
            });
         eleCount += 1;
         return (
            <React.Fragment key={eleCount}>
               {feeRows}
               {disciplinaryFeeRows}
            </React.Fragment>
         );
      });
   };

   return (
      <Table className="table-head-compressed borderless licence-table">
         <TableBody>{generateTableRows()}</TableBody>
      </Table>
   );
};

export default CompanyLicenceRenewalTable;
