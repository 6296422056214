import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as SUPERVISION_HELPERS from 'pgdb-data-layer/lib/Helpers/Supervision';
import { CONTACT_STATUS_DECEASED } from 'pgdb-data-layer/lib/Constants/Contact';
import SupervisionActions, {
   isFetchingSupervision,
} from 'pgdb-data-layer/lib/Redux/SupervisionRedux';
import { SUPERVISION_STATUS } from 'pgdb-data-layer/lib/Constants/Supervision';
import TableTextBoxCell from '../../../Components/GenericTable/TableTextBoxCell';
import { getToken } from '../../../Session/SessionRedux';
import TextLink from '../../../Components/TextLink';

const SupervisionTextBoxCell = ({
   selectedSupervisee,
   clearInputTarget,
   searchedSupervisors,
   isLoading,
   dispatch,
   newSupervisor,
   myRegistrationNumber,
   myCity,
   token,
   registrationPaymentFontSize,
}) => {
   const [inputSubmitted, setinputSubmitted] = useState(false);
   const [searchedRegistration, setSearchedRegistration] = useState(null);
   const [searchRegistrationNumber, setSearchRegistrationNumber] = useState('');
   let inputValid = true;
   let errorText = '';

   const searchSupervisor = registrationNumberText => {
      const registrationNumber = parseInt(registrationNumberText, 10);
      const foundSupervisor =
         registrationNumber &&
         searchedSupervisors.find(val => {
            return parseInt(val.registrationNumber, 10) === registrationNumber;
         });
      if (!foundSupervisor) {
         dispatch(
            SupervisionActions.searchSupervisorRequest(
               token,
               registrationNumber
            )
         );
      }
      setinputSubmitted(true);
      setSearchedRegistration(registrationNumber);
   };

   const checkSupervisorFoundAndValid = () => {
      const searchedRegistrationNumber = parseInt(searchedRegistration, 10);
      const foundSupervisor =
         searchedRegistrationNumber &&
         searchedSupervisors.find(val => {
            return (
               parseInt(val.registrationNumber, 10) ===
               searchedRegistrationNumber
            );
         });

      if (!foundSupervisor) {
         errorText = 'Please provide the correct licence number';
         return null;
      }
      if (foundSupervisor.supervisorStatus === CONTACT_STATUS_DECEASED) {
         errorText =
            'The supervisor you have chosen is unavailable for supervision. Please choose another supervisor.';
         return null;
      }
      // Foundsupervisor is you
      if (
         parseInt(foundSupervisor.registrationNumber, 10) ===
         myRegistrationNumber
      ) {
         errorText = 'You cannot nominate yourself as a supervisor.';
         return null;
      }
      // Foundsupervisor is same as current and not declined and has not ended
      if (
         parseInt(foundSupervisor.registrationNumber, 10) ===
            selectedSupervisee.registrationNumber &&
         selectedSupervisee.supervisionStatus !== SUPERVISION_STATUS.DECLINED &&
         SUPERVISION_HELPERS.isSupervisionDateValid(selectedSupervisee)
      ) {
         errorText = 'Please nominate a different supervisor.';
         return null;
      }
      const validLicence = foundSupervisor.supervisorRegistrations.find(val => {
         return SUPERVISION_HELPERS.isRegistrationValid(
            val,
            selectedSupervisee
         );
      });
      if (!validLicence) {
         errorText = `${
            foundSupervisor.fullName
         } is not a registered Certifier for this licence type. Please choose another supervisor.`;
         return null;
      }

      if (
         !SUPERVISION_HELPERS.canSupervise(foundSupervisor, selectedSupervisee)
      ) {
         errorText = `${
            foundSupervisor.fullName
         } cannot be nominated at this time, nominate another Certifier or contact the licensing team on 0800 743 262 for assistance.`;
         return null;
      }

      return foundSupervisor;
   };

   if (inputSubmitted && !isLoading) {
      const foundSupervisor = checkSupervisorFoundAndValid();
      if (foundSupervisor) {
         const supervisorCity =
            foundSupervisor.physicalCity !== null
               ? foundSupervisor.physicalCity
               : foundSupervisor.mailingCity;

         if (
            supervisorCity.trim().toLowerCase() !== myCity.trim().toLowerCase()
         ) {
            const changeOrNew = !newSupervisor ? 'Change' : 'New';
            dispatch(
               SupervisionActions.openModal(
                  changeOrNew + 'DifferentCitySupervisor',
                  selectedSupervisee,
                  foundSupervisor
               )
            );
         } else {
            // render prompt
            if (!newSupervisor) {
               dispatch(
                  SupervisionActions.openModal(
                     'ChangeSupervisor',
                     selectedSupervisee,
                     foundSupervisor
                  )
               );
            } else {
               dispatch(
                  SupervisionActions.openModal(
                     'NewSupervisor',
                     selectedSupervisee,
                     foundSupervisor
                  )
               );
            }
         }
         clearInputTarget();
      } else {
         inputValid = false;
      }
   }

   return (
      <>
         <TableTextBoxCell
            onSubmit={() => {
               searchSupervisor(searchRegistrationNumber);
            }}
            errorText={errorText}
            onChange={e => {
               setSearchRegistrationNumber(e.target.value);
            }}
            inputValid={inputValid}
            registrationPaymentFontSize={registrationPaymentFontSize}
         >
            <TextLink
               onClick={() => {
                  searchSupervisor(searchRegistrationNumber);
               }}
               className="small supervision-search-text"
            >
               Search
            </TextLink>
         </TableTextBoxCell>
      </>
   );
};

const mapStateToProps = state => {
   return {
      isLoading: isFetchingSupervision('searchSupervisor', state),
      searchedSupervisors: state.supervision.searchedSupervisors,
      myRegistrationNumber: state.user.contactData.registrationNumber,
      myCity: state.user.contactData.physicalCity
         ? state.user.contactData.physicalCity
         : state.user.contactData.mailingCity,
      token: getToken(state),
   };
};

export default connect(mapStateToProps)(SupervisionTextBoxCell);
