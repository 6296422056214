import React, { Component } from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from './TelemetryService';
import { withRouter } from 'react-router-dom';
import ErrorHandler from '../Components/ErrorHandler';
import CanPerformOnlineHandler from '../Components/CanPerformOnlineHandler';

/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 */
const AppInsightsInstrumentationKey =
   process.env.REACT_APP_INSIGHTS_INSTRUMENTATION_KEY;
class TelemetryProvider extends Component {
   state = {
      initialized: false,
      appInsight: null,
   };

   componentDidMount() {
      const { history } = this.props;
      const { initialized } = this.state;
      if (
         !Boolean(initialized) &&
         Boolean(AppInsightsInstrumentationKey) &&
         Boolean(history)
      ) {
         const appInsight = ai.initialize(
            AppInsightsInstrumentationKey,
            history
         );
         this.setState({ initialized: true, appInsight: appInsight });
      }
   }

   render() {
      const { children } = this.props;
      return (
         <ErrorHandler appInsight={this.state.appInsight}>
            <CanPerformOnlineHandler>{children}</CanPerformOnlineHandler>
         </ErrorHandler>
      );
   }
}

export default withRouter(
   withAITracking(ai.reactPlugin, TelemetryProvider, undefined, 'ai-container')
);
