import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Prompt } from 'react-router';
import { isEqual } from 'lodash';
import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-v3';
import PropTypes from 'prop-types';
import M from 'materialize-css';
import CompanyRenewalActions from 'pgdb-data-layer/lib/Redux/CompanyRenewalRedux';
import TextInput from '../../../Components/TextInput/index';
import AddressInput from '../../../Components/AddressInput/index';
import Navigator from '../../../Navigation/Navigator';
import Validator from '../../../Utilities/Validator';
import AddressFinder from '../../../Utilities/AddressFinder';
import './style.css';

class CompanyContactDetails extends Component {
   constructor(props) {
      super(props);
      this.lastSavedState = {
         businessName: '',
         businessEmail: '',
         contactName: '',
         phoneNumber: '',
         businessBillingAddress: '',
         allFieldsValid: true,
         formValidity: {
            businessName: true,
            businessEmail: true,
            contactName: true,
            phoneNumber: true,
            businessBillingAddress: true,
         },
         isVerified: false,
         recaptchaToken: '',
         dataLoaded: false,
      };
      this.state = Object.assign({}, this.lastSavedState);
   }

   componentDidMount() {
      const { companyRenewal } = this.props;
      const { businessName } = this.state;
      if (
         !Validator.isNullOrWhiteSpace(companyRenewal.businessName) &&
         businessName !== companyRenewal.businessName
      ) {
         this.populateToState();
      } else {
         this.setState({ dataLoaded: true });
      }

      loadReCaptcha(process.env.REACT_APP_RECAPTCHA_KEY);
      window.onbeforeunload = () => {
         return !isEqual(this.state, this.lastSavedState);
      };
   }

   componentDidUpdate() {
      const { companyRenewal } = this.props;
      const returnUrl = new URLSearchParams(window.location.search).get(
         'returnurl'
      );
      if (companyRenewal.hasSaved) {
         returnUrl
            ? Navigator.to(`/${returnUrl}`)
            : Navigator.toCompanyPayAndNominate();
      }
   }

   verifyCallback = recaptchaToken => {
      this.setState({ isVerified: true, recaptchaToken });
   };

   next = () => {
      const validity = this.validateFormFields();
      M.Toast.dismissAll();

      if (validity.allFieldsValid) {
         const { isVerified } = this.state;

         if (isVerified) {
            this.lastSavedState = Object.assign({}, this.state);
            const { saveCompanyDetails } = this.props;
            saveCompanyDetails(this.constructData());
            M.toast({
               html: 'Your changes have been temporarily stored',
               classes: 'success',
            });
         } else {
            M.toast({
               html: `Sorry we couldn't process the request, please try again later.`,
               classes: 'error',
            });
         }
      } else {
         M.toast({
            html: 'Please correctly fill in all mandatory fields',
            classes: 'error',
         });
      }
   };

   back = () => {
      Navigator.toBackward();
   };

   validateFormFields() {
      const {
         businessName,
         businessEmail,
         contactName,
         phoneNumber,
         businessBillingAddress,
      } = this.state;

      const validity = {
         businessName:
            !Validator.isNullOrWhiteSpace(businessName) &&
            Validator.isValidLength(businessName, 100),
         businessEmail:
            Validator.isValidEmail(businessEmail) &&
            Validator.isValidLength(businessEmail, 100),
         contactName:
            !Validator.isNullOrWhiteSpace(contactName) &&
            Validator.isValidLength(contactName, 100),
         phoneNumber:
            Validator.isValidNumber(phoneNumber) &&
            Validator.isValidLength(phoneNumber, 100),
         businessBillingAddress: Validator.isValidAddress(
            businessBillingAddress
         ),
      };

      const allFieldsValid = Object.values(validity).every(v => !!v);
      this.setState({ formValidity: validity, allFieldsValid });
      return { formValidity: validity, allFieldsValid };
   }

   constructData() {
      const {
         businessName,
         businessEmail,
         contactName,
         phoneNumber,
         businessBillingAddress,
         recaptchaToken,
      } = this.state;

      const data = {
         businessName,
         businessEmail,
         contactName,
         phoneNumber,
         businessBillingAddressAddressLine1:
            businessBillingAddress.address_line_1,
         businessBillingAddressAddressLine2:
            businessBillingAddress.address_line_2,
         businessBillingAddressSuburbTown: businessBillingAddress.suburb,
         businessBillingAddressPostalCode: businessBillingAddress.postcode,
         businessBillingAddressCity: businessBillingAddress.city,
         businessBillingAddressCountry: businessBillingAddress.country,
         businessBillingAddressCountryCode: businessBillingAddress.countryCode,
         businessBillingAddress,
         recaptchaToken,
         hasSaved: true,
      };

      return data;
   }

   populateToState() {
      const { companyRenewal } = this.props;
      const newState = {};
      newState.businessName = companyRenewal.businessName;
      newState.businessEmail = companyRenewal.businessEmail;
      newState.contactName = companyRenewal.contactName;
      newState.phoneNumber = companyRenewal.phoneNumber;

      newState.businessBillingAddress = AddressFinder.getObject(
         companyRenewal.businessBillingAddressAddressLine1,
         companyRenewal.businessBillingAddressAddressLine2,
         companyRenewal.businessBillingAddressSuburbTown,
         companyRenewal.businessBillingAddressCity,
         companyRenewal.businessBillingAddressPostalCode,
         companyRenewal.businessBillingAddressCountry,
         companyRenewal.businessBillingAddressCountryCode
      );

      newState.allFieldsValid = true;
      newState.formValidity = {
         businessName: true,
         businessEmail: true,
         contactName: true,
         phoneNumber: true,
         businessPostalAddress: true,
         businessPhysicalAddress: true,
      };
      newState.dataLoaded = true;

      this.lastSavedState = Object.assign({}, this.state, newState);
      this.setState(newState);
   }

   render() {
      const {
         businessName,
         businessEmail,
         contactName,
         phoneNumber,
         businessBillingAddress,
         formValidity,
         dataLoaded,
         recaptchaToken,
      } = this.state;

      const { companyRenewal } = this.props;

      return (
         <React.Fragment>
            <Prompt
               when={
                  !companyRenewal.hasSaved &&
                  !isEqual(this.state, this.lastSavedState)
               }
               message="You have unsaved changes. Are you sure you wish to leave the page and lose your changes?"
            />
            <main>
               <div className="section no-pad-bot" id="index-banner">
                  <div className="container company-contact-details">
                     <div className="row">
                        <div className="col s12 m12 l6 offset-l1">
                           <div className="col s12 m12 l12">
                              <p className="columnheader">
                                 What are your company details?
                              </p>
                           </div>
                           <div className="col s12 m12 l12">
                              <span className="section-header sub-heading">
                                 Company Details
                              </span>
                           </div>
                           <div className="col s12 m12 l12">
                              <TextInput
                                 type="text"
                                 id="business_name"
                                 placeholder="Enter the Business Name"
                                 label="Business Name"
                                 mandatory
                                 value={businessName}
                                 inputValid={formValidity.businessName}
                                 onChange={e => {
                                    this.setState({
                                       businessName: e.target.value,
                                    });
                                 }}
                              />
                           </div>
                           <div className="col s12 m12 l12">
                              <TextInput
                                 type="text"
                                 id="business_email"
                                 placeholder="Enter the Business Email"
                                 label="Business Email"
                                 mandatory
                                 value={businessEmail}
                                 inputValid={formValidity.businessEmail}
                                 onChange={e => {
                                    this.setState({
                                       businessEmail: e.target.value,
                                    });
                                 }}
                              />
                           </div>
                           <div className="col s12 m12 l6">
                              <TextInput
                                 type="text"
                                 id="contact_name"
                                 placeholder="Enter the Contact Name"
                                 label="Contact Name"
                                 mandatory
                                 value={contactName}
                                 inputValid={formValidity.contactName}
                                 onChange={e => {
                                    this.setState({
                                       contactName: e.target.value,
                                    });
                                 }}
                              />
                           </div>
                           <div className="col s12 m12 l6">
                              <TextInput
                                 type="text"
                                 id="phone_number"
                                 placeholder="Enter the Phone Number"
                                 label="Phone Number"
                                 mandatory
                                 value={phoneNumber}
                                 inputValid={formValidity.phoneNumber}
                                 onChange={e => {
                                    this.setState({
                                       phoneNumber: e.target.value,
                                    });
                                 }}
                              />
                           </div>
                           <div className="col s12 m12 l12">
                              {dataLoaded && (
                                 <AddressInput
                                    inputValid={
                                       formValidity.businessBillingAddress
                                    }
                                    onSubmit={value => {
                                       this.setState({
                                          businessBillingAddress: value,
                                       });
                                    }}
                                    onChange={value => {
                                       this.setState({
                                          businessBillingAddress: value,
                                       });
                                    }}
                                    name="businessBillingAddress"
                                    value={businessBillingAddress}
                                    type="text"
                                    id="business_billing_address"
                                    placeholder="Start typing to search for your business billing address"
                                    label="Business Billing Address"
                                    mandatory
                                    postalCheckRequired={false}
                                 />
                              )}
                           </div>
                           <div className="col s12 m12 l12">
                              <ReCaptcha
                                 sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                                 action="company_renewal_contact_details"
                                 verifyCallback={this.verifyCallback}
                              />
                           </div>
                           <div className="col s12 m12 l12 control-row">
                              <br />
                              <br />
                              <button
                                 type="button"
                                 className="waves-effect waves-light btn btn-inactive"
                                 onClick={() => this.back()}
                              >
                                 Back
                              </button>
                              <button
                                 type="button"
                                 className="waves-effect waves-light btn btn-ml-30"
                                 onClick={() => this.next()}
                                 disabled={!recaptchaToken}
                              >
                                 Next
                              </button>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </main>
         </React.Fragment>
      );
   }
}

CompanyContactDetails.propTypes = {
   companyRenewal: PropTypes.any.isRequired,
   saveCompanyDetails: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
   return {
      companyRenewal: state.companyRenewal,
   };
};

const mapDispatchToProps = {
   saveCompanyDetails: CompanyRenewalActions.saveCompanyDetails,
};

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(CompanyContactDetails);
