import React, { useState } from 'react';
import { bool, arrayOf } from 'prop-types';
import Moment from 'moment';
import Modal from 'react-modal';
import Validator from '../../../../Utilities/Validator';
import TextLink from '../../../../Components/TextLink';

import {
   DetailedRow,
   DetailedRowContent,
   SectionHeader,
} from './DetailedSearchRow';
import { registrationType, registrationHistoryType } from '../types';

const LONG_DATE_FORMAT = 'D MMM YYYY';

const RegistrationHistoryCell = ({ registration, isActive }) => {
   const [isModalOpen, setIsModalOpen] = useState(false);

   if (!registration) return <></>;

   const statusText = isActive
      ? ''
      : ` (${registration.registrationStatusName})`;

   return (
      <>
         <span>
            {registration.registrationTypeName}
            {statusText}
         </span>
         <br />
         <span className="pr-subtext">
            {Moment(registration.registeredDate).format(LONG_DATE_FORMAT)}
         </span>
         {!Validator.isNullOrWhiteSpace(registration.conditions) && (
            <>
               <br />
               <span className="pr-subtext">
                  <TextLink
                     className="link"
                     onClick={() => setIsModalOpen(true)}
                  >
                     Conditions
                  </TextLink>{' '}
                  have been placed on this registration.
               </span>
               <Modal
                  isOpen={isModalOpen}
                  shouldCloseOnOverlayClick
                  onRequestClose={() => setIsModalOpen(false)}
                  className="generic-modal condition-modal"
                  overlayClassName="generic-modal-overlay"
               >
                  <>
                     <div className="row">
                        <div className="col l10 push-l1 left-align">
                           <p>
                              <h2>Conditions</h2>
                              <br />
                              {registration.conditions}
                           </p>
                        </div>
                     </div>
                     <div className="row">
                        <div className="col l12">
                           <button
                              type="button"
                              className="waves-effect waves-light btn"
                              onClick={() => setIsModalOpen(false)}
                           >
                              OK
                           </button>
                           <br />
                        </div>
                     </div>
                  </>
               </Modal>
            </>
         )}
      </>
   );
};

RegistrationHistoryCell.propTypes = {
   registration: registrationType,
   isActive: bool.isRequired,
};

RegistrationHistoryCell.defaultProps = {
   registration: null,
};

const RegistrationHistoryRow = ({ active, history }) => {
   return (
      <DetailedRowContent>
         <div className="col s6">
            <RegistrationHistoryCell registration={active} isActive />
         </div>
         <div className="col s6">
            <RegistrationHistoryCell registration={history} isActive={false} />
         </div>
      </DetailedRowContent>
   );
};

RegistrationHistoryRow.propTypes = {
   active: registrationType.isRequired,
   history: registrationType,
};

RegistrationHistoryRow.defaultProps = {
   history: null,
};

const RegistrationHistory = ({ history }) => {
   const registrationRows = history.map((h, i) => {
      return (
         <RegistrationHistoryRow
            key={i}
            active={h.active}
            history={h.history}
         />
      );
   });

   return (
      <DetailedRow>
         <SectionHeader label="Registration Date(s)" />
         {registrationRows}
      </DetailedRow>
   );
};

RegistrationHistory.propTypes = {
   history: arrayOf(registrationHistoryType).isRequired,
};

export default RegistrationHistory;
