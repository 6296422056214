import React from 'react';
import Modal from 'react-modal';
import './ModalStyle.scss';

const ModalDialog = ({
   isOpen,
   body,
   footer,
   className,
   overlayClassName,
   shouldCloseOnOverlayClick,
   onRequestClose,
}) => {
   return (
      <Modal
         isOpen={isOpen}
         onRequestClose={
            onRequestClose ? onRequestClose : () => (isOpen = false)
         }
         ariaHideApp={false}
         shouldCloseOnOverlayClick={
            shouldCloseOnOverlayClick ? shouldCloseOnOverlayClick : false
         }
         className={className ? className : 'model-dialog'}
         overlayClassName={
            overlayClassName ? overlayClassName : 'model-dialog-overlay'
         }
      >
         <div className="row">{body}</div>
         {footer && <div className="row model-dialog-footer">{footer}</div>}
      </Modal>
   );
};

export default ModalDialog;
