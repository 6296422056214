import { takeLatest, all } from 'redux-saga/effects';
import 'regenerator-runtime/runtime';

import {
   ApplicationsService,
   CoursesService,
   LicenceService,
   PublicRegisterService,
   ReceiptsService,
   ResultsService,
   SupervisionService,
   UserInfoService,
   DashboardService,
   ExaminationService,
   RenewalService,
   PaymentService,
   EnumService,
   CompanyRenewalService,
   RenewalSummaryService,
   BulkRenewalService,
   RegistrationService,
   GenderService,
   EthnicityService,
   FitAndProperService,
} from 'pgdb-data-layer/lib/Services';

import {
   ApplicationsTypes,
   CoursesTypes,
   LicenceTypes,
   PublicRegisterTypes,
   ReceiptsTypes,
   ResultTypes,
   SupervisionTypes,
   UserInfoTypes,
   DashboardTypes,
   ExaminationTypes,
   RenewalTypes,
   PaymentTypes,
   EnumTypes,
   CompanyRenewalTypes,
   RenewalSummaryTypes,
   BulkRenewalTypes,
   RegistrationTypes,
   GenderTypes,
   EthnicityTypes,
   FitAndProperTypes,
} from 'pgdb-data-layer/lib/Redux';

import {
   getAvailableCourses,
   getCourseUrlAndDate,
   getLicences,
   getSelectedApplications,
   getSelectedDetails,
   getUserApplicationsRenewal,
   changeSupervisionEndDate,
   getUserApplications,
   searchSupervisor,
   changeSupervisor,
   newSupervisor,
   publicRegister,
   supervisionUser,
   acceptSupervisionUser,
   declineSupervisionUser,
   getUserSupervisionTasksComplete,
   getMyActiveCertifyingRegistrations,
   getResultsByUserId,
   getExaminationResults,
   saveContactDetailsConfirmation,
   saveApplicationRenewalAction,
   getUsefulLinks,
   getUserExamEligibility,
   getUserExamEligibilityReport,
   getUserExams,
   getUserExaminationDetails,
   getExamCycles,
   getAvailableExamVenues,
   getExamVenues,
   getExaminationResultAddress,
   withdrawUserFromExamination,
   getUserContactInfo,
   saveUserContactInfo,
   saveUserPhotoInfo,
   getUserReceipts,
   getUserReceiptDetails,
   getUserReceiptDetailsFromReference,
   saveApplicationData,
   getApplicationData,
   getApplicationsByUser,
   saveApplication,
   submitRegistration,
   getCoursesByLicenceYear,
   getHowIoUrlSso,
   getPaymentUrl,
   getRenewalApplication,
   getCompanyRenewalSupervisor,
   getRenewalSummary,
   getRegistrationLicenceSummary,
   applyLicenceApplications,
   saveRenewalApplicationData,
   saveExamApplicationData,
   getConfirmationResults,
   getPaymentResponse,
   saveCompanyRenewalApplicationData,
   getCompanyRenewalApplications,
   getCompanyPaymentReceipt,
   getUnmetApplicationCriteria,
   getUnmetApplicationCriteriaByIds,
   getBulkRenewalApplication,
   getBulkRenewalSupervisor,
   saveBulkRenewalApplicationData,
   getNotifications,
   getGlobalNotification,
   removeNotification,
   updateExaminationResultsAddress,
   getPaymentProcessingStatus,
   putFeeRequest,
   clearSupervisionNotifications,
   specialUser,
   getUserRegistration,
   getUserApplicationsRegistrationLicence,
   getRegistrationFile,
   getRegistrationFilesByApplication,
   getRegistrationFileByContact,
   getRegistrationFileDownload,
   putRegistrationFile,
   deleteRegistrationFile,
   getGenders,
   getEthnicities,
   getCities,
   getPostCodeCities,
   getQsAndAs,
   saveAnswerList,
   deleteApplication,
   getAnotherFilesToUpload,
   submitAnotherFilesToUpload,
} from 'pgdb-data-layer/lib/Sagas';

/* ------------- API ------------- */

// Set baseURL from environment variables
const baseURL = process.env.REACT_APP_API_URL;

const applicationsService = ApplicationsService.create(baseURL);
const licenceService = LicenceService.create(baseURL);
const coursesService = CoursesService.create(baseURL);
const publicRegisterService = PublicRegisterService.create(baseURL);
const resultsService = ResultsService.create(baseURL);
const receiptsService = ReceiptsService.create(baseURL);
const supervisionService = SupervisionService.create(baseURL);
const userInfoService = UserInfoService.create(baseURL);

const renewalService = RenewalService.create(baseURL);
const dashboardService = DashboardService.create(baseURL);
const examinationService = ExaminationService.create(baseURL);
const paymentService = PaymentService.create(baseURL);
const enumService = EnumService.create(baseURL);

const companyRenewalService = CompanyRenewalService.create(baseURL);
const renewalSummaryService = RenewalSummaryService.create(baseURL);
const bulkRenewalService = BulkRenewalService.create(baseURL);
const registrationService = RegistrationService.create(baseURL);

const genderService = GenderService.create(baseURL);
const ethnicityService = EthnicityService.create(baseURL);
const fitAndProperService = FitAndProperService.create(baseURL);

/* ------------- Connect Types To Sagas ------------- */
export default function* root() {
   yield all([
      takeLatest(
         ApplicationsTypes.SAVE_APPLICATION_DATA_REQUEST,
         saveApplicationData,
         applicationsService
      ),
      takeLatest(
         ApplicationsTypes.SUBMIT_REGISTRATION_REQUEST,
         submitRegistration,
         applicationsService
      ),
      takeLatest(
         ApplicationsTypes.GET_APPLICATION_DATA_REQUEST,
         getApplicationData,
         applicationsService
      ),

      takeLatest(
         ApplicationsTypes.GET_APPLICATIONS_BY_USER_REQUEST,
         getApplicationsByUser,
         applicationsService
      ),
      takeLatest(
         ApplicationsTypes.SAVE_APPLICATION_REQUEST,
         saveApplication,
         applicationsService
      ),
      takeLatest(
         CoursesTypes.GET_AVAILABLE_COURSES_REQUEST,
         getAvailableCourses,
         coursesService
      ),
      takeLatest(
         CoursesTypes.GET_COURSES_BY_LICENCE_YEAR_REQUEST,
         getCoursesByLicenceYear,
         coursesService
      ),
      takeLatest(
         CoursesTypes.GET_HOW_IO_URL_SSO_REQUEST,
         getHowIoUrlSso,
         coursesService
      ),
      takeLatest(LicenceTypes.LICENCE_REQUEST, getLicences, licenceService),
      takeLatest(
         LicenceTypes.LICENCE_APPLICATION_REQUEST,
         getSelectedApplications,
         licenceService
      ),
      takeLatest(
         LicenceTypes.LICENCE_DETAILS_REQUEST,
         getSelectedDetails,
         licenceService
      ),
      takeLatest(
         PublicRegisterTypes.PUBLIC_REGISTER_REQUEST,
         publicRegister,
         publicRegisterService
      ),
      takeLatest(
         ReceiptsTypes.GET_USER_RECEIPTS_REQUEST,
         getUserReceipts,
         receiptsService
      ),
      takeLatest(
         ReceiptsTypes.GET_USER_RECEIPT_DETAILS_REQUEST,
         getUserReceiptDetails,
         receiptsService
      ),
      takeLatest(
         ReceiptsTypes.GET_USER_RECEIPT_DETAILS_FROM_REFERENCE_REQUEST,
         getUserReceiptDetailsFromReference,
         receiptsService
      ),
      takeLatest(
         ResultTypes.GET_RESULTS_BY_USER_ID_REQUEST,
         getResultsByUserId,
         resultsService
      ),
      takeLatest(
         ResultTypes.GET_EXAMINATION_RESULTS_REQUEST,
         getExaminationResults,
         resultsService
      ),
      takeLatest(
         SupervisionTypes.SUPERVISION_USER_REQUEST,
         supervisionUser,
         supervisionService
      ),
      takeLatest(
         SupervisionTypes.ACCEPT_SUPERVISION_REQUEST,
         acceptSupervisionUser,
         supervisionService
      ),
      takeLatest(
         SupervisionTypes.DECLINE_SUPERVISION_REQUEST,
         declineSupervisionUser,
         supervisionService
      ),
      takeLatest(
         SupervisionTypes.GET_USER_APPLICATIONS_RENEWAL_REQUEST,
         getUserApplicationsRenewal,
         supervisionService
      ),
      takeLatest(
         SupervisionTypes.CHANGE_SUPERVISION_END_DATE_REQUEST,
         changeSupervisionEndDate,
         supervisionService
      ),
      takeLatest(
         SupervisionTypes.GET_USER_APPLICATIONS_REGISTRATION_LICENCE_REQUEST,
         getUserApplicationsRegistrationLicence,
         supervisionService
      ),
      takeLatest(
         SupervisionTypes.GET_USER_APPLICATIONS_REQUEST,
         getUserApplications,
         supervisionService
      ),
      takeLatest(
         SupervisionTypes.SEARCH_SUPERVISOR_REQUEST,
         searchSupervisor,
         supervisionService
      ),
      takeLatest(
         SupervisionTypes.CHANGE_SUPERVISOR_REQUEST,
         changeSupervisor,
         supervisionService
      ),
      takeLatest(
         SupervisionTypes.NEW_SUPERVISOR_REQUEST,
         newSupervisor,
         supervisionService
      ),
      takeLatest(
         SupervisionTypes.GET_USER_SUPERVISION_TASKS_COMPLETE_REQUEST,
         getUserSupervisionTasksComplete,
         supervisionService
      ),
      takeLatest(
         SupervisionTypes.GET_MY_ACTIVE_CERTIFYING_REGISTRATIONS_REQUEST,
         getMyActiveCertifyingRegistrations,
         supervisionService
      ),
      takeLatest(
         UserInfoTypes.GET_USER_CONTACT_INFO_REQUEST,
         getUserContactInfo,
         userInfoService
      ),
      takeLatest(
         UserInfoTypes.SAVE_USER_CONTACT_INFO_REQUEST,
         saveUserContactInfo,
         userInfoService
      ),
      takeLatest(
         UserInfoTypes.SAVE_USER_PHOTO_INFO_REQUEST,
         saveUserPhotoInfo,
         userInfoService
      ),
      takeLatest(
         ExaminationTypes.GET_USER_EXAM_ELIGIBILITY_REQUEST,
         getUserExamEligibility,
         examinationService
      ),
      takeLatest(
         ExaminationTypes.GET_USER_EXAMINATION_DETAILS_REQUEST,
         getUserExaminationDetails,
         examinationService
      ),
      takeLatest(
         ExaminationTypes.GET_USER_EXAM_ELIGIBILITY_REPORT_REQUEST,
         getUserExamEligibilityReport,
         examinationService
      ),
      takeLatest(
         ExaminationTypes.GET_USER_EXAMS_REQUEST,
         getUserExams,
         examinationService
      ),
      takeLatest(
         ExaminationTypes.GET_AVAILABLE_EXAM_VENUES_REQUEST,
         getAvailableExamVenues,
         examinationService
      ),
      takeLatest(
         ExaminationTypes.GET_EXAM_VENUES_REQUEST,
         getExamVenues,
         examinationService
      ),
      takeLatest(
         ExaminationTypes.GET_EXAM_CYCLES_REQUEST,
         getExamCycles,
         examinationService
      ),
      takeLatest(
         ExaminationTypes.GET_EXAMINATION_RESULT_ADDRESS_REQUEST,
         getExaminationResultAddress,
         examinationService
      ),
      takeLatest(
         ExaminationTypes.SAVE_EXAM_APPLICATION_DATA_REQUEST,
         saveExamApplicationData,
         examinationService
      ),
      takeLatest(
         ExaminationTypes.GET_CONFIRMATION_RESULTS_REQUEST,
         getConfirmationResults,
         examinationService
      ),
      takeLatest(
         ExaminationTypes.WITHDRAW_USER_FROM_EXAMINATION_REQUEST,
         withdrawUserFromExamination,
         examinationService
      ),
      takeLatest(
         ExaminationTypes.UPDATE_EXAMINATION_RESULTS_ADDRESS_REQUEST,
         updateExaminationResultsAddress,
         examinationService
      ),
      takeLatest(
         RenewalTypes.GET_COURSE_URL_AND_DATE_REQUEST,
         getCourseUrlAndDate,
         renewalService
      ),
      takeLatest(
         RenewalTypes.GET_UNMET_APPLICATION_CRITERIA_REQUEST,
         getUnmetApplicationCriteria,
         renewalService
      ),
      takeLatest(
         RenewalTypes.GET_UNMET_APPLICATION_CRITERIA_BY_IDS_REQUEST,
         getUnmetApplicationCriteriaByIds,
         renewalService
      ),
      takeLatest(
         RenewalTypes.SAVE_CONTACT_DETAILS_CONFIRMATION_REQUEST,
         saveContactDetailsConfirmation,
         renewalService
      ),
      takeLatest(
         RenewalTypes.SAVE_APPLICATION_RENEWAL_ACTION_REQUEST,
         saveApplicationRenewalAction,
         renewalService
      ),
      takeLatest(
         DashboardTypes.SPECIAL_USER_REQUEST,
         specialUser,
         dashboardService
      ),
      takeLatest(
         DashboardTypes.GET_USEFUL_LINKS_REQUEST,
         getUsefulLinks,
         dashboardService
      ),
      takeLatest(
         DashboardTypes.GET_NOTIFICATIONS_REQUEST,
         getNotifications,
         dashboardService
      ),
      takeLatest(
         DashboardTypes.GET_GLOBAL_NOTIFICATION_REQUEST,
         getGlobalNotification,
         dashboardService
      ),
      takeLatest(
         DashboardTypes.REMOVE_NOTIFICATION_REQUEST,
         removeNotification,
         dashboardService
      ),
      takeLatest(
         PaymentTypes.GET_PAYMENT_URL_REQUEST,
         getPaymentUrl,
         paymentService
      ),
      takeLatest(
         CompanyRenewalTypes.GET_RENEWAL_APPLICATION_REQUEST,
         getRenewalApplication,
         companyRenewalService
      ),
      takeLatest(
         CompanyRenewalTypes.GET_COMPANY_RENEWAL_SEARCH_SUPERVISOR_REQUEST,
         getCompanyRenewalSupervisor,
         companyRenewalService
      ),
      takeLatest(
         CompanyRenewalTypes.GET_COMPANY_PAYMENT_RECEIPT_REQUEST,
         getCompanyPaymentReceipt,
         companyRenewalService
      ),
      takeLatest(
         RenewalSummaryTypes.GET_RENEWAL_SUMMARY_REQUEST,
         getRenewalSummary,
         renewalSummaryService
      ),
      takeLatest(
         RenewalSummaryTypes.GET_REGISTRATION_LICENCE_SUMMARY_REQUEST,
         getRegistrationLicenceSummary,
         renewalSummaryService
      ),
      takeLatest(
         RenewalSummaryTypes.APPLY_LICENCE_APPLICATIONS_REQUEST,
         applyLicenceApplications,
         renewalSummaryService
      ),
      takeLatest(
         RenewalTypes.SAVE_RENEWAL_APPLICATION_DATA_REQUEST,
         saveRenewalApplicationData,
         renewalService
      ),
      takeLatest(
         PaymentTypes.GET_PAYMENT_RESPONSE_REQUEST,
         getPaymentResponse,
         paymentService
      ),
      takeLatest(
         CompanyRenewalTypes.SAVE_COMPANY_RENEWAL_APPLICATION_DATA_REQUEST,
         saveCompanyRenewalApplicationData,
         companyRenewalService
      ),
      takeLatest(
         CompanyRenewalTypes.GET_COMPANY_RENEWAL_APPLICATIONS_REQUEST,
         getCompanyRenewalApplications,
         companyRenewalService
      ),
      takeLatest(
         BulkRenewalTypes.GET_BULK_RENEWAL_APPLICATION_REQUEST,
         getBulkRenewalApplication,
         bulkRenewalService
      ),
      takeLatest(
         BulkRenewalTypes.GET_BULK_RENEWAL_SEARCH_SUPERVISOR_REQUEST,
         getBulkRenewalSupervisor,
         bulkRenewalService
      ),
      takeLatest(
         BulkRenewalTypes.SAVE_BULK_RENEWAL_APPLICATION_DATA_REQUEST,
         saveBulkRenewalApplicationData,
         bulkRenewalService
      ),
      takeLatest(
         PaymentTypes.GET_PAYMENT_PROCESSING_STATUS_REQUEST,
         getPaymentProcessingStatus,
         paymentService
      ),
      takeLatest(PaymentTypes.PUT_FEE_REQUEST, putFeeRequest, paymentService),
      takeLatest(EnumTypes.GET_CITIES_REQUEST, getCities, enumService),
      takeLatest(
         EnumTypes.GET_POST_CODE_CITIES_REQUEST,
         getPostCodeCities,
         enumService
      ),
      takeLatest(
         SupervisionTypes.CLEAR_SUPERVISION_NOTIFICATIONS_REQUEST,
         clearSupervisionNotifications,
         supervisionService
      ),
      takeLatest(
         RegistrationTypes.GET_USER_REGISTRATION_REQUEST,
         getUserRegistration,
         registrationService
      ),
      takeLatest(
         RegistrationTypes.GET_REGISTRATION_FILE_REQUEST,
         getRegistrationFile,
         registrationService
      ),
      takeLatest(
         RegistrationTypes.GET_REGISTRATION_FILE_DOWNLOAD_REQUEST,
         getRegistrationFileDownload,
         registrationService
      ),
      takeLatest(
         RegistrationTypes.GET_REGISTRATION_FILES_BY_APPLICATION_REQUEST,
         getRegistrationFilesByApplication,
         registrationService
      ),
      takeLatest(
         RegistrationTypes.GET_REGISTRATION_FILE_BY_CONTACT_REQUEST,
         getRegistrationFileByContact,
         registrationService
      ),
      takeLatest(
         RegistrationTypes.PUT_REGISTRATION_FILE_REQUEST,
         putRegistrationFile,
         registrationService
      ),
      takeLatest(
         RegistrationTypes.DELETE_REGISTRATION_FILE_REQUEST,
         deleteRegistrationFile,
         registrationService
      ),
      takeLatest(
         RegistrationTypes.GET_ANOTHER_FILES_TO_UPLOAD_REQUEST,
         getAnotherFilesToUpload,
         registrationService
      ),
      takeLatest(
         RegistrationTypes.SUBMIT_ANOTHER_FILES_TO_UPLOAD_REQUEST,
         submitAnotherFilesToUpload,
         registrationService
      ),
      takeLatest(GenderTypes.GENDER_REQUEST, getGenders, genderService),
      takeLatest(
         EthnicityTypes.ETHNICITY_REQUEST,
         getEthnicities,
         ethnicityService
      ),

      takeLatest(
         FitAndProperTypes.QUESTIONS_AND_ANSWERS_REQUEST,
         getQsAndAs,
         fitAndProperService
      ),

      takeLatest(
         FitAndProperTypes.SAVE_ANSWER_LIST_REQUEST,
         saveAnswerList,
         fitAndProperService
      ),

      takeLatest(
         ApplicationsTypes.DELETE_APPLICATION_REQUEST,
         deleteApplication,
         applicationsService
      ),
   ]);
}
