import React from 'react';
import { string, arrayOf } from 'prop-types';
import Icon from '../../../../Components/Icon';

import { registrationType } from '../types';

const EMPLOYER_CONTACT_TYPE = 'E';
const ACTIVE_REGISTRATION = 'ACTV';

const EmptyRegistrationsCell = () => {
   return (
      <span className="block-text text-red bold">
         <Icon className="inline-icon" iconName="clear" />
         No Current Registrations Held
      </span>
   );
};

const RegistrationsCell = ({ contactType, registrations }) => {
   if (contactType === EMPLOYER_CONTACT_TYPE) return <></>;

   const activeRegistrations = registrations.filter(
      r => r.registrationStatusCode === ACTIVE_REGISTRATION
   );

   if (activeRegistrations.length === 0) return <EmptyRegistrationsCell />;

   const regLines = activeRegistrations.map(r => {
      return (
         <span
            key={r.registrationId}
            className="block-text black-text bold multi-line-cell"
         >
            <Icon className="inline-icon" iconName="check" />
            {r.registrationTypeName}
         </span>
      );
   });

   return <>{regLines}</>;
};

RegistrationsCell.propTypes = {
   contactType: string.isRequired,
   registrations: arrayOf(registrationType).isRequired,
};

export default RegistrationsCell;
