import React from 'react';
import PropTypes from 'prop-types';

const GenericTable = ({ tableData, checkAll, onCheckOne }) => {
   return tableData.map(data => {
      return (
         <>
            <div>
               <div />
            </div>
         </>
      );
   });
};

const { object: obj } = PropTypes;

GenericTable.propTypes = {
   tableData: obj.isRequired,
};

export default GenericTable;
