import React from 'react';

const RegistrationTypeReview = ({ applicationPayload }) => {
   return (
      <div className="registration-review-container">
         <div className="review-heading">
            <h6 className="review-title">REGISTRATION TYPE</h6>
         </div>
         {applicationPayload.map(x => (
            <div className="review-description">
               <b>{x.applicationTypeDescription}</b>
            </div>
         ))}
      </div>
   );
};

export default RegistrationTypeReview;
