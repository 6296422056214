import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import M from 'materialize-css';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import * as REGISTRATION from 'pgdb-data-layer/lib/Constants/Registration';
import RegistrationActions, {
   isFetchingRegistration,
} from 'pgdb-data-layer/lib/Redux/RegistrationRedux';
import ApplicationsAction, {
   isSaveApplicationSuccess,
   isSaveApplicationFailure,
   isSavingApplication,
} from 'pgdb-data-layer/lib/Redux/ApplicationsRedux';
import Modal from 'react-modal';
import Icon from '../../../Components/Icon';
import Loading from '../../../Components/Loading';
import Navigator from '../../../Navigation/Navigator';
import { getToken } from '../../../Session/SessionRedux';
import OptionSection from './OptionSection';
import './RegistrationType.scss';

const RegistrationType = ({
   getUserRegistrationRequest,
   isFetchingRegistration,
   token,
   userRegistration,
   saveApplicationRequest,
   userContactData,
   isSaveApplicationSuccess,
   isSaveApplicationFailure,
   isSavingApplicationBusy,
}) => {
   const [hasDispatched, setHasDispatched] = useState(false);
   const [isOpenInfo, setIsOpenInfo] = useState(false);
   const isLoading = !hasDispatched || isFetchingRegistration;
   const [saveSubmitted, setSaveSubmitted] = useState(false);
   const [isDisabled, setIsDisabled] = useState(false);
   const [plumberApplication, setPlumberApplication] = useState(null);
   const [gasfitterApplication, setGasfitterApplication] = useState(null);
   const [drainlayerApplication, setDrainlayerApplication] = useState(null);

   const plumber = [
      'Journeyman Plumber',
      'Tradesman Plumber',
      'Certifying Plumber',
   ];
   const gasfitter = [
      'Journeyman Gasfitter',
      'Tradesman Gasfitter',
      'Certifying Gasfitter',
   ];
   const drainlayer = [
      'Journeyman Drainlayer',
      'Tradesman Drainlayer',
      'Certifying Drainlayer',
   ];

   const sendPlumber = data => {
      setPlumberApplication(data);
   };

   const sendGasfitter = data => {
      setGasfitterApplication(data);
   };

   const sendDrainlayer = data => {
      setDrainlayerApplication(data);
   };

   useEffect(() => {
      getUserRegistrationRequest(token);
      setHasDispatched(true);
   }, [token]);

   useEffect(() => {
      if (isSaveApplicationSuccess && saveSubmitted) {
         M.toast({
            html: 'Successfully created the applications.',
            classes: 'success',
         });

         Navigator.toRegistrationApplicantDetails();
      } else if (isSaveApplicationFailure && saveSubmitted) {
         M.toast({
            html: 'Unable to proceed. Please check your existing applications.',
            classes: 'error',
         });
      }
   }, [isSaveApplicationSuccess, isSaveApplicationFailure]);

   useEffect(() => {
      if (
         !plumberApplication &&
         !gasfitterApplication &&
         !drainlayerApplication
      ) {
         setIsDisabled(true);
      } else {
         setIsDisabled(false);
      }
   }, [plumberApplication, gasfitterApplication, drainlayerApplication]);

   const checkIfDraftExists = () => {
      const { applicationRegistrations } = userRegistration.registration;

      // Goes through the user's applications and checks if there are any
      // existing applications of the same discipline that have been selected.

      if (applicationRegistrations.length !== 0) {
         const result = applicationRegistrations.filter(application => {
            return (
               application.applicationStatusCode !== 'DECL' && // needs to be adjusted after backend changes
               ((plumberApplication &&
                  plumberApplication.includes(application.discipline)) ||
                  (gasfitterApplication &&
                     gasfitterApplication.includes(application.discipline)) ||
                  (drainlayerApplication &&
                     drainlayerApplication.includes(application.discipline)))
            );
         });

         if (result.length > 0) {
            setIsOpenInfo(true);
            return true;
         }
      }
      setIsOpenInfo(false);
      return false;
   };

   const onSaveApplication = () => {
      if (!checkIfDraftExists()) {
         const payload = {
            applications: [],
         };
         if (plumberApplication !== null) {
            payload.applications.push({
               contactId: userContactData.contactId,
               applicationTypeCode: plumberApplication,
               licenseYearId: 0,
               createLicence: true,
               isRegistrationApplication: true,
            });
         }

         if (gasfitterApplication !== null) {
            payload.applications.push({
               contactId: userContactData.contactId,
               applicationTypeCode: gasfitterApplication,
               licenseYearId: 0,
               createLicence: true,
               isRegistrationApplication: true,
            });
         }

         if (drainlayerApplication !== null) {
            payload.applications.push({
               contactId: userContactData.contactId,
               applicationTypeCode: drainlayerApplication,
               licenseYearId: 0,
               createLicence: true,
               isRegistrationApplication: true,
            });
         }

         saveApplicationRequest(token, payload);
      }
   };

   const importantNote = () => {
      return (
         <div className="important-note">
            <div className="important-note-header">
               <ErrorOutlineIcon />
               <b>Important Note</b>
            </div>
            <p className="important-note-content">
               You can select more than one trade, however, you can not apply
               for more than one registration for the same trade.
            </p>
            <footer className="important-note-footer">
               If you need help, call us on our free phone{' '}
               <span className="important-note-phone">
                  <a href="tel:0800743262" rel="nofollow">
                     0800 743 262
                  </a>{' '}
               </span>
               or email{' '}
               <a
                  className="activeblue-text important-note-email"
                  href="mailto:registration@pgdb.co.nz"
               >
                  registration@pgdb.co.nz
               </a>
               <p>
                  Please continue with the rest of your registration
                  application.
               </p>
            </footer>
         </div>
      );
   };

   return (
      <main>
         <Loading isLoading={isLoading}>
            <div className="section no-pad-bot" id="index-banner">
               <div className="container registration-container">
                  <div className="row no-margin flex">
                     <div className="col l9 m8 s12">
                        <div className="col m8 offset-l1 s12">
                           <h4 className="columnheader">
                              Please select your registration type to start.
                           </h4>
                        </div>
                        <div className="col m12 l9 offset-l1 s11">
                           <OptionSection
                              disciplineCode={
                                 REGISTRATION.DISCIPLINE_CODE_PLUMBER
                              }
                              heading={
                                 REGISTRATION.DISCIPLINE_DESCRIPTION_PLUMBER
                              }
                              options={plumber}
                              sender={sendPlumber}
                           />
                           <OptionSection
                              disciplineCode={
                                 REGISTRATION.DISCIPLINE_CODE_GASFITTER
                              }
                              heading={
                                 REGISTRATION.DISCIPLINE_DESCRIPTION_GASFITTER
                              }
                              options={gasfitter}
                              sender={sendGasfitter}
                           />
                           <OptionSection
                              disciplineCode={
                                 REGISTRATION.DISCIPLINE_CODE_DRAINLAYER
                              }
                              heading={
                                 REGISTRATION.DISCIPLINE_DESCRIPTION_DRAINLAYER
                              }
                              options={drainlayer}
                              sender={sendDrainlayer}
                           />
                           <div className="row registration-type-profile-row next-button-registration right">
                              <div className="navigate-buttons">
                                 <button
                                    type="button"
                                    className="waves-light btn btn-inactive"
                                    onClick={() =>
                                       Navigator.toRegistrationApply()
                                    }
                                 >
                                    BACK
                                 </button>
                                 <button
                                    type="button"
                                    className={`waves-effect waves-light btn btn-ml-30
                                      ${
                                         isSavingApplicationBusy
                                            ? 'loading-button'
                                            : ''
                                      }`}
                                    onClick={() => {
                                       setSaveSubmitted(true);
                                       onSaveApplication();
                                    }}
                                    disabled={
                                       isDisabled || isSavingApplicationBusy
                                    }
                                 >
                                    SAVE DRAFT AND NEXT
                                 </button>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col l3 side-note-background">
                        {importantNote()}
                     </div>

                     <Modal
                        isOpen={isOpenInfo}
                        onRequestClose={() => {
                           setIsOpenInfo(false);
                        }}
                        className="delete-modal"
                        overlayClassName="generic-modal-overlay"
                        appElement={document.getElementById('root')}
                     >
                        <Icon
                           className="fit-model-icon material-icons right"
                           iconName="close"
                           onClick={() => setIsOpenInfo(false)}
                        />
                        <br />
                        <div className="fit-modal-body">
                           <h4>
                              You have a version of this application type in
                              progress!
                           </h4>
                           <p>
                              You can't make two applications for the same
                              registration type. If you have an application in
                              draft, close this tab and go to your Registrations
                              tab to continue or delete the existing
                              application. If you have an application for the
                              type in progress, wait for it to be
                              accepted/declined before starting a new
                              application.
                           </p>
                        </div>
                     </Modal>
                  </div>
               </div>
            </div>
         </Loading>
      </main>
   );
};

RegistrationType.propTypes = {};

const mapDispatchToProps = {
   getUserRegistrationRequest: RegistrationActions.getUserRegistrationRequest,
   saveApplicationRequest: ApplicationsAction.saveApplicationRequest,
};

const mapStateToProps = state => {
   return {
      userRegistration: state.registration,
      isFetchingRegistration: isFetchingRegistration(
         'getUserRegistration',
         state
      ),
      token: getToken(state),
      userContactData: state.user.contactData,
      isSaveApplicationSuccess: isSaveApplicationSuccess(
         'saveApplication',
         state
      ),
      isSaveApplicationFailure: isSaveApplicationFailure(
         'saveApplication',
         state
      ),
      isSavingApplicationBusy: isSavingApplication('saveApplication', state),
   };
};

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(RegistrationType);
