import React from 'react';
import { connect } from 'react-redux';
import SupervisionActions from 'pgdb-data-layer/lib/Redux/SupervisionRedux';
import { getToken } from '../../../Session/SessionRedux';

const ChangeDifferentCitySupervisorModalBody = ({
   modalState,
   dispatch,
   token,
}) => {
   const onModalConfirm = () => {
      dispatch(
         SupervisionActions.changeSupervisorRequest(token, {
            supervisionId: modalState.selectedRow.supervisionId,
            newSupervisorId: modalState.pendingNewSupervisor.supervisorGuid,
         })
      );
      dispatch(SupervisionActions.closeModal());
   };

   return (
      <>
         <div className="modal-content-padding">
            <div className="medium-font-size modal-content-line-height">
               <h4>Supervisor Not in Your Location</h4>
               <p>
                  Nominating a supervisor outside of your location will stop
                  your licence from going active until a supervision check has
                  taken place by the Board. Go back to nominate a different
                  supervisor or continue.
               </p>
            </div>
         </div>
         <div id="modal-footer" className="modal-footer center">
            <button
               type="submit"
               onClick={() => {
                  dispatch(SupervisionActions.closeModal());
               }}
               className="waves-effect waves-light btn btn-outline button-margin"
            >
               Back
            </button>
            <button
               type="submit"
               onClick={() => onModalConfirm()}
               className="waves-effect waves-light btn button-margin"
            >
               Continue
            </button>
         </div>
      </>
   );
};

const mapStateToProps = state => {
   return {
      modalState: state.supervision.modalState,
      token: getToken(state),
   };
};

export default connect(mapStateToProps)(ChangeDifferentCitySupervisorModalBody);
