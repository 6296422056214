import React, { useEffect } from 'react';
import { object, bool } from 'prop-types';
import { connect } from 'react-redux';
import './Summary.scss';

import FitAndProperActions, {
   getQAsData,
   isFetchingQA,
   isFetchingQASuccess,
} from 'pgdb-data-layer/lib/Redux/FitAndProperRedux';
import '../FitAndProper/FitAndProper.scss';

import { getToken } from '../../../Session/SessionRedux';
import Loading from '../../../Components/Loading';

const OPTION_YES = 'Yes';
const OPTION_NO = 'No';

const FitAndProperSummary = ({
   token,
   dispatch,
   fitAndProperData,
   isLoading,
}) => {
   const loadFitAndProperData = () => {
      dispatch(FitAndProperActions.questionsAndAnswersReset());
      dispatch(FitAndProperActions.questionsAndAnswersRequest(token));
   };

   useEffect(() => {
      loadFitAndProperData();
   }, []);

   return (
      <main>
         <Loading isLoading={isLoading}>
            <div className="section no-pad-bot" id="index-banner">
               <div className="">
                  <div className="row">
                     <div className="questions-page-divider">
                        <div className="questions-section">
                           {fitAndProperData.questionsAndAnswers.map((q, i) => {
                              const latestAnswer = q.latestAnswer === true 
                                       ? OPTION_YES : q.latestAnswer === false 
                                       ? OPTION_NO : 'Not Answered'

                              const answerColor = latestAnswer === OPTION_YES ? 'red-text' :
                                                   latestAnswer === OPTION_NO ? '' :
                                                   'grey-text';
                              
                              return <div className='question-and-answer' key={i}>
                                 <div className='col s10'>{q.questionText}</div>
                                 <div className={`col s2 ${answerColor}`}>{latestAnswer}</div>
                              </div>
                           })}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </Loading>
      </main>
   );
};

FitAndProperSummary.propTypes = {
   fitAndProperData: object.isRequired,
   isLoading: bool,
   isSaveSuccess: bool
};

const mapStateToProps = state => {
   return {
      token: getToken(state),
      isFitAndProperLoaded: isFetchingQASuccess(state),
      fitAndProperData: getQAsData(state),
      isLoading: isFetchingQA(state),
   };
};

export default connect(mapStateToProps)(FitAndProperSummary);