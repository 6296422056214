import React from 'react';
import { object, bool, func, number, oneOfType, string } from 'prop-types';
import PropTypes from 'prop-types';

const Dropdown = ({
   id,
   placeholder,
   values,
   disabled,
   value = undefined,
   keepPlaceholder = false,
   highlighted = false,
   onBlur = null,
   onChange = null,
   label = null,
   mandatory,
   inputValid = true,
}) => {
   const inputClasses = `browser-default form-control input-validation-error select-input${
      highlighted ? ' select-highlighted' : ''
   }${disabled ? ' input-disabled' : ''}${inputValid ? '' : ' input-invalid'}`;

   const optionEntries = Object.keys(values).map((key, _) => {
      return (
         <option key={key} value={key}>
            {values[key]}
         </option>
      );
   });

   return (
      <>
         {label && <span className="grey2-text input-header">{label}</span>}
         {mandatory && <span className="text-red"> *</span>}
         <select
            className={inputClasses}
            id={id}
            value={value}
            placeholder={placeholder}
            onBlur={onBlur}
            onChange={onChange}
            required
         >
            {placeholder !== null && !keepPlaceholder && (
               <option
                  value=""
                  disabled={!keepPlaceholder}
                  hidden={!keepPlaceholder}
               >
                  {placeholder}
               </option>
            )}
            {optionEntries}
         </select>
      </>
   );
};

Dropdown.propTypes = {
   id: string,
   placeholder: string,
   values: object.isRequired,
   disabled: bool,
   value: oneOfType([string, number]),
   keepPlaceholder: bool,
   highlighted: bool,
   onBlur: func,
   onChange: func,
   label: string,
   mandatory: PropTypes.bool,
   inputValid: bool,
};

Dropdown.defaultProps = {
   id: '',
   placeholder: '',
   disabled: false,
   value: undefined,
   keepPlaceholder: false,
   highlighted: false,
   onBlur: null,
   onChange: null,
   label: null,
   mandatory: false,
   inputValid: true,
};

export default Dropdown;
