import React from 'react';
import { string, func } from 'prop-types';
import { NOT_RENEWED } from 'pgdb-data-layer/lib/Constants/LicenceCard';
import * as LICENCE_CARD_HELPERS from 'pgdb-data-layer/lib/Helpers/LicenceCard';
import './LicenceCard.scss';
import { CardFooterLink } from './CardFooterLink';
import { LicenceStatus } from './LicenceStatus';
import { LicenceTitle } from './LicenceTitle';
import GetIconPath from './GetIconPath';

export const LicenceCard = ({
   level, // e.g. Certifying
   discipline, // e.g. Plumber
   status, // e.g. 'Active', 'Not Renewed', 'Pending' etc
   openCard,
   downloadCard,
}) => {
   const icon = LICENCE_CARD_HELPERS.getIconForStatus(status);
   const iconPath = GetIconPath(status, discipline);

   const color = LICENCE_CARD_HELPERS.getColorForLicences(status, discipline);
   const disabled = status === NOT_RENEWED;
   const opacityModifier = disabled ? ' opacity-01' : '';

   const cardClasses = `licence-card ${color}-licence`;
   const imageClass = `card-image${opacityModifier}`;

   return (
      <div className="col l4">
         <div className={cardClasses}>
            <img
               alt={`${discipline} Licence Card`}
               className={imageClass}
               src={iconPath}
            />
            <LicenceTitle level={level} discipline={discipline} />
            <LicenceStatus status={status} iconName={icon} />
         </div>
         <CardFooterLink
            status={status}
            openCard={openCard}
            downloadCard={downloadCard}
         />
      </div>
   );
};

LicenceCard.propTypes = {
   level: string.isRequired,
   discipline: string.isRequired,
   status: string.isRequired,
   openCard: func.isRequired,
   downloadCard: func.isRequired,
};
