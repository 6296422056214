import React, { Component } from 'react';
import { connect } from 'react-redux';
import './style.scss';
import Icon from '../../../Components/Icon';
import Callout from '../../../Components/Callout';
import { getErrorMessage } from 'pgdb-data-layer/lib/Helpers/Errors';

class ErrorPage extends Component {
   render() {
      const { location } = this.props;
      if (!location || !location.error || !location.error.type) {
         return null;
      }

      return (
         <main>
            <div className="container error-page-container">
               <div className="row">
                  <div className="col s12 offset-l1 header-title">
                     <h4 className="columnheader left">
                        <Icon iconName="warning" className="warning-icon" />
                        Oops!
                     </h4>
                  </div>
               </div>
               <div className="row error-message-body">
                  <div className="col s12 offset-l1">
                     <Callout>{getErrorMessage(location.error)}</Callout>
                  </div>
               </div>
               <div className="row controls">
                  <div className="col s12 offset-l1">
                     <button
                        className="waves-effect waves-light btn btn-ml-30"
                        onClick={() =>
                           (window.location =
                              'https://www.pgdb.co.nz/about/contact.html')
                        }
                     >
                        Contact Administrator
                     </button>
                  </div>
               </div>
            </div>
         </main>
      );
   }
}

const mapStateToProps = state => {
   return {
      state: state,
   };
};

export default connect(mapStateToProps)(ErrorPage);
