import React, { useEffect, useState } from 'react';
import { array, bool } from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import SupervisionActions, {
   isFetchingSupervision,
} from 'pgdb-data-layer/lib/Redux/SupervisionRedux';
import * as SUPERVISION_RENEWAL_HELPERS from 'pgdb-data-layer/lib/Helpers/RenewalSupervision';
import MySupervisorsTable from './Supervision/MySupervisorsTable';
import Callout from '../../Components/Callout';
import ExternalLink from '../../Components/ExternalLink';
import Navigator from '../../Navigation/Navigator';
import SupervisionModalAlert from '../../Components/SupervisionModalAlert/SupervisionModalAlert';
import { getToken } from '../../Session/SessionRedux';
import Loading from '../../Components/Loading';

const Footer = ({ cpdSkipped, applications }) => {
   const previousUrl = cpdSkipped ? '/renew-licences' : '/renew-cpd';
   const nextUrl = '/bulk-renew-landing';
   const returnUrl = new URLSearchParams(window.location.search).get(
      'returnurl'
   );

   return (
      <div className="col s3 m6 l6 offset-l1">
         <div className="row profile-row next-button-wrapper">
            <div className="col l6">
               <button
                  type="button"
                  className="waves-effect waves-light btn btn-inactive"
                  onClick={() => {
                     Navigator.to(previousUrl);
                  }}
               >
                  Back
               </button>
               <button
                  type="button"
                  className="waves-effect waves-light btn btn-ml-30"
                  disabled={
                     !SUPERVISION_RENEWAL_HELPERS.validRenewalActions(
                        applications
                     )
                  }
                  onClick={() => {
                     returnUrl
                        ? Navigator.to(`/${returnUrl}`)
                        : Navigator.to(nextUrl);
                  }}
               >
                  Next
               </button>
            </div>
         </div>
      </div>
   );
};

Footer.propTypes = {
   applications: array,
   cpdSkipped: bool,
};

Footer.defaultProps = {
   applications: [],
   cpdSkipped: false,
};

const loadApi = ({ dispatch, token }) => {
   dispatch(SupervisionActions.getUserApplicationsRenewalRequest(token, true));
};

const RenewSupervisors = ({
   dispatch,
   userApplications,
   modalState,
   location,
   token,
   isLoadingPage,
   isLoadingChangeSupervision,
}) => {
   const [hasDispatched, setHasDispatched] = useState(false);
   useEffect(() => {
      loadApi({ dispatch, token });
      setHasDispatched(true);
   }, []);

   const cpdSkipped = location && location.state && location.state.cpdSkipped;

   return (
      <main>
         <Loading isLoading={isLoadingPage || !hasDispatched}>
            <div className="section no-pad-bot" id="index-banner">
               <div className="container">
                  <div className="row">
                     <div className="col s3 m6 l10 offset-l1">
                        {modalState.modalOpen && <SupervisionModalAlert />}
                        <div className="row">
                           <h4 className="columnheader left">
                              Who are your Supervisors?
                           </h4>
                        </div>
                        {!isEmpty(userApplications) && (
                           <Loading
                              overlayOnChildren
                              isLoading={isLoadingChangeSupervision}
                           >
                              <MySupervisorsTable
                                 renew
                                 tableData={userApplications}
                              />
                           </Loading>
                        )}
                        <div className="row">
                           <div className="col l6">
                              {!SUPERVISION_RENEWAL_HELPERS.validRenewalActions(
                                 userApplications
                              ) ? (
                                 <Callout>
                                    You must nominate Supervisors to renew these
                                    licences&hellip;
                                 </Callout>
                              ) : (
                                 <Callout>
                                    Your licences will not be active until your
                                    nomination is approved by the supervisor.
                                    You can view our{' '}
                                    <ExternalLink
                                       href="https://www.pgdb.co.nz/media/zjgjzefm/supervision-policy-29-may-2023.pdf"
                                       className="activeblue-text"
                                    >
                                       Supervision Policy here.
                                    </ExternalLink>
                                 </Callout>
                              )}
                           </div>
                        </div>
                     </div>
                     <Footer
                        cpdSkipped={cpdSkipped}
                        applications={userApplications}
                     />
                  </div>
               </div>
            </div>
         </Loading>
      </main>
   );
};

const mapStateToProps = state => {
   const isLoadingChangeSupervision =
      isFetchingSupervision('changeSupervisor', state) ||
      isFetchingSupervision('newSupervisor', state);
   return {
      userApplications: state.supervision.mySupervisors,
      modalState: state.supervision.modalState,
      isLoadingPage: isFetchingSupervision('getUserApplicationsRenewal', state),
      isLoadingChangeSupervision,
      token: getToken(state),
   };
};

export default connect(mapStateToProps)(RenewSupervisors);
