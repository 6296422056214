import * as CONTACT from 'pgdb-data-layer/lib/Constants/Contact';
import * as REGISTRATION from 'pgdb-data-layer/lib/Constants/Registration';
import { object, string } from 'prop-types';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import React from 'react';
import RadioButtonsLeftSide from '../../../Components/RadioButtonsLeftSide';
import { 
  APPLICATION_TYPE_CERTIFYING_PLUMBER,
  APPLICATION_TYPE_CERTIFYING_GASFITTER,
  APPLICATION_TYPE_CERTIFYING_DRAINLAYER,
  APPLICATION_TYPE_TRADESMAN_PLUMBER,
  APPLICATION_TYPE_TRADESMAN_GASFITTER,
  APPLICATION_TYPE_TRADESMAN_DRAINLAYER,
  APPLICATION_TYPE_JOURNEYMAN_PLUMBER,
  APPLICATION_TYPE_JOURNEYMAN_GASFITTER,
  APPLICATION_TYPE_JOURNEYMAN_DRAINLAYER
} from 'pgdb-data-layer/lib/Constants/Application';

const OptionSection = ({
   heading,
   disciplineCode,
   options,
   userRegistration,
   sender
}) => {
   const [tradeRegistrations, setTradeRegistrations] = useState();
   const [disciplineType, setDisciplineType] = useState(null);
   const toDisable = [];

   const disableField = type => {
      if (disciplineCode === 'P') {
         toDisable.push(`${type} Plumber`);
      } else if (disciplineCode === 'G') {
         toDisable.push(`${type} Gasfitter`);
      } else if (disciplineCode === 'D') {
         toDisable.push(`${type} Drainlayer`);
      }
   };

   useEffect(() => {
      if (userRegistration && userRegistration.registration) {
         if (userRegistration.registration.registrations) {
            setTradeRegistrations(
               userRegistration.registration.registrations.filter(
                  reg =>
                     reg.registrationDiscipline === disciplineCode &&
                     (reg.registrationStatusCode ===
                        REGISTRATION.STATUS_ACTIVE ||
                        reg.registrationStatusCode ===
                           CONTACT.REGISTRATION_SUPERSEDED_STATUS_CODE ||
                        reg.registrationStatusCode ===
                           CONTACT.REGISTRATION_SUSPENDED_STATUS_CODE ||
                        reg.registrationStatusCode ===
                           CONTACT.REGISTRATION_CANCELLED_STATUS_CODE)
               )
            );
         }
      }
   }, [userRegistration]);

   if (!tradeRegistrations) return <></>;

   const journeymanRegistration = tradeRegistrations.find(reg =>
      reg.registrationTypeCode.startsWith(REGISTRATION.CODE_JOURNEYMAN)
   );

   const tradesmanRegistration = tradeRegistrations.find(reg =>
      reg.registrationTypeCode.startsWith(REGISTRATION.CODE_TRADESMAN)
   );

   const certifyingRegistration = tradeRegistrations.find(reg =>
      reg.registrationTypeCode.startsWith(REGISTRATION.CODE_CERTIFIER)
   );

   if (certifyingRegistration !== undefined) {
      // Cannot apply any registration if already a Certifier
      disableField('Journeyman');
      disableField('Tradesman');
      disableField('Certifying');
   } else if (tradesmanRegistration !== undefined) {
      // Cannot apply for Journeyman or Tradesman if already a Tradesman
      disableField('Journeyman');
      disableField('Tradesman');
   } else if (journeymanRegistration !== undefined) {
      // Cannot apply for Journeyman and Certifying if license is only Journeyman alone
      disableField('Journeyman');
      disableField('Certifying');
   } else if (
      certifyingRegistration === undefined ||
      tradesmanRegistration === undefined ||
      journeymanRegistration === undefined
   ) {
      // Can only apply for Journeyman or Tradesman if there's no existing registration at all
      disableField('Certifying');
   }

   const onValueChange = (groupName, val) => {
     if(val === disciplineType){
      setDisciplineType(null);
      sender(null);
      return;
     }else{
      setDisciplineType(val);
     }

     if(val === 'Certifying Plumber'){
      sender(APPLICATION_TYPE_CERTIFYING_PLUMBER);
     }
     else if(val === 'Certifying Gasfitter'){
       sender(APPLICATION_TYPE_CERTIFYING_GASFITTER)
     }
     else if(val === 'Certifying Drainlayer'){
       sender(APPLICATION_TYPE_CERTIFYING_DRAINLAYER)
     }
     else if(val === 'Tradesman Plumber'){
       sender(APPLICATION_TYPE_TRADESMAN_PLUMBER)
     }
     else if(val === 'Tradesman Gasfitter'){
       sender(APPLICATION_TYPE_TRADESMAN_GASFITTER)
     }
     else if(val === 'Tradesman Drainlayer'){
       sender(APPLICATION_TYPE_TRADESMAN_DRAINLAYER)
     }
     else if(val === 'Journeyman Plumber'){
       sender(APPLICATION_TYPE_JOURNEYMAN_PLUMBER)
     }
     else if(val === 'Journeyman Gasfitter'){
       sender(APPLICATION_TYPE_JOURNEYMAN_GASFITTER)
     }
     else if(val === 'Journeyman Drainlayer'){
       sender(APPLICATION_TYPE_JOURNEYMAN_DRAINLAYER)
     }
    }
   return (
      <div>
         <RadioButtonsLeftSide
            radioLabel="radio-label s10"
            radioDisplay="s2 registration-type-radio radio-display"
            radioOptions={options}
            title={heading}
            groupName={heading}
            onClick={onValueChange}
            toDisable={toDisable}
            value={disciplineType}
         />
      </div>
   );
};

OptionSection.propTypes = {
   heading: string.isRequired,
   disciplineCode: string.isRequired,
   options: Array.isRequired,
   userRegistration: object,
};

const mapStateToProps = state => {
   return {
      userRegistration: state.registration,
   };
};

export default connect(mapStateToProps)(OptionSection);
