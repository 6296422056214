import React, { Component } from 'react';
import './Supervision.scss';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { NavLink } from 'react-router-dom';
import SupervisionActions, {
   pendingSuperviseesSelector,
   acceptedSuperviseesSelector,
   getModalState,
   getMySupervisors,
   isFetchingSupervisionSuccess,
   isFetchingSupervision,
   canContactSupervise,
} from 'pgdb-data-layer/lib/Redux/SupervisionRedux';
import LicenceActions, {
   getLicenceData,
   isFetchingLicenceSuccess,
} from 'pgdb-data-layer/lib/Redux/LicenceRedux';
import MySupervisorsTable from './MySupervisorsTable';
import PendingSuperviseesTable from './PendingSuperviseesTable';
import ApprovedSuperviseesTable from './ApprovedSuperviseesTable';
import Callout from '../../../Components/Callout';
import ExternalLink from '../../../Components/ExternalLink';
import SupervisionModalAlert from '../../../Components/SupervisionModalAlert/SupervisionModalAlert';
import { getToken } from '../../../Session/SessionRedux';
import Loading from '../../../Components/Loading';

class Supervision extends Component {
   componentDidMount() {
      const { dispatch, token } = this.props;
      dispatch(SupervisionActions.getUserApplicationsRequest(token));
      dispatch(SupervisionActions.supervisionUserRequest(token));
      dispatch(
         SupervisionActions.getMyActiveCertifyingRegistrationsRequest(token)
      );
      dispatch(LicenceActions.licenceRequest(token));
      dispatch(SupervisionActions.clearSupervisionNotificationsRequest(token));
   }

   getSuperviseesSection = (
      pendingSupervisees,
      approvedSupervisees,
      isLoadingSupervisees,
      isLoadingEndDate
   ) => {
      const hasSupervisees =
         !isEmpty(pendingSupervisees) || !isEmpty(approvedSupervisees);

      if (!hasSupervisees) {
         return (
            <div className="col s3 m6 l10 offset-l1">
               <h4 className="columnheader">
                  There are no current licences under your supervision
               </h4>
            </div>
         );
      }

      return (
         <div className="col s3 m6 l10 offset-l1">
            <h4 className="columnheader">I&apos;m Supervising</h4>
            {!isEmpty(pendingSupervisees) && (
               <>
                  <span className="grey2-text medium-text medium-font">
                     People who have nominated you as their supervisor
                  </span>
                  <Loading overlayOnChildren isLoading={isLoadingSupervisees}>
                     <PendingSuperviseesTable tableData={pendingSupervisees} />
                  </Loading>
               </>
            )}
            <br />

            {!isEmpty(approvedSupervisees) && (
               <>
                  <hr />
                  <span className="grey2-text medium-text medium-font">
                     People under your supervision
                  </span>
                  <Loading overlayOnChildren isLoading={isLoadingEndDate}>
                     <ApprovedSuperviseesTable
                        tableData={approvedSupervisees}
                     />
                  </Loading>
               </>
            )}
         </div>
      );
   };

   render() {
      const {
         isLoading,
         isLoadingChangeSupervision,
         isLoadingSupervisees,
         isLoadingEndDate,
         userApplications,
         pendingSupervisees,
         acceptedSupervisees,
         modalState,
         contactAbleToSupervise,
      } = this.props;

      return (
         <main>
            <Loading isLoading={isLoading}>
               <div className="section no-pad-bot" id="index-banner">
                  <div className="container">
                     <div className="row">
                        <div className="col s3 m6 l10 offset-l1">
                           {modalState.modalOpen && <SupervisionModalAlert />}
                           {!isEmpty(userApplications) && (
                              <>
                                 <h4 className="columnheader">
                                    My Supervisors
                                 </h4>
                                 <span className="grey2-text medium-text medium-font">
                                    These are the supervisors for your current
                                    licences.
                                 </span>
                                 <Loading
                                    overlayOnChildren
                                    isLoading={isLoadingChangeSupervision}
                                 >
                                    <MySupervisorsTable
                                       tableData={userApplications}
                                    />
                                 </Loading>
                                 <Callout>
                                    Your licences will not be active until your
                                    nomination is approved by the supervisor.
                                    You can view our{' '}
                                    <ExternalLink href="https://www.pgdb.co.nz/media/zjgjzefm/supervision-policy-29-may-2023.pdf">
                                       Supervision Policy here.
                                    </ExternalLink>
                                 </Callout>
                              </>
                           )}
                           {isEmpty(userApplications) &&
                              !contactAbleToSupervise && (
                                 <>
                                    <h4 className="columnheader">
                                       No current supervision information
                                       available
                                    </h4>
                                    <span className="grey2-text medium-text medium-font">
                                       To relicense, click on{' '}
                                       <NavLink exact to="/dashboard">
                                          Home
                                       </NavLink>
                                       , and then on the Renewal tile.
                                    </span>
                                 </>
                              )}
                        </div>
                        {contactAbleToSupervise &&
                           this.getSuperviseesSection(
                              pendingSupervisees,
                              acceptedSupervisees,
                              isLoadingSupervisees,
                              isLoadingEndDate
                           )}
                     </div>
                  </div>
               </div>
            </Loading>
         </main>
      );
   }
}

const mapStateToProps = state => {
   const isLoaded =
      isFetchingLicenceSuccess(state) &&
      isFetchingSupervisionSuccess('getSupervisionUser', state) &&
      isFetchingSupervisionSuccess('getUserApplications', state) &&
      isFetchingSupervisionSuccess('getMyActiveCertifyingRegistrations', state);

   return {
      isLoading: !isLoaded,
      isLoadingChangeSupervision: isFetchingSupervision(
         'changeSupervisor',
         state
      ),
      isLoadingEndDate: isFetchingSupervision(
         'changeSupervisionEndDate',
         state
      ),
      isLoadingSupervisees:
         isFetchingSupervision('declineSupervision', state) ||
         isFetchingSupervision('acceptSupervision', state),
      userApplications: getMySupervisors(state),
      modalState: getModalState(state),
      licences: getLicenceData(state),
      contactAbleToSupervise: canContactSupervise(state),
      acceptedSupervisees: acceptedSuperviseesSelector(state),
      pendingSupervisees: pendingSuperviseesSelector(state),
      token: getToken(state),
   };
};

export default connect(mapStateToProps)(Supervision);
