import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { isSaveQASuccess } from 'pgdb-data-layer/lib/Redux/FitAndProperRedux';
import { func, object, string } from 'prop-types';
import FitAndProper from '../FitAndProper';
import { getToken } from '../../../Session/SessionRedux';
import * as CPD_HELPERS from 'pgdb-data-layer/lib/Helpers/CPD';
import Navigator from '../../../Navigation/Navigator';
import { getQAsData } from 'pgdb-data-layer/lib/Redux/FitAndProperRedux';
import UserActions from 'pgdb-data-layer/lib/Redux/UserInfoRedux';
import { isEmpty } from 'lodash';

const allNotAnswered = answerState => {
   const answers = Object.values(answerState);
   const notAnswered =
      answers.some(answer => answer === null) || answers.length === 0;

   return notAnswered;
};

const footerGenerator = (
   onBackAction,
   onNextAction,
   answerState,
   isRegistered
) => {
   return onSaveHandler => {
      return (
         <>
            <div className="col offset-l1">
               <div className="row profile-row next-button-wrapper">
                  <div className="col">
                     <button
                        type="button"
                        className="waves-effect waves-light btn btn-inactive"
                        onClick={() => {
                           let saved = false;
                           if (onSaveHandler) saved = onSaveHandler();
                           if (saved && onBackAction) onBackAction();
                        }}
                     >
                        Back
                     </button>
                     <button
                        type="button"
                        className="waves-effect waves-light btn btn-ml-30"
                        onClick={() => {
                           let saved = false;
                           if (onSaveHandler) saved = onSaveHandler();
                           if (saved && onNextAction) onNextAction();
                        }}
                        disabled={allNotAnswered(answerState) && isRegistered}
                     >
                        Next
                     </button>
                  </div>
               </div>
            </div>
         </>
      );
   };
};

const LicenceRegistrationFitAndProperPage = ({
   token,
   dispatch,
   fitAndProperData,
   userContactData,
}) => {
   const [navigateTo, setNavigateTo] = useState('');
   const [performedFooterOperation, setPerformedFooterOperation] = useState(
      false
   );
   const [answerState, setAnswerState] = useState({});
   const [isRegistered, setIsRegistered] = useState(false);

   useEffect(() => {
      dispatch(UserActions.getUserContactInfoRequest(token));
   }, []);

   useEffect(() => {
      if (
         fitAndProperData &&
         fitAndProperData.questionsAndAnswers &&
         fitAndProperData.questionsAndAnswers.length > 0 &&
         allNotAnswered(answerState)
      ) {
         fitAndProperData.questionsAndAnswers.map((q, i) => {
            answerState[q.id] = q.latestAnswer;
         });
         setAnswerState(answerState);
      }
   }, [fitAndProperData]);

   useEffect(() => {
      if (CPD_HELPERS.checkUserRegistration(userContactData)) {
         // only proceed with getting user results if they have a registration/licence
         setIsRegistered(true);
      }
   }, [userContactData]);

   if (navigateTo === 'back') {
      Navigator.to('/licence-regapp-contact-details');
   }

   if (navigateTo === 'next') {
      Navigator.to('/licence-regapp-cpd');
   }

   const readyToPreformFooterOpertation = () => {
      if (!performedFooterOperation) {
         setPerformedFooterOperation(true);
      }
   };

   return (
      <main>
         <div className="section no-pad-bot" id="index-banner">
            <FitAndProper
               footer={footerGenerator(
                  () => {
                     setNavigateTo('back');
                  },
                  () => {
                     setNavigateTo('next');
                  },
                  answerState,
                  isRegistered
               )}
               readyToPreformFooterOpertation={readyToPreformFooterOpertation}
               setAnswerState={setAnswerState}
            />
         </div>
      </main>
   );
};

LicenceRegistrationFitAndProperPage.propTypes = {
   dispatch: func.isRequired,
   location: object,
   fitAndProperData: object.isRequired,
   token: string.isRequired,
   userContactData: object.isRequired,
};

const mapStateToProps = state => {
   return {
      token: getToken(state),
      saveInProgress: isSaveQASuccess('saveAnswerList', state),
      fitAndProperData: getQAsData(state),
      userContactData: state.user.contactData,
   };
};

export default connect(mapStateToProps)(LicenceRegistrationFitAndProperPage);
