import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ReCaptcha } from 'react-recaptcha-v3';
import { NavLink } from 'react-router-dom';
import M from 'materialize-css';
import CompanyRenewalActions from 'pgdb-data-layer/lib/Redux/CompanyRenewalRedux';
import PaymentActions from 'pgdb-data-layer/lib/Redux/PaymentRedux';
import { COMPANY_RENEWAL } from 'pgdb-data-layer/lib/Constants/PaymentTransaction';
import * as _ from 'lodash';
import { isEmpty, pick } from 'lodash';
import Callout from '../../../../Components/Callout';
import Navigator from '../../../../Navigation/Navigator';
import CompanyLicenceRenewalTable from './Views/CompanyLicenceRenewalTable';
import CompanyLicenceRenewalTotal from './Views/CompanyLicenceRenewalTotal';
import Icon from '../../../../Components/Icon';
import ExternalLink from '../../../../Components/ExternalLink';
import Validator from '../../../../Utilities/Validator';
import './style.css';

const contactKeys = {
   businessName: 'Business Name',
   businessEmail: 'Business Email',
   contactName: 'Contact Name',
   phoneNumber: 'Contact Phone',
   businessAddress: 'Business Address',
};

class CompanyRenewalSummary extends Component {
   constructor(props) {
      super(props);
      this.state = {
         companyRenewal: {},
         disableProceedButton: true,
         loadRecaptcha: false,
         isVerified: false,
         recaptchaToken: '',
         getPaymentUrlRequestSubmitted: false,
         proceedToPayment: false,
         renewedToken: true,
      };

      this.recaptchaRef = React.createRef();
   }

   componentDidMount() {
      const { companyRenewal } = this.props;
      if (
         !companyRenewal ||
         !companyRenewal.selectedApplications ||
         !companyRenewal.selectedApplications.length
      ) {
         Navigator.toCompanyContactDetails();
      } else {
         const { removeSavingStatus } = this.props;
         this.setState({ loadRecaptcha: true });
         removeSavingStatus();
      }
      this.setState({ companyRenewal });
      window.onbeforeunload = null;
   }

   componentDidUpdate(prevProps, prevState) {
      const elems = document.querySelectorAll('.collapsible');
      M.Collapsible.init(elems);

      const {
         companyRenewal,
         getPaymentUrlRequest,
         paymentDetails,
      } = this.props;
      const { getPaymentUrlRequestSubmitted, renewedToken } = this.state;
      if (
         !Validator.isNullOrWhiteSpace(companyRenewal.transactionNumber) &&
         !getPaymentUrlRequestSubmitted &&
         renewedToken
      ) {
         const { recaptchaToken } = this.state;
         this.setState(
            { getPaymentUrlRequestSubmitted: true, renewedToken: false },
            () => {
               getPaymentUrlRequest(
                  {
                     transactionNumber: companyRenewal.transactionNumber,
                     urlFail: `${window.location.origin.toString()}/payment-fail`,
                     urlSuccess: `${window.location.origin.toString()}/payment-success/renew-company-payment-successful`,
                     transactionType: COMPANY_RENEWAL,
                  },
                  recaptchaToken
               );
            }
         );
         this.recaptchaRef.execute();
      }

      if (getPaymentUrlRequestSubmitted && _.has(paymentDetails, 'isError')) {
         if (!paymentDetails.isError) {
            window.location = paymentDetails.response;
         } else {
            this.setState({ getPaymentUrlRequestSubmitted: false }, () => {
               M.toast({
                  html: `Sorry we couldn't process the payment.
                  Please contact the Plumbers, Gasfitters and Drainlayers Board.`,
                  classes: 'error',
               });
            });
         }
      }
   }

   verifyCallback = recaptchaToken => {
      this.setState({ isVerified: true, recaptchaToken, renewedToken: true });
   };

   render() {
      const { companyRenewal, loadRecaptcha } = this.state;
      if (
         !companyRenewal ||
         !companyRenewal.selectedApplications ||
         !companyRenewal.selectedApplications.length
      ) {
         return null;
      }

      let total = 0;
      companyRenewal.selectedApplications.forEach(contact =>
         contact.applications.forEach(app =>
            app.fees
               .filter(f => !f.isPaid)
               .forEach(fee => (total += fee.amount))
         )
      );

      return (
         <main className="company-root">
            <div className="section no-pad-bot">
               <div className="container summary">
                  <div className="row">
                     <div className="col s12">
                        <h4 className="columnheader left">
                           Can you confirm all this information is correct?
                        </h4>
                     </div>
                  </div>
                  <Callout>
                     Please review the following information before proceeding
                     with payment
                  </Callout>

                  <hr />
                  {/* SECTION 1: My Contact Details */}
                  {this.rederCompanyContactDetails()}

                  <hr />
                  {/* SECTION 2: Lincences: Paid section */}
                  {this.renderLicences(
                     'Paid',
                     companyRenewal.selectedApplications,
                     1
                  )}

                  <hr />
                  {/* SECTION 3: Lincences: To Pay section */}
                  {this.renderLicences(
                     'To Pay',
                     companyRenewal.selectedApplications,
                     2
                  )}

                  <hr />
                  {/* SECTION 4: Total */}
                  <CompanyLicenceRenewalTotal totalAmount={total} />

                  <hr className="thick" />

                  {/* SECTION 6: Ts&Cs */}
                  {this.renderDeclaration(total)}

                  {loadRecaptcha && (
                     <ReCaptcha
                        ref={ref => (this.recaptchaRef = ref)}
                        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                        action="company_renewal_summary"
                        verifyCallback={this.verifyCallback}
                     />
                  )}
               </div>
            </div>
         </main>
      );
   }

   goBack = () => {
      const { savePaymentSummary } = this.props;
      savePaymentSummary();
      Navigator.toCompanyPayAndNominate();
   };

   handleDeclarationCheck = e => {
      if (e.target.checked) {
         this.setState({ disableProceedButton: false });
      } else {
         this.setState({ disableProceedButton: true });
      }
   };

   proceedPayment = () => {
      M.Toast.dismissAll();
      const { isVerified, recaptchaToken } = this.state;
      const {
         companyRenewal,
         saveCompanyRenewalApplicationDataRequest,
      } = this.props;

      if (!isVerified) {
         M.toast({
            html: `Sorry we couldn't process the request, please try again later.`,
            classes: 'error',
         });
         return;
      }

      if (
         !companyRenewal.selectedApplications ||
         companyRenewal.selectedApplications.length === 0
      ) {
         M.toast({
            html: `Sorry we couldn't process the payment.
            Please contact the Plumbers, Gasfitters and Drainlayers Board.`,
            classes: 'error',
         });
         return;
      }

      const applications = [];
      companyRenewal.selectedApplications.forEach(sa => {
         sa.applications.forEach(a => {
            applications.push(a);
         });
      });

      this.setState({ proceedToPayment: true, renewedToken: false }, () => {
         saveCompanyRenewalApplicationDataRequest(
            {
               businessName: companyRenewal.businessName,
               businessEmail: companyRenewal.businessEmail,
               contactName: companyRenewal.contactName,
               phoneNumber: companyRenewal.phoneNumber,
               businessBillingAddressAddressLine1:
                  companyRenewal.businessBillingAddressAddressLine1,
               businessBillingAddressAddressLine2:
                  companyRenewal.businessBillingAddressAddressLine2,
               businessBillingAddressSuburbTown:
                  companyRenewal.businessBillingAddressSuburbTown,
               businessBillingAddressPostalCode:
                  companyRenewal.businessBillingAddressPostalCode,
               businessBillingAddressCity:
                  companyRenewal.businessBillingAddressCity,
               businessBillingAddressFullAddress:
                  companyRenewal.businessBillingAddress.fullAddress,
               percentageCardFee: companyRenewal.percentageCardFee,
               applications,
            },
            recaptchaToken
         );
      });
      this.recaptchaRef.execute();
   };

   renderDeclaration = total => {
      const { proceedToPayment, companyRenewal } = this.state;

      return (
         <div className="row drawer">
            <li className="active">
               <div className=" drawer-outer-body">
                  <div className="drawer-inner-body table-container">
                     <div className="row mock-collapsible-header">
                        <label>
                           <input
                              type="checkbox"
                              className="filled-in checkbox-blue"
                              onChange={e => this.handleDeclarationCheck(e)}
                              disabled={total <= 0}
                           />
                           <span className="black-text terms-and-cond">
                              I declare that all information provided is true
                              and correct; and that I acknowledge and accept the
                              Plumbers, Gasfitters, and Drainlayers Board&apos;s
                              Terms of Use,{' '}
                              <ExternalLink href="https://www.pgdb.co.nz/trade/legislation-hub/policies/">
                                 Official Information and Privacy Policy
                              </ExternalLink>
                              <br />I also understand and acknowledge that all
                              fees are expressed in New Zealand Dollars and are
                              inclusive of Goods and Services Tax (GST); that
                              payments are made to the Plumbers, Gasfitters, and
                              Drainlayers Board; and that they can only be
                              completed by either Visa or Mastercard credit or
                              debit cards, or Account2Account transfers from
                              eligible banks. A 1.90% card fee will be applied
                              to all credit and debit card transactions.
                              {/* //// TODO get the parameter from the variable percentageCardFee but it is too complicate to  understand where come from and It has to be live as soon as possible
                                 {companyRenewal.percentageCardFee
                                 ? ` A ${
                                      companyRenewal.percentageCardFee
                                   }% card fee will be applied  to all credit and debit card transactions.`
                                 : ''} */}
                           </span>
                        </label>

                        <div className="btn-search-margin">
                           <button
                              type="button"
                              disabled={
                                 this.state.disableProceedButton ||
                                 proceedToPayment
                              }
                              className={`waves-effect waves-light btn btn-ml-30 ${
                                 proceedToPayment ? 'loading-button' : ''
                              }`}
                              onClick={this.proceedPayment}
                           >
                              Proceed to Payment
                           </button>
                        </div>
                     </div>
                  </div>
               </div>
            </li>
         </div>
      );
   };

   renderLicences(title, items, sectionId) {
      return (
         <div className="row collapsible drawer">
            <li className="active">
               <div className="collapsible-header drawer-title">
                  <div className="header-title">{title}</div>
                  <div onClick={() => this.goBack()} className="edit-btn">
                     <span className="inner-edit-btn">Edit</span>
                  </div>
                  <Icon className="caret" iconName="keyboard_arrow_down" />
               </div>
               <div className="collapsible-body drawer-outer-body">
                  <div className="drawer-inner-body">
                     <CompanyLicenceRenewalTable
                        renewalItems={items}
                        sectionId={sectionId}
                        onEdit={this.goBack}
                     />
                  </div>
               </div>
            </li>
         </div>
      );
   }

   rederCompanyContactDetails = () => {
      return (
         <div className="row collapsible drawer company-section">
            <li className="active">
               <div className="collapsible-header drawer-title">
                  <div className="header-title">Company Details</div>
                  <NavLink
                     exact
                     to="/renew-company-contact-details?returnurl=company-renewal-summary"
                     className="edit-btn"
                  >
                     <span className="inner-edit-btn">Edit</span>
                  </NavLink>
                  <Icon className="caret" iconName="keyboard_arrow_down" />
               </div>
               <div className="collapsible-body drawer-outer-body">
                  <div className="drawer-inner-body">
                     {this.renderContact()}
                  </div>
               </div>
            </li>
         </div>
      );
   };

   renderContact = () => {
      const { companyRenewal } = this.state;

      if (!companyRenewal) {
         return;
      }

      let filteredContactVals = {};
      filteredContactVals = pick(companyRenewal, [
         'businessName',
         'businessEmail',
         'contactName',
         'phoneNumber',
         'businessAddress',
      ]);

      filteredContactVals.businessAddress =
         companyRenewal.businessBillingAddress.fullAddress;

      const pairs = this.generateRows(filteredContactVals);
      if (isEmpty(pairs)) return;

      return pairs.map(element => {
         return (
            <div className="row shorter" key={element.fieldName}>
               <div className="col s3 subtitle">{element.fieldName}</div>
               <div className="col description">{element.value}</div>
            </div>
         );
      });
   };

   generateRows = contactVals => {
      const pairs = [];
      for (const key in contactKeys) {
         if (contactVals[key]) {
            const fieldName = contactKeys[key];
            if (!fieldName) continue;
            const value = contactVals[key];
            if (!value) continue;

            pairs.push({ fieldName, value });
         }
      }
      return pairs;
   };
}

const mapStateToProps = state => {
   return {
      companyRenewal: state.companyRenewal,
      paymentDetails: state.payment.paymentDetails,
   };
};

const mapDispatchToProps = {
   removeSavingStatus: CompanyRenewalActions.removeSavingStatus,
   savePaymentSummary: CompanyRenewalActions.savePaymentSummary,
   saveCompanyRenewalApplicationDataRequest:
      CompanyRenewalActions.saveCompanyRenewalApplicationDataRequest,
   getPaymentUrlRequest: PaymentActions.getPaymentUrlRequest,
};

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(CompanyRenewalSummary);
