import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import './RegistrationPayment.scss';
import SupervisionActions, {
    getMySupervisors,
    isFetchingSupervision,
} from 'pgdb-data-layer/lib/Redux/SupervisionRedux';
import PaymentActions, {
    isFetchingFeeRequest,
    isFetchingPaymentUrl,
    isOperationSuccess,
    isOperationFailure,
} from 'pgdb-data-layer/lib/Redux/PaymentRedux';
import RegistrationActions, {
    isFetchingRegistration,
} from 'pgdb-data-layer/lib/Redux/RegistrationRedux';
import M from 'materialize-css';
import { useParams, Redirect } from 'react-router-dom';
import { Icon } from '@material-ui/core';
import { REGISTRATION } from 'pgdb-data-layer/lib/Constants/PaymentTransaction';
import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-v3';
import { has } from 'lodash';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import RegistrationPaymentPopup from './Components/RegistrationPaymentPopup';
import RegistrationPaymentFailed from './RegistrationPaymentFailed';
import RadioButtons from '../../../Components/RadioButtons';
import CheckboxInput from '../../../Components/CheckboxInput';
import MySupervisorsTable from '../Supervision/MySupervisorsTable';
import Loading from '../../../Components/Loading';
import { getToken } from '../../../Session/SessionRedux';
import SupervisionModalAlert from '../../../Components/SupervisionModalAlert/SupervisionModalAlert';

const RegistrationPayment = ({
    userApplications,
    getUserApplicationsRequest,
    token,
    isLoadingRegistrations,
    isLoadingApplications,
    isFetchingPaymentUrl,
    userRegistration,
    getUserRegistrationRequest,
    putFeeRequest,
    registrationApplicationPayment,
    isFetchingFeeRequest,
    isFetchingFeeSuccess,
    isFetchingFeeFailure,
    modalState,
    isLoadingPage,
    isLoadingChangeSupervision,
    paymentDetails,
    getPaymentUrlRequest,
    user,
}) => {
    const [registrationOnly, setRegistrationOnly] = useState(false);
    const [selectedRadio, setSelectedRadio] = useState('');
    const [paymentTextToDisplay, setPaymentTextToDisplay] = useState('');
    const [isFormValid, setIsFormValid] = useState(false);
    const [isOpenInfo, setIsOpenInfo] = useState(false);
    const [isPaymentFailedPopup, setIsPaymentFailedPopup] = useState(false);
    const [selectedApplication, setSelectedApplication] = useState([]);
    const [isCertifying, setIsCertifying] = useState(false);
    const [recaptchaToken, setRecaptchaToken] = useState('');
    const [paymentResponse, setPaymentResponse] = useState(false);
    const [paymentFailed, setPaymentFailed] = useState(false);
    const [registrationPaymentType, setRegistrationPaymentType] = useState(-1);
    const [hasSupervisor, setHasSupervisor] = useState(true);

    const registrationOptions = [
        'Registration only',
        'Registration and practising licence',
        'Registration, practising licence and physical licence card ($4.50)',
    ];
    const { applicationId, contactId } = useParams();
    const formValidation = () => {
        if (registrationOptions.includes(selectedRadio)) {
            if (selectedRadio === 'Registration only' && registrationOnly) {
                setIsFormValid(true);
            } else if (
                selectedRadio !== 'Registration only' &&
                selectedRadio !== ''
            ) {
                setIsFormValid(true);
            } else {
                setIsFormValid(false);
            }
        } else {
            setIsFormValid(false);
        }
    };

    const getApplicationRegistrationCost = () => {
        if (selectedRadio === 'Registration only' && registrationOnly) {
            putFeeRequest({ applicationId, selectedOption: 0 }, token);
            setPaymentTextToDisplay('Registration:');
            setRegistrationPaymentType(0);
        } else if (selectedRadio === 'Registration and practising licence') {
            putFeeRequest({ applicationId, selectedOption: 1 }, token);
            setPaymentTextToDisplay('Registration and practising licence:');
            setRegistrationPaymentType(1);
        } else if (
            selectedRadio ===
            'Registration, practising licence and physical licence card ($4.50)'
        ) {
            putFeeRequest({ applicationId, selectedOption: 2 }, token);
            setPaymentTextToDisplay('Registration, practising licence and physical licence card:');
            setRegistrationPaymentType(2);
        }
    };

    const getCurrentApplication = () => {
        if (
            userRegistration.registration.applicationRegistrations !== undefined
        ) {
            const filterRegistration = userRegistration.registration.applicationRegistrations.filter(
                data => {
                    return data.applicationId === +applicationId;
                }
            );
            /* Check if application type code is among the certifying applications:
            APPLICATION_TYPE_CERTIFYING_PLUMBER = 'PC'
            APPLICATION_TYPE_CERTIFYING_GASFITTER = 'GC'
            APPLICATION_TYPE_CERTIFYING_DRAINLAYER = 'DC'
         */
            const isCertifying = filterRegistration.some(application =>
                application.applicationTypeCode.endsWith('C')
            );

            if (filterRegistration && filterRegistration.length !== 0) {
                const filteredSupervisor = userApplications.filter(supervisor => {
                    // Only show supervisor for specific application
                    return (
                        supervisor.licenceName ===
                        filterRegistration[0].applicationTypeDescription
                    );
                });

                // Checking if user has supervisor
                if (!isCertifying && !!selectedRadio &&
                    selectedRadio !== 'Registration only') {
                    if (filteredSupervisor.length === 0 && filterRegistration[0].supervisors === null) {
                        setHasSupervisor(false);
                    } else if (filteredSupervisor.length > 0 || filterRegistration[0].supervisors !== null) {
                        setHasSupervisor(true);
                    }
                }
            }

            setIsCertifying(isCertifying);
            return filterRegistration;
        }

        return undefined;
    };

    useEffect(() => {
        getUserRegistrationRequest(token);
        getUserApplicationsRequest(token);
    }, [token]);

    useEffect(() => {
        setSelectedApplication(getCurrentApplication());
    }, [userRegistration, selectedRadio, registrationOnly]);

    useEffect(() => {
        formValidation();
        if (selectedRadio !== 'Registration only' && !registrationOnly) {
            getApplicationRegistrationCost();
        }
    }, [selectedRadio]);

    useEffect(() => {
        formValidation();
        if (registrationOnly) {
            getApplicationRegistrationCost();
        }

    }, [registrationOnly]);

    useEffect(() => {
        loadReCaptcha(process.env.REACT_APP_RECAPTCHA_KEY);
    }, []);

    useEffect(() => {
        if (!isFetchingFeeRequest) {
            if (!isFetchingFeeSuccess && isFetchingFeeFailure) {
                M.toast({
                    html: 'Unable to process fees. Please try again after 1 minute.',
                    classes: 'error',
                });
            }
        }
    }, [isFetchingFeeRequest]);

    const updateToken = () => {
        // you will get a new token in verifyCallback
        recaptchaToken.execute();
    };

    const sideNote = () => {
        return (
            <div className="important-note">
                <div className="important-note-header">
                    <b>What is registration?</b>
                </div>
                <div className="important-note-content">
                    <p>
                        {`Being registered shows the public that you have proven knowledge, understanding and 
                     competence as a sanitary plumber, gasfitter or drainlayer.`}
                        <br />
                        {`Registration does not mean you can work as a sanitary plumber, gasfitter or drainlayer. 
                     You need a license to practice in the trades.`}
                    </p>
                    <p className="application-fee-title">
                        What is a practising licence?
                    </p>
                    <p>
                        {`You need a practising license to work as a sanitary plumber, gasfitter or drainlayer.`}
                        <br />
                        {`You need to be registered first before you can get a licence. `}
                        <br />
                        {`A licence needs to be renewed each year before 31 March.  `}
                    </p>
                    <p className="application-fee-title">
                        Fees
                        <br />
                        <a
                            target="_blank"
                            href="https://www.pgdb.co.nz/apply_for_registration/fees/"
                            rel="noopener noreferrer"
                        >
                            View Full Fees
                        </a>
                    </p>
                    <p className="application-fee-title">Supervisor Nomination </p>
                    <p>
                        {`If you choose to obtain a licence as well as registration, you
                  are required to nominate a supervisor.`}
                    </p>
                    <p>Your supervisor must:</p>
                    <p>
                        <FiberManualRecordIcon /> Be registered in a specific trade.
                    </p>
                    <p>
                        {`Your licence will not be active until your nomination is
                  approved by the supervisor.`}{' '}
                        <a
                            target="_blank"
                            href="https://www.pgdb.co.nz/manage_your_licence/supervision/"
                            rel="noopener noreferrer"
                        >
                            View Supervision policy
                        </a>
                    </p>
                    <p className="important-note-footer">
                        If you need help, call us on our free phone{' '}
                        <span className="important-note-phone">
                            <a href="tel:0800743262" rel="nofollow">
                                0800 743 262
                            </a>{' '}
                        </span>
                        or email{' '}
                        <a
                            className="activeblue-text important-note-email"
                            href="mailto:registration@pgdb.co.nz"
                        >
                            registration@pgdb.co.nz
                        </a>
                    </p>
                </div>
            </div>
        );
    };
    const registrationOnlyCheckbox = () => {
        const labelString = `By selecting "Registration only", I understand and 
      acknowledge that I am applying for registration only, 
      and do not require a practising licence. I am aware that 
      I cannot carry out restricted work without a current licence. 
      I am aware that once this registration class has been obtained,
      any authorisations that I currently hold in this trade will be cancelled.`;

        return (
            <div className="registration-only-container">
                <CheckboxInput
                    name={labelString}
                    label={labelString}
                    value={registrationOnly}
                    onChange={e => {
                        setRegistrationOnly(e.target.checked);
                    }}
                    disabled={selectedRadio !== 'Registration only'}
                />
            </div>
        );
    };

    const renderAmountTable = () => {
        if (!isFormValid) {
            return (
                <div>
                    <table className="total-amount-container">
                        <tbody>
                            <tr className="initial-amount">
                                <td className="text">Total balance to pay:</td>
                                <td className="amount">NZD 0.00</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="table-clause-container">
                        <p className="table-clause-text">
                            {`* This amount incl. GST and excl. payment fees.`}
                        </p>
                    </div>
                </div>
            );
        }
        if (
            isFormValid &&
            !isFetchingFeeRequest &&
            isFetchingFeeSuccess &&
            !isFetchingFeeFailure
        ) {
            return (
                <div>
                    <table className="total-amount-container">
                        <tbody>
                            <tr>
                                <td className="text first-row">{`${paymentTextToDisplay}`}</td>
                                <td className="amount">
                                    {registrationApplicationPayment.registrationTotalCost !==
                                        undefined
                                        ? `NZD ${registrationApplicationPayment.registrationTotalCost.toFixed(
                                            2
                                        )}`
                                        : 0}
                                </td>
                            </tr>
                            {registrationApplicationPayment.levy > 0 ? (
                                <tr>
                                    <td className="text">
                                        Disciplinary and prosecution levy:
                                    </td>
                                    <td className="amount">{`NZD ${registrationApplicationPayment.levy.toFixed(
                                        2
                                    )}`}</td>
                                </tr>
                            ) : null}
                            {registrationApplicationPayment.discount > 0 ? (
                                <tr>
                                    <td className="text">Waiver:</td>
                                    <td className="amount">{`NZD ${registrationApplicationPayment.discount.toFixed(
                                        2
                                    )}`}</td>
                                </tr>
                            ) : null}
                            <tr>
                                <td className="text last-row">Total balance to pay:</td>
                                <td className="amount">
                                    {registrationApplicationPayment.totalToBePaid !==
                                        undefined
                                        ? `NZD ${registrationApplicationPayment.totalToBePaid.toFixed(
                                            2
                                        )}`
                                        : 0}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="table-clause-container">
                        <p className="table-clause-text">
                            {`* This amount incl. GST and excl. payment fees.`}
                        </p>
                    </div>
                </div>
            );
        }
        if (isFetchingFeeRequest) {
            return (
                <div className="spinner-container">
                    <Icon className="loading-button" />
                </div>
            );
        }
        return null;
    };

    const renderWaiverText = () => {
        const application = userRegistration.registration.applicationRegistrations.filter(
            application => {
                return application.applicationId === parseInt(applicationId);
            }
        );

        return (
            <div className="waiver-note-container">
                <p className="waiver-text">
                    {application.length === 1 && application[0].discountNote !== ''
                        ? `* ${application[0].discountNote}`
                        : null}
                </p>
            </div>
        );
    };

    const renderSupervisor = () => {
        if (userRegistration.registration.applicationRegistrations) {
            if (selectedApplication && selectedApplication.length !== 0) {
                const filteredSupervisor = userApplications.filter(supervisor => {
                    // Only show supervisor for specific application
                    return (
                        supervisor.licenceName ===
                        selectedApplication[0].applicationTypeDescription
                    );
                });

                // Checking if user nominated a supervisor
                if (filteredSupervisor.length === 1 && !!selectedRadio &&
                    selectedRadio !== 'Registration only') {
                    if (!hasSupervisor) {
                        setHasSupervisor((prev) => !prev);
                    }
                }
                // Use 'mySupervisor' state
                if (filteredSupervisor.length !== 0) {
                    return (
                        <MySupervisorsTable
                            tableData={filteredSupervisor}
                            registrationSupervisor
                        />
                    );
                }
                const newSupervisor = [];

                if (selectedApplication[0].supervisors !== null) {
                    // If registration application supervisor is available for selected application then pass it as props
                    newSupervisor.push({
                        licence: null,
                        licenceDisciplineCode: selectedApplication[0].discipline,
                        licenceName:
                            selectedApplication[0].applicationTypeDescription,
                        applicationId:
                            selectedApplication[0].supervisors.applicationId,
                        supervisionId:
                            selectedApplication[0].supervisors.supervisionId,
                        supervisorName:
                            selectedApplication[0].supervisors.supervisorName,
                        registrationNumber:
                            selectedApplication[0].supervisors.registrationNumber,
                        supervisionStatus:
                            selectedApplication[0].supervisors.supervisionStatus,
                        supervisorRenewedLicence:
                            selectedApplication[0].supervisors.supervisorRenewedLicence,
                        startDate:
                            selectedApplication[0].supervisors.startDate,
                        endDate:
                            selectedApplication[0].supervisors.endDate,
                    });
                } else {
                    // If registration application is not available for selected application meaning we need to nominate new supervisor
                    newSupervisor.push({
                        licence: null,
                        licenceDisciplineCode: selectedApplication[0].discipline,
                        licenceName:
                            selectedApplication[0].applicationTypeDescription,
                        applicationId: selectedApplication[0].applicationId,
                    });
                }

                if (selectedApplication[0].supervisors !== null) {
                    // If registration application is available then do not pass renew flag
                    return (
                        <MySupervisorsTable
                            tableData={newSupervisor}
                            registrationSupervisor
                            className="registration-payment-table"
                        />
                    );
                }
                // If registration application is NOT available then pass renew flag and newSupervisor state to enable the 'Nominate Supervisor' command
                return (
                    <MySupervisorsTable
                        renew
                        tableData={newSupervisor}
                        registrationSupervisor
                        className="registration-payment-table"
                    />
                );
            }
        }
    };

    const radioSelection = (groupName, selectedValue) => {
        setSelectedRadio(selectedValue);
        if (selectedValue === 'Registration and practising licence') {
            setRegistrationOnly(false);
        } else if (
            selectedValue ===
            'Registration, practising licence and physical licence card ($4.50)'
        ) {
            setRegistrationOnly(false);
        }
    };

    const displayPaymentPopup = () => {
        // closes open payment popup, or opens closed payment popup
        setIsOpenInfo(!isOpenInfo);
    };

    const displayPaymentFailedPopup = () => {
        setIsPaymentFailedPopup(!isPaymentFailedPopup);
    };

    const getPolyPayment = () => {
        setPaymentResponse(true);
        getPaymentUrlRequest(
            {
                urlFail: `${window.location.origin.toString()}/registration-payment/${applicationId}/${contactId}?payment-fail`,
                urlCancel: `${window.location.origin.toString()}/registration-payment/${applicationId}/${contactId}?payment-fail`,
                urlSuccess: `${window.location.origin.toString()}/payment-success/registration-payment-success?paymentType=PoliPayment`,
                createLicence: selectedRadio === "Registration only" ? false : true,
                contactId: contactId,
                registrationPaymentType: registrationPaymentType,
                transactionType: REGISTRATION,
                applicationId: +applicationId,
                paymentType: 'PoliPayment',
            },
            recaptchaToken
        );
        displayPaymentPopup();
    };

    const getWindcavePayment = () => {
        setPaymentResponse(true);
        getPaymentUrlRequest(
            {
                urlFail: `${window.location.origin.toString()}/registration-payment/${applicationId}/${contactId}?payment-fail`,
                urlSuccess: `${window.location.origin.toString()}/payment-success/registration-payment-success`,
                createLicence: selectedRadio === "Registration only" ? false : true,
                contactId,
                registrationPaymentType: registrationPaymentType,
                transactionType: REGISTRATION,
                applicationId: +applicationId,
            },
            recaptchaToken
        );
        displayPaymentPopup();
    };

    useEffect(() => {
        const result = new URLSearchParams(window.location.search);
        if (result.has('payment-fail')) {
            setPaymentFailed(true);
            setRegistrationOnly(false)
            displayPaymentFailedPopup();
        }
    }, []);

    useEffect(() => {
        if (paymentResponse && has(paymentDetails, 'isError')) {
            if (!paymentDetails.isError) {
                window.location = paymentDetails.response;
            } else {
                M.toast({
                    html: `Sorry we couldn't process the payment.`,
                    classes: 'error',
                });
                setPaymentFailed(true);
                setRegistrationOnly(false)
                displayPaymentFailedPopup();
                setPaymentResponse(false);
            }
        }
    }, [paymentDetails, paymentResponse]);

    const verifyCallback = recaptchaToken => {
        setRecaptchaToken(recaptchaToken);
    };

    if (!isLoadingApplications && selectedApplication && selectedApplication[0]) {
        if (selectedApplication[0].applicationStatusCode !== 'PPAY') {
            return <Redirect push to="/dashboard" />;
        } else if (selectedApplication[0].applicationStatusCode === 'PPAY') {
            if (Number(selectedApplication[0].contactId) !== Number(contactId)) {
                return <Redirect push to="/dashboard" />;
            }
            return (
                <main>
                    <Loading
                        isLoading={
                            isLoadingRegistrations ||
                            isLoadingApplications ||
                            isFetchingPaymentUrl
                        }
                    >
                        <div className="section no-pad-bot" id="index-banner">
                            <div className="container registration-payment-container">
                                <div className="row no-margin flex">
                                    <div className="col l9 m8 s12">
                                        <div className="col m10 offset-l1 s11">
                                            <h4 className="columnheader registration-payment-header">
                                                Your registration application has been processed
                                            </h4>
                                            <h6 className="payment-subheader">
                                                Please review the options below and submit payment
                                                amount
                                            </h6>
                                            <h6 className="subheader">
                                                Note: If you are applying for a practising licence
                                                as a journeyman or tradesman, you will need to
                                                nominate a supervisor with an active certifying
                                                licence
                                            </h6>
                                        </div>
                                        <div className="col m10 l10 offset-l1 s11">
                                            <RadioButtons
                                                radioLabel="radio-label s10"
                                                radioDisplay="s2 registration-type-radio radio-display"
                                                radioOptions={registrationOptions}
                                                groupName="PaymentOption"
                                                onChange={(groupName, selectedValue) => {
                                                    radioSelection(groupName, selectedValue);
                                                }}
                                                toDisable={registrationOptions}
                                                conditionalComponent={registrationOnlyCheckbox}
                                                condition={['Registration only']}
                                                disabled={isFetchingFeeRequest}
                                            />
                                            {modalState.modalOpen && <SupervisionModalAlert />}
                                            {!!selectedRadio &&
                                                selectedRadio !== 'Registration only' &&
                                                !isCertifying && (
                                                    <Loading
                                                        overlayOnChildren
                                                        isLoading={isLoadingChangeSupervision}
                                                    >
                                                        {renderSupervisor()}
                                                    </Loading>
                                                )}

                                            {renderAmountTable()}
                                            {isFormValid && !isFetchingFeeRequest
                                                ? renderWaiverText()
                                                : null}
                                            <div className="row profile-row next-button-registration right">
                                                <div className="navigate-buttons">
                                                    <ReCaptcha
                                                        sitekey={
                                                            process.env.REACT_APP_RECAPTCHA_KEY
                                                        }
                                                        action="individual_registration"
                                                        // action="company_renewal_contact_details"
                                                        verifyCallback={verifyCallback}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row profile-row next-button-registration right">
                                                <div className="navigate-buttons">
                                                    <button
                                                        type="button"
                                                        disabled={
                                                            !isFormValid ||
                                                            isFetchingFeeRequest ||
                                                            !recaptchaToken ||
                                                            registrationPaymentType === -1 ||
                                                            selectedRadio === '' ||
                                                            isFetchingFeeFailure ||
                                                            (registrationOnly ? false : !hasSupervisor)
                                                        }
                                                        className="waves-effect waves-light button-margin btn registration-pay-button"
                                                        onClick={() => displayPaymentPopup()}
                                                    >
                                                        Pay
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col l3 m4 side-note-background">
                                        {sideNote()}
                                    </div>
                                    {isOpenInfo && (
                                        <RegistrationPaymentPopup
                                            onChange={() => {
                                                displayPaymentPopup();
                                            }}
                                            isModalOpen={isOpenInfo}
                                            onWindcavePayment={getWindcavePayment}
                                            onPolyPayment={getPolyPayment}
                                        />
                                    )}
                                    {paymentFailed && (
                                        <RegistrationPaymentFailed
                                            onChange={() => {
                                                displayPaymentFailedPopup();
                                            }}
                                            isModalOpen={isPaymentFailedPopup}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </Loading>
                </main>
            );
        }
    }

    return (
        <main>
            {!selectedApplication ? <Loading
                isLoading={
                    isLoadingRegistrations ||
                    isLoadingApplications ||
                    isFetchingPaymentUrl
                }
            >
            </Loading> :
                <div className="section no-pad-bot" id="index-banner">
                    <div className="container registration-payment-container">
                        <div className="row no-margin flex">
                            <div className="col l9 m8 s12">
                                <div className="col m10 offset-l1 s11">
                                    <h4 className="columnheader registration-payment-header">
                                        {selectedApplication.length === 0 ? `You've selected an invalid application` : ``}
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </main>)
};

const mapStateToProps = state => {
    const isLoadingChangeSupervision =
        isFetchingSupervision('changeSupervisor', state) ||
        isFetchingSupervision('newSupervisor', state);
    return {
        userApplications: getMySupervisors(state),
        token: getToken(state),
        isLoadingApplications: isFetchingSupervision(
            'getUserApplications',
            state
        ),
        isLoadingRegistrations: isFetchingRegistration(
            'getUserRegistration',
            state
        ),
        isFetchingPaymentUrl: isFetchingPaymentUrl(state),
        userRegistration: state.registration,
        registrationApplicationPayment:
            state.payment.registrationApplicationPayment,
        modalState: state.supervision.modalState,
        isLoadingPage: isFetchingSupervision('getUserApplicationsRenewal', state),
        isLoadingChangeSupervision,
        isFetchingFeeRequest: isFetchingFeeRequest(state),
        isFetchingFeeSuccess: isOperationSuccess('putFeeRequest', state),
        isFetchingFeeFailure: isOperationFailure('putFeeRequest', state),
        user: state.user,
        paymentDetails: state.payment.paymentDetails,
    };
};

const mapDispatchToProps = {
    getUserRegistrationRequest: RegistrationActions.getUserRegistrationRequest,
    getUserApplicationsRequest: SupervisionActions.getUserApplicationsRequest,
    putFeeRequest: PaymentActions.putFeeRequest,
    getPaymentUrlRequest: PaymentActions.getPaymentUrlRequest,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegistrationPayment);
