import React from 'react';
import { func, string, bool } from 'prop-types';

const SubmitButton = ({ onClick, text, colorInvert }) => {
   const color = colorInvert ? 'button-colour-invert' : '';
   return (
      <button className={`btn-large ${color}`} onClick={onClick}>
         {text}
      </button>
   );
};

SubmitButton.defaultProps = {
   text: 'Search',
   colorInvert: false,
};

SubmitButton.propTypes = {
   onClick: func.isRequired,
   text: string,
   colorInvert: bool,
};

export default SubmitButton;
