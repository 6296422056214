import React from 'react';
import PropTypes from 'prop-types';

const CheckboxInput = ({
   id,
   label,
   onChange,
   name,
   value,
   mandatory = false,
   disabled = false,
}) => {
   return (
      <label>
         <input
            type="checkbox"
            name={name}
            id={id}
            checked={value}
            className="filled-in checkbox-blue"
            onChange={onChange}
            disabled = {disabled}
         />
         <span className="black-text">
            {label}
            {mandatory && <span className="text-red"> *</span>}
         </span>
      </label>
   );
};

const { string, bool, func } = PropTypes;

CheckboxInput.propTypes = {
   label: string.isRequired,
   id: string,
   onChange: func,
   name: string,
   value: bool,
   mandatory: bool,
   disabled: bool
};

CheckboxInput.defaultProps = {
   id: '',
   value: false,
   mandatory: false,
   onChange: null,
   name: '',
   disabled: false
};

export default CheckboxInput;
