import React from 'react';
import PropTypes from 'prop-types';

const TableFooter = ({ className, children }) => {
   return <tfoot className={`${className}`}>{children}</tfoot>;
};

const { string, node } = PropTypes;

TableFooter.defaultProps = {
   className: '',
};

TableFooter.propTypes = {
   className: string,
   children: node.isRequired,
};

export default TableFooter;
