import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getCountryName } from 'pgdb-data-layer/lib/Constants/CountryCode';
import CountryDropdown from '../CountryDropdown';
import Button from '../Buttons/DefaultButton/button';
import Icon from '../Icon';
import './style.scss';

const CountryListMultiSelect = ({
   disabled = false,
   label = 'Select Country',
   inputValid = true,
   mandatory = false,
   selectedCountries = [],
   onUpdateSelectedCountries,
   readOnly = false,
}) => {
   const [country, setCountry] = useState('AFG');

   const renderCountryListItems = () =>
      selectedCountries.map(c => (
         <span className="country-item" key={c.value}>
            {getCountryName(c.value)}
            {!readOnly && (
               <Icon
                  className="delete-button right"
                  iconName="close"
                  onClick={() => deleteItemFromList(c.value)}
               />
            )}
         </span>
      ));

   const deleteItemFromList = countryVal => {
      const updatedList = selectedCountries.filter(f => f.value !== countryVal);
      onUpdateSelectedCountries(updatedList);
   };

   return (
      <div className="country-multiselect row">
         {!readOnly && (
            <div className="country-selector">
               {label && (
                  <div className="grey2-text country-select-header">
                     {label}
                     {mandatory && <span className="text-red"> *</span>}
                  </div>
               )}

               <div className="country-dropdown">
                  <CountryDropdown
                     name="country-dropdown-multiselect"
                     value={country}
                     placeholder="Country"
                     onChange={e => setCountry(e.target.value)}
                     disabled={disabled}
                     inputValid={inputValid}
                     mandatory
                  />
                  <Button
                     onClick={() => {
                        const alreadyAdded = selectedCountries.find(
                           f => f.value === country
                        );

                        if (!alreadyAdded) {
                           onUpdateSelectedCountries(prev => [
                              ...prev,
                              { value: country },
                           ]);
                        }
                     }}
                     text="ADD"
                     className="col country-add-button"
                     size="btn-large"
                     type="secondary"
                  />
               </div>
            </div>
         )}
         <div className="countries-selected-list row">
            {renderCountryListItems()}
         </div>
      </div>
   );
};

const { string, bool, func, arrayOf, shape } = PropTypes;

CountryListMultiSelect.propTypes = {
   placeholder: string,
   id: string,
   value: string,
   disabled: bool,
   onBlur: func,
   onChange: func,
   label: string,
   inputValid: bool,
   mandatory: bool,
   selectedCountries: arrayOf(
      shape({
         id: string,
         value: string,
      })
   ),
};

export default CountryListMultiSelect;
