import React from 'react';
import { string, arrayOf } from 'prop-types';

import {
   DetailedRow,
   DetailedRowContent,
   SectionHeader,
} from './DetailedSearchRow';
import { superviseeType } from '../types';

const Supervisee = ({ supervisee }) => {
   const licences = supervisee.licenceTypeNames || [];
   const supervisedLicences = licences.join(', ');

   return (
      <DetailedRowContent>
         <div className="col s12">
            <span className="bold">{supervisee.superviseeName}</span>
            <br />
            <span className="pr-subtext">{supervisedLicences}</span>
         </div>
      </DetailedRowContent>
   );
};

Supervisee.propTypes = {
   supervisee: superviseeType.isRequired,
};

const CurrentSupervision = ({ firstName, supervisees }) => {
   const sectionHeaderLabel = `${firstName.trim()} is currently supervising`;

   const superviseeRows = supervisees.map(s => {
      return <Supervisee key={s.contactId} supervisee={s} />;
   });

   return (
      <DetailedRow>
         <SectionHeader label={sectionHeaderLabel} />
         {superviseeRows}
      </DetailedRow>
   );
};

CurrentSupervision.propTypes = {
   firstName: string.isRequired,
   supervisees: arrayOf(superviseeType).isRequired,
};

export default CurrentSupervision;
