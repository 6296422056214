import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import PaymentActions, {
   isFetchingProcessingStatusSuccess,
   isFetchingProcessingStatusFailure,
   isFetchingProcessingStatus,
} from 'pgdb-data-layer/lib/Redux/PaymentRedux';
import LoadingScreen from 'react-loading-screen';
import * as helper from '../../Helpers/Helpers';
import Navigator from '../../Navigation/Navigator';

const PaymentSuccess = ({
   payment,
   getPaymentProcessingStatusRequest,
   isLoading,
   isSuccess,
   isError,
   location,
   match,
}) => {
   const redirect = helper.browserBackButtonPrevention();

   if (redirect) {
      return <Redirect push to={redirect} />;
   }

   const [initiated, setInitiated] = useState(false);

   const request = () => {
      const paymentType = new URLSearchParams(location.search).get(
         'paymentType'
      );
      if (paymentType === 'PoliPayment') {
         const result = `${new URLSearchParams(location.search).get('token')}`;
         getPaymentProcessingStatusRequest(result, true);
      } else {
         const result = new URLSearchParams(location.search).get('result');
         getPaymentProcessingStatusRequest(result);
      }
   };

   useEffect(() => {
      if (location.hash === '#!') {
         if (!initiated && !isLoading) {
            request();
            setInitiated(true);
         }
      }
   }, []);

   useEffect(() => {
      if (payment.processingStatus) {
         Navigator.to(`/${match.params.url}${location.search}`);
      }
      if (
         initiated &&
         !payment.processingStatus &&
         !isLoading &&
         isSuccess &&
         isError === null
      ) {
         setTimeout(() => {
            request();
         }, 3000);
      }
   }, [payment, isSuccess, isError, isLoading, initiated, request]);

   return (
      <div>
         <LoadingScreen
            loading
            bgColor="#F4F4F4"
            textColor="#676767"
            logoSrc="/images/loading.gif"
         >
            <div />
         </LoadingScreen>
         <div className="loading-screen-user-message">
            <h3>
               Your transaction is in the processing queue and could take a
               couple of minutes to complete.
            </h3>
            <h4>
               Please don’t close or refresh your browser; or use the browser’s
               back button… just bear with us.
            </h4>
            <p>
               If it has taken more than 2 minutes and you are going to close
               your browser, don’t submit a new payment until you’ve confirmed
               with PGDB that your transaction didn’t go through.
            </p>
         </div>
      </div>
   );
};

PaymentSuccess.propTypes = {
   payment: PropTypes.object.isRequired,
   getPaymentProcessingStatusRequest: PropTypes.func.isRequired,
   location: PropTypes.object.isRequired,
   match: PropTypes.object.isRequired,
   isSuccess: PropTypes.bool,
   isError: PropTypes.bool,
   isLoading: PropTypes.bool,
   isSuccessPoli: PropTypes.bool,
   isErrorPoli: PropTypes.bool,
   isLoadingPoli: PropTypes.bool,
};

PaymentSuccess.defaultProps = {
   isSuccess: false,
   isError: false,
   isLoading: false,
};

const mapState = state => ({
   payment: state.payment,
   isSuccess: isFetchingProcessingStatusSuccess(state),
   isError: isFetchingProcessingStatusFailure(state),
   isLoading: isFetchingProcessingStatus(state),
});

const mapDispatchToProps = {
   getPaymentProcessingStatusRequest:
      PaymentActions.getPaymentProcessingStatusRequest,
};

export default connect(
   mapState,
   mapDispatchToProps
)(PaymentSuccess);
