import React from 'react';
import { NavLink } from 'react-router-dom';

const RegistrationApplicantDetailsReview = ({ applicantDetails }) => {
   return (
      <div className="registration-review-container">
         <div className="review-heading">
            <h6 className="review-title">
               APPLICANT'S AND PUBLIC REGISTER DETAILS
            </h6>
            <div className="review-edit-section">
               <NavLink
                  to="/registration-applicant-details"
                  className="review-edit-button"
               >
                  <i className="material-icons edit-icon">create</i>
                  EDIT
               </NavLink>
            </div>
         </div>
         <div className="review-details">
            <p>
               First Name: <b>{applicantDetails.firstName}</b>
            </p>
            <p>
               Family Name: <b>{applicantDetails.lastName}</b>
            </p>
            <p>
               Gender: <b>{applicantDetails.gender.name}</b>
            </p>
            <p>
               Ethnicity: <b>{applicantDetails.ethnicity.name}</b>
            </p>
            {applicantDetails.physicalAddressLine1 ? (
               <p>
                  Physical Address:{' '}
                  <b>
                     {applicantDetails.physicalAddressLine1 &&
                        `${applicantDetails.physicalAddressLine1}, `}
                     {applicantDetails.physicalAddressLine2 &&
                        `${applicantDetails.physicalAddressLine2}, `}
                     {applicantDetails.physicalSuburbTown &&
                        `${applicantDetails.physicalSuburbTown}, `}
                     {applicantDetails.physicalCity &&
                        `${applicantDetails.physicalCity}, `}
                     {`${applicantDetails.physicalPostalCode}`}
                  </b>
               </p>
            ) : (
               <p>Physical Address: </p>
            )}
            <p>
               Mailing Address:{' '}
               <b>
                  {applicantDetails.mailingAddressLine1 &&
                     `${applicantDetails.mailingAddressLine1}, `}
                  {applicantDetails.mailingAddressLine2 &&
                     `${applicantDetails.mailingAddressLine2}, `}
                  {applicantDetails.mailingSuburbTown &&
                     `${applicantDetails.mailingSuburbTown}, `}
                  {applicantDetails.mailingCity &&
                     `${applicantDetails.mailingCity}, `}
                  {applicantDetails.mailingPostalCode}
               </b>
            </p>

            <p>
               Mobile: <b>{applicantDetails.mobileNumber}</b>
            </p>
            <p>
               Other Numbers: <b>{applicantDetails.phoneNumber}</b>
            </p>
            <p>
               Email: <b>{applicantDetails.emailAddress}</b>
            </p>
            <p>
               Business Phone Number: <b>{applicantDetails.publicPhone}</b>
            </p>
            <p>
               Business Address:{' '}
               <b>
                  {applicantDetails.businessAddressLine1 &&
                     `${applicantDetails.businessAddressLine1}, `}
                  {applicantDetails.businessAddressLine2 &&
                     `${applicantDetails.businessAddressLine2}, `}
                  {applicantDetails.businessSuburbTown &&
                     `${applicantDetails.businessSuburbTown}, `}
                  {applicantDetails.businessCity &&
                     `${applicantDetails.businessCity}, `}
                  {applicantDetails.businessPostalCode}
               </b>
            </p>
         </div>
      </div>
   );
};

export default RegistrationApplicantDetailsReview;
