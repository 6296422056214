import React, { Component } from 'react';
import './Summary.css';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import M from 'materialize-css';
import { isEmpty, pick, isEqual, has, cloneDeep } from 'lodash';
import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-v3';
import {
   INDIVIDUAL_RENEWAL,
   BULK_RENEWAL,
} from 'pgdb-data-layer/lib/Constants/PaymentTransaction';
import {
   CERTIFYING_LICENCE_TYPES,
   TRAINEE_LICENCE_TYPES,
   EXEMPT_LICENCE_TYPES,
   F2P_LICENCE_TYPES,
} from 'pgdb-data-layer/lib/Constants/LicenceTypes';
import RenewActions from 'pgdb-data-layer/lib/Redux/RenewalRedux';
import BulkRenewalActions from 'pgdb-data-layer/lib/Redux/BulkRenewalRedux';
import RenewSummaryActions from 'pgdb-data-layer/lib/Redux/RenewalSummaryRedux';
import PaymentActions from 'pgdb-data-layer/lib/Redux/PaymentRedux';
import PropTypes from 'prop-types';
import Callout from '../../../Components/Callout';
import ExternalLink from '../../../Components/ExternalLink';
import LicenceRenewalTable from './Views/LicenceRenewalTable';
import NominateASupervisorTable from './Views/NominateASupervisorTable';
import { cpdRequirementsMet } from './Views/StylingHelper';
import {
   Table,
   TableBody,
   TableRow,
   TableCell,
} from '../../../Components/GenericTable';
import * as helper from '../../../Helpers/Helpers';
import * as StatusCode from '../Shared/StatusCodes';
import Validator from '../../../Utilities/Validator';
import Icon from '../../../Components/Icon';
import PayAndNominateTable from './Views/PayAndNominateTable';
import { getToken } from '../../../Session/SessionRedux';
import Navigator from '../../../Navigation/Navigator';
import Loading from '../../../Components/Loading';
import FitAndProperSummary from './FitAndProperSummary';

const contactKeys = {
   fullName: 'Name',
   emailAddress: 'Contact Email Address',
   phoneNumber: 'Contact Phone Address',
   mobileNumber: 'Contact Mobile Number',
   physicalAddress: 'Physical Address',
   mailingAddress: 'Mailing Address',
   companyName: 'Business Name',
   businessPhone: 'Business Phone Number',
   businessEmail: 'Business Email',
   businessAddress: 'Business Address',
};

const bulkRenewUrl = '/bulk-renew-pay-and-nominate';

class RenewalSummary extends Component {
   constructor(props) {
      super(props);
      this.state = {
         isLoaded: false,
         summary: {},
         disableProceedButton: true,
         total: 0,
         getPaymentUrlRequestSubmitted: false,
         loadRecaptcha: false,
         isVerified: false,
         proceedToPayment: false,
      };
      this.recaptchaRef = React.createRef();
   }

   componentDidMount() {
      const { getRenewalSummaryRequest, token } = this.props;
      this.setState({ isLoaded: false });
      getRenewalSummaryRequest(token);

      loadReCaptcha(process.env.REACT_APP_RECAPTCHA_KEY);
   }

   componentDidUpdate(prevProps, prevState) {
      if (!isEqual(prevProps.renewalSummary, this.props.renewalSummary)) {
         this.setSummaryState();
      }

      const { bulkRenewal } = this.props;
      if (
         bulkRenewal &&
         bulkRenewal.selectedApplications &&
         bulkRenewal.selectedApplications.length
      ) {
         this.handleBulkPayment(prevProps);
      } else {
         this.handleIndividualPayment(prevProps);
      }
   }

   handleBulkPayment = prevProps => {
      const {
         getPaymentUrlRequest,
         user,
         paymentDetails,
         bulkRenewal,
      } = this.props;
      const { getPaymentUrlRequestSubmitted } = this.state;

      if (
         !Validator.isNullOrWhiteSpace(bulkRenewal.transactionNumber) &&
         prevProps.bulkRenewal.transactionNumber !==
            bulkRenewal.transactionNumber &&
         !getPaymentUrlRequestSubmitted
      ) {
         const { recaptchaToken } = this.state;
         this.setState({ getPaymentUrlRequestSubmitted: true }, () => {
            getPaymentUrlRequest(
               {
                  transactionNumber: bulkRenewal.transactionNumber,
                  urlFail: `${window.location.origin.toString()}/payment-fail`,
                  urlSuccess: `${window.location.origin.toString()}/payment-success/confirmation`,
                  contactId: user.contactData.contactId,
                  transactionType: BULK_RENEWAL,
               },
               recaptchaToken
            );
         });
         this.recaptchaRef.execute();
      }

      if (getPaymentUrlRequestSubmitted && has(paymentDetails, 'isError')) {
         if (!paymentDetails.isError) {
            window.location = paymentDetails.response;
         } else {
            this.setState({ getPaymentUrlRequestSubmitted: false }, () => {
               M.toast({
                  html: `Sorry we couldn't process the payment.
                  Please contact the Plumbers, Gasfitters and Drainlayers Board.`,
                  classes: 'error',
               });
            });
         }
      }
   };

   handleIndividualPayment = prevProps => {
      const {
         renewal,
         getPaymentUrlRequest,
         user,
         paymentDetails,
      } = this.props;
      const { getPaymentUrlRequestSubmitted } = this.state;

      if (
         !Validator.isNullOrWhiteSpace(renewal.transactionNumber) &&
         prevProps.renewal.transactionNumber !== renewal.transactionNumber &&
         !getPaymentUrlRequestSubmitted
      ) {
         const { recaptchaToken } = this.state;
         this.setState({ getPaymentUrlRequestSubmitted: true }, () => {
            getPaymentUrlRequest(
               {
                  transactionNumber: renewal.transactionNumber,
                  urlFail: `${window.location.origin.toString()}/payment-fail`,
                  urlSuccess: `${window.location.origin.toString()}/payment-success/confirmation`,
                  contactId: user.contactData.contactId,
                  transactionType: INDIVIDUAL_RENEWAL,
               },
               recaptchaToken
            );
         });
         this.recaptchaRef.execute();
      }

      if (getPaymentUrlRequestSubmitted && has(paymentDetails, 'isError')) {
         if (!paymentDetails.isError) {
            window.location = paymentDetails.response;
         } else {
            this.setState({ getPaymentUrlRequestSubmitted: false }, () => {
               M.toast({
                  html: `Sorry we couldn't process the payment.
                  Please contact the Plumbers, Gasfitters and Drainlayers Board.`,
                  classes: 'error',
               });
            });
         }
      }
   };

   verifyCallback = recaptchaToken => {
      this.setState({ isVerified: true, recaptchaToken });
   };

   setSummaryState() {
      const { renewalSummary, bulkRenewal } = this.props;

      if (
         renewalSummary &&
         renewalSummary.summary &&
         renewalSummary.summary.applications
      ) {
         let amount = 0;
         renewalSummary.summary.applications.forEach(a => {
            if (
               a.userRenewalAction === StatusCode.USER_ACTION_RENEW ||
               a.isDisciplinaryFee
            ) {
               a.fees
                  .filter(f => !f.isPaid)
                  .forEach(f => {
                     amount += f.amount;
                  });
            }
         });

         // add bulk renewal total as well.
         if (bulkRenewal && bulkRenewal.selectedApplications) {
            bulkRenewal.selectedApplications.forEach(c => {
               if (c.applications) {
                  c.applications.forEach(a => {
                     a.fees
                        .filter(f => !f.isPaid)
                        .forEach(i => (amount += i.amount));
                  });
               }
            });
         }

         this.setState(
            {
               summary: renewalSummary.summary,
               total: amount,
               isLoaded: true,
               loadRecaptcha: true,
            },
            () => {
               const elems = document.querySelectorAll('.collapsible');
               M.Collapsible.init(elems);
            }
         );
      }
   }

   proceedPayment = () => {
      M.Toast.dismissAll();
      const { summary, isVerified } = this.state;
      const { bulkRenewal } = this.props;

      if (!isVerified) {
         M.toast({
            html: `Sorry we couldn't process the request, please try again later.`,
            classes: 'error',
         });
         return;
      }

      const renewalApplications = cloneDeep(
         summary.applications.filter(
            f =>
               f.userRenewalAction === StatusCode.USER_ACTION_RENEW ||
               f.isDisciplinaryFee
         )
      );

      // validate the supervision condition:
      // check if any non-certified licence application without supervisor
      const invalidApplicationList = renewalApplications.filter(
         f =>
            !CERTIFYING_LICENCE_TYPES[f.licenceTypeCode] &&
            !f.isDisciplinaryFee &&
            !f.supervisorName
      );
      if (invalidApplicationList.length > 0) {
         M.toast({
            html: `Please nominate a supervisor for '${
               invalidApplicationList[0].description
            }'`,
            classes: 'error',
         });
         return;
      }

      let isBulk = false;
      let applications = null;

      // Add bulk renewal applications if any
      if (
         bulkRenewal &&
         bulkRenewal.selectedApplications &&
         bulkRenewal.selectedApplications.length
      ) {
         const bulkRenewalApplications = cloneDeep(
            bulkRenewal.selectedApplications
         );
         bulkRenewalApplications.forEach(i => {
            i.applications.forEach(a => {
               renewalApplications.push(a);
            });
         });

         applications = { applications: renewalApplications };
         isBulk = true;
      } else {
         applications = renewalApplications.filter(
            app =>
               app.fees.some(fee => !fee.isPaid) ||
               TRAINEE_LICENCE_TYPES[app.licenceTypeCode]
         ); // filter by payment paid
      }
      this.setState({ proceedToPayment: true }, () => {
         this.saveRenewalApplications(applications, isBulk);
      });
   };

   saveRenewalApplications = (renewalApps, isBulkRenewal) => {
      if (!renewalApps || renewalApps.length === 0) {
         M.toast({
            html: `Sorry we couldn't process the payment.
            Please contact the Plumbers, Gasfitters and Drainlayers Board.`,
            classes: 'error',
         });
         return;
      }

      const {
         saveRenewalApplicationDataRequest,
         saveBulkRenewalApplicationDataRequest,
         token,
      } = this.props;
      isBulkRenewal
         ? saveBulkRenewalApplicationDataRequest(token, renewalApps)
         : saveRenewalApplicationDataRequest(token, renewalApps);
   };

   generateRows = contactVals => {
      const pairs = [];
      for (const key in contactKeys) {
         if (contactVals[key]) {
            const fieldName = contactKeys[key];
            if (!fieldName) continue;
            const value = contactVals[key];
            if (!value) continue;

            pairs.push({ fieldName, value });
         }
      }
      return pairs;
   };

   // Bulk renewal section
   renderPayAndNominateOthers = applications => {
      return (
         <div className="row collapsible drawer">
            <li className="active">
               <div className="collapsible-header drawer-title">
                  <div className="header-title">Pay/Nominate others</div>
                  <NavLink exact to={bulkRenewUrl} className="edit-btn">
                     <span className="inner-edit-btn">Edit</span>
                  </NavLink>
                  <Icon className="caret" iconName="keyboard_arrow_down" />
               </div>
               <div className="collapsible-body drawer-outer-body">
                  <div className="drawer-inner-body table-container">
                     {!isEmpty(applications) && (
                        <>
                           <PayAndNominateTable
                              renewalItems={applications}
                              onEdit={() => Navigator.to(bulkRenewUrl)}
                           />
                        </>
                     )}
                  </div>
               </div>
            </li>
         </div>
      );
   };

   renderContactRows = (section, unfilteredContactVals) => {
      let filteredContactVals = {};

      if (section === 1) {
         filteredContactVals = pick(unfilteredContactVals, [
            'fullName',
            'emailAddress',
            'phoneNumber',
            'mobileNumber',
            'physicalAddress',
            'mailingAddress',
         ]);
      } else {
         filteredContactVals = pick(unfilteredContactVals, [
            'companyName',
            'businessPhone',
            'businessEmail',
            'businessAddress',
         ]);
      }

      const pairs = this.generateRows(filteredContactVals);
      if (isEmpty(pairs)) return;

      return pairs.map(element => {
         return (
            <div className="row shorter" key={element.fieldName}>
               <div className="col s3 subtitle">{element.fieldName}</div>
               <div className="col description">{element.value}</div>
            </div>
         );
      });
   };

   renderContactDetails = () => {
      const { summary } = this.state;
      return (
         <div className="row collapsible drawer">
            <li className="active">
               <div className="collapsible-header drawer-title">
                  <div className="header-title">My Contact Details</div>
                  <NavLink
                     exact
                     to="/profile?returnurl=renew-summary"
                     className="edit-btn"
                  >
                     <span className="inner-edit-btn">Edit</span>
                  </NavLink>
                  <Icon className="caret" iconName="keyboard_arrow_down" />
               </div>
               <div className="collapsible-body drawer-outer-body">
                  <div className="drawer-inner-body">
                     {this.renderContactRows(1, summary)}
                  </div>
                  <div className="collapsible-header drawer-title default-cursor">
                     <div className="header-title">
                        My Public Register Details
                     </div>
                  </div>
                  <div className="drawer-outer-body">
                     <div className="drawer-inner-body">
                        {this.renderContactRows(2, summary)}
                     </div>
                  </div>
               </div>
            </li>
         </div>
      );
   };

   renderLicenceDetails = () => {
      return (
         <div className="row collapsible drawer">
            <li className="active">
               <div className="collapsible-header drawer-title">
                  <div className="header-title">My Licences for renewal</div>
                  <NavLink
                     exact
                     to="/renew-licences?returnurl=renew-summary"
                     className="edit-btn"
                  >
                     <span className="inner-edit-btn">Edit</span>
                  </NavLink>
                  <Icon className="caret" iconName="keyboard_arrow_down" />
               </div>
               <div className="collapsible-body drawer-outer-body">
                  <div className="drawer-inner-body table-container">
                     {!isEmpty(this.state.summary.applications) && (
                        <>
                           <LicenceRenewalTable
                              tableData={this.state.summary.applications}
                           />
                        </>
                     )}
                  </div>
               </div>
            </li>
         </div>
      );
   };

   renderFitAndProperSummary = () => {
      return (
         <div className="row collapsible drawer">
            <li className="active">
               <div className="collapsible-header drawer-title">
                  <div className="header-title">Fit & Proper Questions</div>
                  <NavLink
                     exact
                     to="/fit2practice?returnurl=renew-summary"
                     className="edit-btn"
                  >
                     <span className="inner-edit-btn">Edit</span>
                  </NavLink>
                  <Icon className="caret" iconName="keyboard_arrow_down" />
               </div>
               <div className="collapsible-body drawer-outer-body">
                  <div className="drawer-inner-body table-container">
                     <FitAndProperSummary />
                  </div>
               </div>
            </li>
         </div>
      );
   };

   renderCpdRequirements = () => {
      const cpdDetails = cpdRequirementsMet(
         this.state.summary.metCpdRequirements
      );
      return (
         <div className="row drawer">
            <li className="active">
               <div className="mock-collapsible-header drawer-title">
                  <div className="header-title">My CPD Requirements</div>
               </div>
               <div className=" drawer-outer-body">
                  <div className="drawer-inner-body table-container">
                     <div className="row">
                        <div className="col cpd-cell">
                           <i
                              className={`material-icons inline-icon ${
                                 cpdDetails.color
                              }-text`}
                           >
                              {cpdDetails.icon}
                           </i>
                           <span
                              className={`height-adjust ${
                                 cpdDetails.color
                              }-text bold`}
                           >
                              {cpdDetails.message}
                           </span>
                        </div>
                     </div>
                  </div>
               </div>
            </li>
         </div>
      );
   };

   hasValidSupervisionDetails() {
      if (!this.state.summary || !this.state.summary.applications) {
         return true;
      }

      const certifiedApplicationCount = this.state.summary.applications.filter(
         f =>
            f.userRenewalAction === StatusCode.USER_ACTION_RENEW &&
            !!CERTIFYING_LICENCE_TYPES[f.licenceTypeCode]
      ).length;

      if (
         certifiedApplicationCount ===
         this.state.summary.applications.filter(
            f => f.userRenewalAction === StatusCode.USER_ACTION_RENEW
         ).length
      ) {
         return false;
      }
      return true;
   }

   renderNominateSupervisor = () => {
      // skip the whole section if all the licences are cerfier
      return (
         <div className="row collapsible drawer">
            <li className="active">
               <div className="collapsible-header drawer-title">
                  <div className="header-title">Nominate a Supervisor</div>
                  <NavLink
                     exact
                     to="/renew-supervisors?returnurl=renew-summary"
                     className="edit-btn"
                  >
                     <span className="inner-edit-btn">Edit</span>
                  </NavLink>
                  <Icon className="caret" iconName="keyboard_arrow_down" />
               </div>
               <div className="collapsible-body drawer-outer-body">
                  <div className="drawer-inner-body table-container">
                     {!isEmpty(this.state.summary.applications) && (
                        <NominateASupervisorTable
                           tableData={this.state.summary.applications}
                        />
                     )}
                  </div>
               </div>
            </li>
         </div>
      );
   };

   renderTotalSection = () => {
      const total = this.state.total
         ? helper.formatCurrency(this.state.total, 2)
         : 0.0;

      return (
         <Table className="table-head-compressed borderless total-table">
            <TableBody>
               <TableRow className="total-row">
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell className="total-label-cell">Total</TableCell>
                  <TableCell color="black" className="bold currency-cell">
                     $
                  </TableCell>
                  <TableCell className="total-cell">{total}</TableCell>
               </TableRow>
            </TableBody>
         </Table>
      );
   };

   renderDeclaration = () => {
      const { loadRecaptcha, total, proceedToPayment, summary } = this.state;

      return (
         <div className="row drawer">
            <li className="active">
               <div className=" drawer-outer-body">
                  <div className="drawer-inner-body table-container">
                     {total <= 0 ? (
                        <h3 className="center">
                           Your information has been recorded.
                        </h3>
                     ) : (
                        <div className="row mock-collapsible-header">
                           <label>
                              <input
                                 type="checkbox"
                                 className="filled-in checkbox-blue"
                                 onChange={e => this.handleDeclarationCheck(e)}
                                 disabled={total <= 0}
                              />
                              <span className="black-text terms-and-cond">
                                 I declare that all information provided is true
                                 and correct; and that I acknowledge and accept
                                 the Plumbers, Gasfitters, and Drainlayers
                                 Board&apos;s Terms of Use,{' '}
                                 <ExternalLink href="https://www.pgdb.co.nz/trade/legislation-hub/policies/">
                                    Official Information and Privacy Policy
                                 </ExternalLink>
                                 <br />I also understand and acknowledge that
                                 all fees are expressed in New Zealand Dollars
                                 and are inclusive of Goods and Services Tax
                                 (GST); that payments are made to the Plumbers,
                                 Gasfitters, and Drainlayers Board; and that
                                 they can only be completed by either Visa or
                                 Mastercard credit or debit cards, or
                                 Account2Account transfers from eligible banks.
                                 {summary.percentageCardFee
                                    ? ` A ${
                                         summary.percentageCardFee
                                      }% card fee will be applied  to all credit and debit card transactions.`
                                    : ''}
                              </span>
                           </label>

                           <div className="btn-search-margin">
                              {loadRecaptcha && (
                                 <ReCaptcha
                                    ref={ref => (this.recaptchaRef = ref)}
                                    sitekey={
                                       process.env.REACT_APP_RECAPTCHA_KEY
                                    }
                                    action="renewal_summary"
                                    verifyCallback={this.verifyCallback}
                                 />
                              )}
                              <button
                                 type="button"
                                 disabled={
                                    this.state.disableProceedButton ||
                                    proceedToPayment
                                 }
                                 className={`waves-effect waves-light btn btn-ml-30 ${
                                    proceedToPayment ? 'loading-button' : ''
                                 }`}
                                 onClick={this.proceedPayment}
                              >
                                 Proceed to Payment
                              </button>
                           </div>
                        </div>
                     )}
                  </div>
               </div>
            </li>
         </div>
      );
   };

   handleDeclarationCheck = e => {
      if (e.target.checked) {
         this.setState({ disableProceedButton: false });
      } else {
         this.setState({ disableProceedButton: true });
      }
   };

   render() {
      const { summary, isLoaded } = this.state;
      const { bulkRenewal } = this.props;
      const hasBulkNominationSection =
         bulkRenewal &&
         bulkRenewal.selectedApplications &&
         bulkRenewal.selectedApplications.length;

      // Check if all Trainee (or) Excempt licences ?
      const isAnyNonTrineeOrExcempt = summary.applications
         ? summary.applications.filter(
              f =>
                 !EXEMPT_LICENCE_TYPES[f.licenceTypeCode] &&
                 !TRAINEE_LICENCE_TYPES[f.licenceTypeCode]
           ).length
         : false;

      // check for any licence applications for which F2P applies
      const isF2pLicenceApplication = summary.applications
         ? summary.applications.filter(
              f => F2P_LICENCE_TYPES[f.licenceTypeCode]
           ).length
         : false;

      return (
         <main>
            <Loading isLoading={!isLoaded}>
               <div className="section no-pad-bot">
                  <div className="container summary">
                     <div className="row">
                        <div className="col s12">
                           <h4 className="columnheader left">
                              Can you confirm all this information is correct?
                           </h4>
                        </div>
                     </div>
                     <Callout>
                        Please review the following information before
                        proceeding with payment
                     </Callout>

                     <hr />

                     {/* SECTION 1: My Contact Details */}
                     {this.renderContactDetails()}
                     <hr />

                     {/* SECTION 2: My Licences for renewal */}

                     {this.renderLicenceDetails()}
                     <hr />

                     {/* SECTION 2.5 (haha): Fit & Proper questions */}
                     {isF2pLicenceApplication ? (
                        <>
                           {this.renderFitAndProperSummary()}
                           <hr />
                        </>
                     ) : null}

                     {/* SECTION 3: My CPD Requirements */}
                     {isAnyNonTrineeOrExcempt
                        ? summary &&
                          !summary.metCpdRequirements && (
                             <>
                                {this.renderCpdRequirements()}
                                <hr />
                             </>
                          )
                        : null}

                     {/* SECTION 4: Nominate a Supervisor */}
                     {this.hasValidSupervisionDetails() ? (
                        <>
                           {this.renderNominateSupervisor()} <hr />
                        </>
                     ) : null}

                     {/* SECTION 5: Pay/Nominate otheres */}
                     {hasBulkNominationSection ? (
                        <>
                           {this.renderPayAndNominateOthers(
                              bulkRenewal.selectedApplications
                           )}
                           <hr />
                        </>
                     ) : null}

                     {/* SECTION 6: Total Section */}
                     {this.renderTotalSection()}

                     <hr className="thick" />

                     {/* SECTION 6: Ts&Cs */}
                     {this.renderDeclaration()}
                  </div>
               </div>
            </Loading>
         </main>
      );
   }
}

RenewalSummary.propTypes = {
   getRenewalSummaryRequest: PropTypes.func.isRequired,
   saveRenewalApplicationDataRequest: PropTypes.func.isRequired,
   getPaymentUrlRequest: PropTypes.func.isRequired,
   paymentDetails: PropTypes.object,
   renewalSummary: PropTypes.object,
   renewal: PropTypes.object,
   user: PropTypes.object,
};

const mapStateToProps = state => {
   return {
      renewal: state.renewal,
      renewalSummary: state.renewalSummary,
      user: state.user,
      paymentDetails: state.payment.paymentDetails,
      bulkRenewal: state.bulkRenewal,
      token: getToken(state),
   };
};

const mapDispatchToProps = {
   getRenewalSummaryRequest: RenewSummaryActions.getRenewalSummaryRequest,
   saveRenewalApplicationDataRequest:
      RenewActions.saveRenewalApplicationDataRequest,
   saveBulkRenewalApplicationDataRequest:
      BulkRenewalActions.saveBulkRenewalApplicationDataRequest,
   getPaymentUrlRequest: PaymentActions.getPaymentUrlRequest,
};

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(RenewalSummary);
