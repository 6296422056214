import React, { useState } from 'react';
import { connect } from 'react-redux';
import SupervisionActions from 'pgdb-data-layer/lib/Redux/SupervisionRedux';
import DatePicker from '../../DatePicker/DatePicker';
import { getToken } from '../../../Session/SessionRedux';

const ChangeDateModalBody = ({ modalState, dispatch, token }) => {
   const [isEndOnSpecificDate, setIsEndOnSpecificDate] = useState(false);
   const [showDateInput, setShowDateInput] = useState(false);
   const [selectedDate, setSelectedDate] = useState(null);

   const onModalConfirm = () => {
      if (showDateInput) {
         dispatch(
            SupervisionActions.changeSupervisionEndDateRequest(token, {
               supervisionId: modalState.selectedRow.supervisionId,
               endDate: selectedDate,
               endImmediately: false,
            })
         );
         dispatch(SupervisionActions.closeModal());
      } else if (isEndOnSpecificDate) {
         setShowDateInput(true);
      } else {
         dispatch(SupervisionActions.changeModalAction('EndImmediately'));
      }
   };

   const buttonDisabled =
      (showDateInput && selectedDate === null) ||
      (selectedDate !== null && selectedDate.length === 0);

   return (
      <>
         <div className="modal-content-padding">
            <div className="medium-font-size modal-content-line-height">
               {!showDateInput ? (
                  <>
                     <p>
                        I would like to change my supervision of{' '}
                        {modalState.selectedRow.name} to
                     </p>
                     <div className="left-section">
                        <div className="radio-align-left">
                           <label>
                              <input
                                 onClick={e => {
                                    setIsEndOnSpecificDate(e.target.checked);
                                 }}
                                 className="with-gap"
                                 name="group1"
                                 type="radio"
                              />
                              <span>end on a specific date</span>
                           </label>
                        </div>
                        <div className="radio-align-left">
                           <label>
                              <input
                                 onClick={e => {
                                    setIsEndOnSpecificDate(e.target.checked);
                                 }}
                                 className="with-gap"
                                 name="group1"
                                 type="radio"
                                 defaultChecked
                              />
                              <span>end immediately</span>
                           </label>
                        </div>
                     </div>
                  </>
               ) : (
                  <>
                     <p>
                        You confirm your supervision of{' '}
                        {modalState.selectedRow.name} will end on
                        <DatePicker
                           callback={setSelectedDate}
                           pickerStartDate={new Date()}
                           pickerEndDate={
                              modalState.selectedRow.licenceYearEnds
                           }
                        />
                     </p>
                  </>
               )}
            </div>
         </div>
         <div id="modal-footer" className="modal-footer center">
            <button
               type="submit"
               onClick={() => {
                  dispatch(SupervisionActions.closeModal());
               }}
               className="waves-effect waves-light btn btn-outline button-margin"
            >
               {!showDateInput ? 'Cancel' : 'Back'}
            </button>
            <button
               type="submit"
               disabled={buttonDisabled}
               onClick={() => onModalConfirm()}
               className="waves-effect waves-light btn button-margin"
            >
               {!showDateInput ? 'Confirm' : 'Yes'}
            </button>
         </div>
      </>
   );
};

const mapStateToProps = state => {
   return {
      modalState: state.supervision.modalState,
      token: getToken(state),
   };
};

export default connect(mapStateToProps)(ChangeDateModalBody);
