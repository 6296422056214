import React from 'react';
import { arrayOf } from 'prop-types';
import { isEmpty } from 'lodash';

import {
   DetailedRow,
   DetailedRowContent,
   SectionHeader,
} from './DetailedSearchRow';
import { historicalLicenceType, licenceHistoryType } from '../types';

const LicenceHistoryCell = ({ history }) => {
   const licenceYearsText = history.licenceYears.join(', ');

   return (
      <>
         <span>{history.licenceTypeName}</span>
         <br />
         <span className="pr-subtext">{licenceYearsText}</span>
      </>
   );
};

LicenceHistoryCell.propTypes = {
   history: historicalLicenceType.isRequired,
};

const LicenceHistoryRow = ({ licenceHistory }) => {
   return (
      <DetailedRowContent>
         <div className="col s6">
            {!isEmpty(licenceHistory.current) && (
               <LicenceHistoryCell history={licenceHistory.current} />
            )}
         </div>
         <div className="col s6">
            {!isEmpty(licenceHistory.previous) && (
               <LicenceHistoryCell history={licenceHistory.previous} />
            )}
         </div>
      </DetailedRowContent>
   );
};

LicenceHistoryRow.propTypes = {
   licenceHistory: licenceHistoryType.isRequired,
};

const LicenceHistory = ({ history }) => {
   const licenceRows = history.map((h, i) => {
      return <LicenceHistoryRow key={i} licenceHistory={h} />;
   });

   return (
      <DetailedRow>
         <SectionHeader label="Licence History" />
         {licenceRows}
      </DetailedRow>
   );
};

LicenceHistory.propTypes = {
   history: arrayOf(licenceHistoryType).isRequired,
};

export default LicenceHistory;
