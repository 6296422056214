import React, { useRef, useEffect, useState, Fragment } from 'react';
import M from 'materialize-css';
import Moment from 'moment';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { array, object, string, func, bool } from 'prop-types';
import LicenceActions, {
   getLicenceData,
   isFetchingLicenceSuccess,
   isFetchingLicenceDetailsSuccess,
} from 'pgdb-data-layer/lib/Redux/LicenceRedux';
import UserActions, {
   getUserInfoData,
   isFetchingUserInfoSuccess,
} from 'pgdb-data-layer/lib/Redux/UserInfoRedux';
import RenewActions, {
   getUnmetApplicationCriteria,
   isFetchingRenewalSuccess,
} from 'pgdb-data-layer/lib/Redux/RenewalRedux';
import * as LICENCE_HELPERS from 'pgdb-data-layer/lib/Helpers/Licence';
import { isActiveLicenceStatus } from 'pgdb-data-layer/lib/Constants/Licence';
import { PENDING } from 'pgdb-data-layer/lib/Constants/LicenceCard';
import * as PROFILE_HELPERS from 'pgdb-data-layer/lib/Helpers/Profile';
import { LicenceCard } from '../../Components/LicenceCard/index';
import LicenceCardBack from '../../Components/LicenceIdCard/LicenceCardBack';
import LicenceCardFront from '../../Components/LicenceIdCard/LicenceCardFront';
import {
   PendingLicenceCallout,
   UnmetCriteriaCallout,
} from '../../Components/CriteriaCallout';
import Callout from '../../Components/Callout';
import './Licences.scss';
import { getToken } from '../../Session/SessionRedux';
import Loading from '../../Components/Loading';
import FileSaver from 'file-saver';

const EmptyLicences = () => {
   return (
      <main>
         <div className="section no-pad-bot">
            <div className="container">
               <br />
               <br />
               <div className="row center-align">
                  <div className="col l7 offset-l1">
                     <div className="inner-page-container">
                        <div className="row">
                           <h4 className="columnheader left">
                              You are currently unlicensed
                           </h4>
                        </div>
                     </div>
                  </div>
               </div>
               <br />
               <br />
            </div>
         </div>
      </main>
   );
};

const renderLicences = (licences, openCardHandler, downloadCardHandler) => {
   return licences.map(licence => {
      const level = LICENCE_HELPERS.getLicenceLevel(licence.typeCode);

      return (
         <LicenceCard
            key={licence.trade}
            level={level}
            discipline={licence.trade}
            status={licence.status}
            openCard={openCardHandler}
            downloadCard={downloadCardHandler}
         />
      );
   });
};

const FutureLicences = ({
   licences,
   expiry,
   unmetCriteria,
   openCardHandler,
   downloadCardHandler,
}) => {
   const unmetCriteriaCallouts = licences.map(l => {
      const matchingApp = unmetCriteria.find(
         c => l.typeCode === c.licenceTypeCode
      );
      if (matchingApp) {
         return (
            <UnmetCriteriaCallout
               key={l.typeCode}
               unmetCriteria={matchingApp}
            />
         );
      }

      if (l.status === PENDING) {
         return (
            <PendingLicenceCallout
               key={l.typeCode}
               licenceTypeCode={l.typeCode}
            />
         );
      }

      return <Fragment key={l.licenceTypeCode} />;
   });

   return (
      <div className="inner-page-container">
         <div className="row">
            <h4 className="columnheader left">
               My {LICENCE_HELPERS.getYearRange(expiry)} Licences
            </h4>
         </div>
         <div className="row">
            <p className="sub-header left">
               These are the licences you are renewing for the{' '}
               {LICENCE_HELPERS.getYearRange(expiry)} year.
            </p>
         </div>
         <div className="center-align card-row row">
            {renderLicences(licences, openCardHandler, downloadCardHandler)}
         </div>
         {unmetCriteriaCallouts}
      </div>
   );
};

FutureLicences.propTypes = {
   licences: array.isRequired,
   expiry: string.isRequired,
   unmetCriteria: array.isRequired,
   openCardHandler: func.isRequired,
   downloadCardHandler: func.isRequired,
};

const CurrentLicences = ({
   licences,
   expiry,
   openCardHandler,
   downloadCardHandler,
}) => {
   return (
      <div className="inner-page-container">
         <div className="row">
            <h4 className="columnheader left">
               My {LICENCE_HELPERS.getYearRange(expiry)} Licences
            </h4>
         </div>
         <div className="center-align card-row row">
            {renderLicences(licences, openCardHandler, downloadCardHandler)}
         </div>
         <Callout>
            You can view your licence details on the{' '}
            <NavLink exact to="/public-register">
               Public Register
            </NavLink>
            . If you are registered, this will include your registration dates
            and licensing history as a registered person.
         </Callout>
      </div>
   );
};

CurrentLicences.propTypes = {
   licences: array.isRequired,
   expiry: string.isRequired,
   openCardHandler: func.isRequired,
   downloadCardHandler: func.isRequired,
};

const createPersonCardDto = (firstName, lastName, photo, licenceId, licences, expiry) => {
   const trades = licences
      .filter(l => isActiveLicenceStatus(l.statusCode))
      .map(l => `${LICENCE_HELPERS.getLicenceLevel(l.typeCode)} ${l.trade}`);
   Moment.locale('en');
   const expire = Moment(expiry)
      .format('DD MMMM YYYY')
      .toUpperCase();

   return {
      FirstName: firstName,
      LastName: lastName,
      Image: photo,
      Barcode: licenceId,
      Expire: expire,
      Trades: trades,
   };
};

const Licence = ({
   userSession,
   userLicence,
   licencesLoaded,
   contactData,
   token,
   dispatch,
   unmetCriteria,
   isDownLoaded,
   licenceDetails,
}) => {
   const currentModalEl = useRef(null);
   const nextModalEl = useRef(null);
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [hasDispatched, setHasDispatched] = useState(false);
   const [isPdfDownloadStarted, setIsPdfDownloadStarted] = useState(false);

   useEffect(() => {
      dispatch(LicenceActions.licenceRequest(token));
      dispatch(UserActions.getUserContactInfoRequest(token));
      dispatch(RenewActions.getUnmetApplicationCriteriaRequest(token));
      setHasDispatched(true);
   }, []);

   useEffect(() => {
      if (isPdfDownloadStarted && isDownLoaded) {
         const blob = new Blob([licenceDetails], {
            type: 'application/pdf',
         });

         FileSaver.saveAs(
            blob,
            `Licence-details-${userSession.licence_id}.pdf`
         );
         setIsPdfDownloadStarted(false);
         dispatch(LicenceActions.licenceDetailsReset());
      }
   }, [licenceDetails, isDownLoaded, isPdfDownloadStarted]);

   const closeModals = refs => {
      setIsModalOpen(false);
      refs.forEach(ref => {
         const element = M.Modal.init(ref);
         element.close();
      });
   };

   const openModal = ref => {
      setIsModalOpen(true);
      const element = M.Modal.init(ref);
      element.open();
   };

   const isEmpty =
      licencesLoaded &&
      LICENCE_HELPERS.checkLicences(userLicence) &&
      (userLicence.currentLicences == null ||
         userLicence.currentLicences.length === 0) &&
      (userLicence.nextLicences == null ||
         userLicence.nextLicences.length === 0);
   if (isEmpty) return <EmptyLicences />;

   const hasCurrentLicences =
      LICENCE_HELPERS.checkLicences(userLicence) &&
      userLicence.currentLicences != null &&
      userLicence.currentLicences.length > 0;

   const hasFutureLicences =
      LICENCE_HELPERS.checkLicences(userLicence) &&
      userLicence.nextLicences != null &&
      userLicence.nextLicences.length > 0;

   const userName = contactData ? contactData.fullName : 'PGDB User';
   const licenceId =
      contactData && contactData.registrationNumber
         ? contactData.registrationNumber
         : 0;

   const currentPersonCard = hasCurrentLicences
      ? createPersonCardDto(
           contactData.firstName,
           contactData.lastName,
           PROFILE_HELPERS.getUserContactPhoto(contactData),
           licenceId,
           userLicence.currentLicences,
           userLicence.currentData.expires
        )
      : {};
   const futurePersonCard = hasFutureLicences
      ? createPersonCardDto(
           contactData.firstName,
           contactData.lastName,
           PROFILE_HELPERS.getUserContactPhoto(contactData),
           licenceId,
           userLicence.nextLicences,
           userLicence.nextData.expires
        )
      : {};

   const downloadLicencePdf = expiryDate => {
      if (token) {
         dispatch(
            LicenceActions.licenceDetailsRequest(token, licenceId, expiryDate)
         );
         setIsPdfDownloadStarted(true);
      }
   };

   return (
      <main>
         <Loading isLoading={!hasDispatched || !licencesLoaded}>
            <div className="section no-pad-bot">
               <div className="container">
                  <br />
                  <br />
                  <div className="row center-align">
                     <div className="col l7 offset-l1">
                        <div
                           id="modal1"
                           ref={currentModalEl}
                           className="modal black-text licence-modal"
                        >
                           {hasCurrentLicences && isModalOpen && (
                              <>
                                 <LicenceCardFront
                                    details={currentPersonCard}
                                 />
                                 <br />
                                 <LicenceCardBack details={currentPersonCard} />
                              </>
                           )}
                        </div>
                        <div
                           id="modal2"
                           ref={nextModalEl}
                           className="modal black-text licence-modal"
                        >
                           {hasFutureLicences && (
                              <>
                                 <LicenceCardFront details={futurePersonCard} />
                                 <br />
                                 <LicenceCardBack details={futurePersonCard} />
                              </>
                           )}
                        </div>
                        {hasFutureLicences && (
                           <FutureLicences
                              licences={userLicence.nextLicences}
                              expiry={userLicence.nextData.expires}
                              unmetCriteria={unmetCriteria}
                              openCardHandler={() => {
                                 closeModals([
                                    currentModalEl.current,
                                    nextModalEl.current,
                                 ]);
                                 openModal(nextModalEl.current);
                              }}
                              downloadCardHandler={() =>
                                 downloadLicencePdf(
                                    userLicence.nextData.expires
                                 )
                              }
                           />
                        )}
                        {hasCurrentLicences && (
                           <CurrentLicences
                              licences={userLicence.currentLicences}
                              expiry={userLicence.currentData.expires}
                              openCardHandler={() => {
                                 closeModals([
                                    currentModalEl.current,
                                    nextModalEl.current,
                                 ]);
                                 openModal(currentModalEl.current);
                              }}
                              downloadCardHandler={() =>
                                 downloadLicencePdf(
                                    userLicence.currentData.expires
                                 )
                              }
                           />
                        )}
                     </div>
                  </div>
                  <br />
                  <br />
               </div>
            </div>
         </Loading>
      </main>
   );
};

Licence.propTypes = {
   userSession: object.isRequired,
   userLicence: object.isRequired,
   licencesLoaded: bool.isRequired,
   contactData: object,
   token: string.isRequired,
   dispatch: func.isRequired,
   unmetCriteria: array.isRequired,
};

const mapStateToProps = state => {
   const isLoaded =
      isFetchingLicenceSuccess(state) &&
      isFetchingUserInfoSuccess('getUserContactInfo', state) &&
      isFetchingRenewalSuccess('getUnmetApplicationCriteria', state);

   return {
      userSession: state.session.user,
      userLicence: getLicenceData(state),
      licencesLoaded: isLoaded,
      contactData: getUserInfoData(state.user),
      token: getToken(state),
      unmetCriteria: getUnmetApplicationCriteria(state.renewal),
      isDownLoaded: isFetchingLicenceDetailsSuccess(state),
      licenceDetails: state.licence.licenceDetails,
   };
};

export default connect(mapStateToProps)(Licence);
