import React, { useEffect, useState } from 'react';
import { func, object, string } from 'prop-types';
import { connect } from 'react-redux';
import M from 'materialize-css';
import Moment from 'moment';
import ExamActions from 'pgdb-data-layer/lib/Redux/ExaminationRedux';
import FileSaver from 'file-saver';
import PaymentActions from 'pgdb-data-layer/lib/Redux/PaymentRedux';
import ReceiptsActions from 'pgdb-data-layer/lib/Redux/ReceiptsRedux';
import { NavLink } from 'react-router-dom';
import Callout from '../../../../Components/Callout';
import TextLink from '../../../../Components/TextLink';
import Icon from '../../../../Components/Icon';
import LoadingPanel from '../../../../Components/LoadingPanel';
import Table from '../../../../Components/GenericTable/Table';
import TableRow from '../../../../Components/GenericTable/TableRow';
import TableCell from '../../../../Components/GenericTable/TableCell';
import TableBody from '../../../../Components/GenericTable/TableBody';
import * as helper from '../../../../Helpers/Helpers';
import { getToken } from '../../../../Session/SessionRedux';
import './ExamConfirmation.scss';

const ConditionLine = ({ description }) => {
   return (
      <li>
         <Icon className="arrow" iconName="arrow_forward" /> {description}
      </li>
   );
};

ConditionLine.propTypes = {
   description: string.isRequired,
};

const Confirmation = ({
   getConfirmationResultsRequest,
   getPaymentResponseRequest,
   getUserReceiptDetailsFromReferenceRequest,
   location,
   payment,
   userReceiptDetails,
   fetchedSuccess,
   confirmationResults,
   token,
}) => {
   const [requestSubmitted, setRequestSubmitted] = useState(false);

   if (!requestSubmitted) {
      const result = new URLSearchParams(location.search).get('result');
      setRequestSubmitted(true);
      getPaymentResponseRequest(result);
   }

   const downloadPDF = () => {
      FileSaver.saveAs(userReceiptDetails, 'Receipt.pdf');
   };

   useEffect(() => {
      if (
         payment.paymentResponse != null &&
         payment.paymentResponse.applicationIds != null
      ) {
         getConfirmationResultsRequest(
            token,
            payment.paymentResponse.referenceNumber
         );
      }

      if (fetchedSuccess) {
         downloadPDF();
      }

      Moment.locale('en');
   }, [payment, userReceiptDetails]);

   const getReceiptDetails = paymentResponse => {
      if (paymentResponse) {
         const paymentReference = paymentResponse.referenceNumber;
         getUserReceiptDetailsFromReferenceRequest(token, paymentReference);
      } else {
         M.toast({
            html: `Sorry there seems to be an issue downloading your receipt at the moment.
            Please contact the Plumbers, Gasfitters and Drainlayers Board.`,
            classes: 'error',
         });
      }
   };

   const renderTotalSection = () => {
      const amountInt = confirmationResults.reduce((cnt, o) => {
         return cnt + o.paid;
      }, 0);
      const total = helper.formatCurrency(amountInt, 2);

      return (
         <Table className="table-head-compressed borderless total-table">
            <TableBody>
               <TableRow className="total-row borderless">
                  <TableCell />
                  <TableCell />
                  <TableCell>Total</TableCell>
                  <TableCell className="total-cell">$ {total}</TableCell>
               </TableRow>
            </TableBody>
         </Table>
      );
   };

   const renderTableData = () => {
      return confirmationResults.map((d, i) => {
         const paid = helper.formatCurrency(d.paid, 2);

         return (
            <tr key={i}>
               <TableCell icon="check">{d.examType}</TableCell>
               <td>
                  <p className="table-item black-text">
                     {Moment(d.examDate).format('DD/MM/YYYY')}
                  </p>
               </td>
               <td>
                  <p className="table-item helvetica-bold">{d.examVenue}</p>
               </td>
               <td>
                  <p className="table-item black-text">$ {paid}</p>
               </td>
            </tr>
         );
      });
   };

   return (
      <>
         {!fetchedSuccess ? (
            <LoadingPanel />
         ) : (
            <main>
               <div className="section no-pad-bot exam-confirmation">
                  <div className="container">
                     <div className="row center-align">
                        <div className="col l10 offset-l1">
                           <div className="">
                              <div className="row">
                                 <h4 className="columnheader left">My Exams</h4>
                              </div>
                              <div className="row">
                                 <p className="sub-header sub-header-small left helvetica-bold black-text">
                                    Payment Complete.
                                 </p>
                              </div>
                              <div className="row">
                                 <p className="sub-header left">
                                    You are now enrolled for the following
                                    Certifying Exams
                                 </p>
                              </div>
                              <div className="row">
                                 <table>
                                    <thead>
                                       <tr>
                                          <th className="grey-text table-heading icon-left-pad">
                                             Exam Type
                                          </th>
                                          <th className="grey-text exam-header table-header ">
                                             Exam Date
                                          </th>
                                          <th className="grey-text exam-header table-header ">
                                             Exam Venue
                                          </th>
                                          <th className="grey-text exam-header table-header ">
                                             Paid
                                          </th>
                                       </tr>
                                    </thead>
                                    <tbody className="offset">
                                       {renderTableData()}
                                    </tbody>
                                 </table>
                                 {renderTotalSection()}
                                 <hr className="thick" />
                              </div>

                              <Callout>
                                 Thank you for your payment.
                                 <br />
                                 We&apos;ve emailed you a receipt but you can
                                 also{' '}
                                 <TextLink
                                    onClick={() =>
                                       getReceiptDetails(
                                          payment.paymentResponse
                                       )
                                    }
                                 >
                                    download it here.
                                 </TextLink>
                              </Callout>

                              <NavLink
                                 to="/exams"
                                 className="btn-small manual-save left"
                              >
                                 Done
                              </NavLink>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </main>
         )}
      </>
   );
};

const mapStateToProps = state => {
   return {
      payment: state.payment,
      confirmationResults: state.examination.confirmationResults,
      userReceiptDetails: state.receipt.receiptDetailsFromReference,
      // fetchedSuccess: isFetchingReceipts('getUserReceiptDetails', state),
      fetchedSuccess:
         state.receipt.async.getUserReceiptDetailsFromReference.success,
      token: getToken(state),
   };
};

Confirmation.propTypes = {
   getPaymentResponseRequest: func.isRequired,
   location: object.isRequired,
};

const mapDispatchToProps = {
   getPaymentResponseRequest: PaymentActions.getPaymentResponseRequest,
   getConfirmationResultsRequest: ExamActions.getConfirmationResultsRequest,
   getUserReceiptDetailsFromReferenceRequest:
      ReceiptsActions.getUserReceiptDetailsFromReferenceRequest,
};

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(Confirmation);
