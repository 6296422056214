import React from 'react';
import Navigator from '../../Navigation/Navigator';

const UnableToPerformOnline = () => {
   return (
      <main>
         <div className="section no-pad-bot" id="index-banner">
            <div className="container">
               <div className="row">
                  <div className="col s12 m12 l7 offset-l1">
                     <div className="row">
                        <div className="col l12">
                           <p className="columnheader">
                              Unable to perform online
                           </p>
                        </div>
                     </div>
                  </div>
                  <div className="col s12 m12 l6 offset-l1">
                     <div className="row">
                        <div className="col l12">
                           <span className="">
                              You cannot perform this action online. Please
                              contact the licensing team on{' '}
                              <a href="tel:0800743262" rel="nofollow">
                                 0800 743 262
                              </a>{' '}
                              for assistance
                           </span>
                        </div>
                     </div>
                  </div>
                  <div className="col s12 m12 l6 offset-l1">
                     <div className="row">
                        <div className="col l12">
                           <br />
                           <br />
                           <button
                              type="button"
                              className="waves-effect waves-light btn"
                              onClick={() => {
                                 Navigator.toDashboard();
                              }}
                           >
                              Home
                           </button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </main>
   );
};

export default UnableToPerformOnline;
