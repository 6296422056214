import React from 'react';

const PaymentSuccessfulFooter = ({ emailAddress, onCheck }) => {
    return (
        <div className="row">
            <div className="col s12 offset-l1">
                <label>
                    <input 
                        type="checkbox" 
                        className="filled-in checkbox-blue" 
                        defaultChecked={true} 
                        onChange={onCheck}
                    />
                    <span className="black-text terms-and-cond">
                        Send updates of the progress of these licences to my email address: 
                        <span className="active-blue">&nbsp;{emailAddress}</span>
                    </span>
                </label>
            </div>
        </div>
    );
};

export default PaymentSuccessfulFooter;