import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import LoadingScreen from 'react-loading-screen';
import * as session from '../../Session';
import Callout from '../../Components/Callout';
import 'url-search-params-polyfill';
import '../style.css';

class SignInCallback extends Component {
   constructor(props, context) {
      super(props, context);
      this.state = { loading: true, error: null };
   }

   async componentDidMount() {
      try {
         await session.signinRedirectCallback();
         this.setState({ loading: false });
      } catch (e) {
         this.setState({ error: e });
      }
   }

   render() {
      const { loading, error } = this.state;
      if (!loading) return <Redirect push to="/dashboard" />;

      if (error) {
         return (
            <main>
               <div className="container error-page-container">
                  <div className="row error-message-body">
                     <div className="col s6 offset-l1">
                        <Callout>{error.message}</Callout>
                     </div>
                  </div>
                  <div className="row controls">
                     <div className="col s12 offset-l1">
                        <button
                           type="button"
                           className="waves-effect waves-light btn btn-ml-30"
                           onClick={() => {
                              window.location.href = window.location.origin;
                           }}
                        >
                           Home
                        </button>
                     </div>
                  </div>
               </div>
            </main>
         );
      }

      return (
         <LoadingScreen
            loading
            bgColor="#F4F4F4"
            textColor="#676767"
            logoSrc="/images/loading.gif"
            text="Logging in..."
         >
            <div />
         </LoadingScreen>
      );
   }
}

export default SignInCallback;
