import React, { Component } from 'react';
import { connect } from 'react-redux';
import BulkRenewalActions from 'pgdb-data-layer/lib/Redux/BulkRenewalRedux';
import ModalDialog from '../../../Components/ModalDialog';
import Navigator from '../../../Navigation/Navigator';
import { getToken } from '../../../Session/SessionRedux';
import './style.scss';

const nextUrl = '/renew-summary';
const bulkPayAndNominate = '/bulk-renew-pay-and-nominate';

class BulkRenewLanding extends Component {
   constructor(props) {
      super(props);
      this.state = {
         isConfirmVisible: false,
      };
   }

   componentDidMount() {
      const { bulkRenewal, token } = this.props;

      // if any licence selected before then show the bulk licence page
      if (
         bulkRenewal &&
         bulkRenewal.selectedApplications &&
         bulkRenewal.selectedApplications.length
      ) {
         this.props.dispatch(BulkRenewalActions.saveBulkDetails(token, {}));
         Navigator.to(bulkPayAndNominate);
      }
   }

   render() {
      return (
         <main>
            <div className="container bulk-renew-container">
               <div className="row">
                  <div className="col l12 offset-l1">
                     <h3 className="columnheader" id="cpd-section-header">
                        Do you want to pay and nominate supervisors for others?
                     </h3>
                  </div>
               </div>
               <div className="row">
                  <div className="col l12 offset-l1 controls">
                     <button
                        type="button"
                        className="waves-effect waves-light btn btn-inactive"
                        onClick={() =>
                           this.setState({ isConfirmVisible: true })
                        }
                     >
                        Yes
                     </button>
                     <button
                        type="button"
                        className="waves-effect waves-light btn"
                        onClick={() => Navigator.to(nextUrl)}
                     >
                        No
                     </button>
                  </div>
               </div>
            </div>
            {this.renderConfirmModel()}
         </main>
      );
   }

   renderConfirmModel() {
      const modelBody = (
         <div className="row confirm-modal-body">
            <div className="col l12">
               <span className="confirm-message">
                  You&apos;ll need the registration numbers of the people you
                  want to pay and nominate supervisors for
               </span>
            </div>
         </div>
      );

      const footer = (
         <div className="row">
            <div className="col l12 confirm-controls">
               <button
                  type="button"
                  className="waves-effect waves-light btn btn-inactive"
                  onClick={() => this.setState({ isConfirmVisible: false })}
               >
                  Back
               </button>
               <button
                  type="button"
                  className="waves-effect waves-light btn"
                  onClick={() => Navigator.to(bulkPayAndNominate)}
               >
                  OK
               </button>
            </div>
         </div>
      );

      return (
         <ModalDialog
            isOpen={this.state.isConfirmVisible}
            body={modelBody}
            footer={footer}
         />
      );
   }
}

const mapDispatchToProps = state => {
   return {
      bulkRenewal: state.bulkRenewal,
      token: getToken(state),
   };
};

export default connect(mapDispatchToProps)(BulkRenewLanding);
